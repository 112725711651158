import { DateTime } from 'luxon';
import React from 'react';
import * as uuid from 'uuid';
import { UserEditDialog } from '../../components/UserManagement/UserEditDialog';

const now = DateTime.now();
const id = uuid.v4();

const GUserEditDialog: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const user: User = {
    id,
    user_id: id,
    meta: {
      created_at: now,
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: now,
    },
    name: 'Test User',
    email: 'testuser@example.com',
  };

  return (
    <>
      <UserEditDialog
        user={user}
        loading={false}
        open={open}
        onClose={() => setOpen(false)}
        onRemove={() => {
          /* nop */
        }}
        onSave={() => {
          /* nop */
        }}
      />
      <button
        className="rounded border border-workorder-medium p-1 text-workorder-medium hover:bg-workorder-light/50"
        onClick={() => setOpen(!open)}
      >
        Open Dialog
      </button>
    </>
  );
};

export default GUserEditDialog;
