import { ApiEntityType } from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { randInt } from '../helpers/math_helpers';
import { tdGetEntityChangesets } from './changesets';
import { tdUsers } from './users';
import { dateFormat } from '../helpers/datetime_helpers';

let entities: ApiEntity[] | undefined;

export const tdEntityTypes: ApiEntityType[] = [
  {
    name: 'stop',
    id: 'stop',
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: 'vl',
  },
];

/** Props from VL. */
export const entityProps: ApiEntity['properties'][] = [
  {
    'meta.name': 'Fallänge',
    'meta.zone': '6370',
    'meta.class': '',
    'meta.location.x': 1523976,
    'meta.location.y': 6655784,
    'meta.location.longitude': 16.235072663557812,
    'meta.location.latitude': 60.02032191745758,
    'meta.id': '63363-A',
    'meta.group_id': '63363',
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.5': 'Väg/Utfart',
    'inventory.8': 'Ingen',
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': false,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.38': false,
    'inventory.39': false,
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.50': false,
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.52': false,
    'inventory.37': 'Nej',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.4': true,
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.9': 0,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.23': 0,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.40': 'Nej',
    'inventory.51': '0 st',
    'inventory.49': '0 st',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.07633587786259542,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 0,
    'meta.passing_routes': ['461'],
    'inventory.65': [1, 2, 3],
    'inventory.66': ['test1', 'test2', 'test3'],
    'derived.last_inventory': '2020-12-01T00:00:00.000Z',
  },
  {
    'meta.name': 'Fallänge',
    'meta.zone': '6370',
    'meta.class': '',
    'meta.location.x': 1523986,
    'meta.location.y': 6655794,
    'meta.location.longitude': 16.2360726635578,
    'meta.location.latitude': 60.02132191745758,
    'meta.id': '63363-B',
    'meta.group_id': '63363',
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.5': 'Väg/Utfart',
    'inventory.8': 'Ingen',
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': false,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.38': false,
    'inventory.39': false,
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.50': false,
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.52': false,
    'inventory.37': 'Nej',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.9': 0,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.23': 0,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.40': 'Nej',
    'inventory.51': '0 st',
    'inventory.49': '0 st',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.07633587786259542,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 0,
    'meta.passing_routes': ['461'],
  },
  {
    'meta.name': 'Hyttbacken "',
    'meta.zone': '6460',
    'meta.location.latitude': 60.03417666234558,
    'meta.location.longitude': 16.06358704178826,
    'meta.id': '68018-A',
    'meta.group_id': '68018',
    'meta.location.x': 1514407,
    'meta.location.y': 6657278,
    'meta.class': '99',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1962,
    'meta.municipality.name': 'Norberg',
    'meta.location_diff': 14,
    'meta.passing_routes': ['86'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0.045454545454545456,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.05343511450381679,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0.030534351145038167,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Ringvägen Nedre',
    'meta.zone': '6461',
    'meta.location.latitude': 60.00066971738451,
    'meta.location.longitude': 15.81249834704612,
    'meta.id': '64064-B',
    'meta.group_id': '64064',
    'meta.location.x': 1500411,
    'meta.location.y': 6653517,
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.7': 1,
    'inventory.8': 'Granit',
    'inventory.9': 8,
    'inventory.6': 25,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Gång',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': false,
    'inventory.53': 'Skylt delar stolpe med vägskylt.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '99',
    'derived.last_inventory': '2017-09-21T11:53:22.167Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1982,
    'meta.municipality.name': 'Fagersta',
    'meta.location_diff': 12,
    'meta.passing_routes': ['303'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0.4,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 1.0909090909090908,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 4.7272727272727275,
    'meta.avg_passengers_off_last_month_saturdays': 0.8,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0.4444444444444444,
    'meta.avg_passengers_on_last_year_workdays': 0.15648854961832062,
    'meta.avg_passengers_on_last_year_saturdays': 0.019230769230769232,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0.009615384615384616,
    'meta.avg_passengers_off_last_year_workdays': 0.8931297709923665,
    'meta.avg_passengers_off_last_year_saturdays': 0.1346153846153846,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0.0673076923076923,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Tortuna vsk',
    'meta.zone': '6010',
    'meta.location.latitude': 59.69915133221791,
    'meta.location.longitude': 16.647605568750134,
    'meta.id': '60331-A',
    'meta.group_id': '60331',
    'meta.location.x': 1547435,
    'meta.location.y': 6620225,
    'meta.class': '4',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.003816793893129771,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['31'],
  },
  {
    'meta.name': 'Skultuna vsk',
    'meta.zone': '6370',
    'meta.location.latitude': 59.83169638430557,
    'meta.location.longitude': 16.52994233992066,
    'meta.id': '63118-A',
    'meta.group_id': '63118',
    'meta.location.x': 1540649,
    'meta.location.y': 6634913,
    'inventory.6': 15,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.7': 3,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': false,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'Tejbrant Concreta 3',
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '4',
    'derived.last_inventory': '2017-06-29T13:37:27.183Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 158,
    'meta.passing_routes': ['62', '569'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0.2,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0.4090909090909091,
    'meta.avg_passengers_on_last_month_saturdays': 0.4,
    'meta.avg_passengers_on_last_month_sundays': 0.75,
    'meta.avg_passengers_on_last_month_weekends': 0.5555555555555556,
    'meta.avg_passengers_off_last_month_workdays': 0.2727272727272727,
    'meta.avg_passengers_off_last_month_saturdays': 0.2,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0.1111111111111111,
    'meta.avg_passengers_on_last_year_workdays': 0.12595419847328243,
    'meta.avg_passengers_on_last_year_saturdays': 0.057692307692307696,
    'meta.avg_passengers_on_last_year_sundays': 0.038461538461538464,
    'meta.avg_passengers_on_last_year_weekends': 0.04807692307692308,
    'meta.avg_passengers_off_last_year_workdays': 0.06870229007633588,
    'meta.avg_passengers_off_last_year_saturdays': 0.038461538461538464,
    'meta.avg_passengers_off_last_year_sundays': 0.019230769230769232,
    'meta.avg_passengers_off_last_year_weekends': 0.028846153846153848,
    'meta.avg_validations_last_week_workdays': 0.4,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0.4090909090909091,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.015267175572519083,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Härsta',
    'meta.zone': '6370',
    'meta.location.latitude': 59.97367068406359,
    'meta.location.longitude': 16.69739456659196,
    'meta.id': '63189-A',
    'meta.group_id': '63189',
    'meta.location.x': 1549827,
    'meta.location.y': 6650844,
    'meta.class': '4',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 9,
    'meta.passing_routes': ['60'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0.18181818181818182,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0.045454545454545456,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.09541984732824428,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0.04198473282442748,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Kårbo',
    'meta.zone': '6370',
    'meta.location.latitude': 59.964948828112874,
    'meta.location.longitude': 16.28267545907063,
    'meta.id': '63309-B',
    'meta.group_id': '63309',
    'meta.location.x': 1526675,
    'meta.location.y': 6649633,
    'meta.class': '99',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 0,
    'meta.passing_routes': ['4461'],
  },
  {
    'meta.name': 'Sala Resecentrum',
    'meta.zone': '6371',
    'meta.location.latitude': 59.92476347043587,
    'meta.location.longitude': 16.60569223712126,
    'meta.id': '63999-H',
    'meta.group_id': '63999',
    'meta.location.x': 1544772,
    'meta.location.y': 6645330,
    'inventory.1': false,
    'inventory.2': true,
    'inventory.3': 'Avskild',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 12,
    'inventory.7': 4,
    'inventory.8': 'Granit',
    'inventory.9': 9,
    'inventory.10': 'Ja',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'Gång',
    'inventory.15': true,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'TB F 3 Normal',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '1 st',
    'inventory.48': '2 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.53':
      'Pendlarparkering bil och cykel fördelas på alla hpl-lägen.',
    'inventory.16': 131,
    'inventory.17': 2,
    'inventory.18': 0,
    'inventory.19': 3,
    'inventory.22': true,
    'inventory.23': 152,
    'inventory.24': false,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',

    'derived.last_inventory': '2017-07-07T09:30:24.203Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1981,
    'meta.municipality.name': 'Sala',
    'meta.location_diff': 2,
    'meta.passing_routes': ['60', '62', '63', '401', '467'],
    'meta.avg_passengers_on_last_week_workdays': 2.2,
    'meta.avg_passengers_on_last_week_saturdays': 2,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 1,
    'meta.avg_passengers_off_last_week_workdays': 7.4,
    'meta.avg_passengers_off_last_week_saturdays': 2,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 1,
    'meta.avg_passengers_on_last_month_workdays': 2.5,
    'meta.avg_passengers_on_last_month_saturdays': 0.6,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0.3333333333333333,
    'meta.avg_passengers_off_last_month_workdays': 4.818181818181818,
    'meta.avg_passengers_off_last_month_saturdays': 1.4,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0.7777777777777778,
    'meta.avg_passengers_on_last_year_workdays': 12.633587786259541,
    'meta.avg_passengers_on_last_year_saturdays': 1.2692307692307692,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0.6346153846153846,
    'meta.avg_passengers_off_last_year_workdays': 36.1412213740458,
    'meta.avg_passengers_off_last_year_saturdays': 1.9807692307692308,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0.9903846153846154,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.011450381679389313,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Hamngatan',
    'meta.zone': '6143',

    'meta.location.x': 1516401,
    'meta.location.y': 6589507,
    'meta.location.longitude': 16.094083812960484,
    'meta.location.latitude': 59.42579809757092,
    'meta.id': '67028-A',
    'meta.group_id': '67028',
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 15,
    'inventory.7': 5,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': true,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 12,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'TB F 3 Normal',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '1 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.53': 'Inventeras om när allt är färdigställt.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.municipality.code': 1960,
    'meta.municipality.name': 'Kungsör',
    'meta.location_diff': 0,
    'meta.passing_routes': ['49', '502'],
    'meta.avg_passengers_on_last_week_workdays': 32.6,
    'meta.avg_passengers_on_last_week_saturdays': 20,
    'meta.avg_passengers_on_last_week_sundays': 12,
    'meta.avg_passengers_on_last_week_weekends': 16,
    'meta.avg_passengers_off_last_week_workdays': 6.2,
    'meta.avg_passengers_off_last_week_saturdays': 11,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 5.5,
    'meta.avg_passengers_on_last_month_workdays': 27.545454545454547,
    'meta.avg_passengers_on_last_month_saturdays': 21.2,
    'meta.avg_passengers_on_last_month_sundays': 6.75,
    'meta.avg_passengers_on_last_month_weekends': 14.777777777777779,
    'meta.avg_passengers_off_last_month_workdays': 4.909090909090909,
    'meta.avg_passengers_off_last_month_saturdays': 5.8,
    'meta.avg_passengers_off_last_month_sundays': 0.25,
    'meta.avg_passengers_off_last_month_weekends': 3.3333333333333335,
    'meta.avg_passengers_on_last_year_workdays': 11.67175572519084,
    'meta.avg_passengers_on_last_year_saturdays': 3.8653846153846154,
    'meta.avg_passengers_on_last_year_sundays': 2.423076923076923,
    'meta.avg_passengers_on_last_year_weekends': 3.144230769230769,
    'meta.avg_passengers_off_last_year_workdays': 4.057251908396947,
    'meta.avg_passengers_off_last_year_saturdays': 1.0576923076923077,
    'meta.avg_passengers_off_last_year_sundays': 0.21153846153846154,
    'meta.avg_passengers_off_last_year_weekends': 0.6346153846153846,
    'meta.avg_validations_last_week_workdays': 28.8,
    'meta.avg_validations_last_week_saturdays': 14,
    'meta.avg_validations_last_week_sundays': 14,
    'meta.avg_validations_last_week_weekends': 14,
    'meta.avg_validations_last_month_workdays': 24.363636363636363,
    'meta.avg_validations_last_month_saturdays': 15.8,
    'meta.avg_validations_last_month_sundays': 6.5,
    'meta.avg_validations_last_month_weekends': 11.666666666666666,
    'meta.avg_validations_last_year_workdays': 10.969465648854962,
    'meta.avg_validations_last_year_saturdays': 2.8846153846153846,
    'meta.avg_validations_last_year_sundays': 2.1153846153846154,
    'meta.avg_validations_last_year_weekends': 2.5,
  },
  {
    'meta.name': 'Gruvbyn',
    'meta.zone': '6460',
    'meta.location.latitude': 60.07171487515909,
    'meta.location.longitude': 15.899060628677386,
    'meta.id': '68005-A',
    'meta.group_id': '68005',
    'meta.location.x': 1505230,
    'meta.location.y': 6661436,
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Väg/Utfart',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.8': 'Ingen',
    'inventory.9': 0,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': false,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': false,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',

    'derived.last_inventory': '2017-07-20T18:19:18.710Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1962,
    'meta.municipality.name': 'Norberg',
    'meta.location_diff': 21,
    'meta.passing_routes': ['87'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Halvarsbenning',
    'meta.zone': '6460',
    'meta.location.latitude': 60.093879996782945,
    'meta.location.longitude': 15.789925094974569,
    'meta.id': '68396-B',
    'meta.group_id': '68396',
    'meta.location.x': 1499154,
    'meta.location.y': 6663902,
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Väg/Utfart',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.8': 'Ingen',
    'inventory.9': 0,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': false,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': false,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',

    'derived.last_inventory': '2017-07-24T06:42:38.011Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1962,
    'meta.municipality.name': 'Norberg',
    'meta.location_diff': 8,
    'meta.passing_routes': ['87'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.13358778625954199,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0.007633587786259542,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.2862595419847328,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Klackberg',
    'meta.zone': '6460',
    'meta.location.latitude': 60.07687778827044,
    'meta.location.longitude': 15.896183126409156,
    'meta.id': '68822-A',
    'meta.group_id': '68822',
    'meta.location.x': 1505069,
    'meta.location.y': 6662011,
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Väg/Utfart',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.8': 'Ingen',
    'inventory.9': 0,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '0 st',
    'inventory.48': '0 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': false,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',

    'derived.last_inventory': '2017-07-20T18:17:50.058Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1962,
    'meta.municipality.name': 'Norberg',
    'meta.location_diff': 8,
    'meta.passing_routes': ['87'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.003816793893129771,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0.10687022900763359,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.003816793893129771,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Klackberg',
    'meta.zone': '6460',
    'meta.location.latitude': 60.07677925270096,
    'meta.location.longitude': 15.895895455126249,
    'meta.id': '68822-B',
    'meta.group_id': '68822',
    'meta.location.x': 1505053,
    'meta.location.y': 6662000,
    'inventory.1': true,
    'inventory.2': false,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Väg/Utfart',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.8': 'Ingen',
    'inventory.9': 0,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': false,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Blå',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '1 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',

    'derived.last_inventory': '2017-07-20T18:18:24.262Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1962,
    'meta.municipality.name': 'Norberg',
    'meta.location_diff': 11,
    'meta.passing_routes': ['87'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.015267175572519083,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0,
    'meta.avg_passengers_on_last_year_weekends': 0,
    'meta.avg_passengers_off_last_year_workdays': 0,
    'meta.avg_passengers_off_last_year_saturdays': 0,
    'meta.avg_passengers_off_last_year_sundays': 0,
    'meta.avg_passengers_off_last_year_weekends': 0,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.0648854961832061,
    'meta.avg_validations_last_year_saturdays': 0,
    'meta.avg_validations_last_year_sundays': 0,
    'meta.avg_validations_last_year_weekends': 0,
  },
  {
    'meta.name': 'Stångjärnsgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.598979039642714,
    'meta.location.longitude': 16.487358998269702,
    'meta.id': '360015-B',
    'meta.group_id': '360015',
    'meta.location.x': 1538527,
    'meta.location.y': 6608962,
    'inventory.6': 17,
    'inventory.7': 3,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Granit',
    'inventory.9': 10,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.53': 'Fickan är smal, buss kan störa trafiken.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:27:06.851Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 108.12595419847328,
    'meta.avg_passengers_on_last_year_saturdays': 41.03846153846154,
    'meta.avg_passengers_on_last_year_sundays': 34.80769230769231,
    'meta.avg_passengers_on_last_year_weekends': 37.92307692307692,
    'meta.avg_passengers_off_last_year_workdays': 6.973282442748092,
    'meta.avg_passengers_off_last_year_saturdays': 3.173076923076923,
    'meta.avg_passengers_off_last_year_sundays': 2.6153846153846154,
    'meta.avg_passengers_off_last_year_weekends': 2.894230769230769,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 115.66412213740458,
    'meta.avg_validations_last_year_saturdays': 43.38461538461539,
    'meta.avg_validations_last_year_sundays': 35.34615384615385,
    'meta.avg_validations_last_year_weekends': 39.36538461538461,
  },
  {
    'meta.name': 'Vårdcentralen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60015901284143,
    'meta.location.longitude': 16.47962658207483,
    'meta.id': '360017-A',
    'meta.group_id': '360017',
    'meta.location.x': 1538089,
    'meta.location.y': 6609089,
    'inventory.6': 18,
    'inventory.7': 2,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.8': 'Granit',
    'inventory.9': 10,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '4 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.53': 'Val och taktila plattor finns bakom plattform på GC.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-05-16T05:37:48.850Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 8.946564885496183,
    'meta.avg_passengers_on_last_year_saturdays': 5.6923076923076925,
    'meta.avg_passengers_on_last_year_sundays': 3.6923076923076925,
    'meta.avg_passengers_on_last_year_weekends': 4.6923076923076925,
    'meta.avg_passengers_off_last_year_workdays': 164.69847328244273,
    'meta.avg_passengers_off_last_year_saturdays': 60.51923076923077,
    'meta.avg_passengers_off_last_year_sundays': 42.90384615384615,
    'meta.avg_passengers_off_last_year_weekends': 51.71153846153846,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 8.259541984732824,
    'meta.avg_validations_last_year_saturdays': 5.230769230769231,
    'meta.avg_validations_last_year_sundays': 3.519230769230769,
    'meta.avg_validations_last_year_weekends': 4.375,
  },
  {
    'meta.name': 'Stjärngatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60586258270805,
    'meta.location.longitude': 16.513055867331648,
    'meta.id': '360156-A',
    'meta.group_id': '360156',
    'meta.location.x': 1539970,
    'meta.location.y': 6609744,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.53': 'Kan inte välja ja på rätt hållplatsnamn.',
    'inventory.5': 'Asfalt',
    'inventory.8': 'Granit',
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'Gång',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.6': 11,
    'inventory.7': 1,
    'inventory.9': 5,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-06-07T05:43:10.573Z',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.9389312977099237,
    'meta.avg_passengers_on_last_year_saturdays': 0.8076923076923077,
    'meta.avg_passengers_on_last_year_sundays': 0.8076923076923077,
    'meta.avg_passengers_on_last_year_weekends': 0.8076923076923077,
    'meta.avg_passengers_off_last_year_workdays': 4.038167938931298,
    'meta.avg_passengers_off_last_year_saturdays': 2.0576923076923075,
    'meta.avg_passengers_off_last_year_sundays': 1.4230769230769231,
    'meta.avg_passengers_off_last_year_weekends': 1.7403846153846154,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 1.015267175572519,
    'meta.avg_validations_last_year_saturdays': 0.8461538461538461,
    'meta.avg_validations_last_year_sundays': 0.9230769230769231,
    'meta.avg_validations_last_year_weekends': 0.8846153846153846,
  },
  {
    'meta.name': 'Vårdcentralen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60026198506049,
    'meta.location.longitude': 16.48054948106277,
    'meta.id': '360017-B',
    'meta.group_id': '360017',
    'meta.location.x': 1538141,
    'meta.location.y': 6609101,
    'inventory.6': 19,
    'inventory.7': 3,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Granit',
    'inventory.9': 11,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'Gång',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 8,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': true,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '1',
    'derived.last_inventory': '2018-01-15T12:22:25.007Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 256.32824427480915,
    'meta.avg_passengers_on_last_year_saturdays': 128.98076923076923,
    'meta.avg_passengers_on_last_year_sundays': 98.0576923076923,
    'meta.avg_passengers_on_last_year_weekends': 113.51923076923077,
    'meta.avg_passengers_off_last_year_workdays': 9.408396946564885,
    'meta.avg_passengers_off_last_year_saturdays': 5.346153846153846,
    'meta.avg_passengers_off_last_year_sundays': 4.4423076923076925,
    'meta.avg_passengers_off_last_year_weekends': 4.894230769230769,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 275.07251908396944,
    'meta.avg_validations_last_year_saturdays': 138.76923076923077,
    'meta.avg_validations_last_year_sundays': 101.42307692307692,
    'meta.avg_validations_last_year_weekends': 120.09615384615384,
  },
  {
    'meta.name': 'Bäckby C',
    'meta.zone': '6011',
    'meta.location.latitude': 59.598592648381405,
    'meta.location.longitude': 16.47523919708746,
    'meta.id': '360018-A',
    'meta.group_id': '360018',
    'meta.location.x': 1537843,
    'meta.location.y': 6608912,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Enkel',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 24,
    'inventory.7': 3,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Nej',
    'inventory.11': 'Delvis',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.32': true,
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'inventory.33': 'CC 3',
    'derived.last_inventory': '2017-05-16T05:38:30.285Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 17.477099236641223,
    'meta.avg_passengers_on_last_year_saturdays': 7,
    'meta.avg_passengers_on_last_year_sundays': 4.9423076923076925,
    'meta.avg_passengers_on_last_year_weekends': 5.971153846153846,
    'meta.avg_passengers_off_last_year_workdays': 110.3473282442748,
    'meta.avg_passengers_off_last_year_saturdays': 45.88461538461539,
    'meta.avg_passengers_off_last_year_sundays': 27.23076923076923,
    'meta.avg_passengers_off_last_year_weekends': 36.55769230769231,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 17.251908396946565,
    'meta.avg_validations_last_year_saturdays': 6.884615384615385,
    'meta.avg_validations_last_year_sundays': 4.884615384615385,
    'meta.avg_validations_last_year_weekends': 5.884615384615385,
  },
  {
    'meta.name': 'Tomtebovägen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60258120422527,
    'meta.location.longitude': 16.52387847706123,
    'meta.id': '360009-B',
    'meta.group_id': '360009',
    'meta.location.x': 1540585,
    'meta.location.y': 6609385,
    'inventory.6': 18,
    'inventory.7': 5,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.50': true,
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '3 st',
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.53': '',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2017-05-16T05:34:02.010Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32', '515'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0.6,
    'meta.avg_passengers_off_last_week_saturdays': 2,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 1,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0.5,
    'meta.avg_passengers_off_last_month_saturdays': 0.8,
    'meta.avg_passengers_off_last_month_sundays': 0.25,
    'meta.avg_passengers_off_last_month_weekends': 0.5555555555555556,
    'meta.avg_passengers_on_last_year_workdays': 47.52671755725191,
    'meta.avg_passengers_on_last_year_saturdays': 24.26923076923077,
    'meta.avg_passengers_on_last_year_sundays': 15.26923076923077,
    'meta.avg_passengers_on_last_year_weekends': 19.76923076923077,
    'meta.avg_passengers_off_last_year_workdays': 9.423664122137405,
    'meta.avg_passengers_off_last_year_saturdays': 4.730769230769231,
    'meta.avg_passengers_off_last_year_sundays': 3.6346153846153846,
    'meta.avg_passengers_off_last_year_weekends': 4.1826923076923075,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 51.030534351145036,
    'meta.avg_validations_last_year_saturdays': 27.21153846153846,
    'meta.avg_validations_last_year_sundays': 16.057692307692307,
    'meta.avg_validations_last_year_weekends': 21.634615384615383,
  },
  {
    'meta.name': 'Sjöhagen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.598916188357215,
    'meta.location.longitude': 16.516115176469246,
    'meta.id': '360010-A',
    'meta.group_id': '360010',
    'meta.location.x': 1540151,
    'meta.location.y': 6608972,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 25,
    'inventory.7': 1,
    'inventory.8': 'Granit',
    'inventory.9': 15,
    'inventory.10': 'Nej',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3 Smal',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'Nej',
    'inventory.36': 'In/Ut',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '1 st',
    'inventory.48': '3 st',
    'inventory.49': '0 st',
    'inventory.50': true,
    'inventory.51': '0 st',
    'inventory.52': false,
    'inventory.53': 'Stor karta på baksida av infotavla.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2017-05-17T05:24:45.428Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32', '515'],
    'meta.avg_passengers_on_last_week_workdays': 5.6,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 2,
    'meta.avg_passengers_on_last_week_weekends': 1,
    'meta.avg_passengers_off_last_week_workdays': 0.2,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 4.363636363636363,
    'meta.avg_passengers_on_last_month_saturdays': 2.6,
    'meta.avg_passengers_on_last_month_sundays': 1.5,
    'meta.avg_passengers_on_last_month_weekends': 2.111111111111111,
    'meta.avg_passengers_off_last_month_workdays': 0.09090909090909091,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 31.51145038167939,
    'meta.avg_passengers_on_last_year_saturdays': 15.673076923076923,
    'meta.avg_passengers_on_last_year_sundays': 9.846153846153847,
    'meta.avg_passengers_on_last_year_weekends': 12.759615384615385,
    'meta.avg_passengers_off_last_year_workdays': 107.72137404580153,
    'meta.avg_passengers_off_last_year_saturdays': 56.57692307692308,
    'meta.avg_passengers_off_last_year_sundays': 36.42307692307692,
    'meta.avg_passengers_off_last_year_weekends': 46.5,
    'meta.avg_validations_last_week_workdays': 6.6,
    'meta.avg_validations_last_week_saturdays': 1,
    'meta.avg_validations_last_week_sundays': 2,
    'meta.avg_validations_last_week_weekends': 1.5,
    'meta.avg_validations_last_month_workdays': 4.5,
    'meta.avg_validations_last_month_saturdays': 3,
    'meta.avg_validations_last_month_sundays': 1.5,
    'meta.avg_validations_last_month_weekends': 2.3333333333333335,
    'meta.avg_validations_last_year_workdays': 31.84351145038168,
    'meta.avg_validations_last_year_saturdays': 15.961538461538462,
    'meta.avg_validations_last_year_sundays': 9.961538461538462,
    'meta.avg_validations_last_year_weekends': 12.961538461538462,
  },
  {
    'meta.name': 'Sjöhagen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59957782252467,
    'meta.location.longitude': 16.518254103980503,
    'meta.id': '360010-B',
    'meta.group_id': '360010',
    'meta.location.x': 1540271,
    'meta.location.y': 6609047,
    'inventory.6': 19,
    'inventory.7': 3,
    'inventory.9': 14,
    'inventory.8': 'Granit',
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 6,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 4',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '3 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': false,
    'inventory.51': '3 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2017-07-05T11:51:46.209Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32', '515'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 5.2,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 2,
    'meta.avg_passengers_off_last_week_weekends': 1,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0.8,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0.4444444444444444,
    'meta.avg_passengers_off_last_month_workdays': 4.2272727272727275,
    'meta.avg_passengers_off_last_month_saturdays': 3,
    'meta.avg_passengers_off_last_month_sundays': 0.75,
    'meta.avg_passengers_off_last_month_weekends': 2,
    'meta.avg_passengers_on_last_year_workdays': 133.07251908396947,
    'meta.avg_passengers_on_last_year_saturdays': 87.98076923076923,
    'meta.avg_passengers_on_last_year_sundays': 54.28846153846154,
    'meta.avg_passengers_on_last_year_weekends': 71.13461538461539,
    'meta.avg_passengers_off_last_year_workdays': 28.99236641221374,
    'meta.avg_passengers_off_last_year_saturdays': 15.557692307692308,
    'meta.avg_passengers_off_last_year_sundays': 12.98076923076923,
    'meta.avg_passengers_off_last_year_weekends': 14.26923076923077,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0.6,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0.3333333333333333,
    'meta.avg_validations_last_year_workdays': 140.5801526717557,
    'meta.avg_validations_last_year_saturdays': 93.9423076923077,
    'meta.avg_validations_last_year_sundays': 55.59615384615385,
    'meta.avg_validations_last_year_weekends': 74.76923076923077,
  },
  {
    'meta.name': 'Liegatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59865316183159,
    'meta.location.longitude': 16.511629590612067,
    'meta.id': '360011-B',
    'meta.group_id': '360011',
    'meta.location.x': 1539898,
    'meta.location.y': 6608940,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 18,
    'inventory.7': 4,
    'inventory.8': 'Granit',
    'inventory.9': 9,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 6,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:27:02.330Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 98.75954198473282,
    'meta.avg_passengers_on_last_year_saturdays': 47.19230769230769,
    'meta.avg_passengers_on_last_year_sundays': 31.153846153846153,
    'meta.avg_passengers_on_last_year_weekends': 39.17307692307692,
    'meta.avg_passengers_off_last_year_workdays': 10.648854961832061,
    'meta.avg_passengers_off_last_year_saturdays': 5.3076923076923075,
    'meta.avg_passengers_off_last_year_sundays': 5.038461538461538,
    'meta.avg_passengers_off_last_year_weekends': 5.173076923076923,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 104.22900763358778,
    'meta.avg_validations_last_year_saturdays': 50.17307692307692,
    'meta.avg_validations_last_year_sundays': 32.5,
    'meta.avg_validations_last_year_weekends': 41.33653846153846,
  },
  {
    'meta.name': 'Liegatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59872706191744,
    'meta.location.longitude': 16.511241569015137,
    'meta.id': '360011-A',
    'meta.group_id': '360011',
    'meta.location.x': 1539876,
    'meta.location.y': 6608948,
    'inventory.6': 18,
    'inventory.7': 3,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.8': 'Granit',
    'inventory.9': 11,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '2 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-05-16T05:35:05.400Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 10.335877862595419,
    'meta.avg_passengers_on_last_year_saturdays': 4.0576923076923075,
    'meta.avg_passengers_on_last_year_sundays': 3.2115384615384617,
    'meta.avg_passengers_on_last_year_weekends': 3.6346153846153846,
    'meta.avg_passengers_off_last_year_workdays': 72.19083969465649,
    'meta.avg_passengers_off_last_year_saturdays': 26.365384615384617,
    'meta.avg_passengers_off_last_year_sundays': 18.23076923076923,
    'meta.avg_passengers_off_last_year_weekends': 22.298076923076923,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 9.48091603053435,
    'meta.avg_validations_last_year_saturdays': 4.096153846153846,
    'meta.avg_validations_last_year_sundays': 3.0384615384615383,
    'meta.avg_validations_last_year_weekends': 3.5673076923076925,
  },
  {
    'meta.name': 'Granbo',
    'meta.zone': '6011',
    'meta.location.latitude': 59.57882628602652,
    'meta.location.longitude': 16.60798363863046,
    'meta.id': '360059-B',
    'meta.group_id': '360059',
    'meta.location.x': 1545366,
    'meta.location.y': 6606793,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Grus',
    'inventory.6': 0,
    'inventory.7': 0,
    'inventory.8': 'Ingen',
    'inventory.9': 0,
    'inventory.10': 'Nej',
    'inventory.11': 'Nej',
    'inventory.12': false,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.53': 'Flexskylt delar stolpe.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2018-01-15T12:23:10.233Z',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 3.5801526717557253,
    'meta.avg_passengers_on_last_year_saturdays': 2.8653846153846154,
    'meta.avg_passengers_on_last_year_sundays': 3.730769230769231,
    'meta.avg_passengers_on_last_year_weekends': 3.298076923076923,
    'meta.avg_passengers_off_last_year_workdays': 0.8015267175572519,
    'meta.avg_passengers_off_last_year_saturdays': 0.23076923076923078,
    'meta.avg_passengers_off_last_year_sundays': 1.1346153846153846,
    'meta.avg_passengers_off_last_year_weekends': 0.6826923076923077,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 3.16412213740458,
    'meta.avg_validations_last_year_saturdays': 2.730769230769231,
    'meta.avg_validations_last_year_sundays': 2.5384615384615383,
    'meta.avg_validations_last_year_weekends': 2.6346153846153846,
  },
  {
    'meta.name': 'Bygatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59651143215437,
    'meta.location.longitude': 16.502554399381566,
    'meta.id': '360013-B',
    'meta.group_id': '360013',
    'meta.location.x': 1539388,
    'meta.location.y': 6608696,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.6': 17,
    'inventory.5': 'Betong',
    'inventory.7': 2,
    'inventory.8': 'Granit',
    'inventory.9': 10,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.53': 'Val och taktila plattor finns bakom plattform på GC ',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:17.990Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 91.62977099236642,
    'meta.avg_passengers_on_last_year_saturdays': 37.73076923076923,
    'meta.avg_passengers_on_last_year_sundays': 27.115384615384617,
    'meta.avg_passengers_on_last_year_weekends': 32.42307692307692,
    'meta.avg_passengers_off_last_year_workdays': 42.48473282442748,
    'meta.avg_passengers_off_last_year_saturdays': 5,
    'meta.avg_passengers_off_last_year_sundays': 5.038461538461538,
    'meta.avg_passengers_off_last_year_weekends': 5.019230769230769,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 96.85496183206106,
    'meta.avg_validations_last_year_saturdays': 40.30769230769231,
    'meta.avg_validations_last_year_sundays': 27.576923076923077,
    'meta.avg_validations_last_year_weekends': 33.94230769230769,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59660000684992,
    'meta.location.longitude': 16.489134657986227,
    'meta.id': '360014-A',
    'meta.group_id': '360014',
    'meta.location.x': 1538630,
    'meta.location.y': 6608698,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.6': 18,
    'inventory.7': 2,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 6,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.53':
      'Fickan är smal, buss kan störa trafiken. Cykelparkering bakom plattform på GC.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-07-20T04:42:22.240Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 14.038167938931299,
    'meta.avg_passengers_on_last_year_saturdays': 10,
    'meta.avg_passengers_on_last_year_sundays': 5.961538461538462,
    'meta.avg_passengers_on_last_year_weekends': 7.980769230769231,
    'meta.avg_passengers_off_last_year_workdays': 74.54961832061069,
    'meta.avg_passengers_off_last_year_saturdays': 45.82692307692308,
    'meta.avg_passengers_off_last_year_sundays': 24.615384615384617,
    'meta.avg_passengers_off_last_year_weekends': 35.22115384615385,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 12.935114503816793,
    'meta.avg_validations_last_year_saturdays': 9.5,
    'meta.avg_validations_last_year_sundays': 5.8076923076923075,
    'meta.avg_validations_last_year_weekends': 7.653846153846154,
  },
  {
    'meta.name': 'Hammarby C',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59769867864582,
    'meta.location.longitude': 16.507165100165853,
    'meta.id': '360012-A',
    'meta.group_id': '360012',
    'meta.location.x': 1539647,
    'meta.location.y': 6608831,
    'inventory.1': false,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.6': 18,
    'inventory.7': 3,
    'inventory.8': 'Granit',
    'inventory.9': 13,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '0 st',
    'inventory.50': false,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.53': 'Val och taktila plattor finns bakom plattform på GC ',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2017-05-16T05:35:11.286Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 22.580152671755727,
    'meta.avg_passengers_on_last_year_saturdays': 7.076923076923077,
    'meta.avg_passengers_on_last_year_sundays': 5.403846153846154,
    'meta.avg_passengers_on_last_year_weekends': 6.240384615384615,
    'meta.avg_passengers_off_last_year_workdays': 103.53435114503817,
    'meta.avg_passengers_off_last_year_saturdays': 42.55769230769231,
    'meta.avg_passengers_off_last_year_sundays': 29.46153846153846,
    'meta.avg_passengers_off_last_year_weekends': 36.00961538461539,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 22.263358778625953,
    'meta.avg_validations_last_year_saturdays': 7.461538461538462,
    'meta.avg_validations_last_year_sundays': 5.4423076923076925,
    'meta.avg_validations_last_year_weekends': 6.451923076923077,
  },
  {
    'meta.name': 'Hammarby C',
    'meta.zone': '6011',
    'meta.location.latitude': 59.597607787042925,
    'meta.location.longitude': 16.507375693752003,
    'meta.id': '360012-B',
    'meta.group_id': '360012',
    'meta.location.x': 1539659,
    'meta.location.y': 6608821,
    'inventory.1': false,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.6': 19,
    'inventory.7': 5,
    'inventory.8': 'Granit',
    'inventory.9': 10,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 6,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': true,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.53': 'Cykelparkering finns vid anslutning till GC ',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:57.838Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1', '32'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 159.67175572519085,
    'meta.avg_passengers_on_last_year_saturdays': 84.73076923076923,
    'meta.avg_passengers_on_last_year_sundays': 55.44230769230769,
    'meta.avg_passengers_on_last_year_weekends': 70.08653846153847,
    'meta.avg_passengers_off_last_year_workdays': 18.484732824427482,
    'meta.avg_passengers_off_last_year_saturdays': 10.76923076923077,
    'meta.avg_passengers_off_last_year_sundays': 8.326923076923077,
    'meta.avg_passengers_off_last_year_weekends': 9.548076923076923,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 169.75954198473283,
    'meta.avg_validations_last_year_saturdays': 90.82692307692308,
    'meta.avg_validations_last_year_sundays': 58.30769230769231,
    'meta.avg_validations_last_year_weekends': 74.5673076923077,
  },
  {
    'meta.name': 'Vetterslund',
    'meta.zone': '6011',
    'meta.location.latitude': 59.61165274644473,
    'meta.location.longitude': 16.50630422918085,
    'meta.id': '360158-A',
    'meta.group_id': '360158',
    'meta.location.x': 1539582,
    'meta.location.y': 6610385,
    'inventory.6': 11,
    'inventory.7': 1,
    'inventory.8': 'Granit',
    'inventory.9': 9,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.53': 'Skylt delvis skymd av träd.',
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.14': 'Körbana',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-06-07T05:44:21.783Z',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.019083969465648856,
    'meta.avg_passengers_on_last_year_saturdays': 0,
    'meta.avg_passengers_on_last_year_sundays': 0.09615384615384616,
    'meta.avg_passengers_on_last_year_weekends': 0.04807692307692308,
    'meta.avg_passengers_off_last_year_workdays': 0.0648854961832061,
    'meta.avg_passengers_off_last_year_saturdays': 0.019230769230769232,
    'meta.avg_passengers_off_last_year_sundays': 0.11538461538461539,
    'meta.avg_passengers_off_last_year_weekends': 0.0673076923076923,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.8282442748091603,
    'meta.avg_validations_last_year_saturdays': 0.3076923076923077,
    'meta.avg_validations_last_year_sundays': 0.5961538461538461,
    'meta.avg_validations_last_year_weekends': 0.4519230769230769,
  },
  {
    'meta.name': 'Välljärnsgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60110765653758,
    'meta.location.longitude': 16.483665474619528,
    'meta.id': '360016-A',
    'meta.group_id': '360016',
    'meta.location.x': 1538316,
    'meta.location.y': 6609197,
    'inventory.7': 2,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.40': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '4 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.53':
      'Val och taktila plattor finns bakom plattform på GC. Flexskylt sitter på stolpe.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-05-16T05:37:29.937Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 10.858778625954198,
    'meta.avg_passengers_on_last_year_saturdays': 5.019230769230769,
    'meta.avg_passengers_on_last_year_sundays': 3.730769230769231,
    'meta.avg_passengers_on_last_year_weekends': 4.375,
    'meta.avg_passengers_off_last_year_workdays': 125.79007633587786,
    'meta.avg_passengers_off_last_year_saturdays': 52.34615384615385,
    'meta.avg_passengers_off_last_year_sundays': 34.01923076923077,
    'meta.avg_passengers_off_last_year_weekends': 43.18269230769231,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 10.13740458015267,
    'meta.avg_validations_last_year_saturdays': 4.6923076923076925,
    'meta.avg_validations_last_year_sundays': 3.519230769230769,
    'meta.avg_validations_last_year_weekends': 4.105769230769231,
  },
  {
    'meta.name': 'Djuphamnsvägen',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60137911343937,
    'meta.location.longitude': 16.520452705518736,
    'meta.id': '360153-A',
    'meta.group_id': '360153',
    'meta.location.x': 1540393,
    'meta.location.y': 6609249,
    'inventory.6': 11,
    'inventory.7': 1,
    'inventory.8': 'Granit',
    'inventory.9': 10,
    'inventory.53':
      'Ledstråk och taktila plattor ligger inte i fas med den raka delen av plattformen.',
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'Gång',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-06-07T05:47:29.740Z',
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 0.2824427480916031,
    'meta.avg_passengers_on_last_year_saturdays': 0.25,
    'meta.avg_passengers_on_last_year_sundays': 0.25,
    'meta.avg_passengers_on_last_year_weekends': 0.25,
    'meta.avg_passengers_off_last_year_workdays': 1.0190839694656488,
    'meta.avg_passengers_off_last_year_saturdays': 1.2307692307692308,
    'meta.avg_passengers_off_last_year_sundays': 0.5384615384615384,
    'meta.avg_passengers_off_last_year_weekends': 0.8846153846153846,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 0.2862595419847328,
    'meta.avg_validations_last_year_saturdays': 0.2692307692307692,
    'meta.avg_validations_last_year_sundays': 0.3076923076923077,
    'meta.avg_validations_last_year_weekends': 0.28846153846153844,
  },
  {
    'meta.name': 'Välljärnsgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.60121124175138,
    'meta.location.longitude': 16.484464461951863,
    'meta.id': '360016-B',
    'meta.group_id': '360016',
    'meta.location.x': 1538361,
    'meta.location.y': 6609209,
    'inventory.6': 16,
    'inventory.7': 3,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Körbana',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Granit',
    'inventory.9': 13,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'Gång',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': true,
    'inventory.23': 8,
    'inventory.24': true,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '2 st',
    'inventory.50': true,
    'inventory.51': '2 st',
    'inventory.52': true,
    'inventory.53': 'Val och taktila plattor finns bakom plattform på G.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:23:40.999Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 143.07251908396947,
    'meta.avg_passengers_on_last_year_saturdays': 73.17307692307692,
    'meta.avg_passengers_on_last_year_sundays': 53.42307692307692,
    'meta.avg_passengers_on_last_year_weekends': 63.29807692307692,
    'meta.avg_passengers_off_last_year_workdays': 11.48091603053435,
    'meta.avg_passengers_off_last_year_saturdays': 6.403846153846154,
    'meta.avg_passengers_off_last_year_sundays': 5.769230769230769,
    'meta.avg_passengers_off_last_year_weekends': 6.086538461538462,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 152.206106870229,
    'meta.avg_validations_last_year_saturdays': 78.4423076923077,
    'meta.avg_validations_last_year_sundays': 54.63461538461539,
    'meta.avg_validations_last_year_weekends': 66.53846153846153,
  },
  {
    'meta.name': 'Stångjärnsgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.59842840163026,
    'meta.location.longitude': 16.487949916456625,
    'meta.id': '360015-A',
    'meta.group_id': '360015',
    'meta.location.x': 1538561,
    'meta.location.y': 6608901,
    'inventory.7': 2,
    'inventory.6': 18,
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Asfalt',
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': true,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': false,
    'inventory.29': true,
    'inventory.30': 'Stolpe',
    'inventory.31': 'Svart',
    'inventory.32': false,
    'inventory.34': 'Nej',
    'inventory.35': 'Nej',
    'inventory.36': 'Nej',
    'inventory.37': 'Nej',
    'inventory.38': false,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': false,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '2 st',
    'inventory.47': '0 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': false,
    'inventory.51': '0 st',
    'inventory.52': true,
    'inventory.53': 'Fickan är smal, buss kan störa trafiken.',
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '3',
    'derived.last_inventory': '2017-05-16T05:37:11.341Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 4.343511450381679,
    'meta.avg_passengers_on_last_year_saturdays': 1.4230769230769231,
    'meta.avg_passengers_on_last_year_sundays': 1.2884615384615385,
    'meta.avg_passengers_on_last_year_weekends': 1.3557692307692308,
    'meta.avg_passengers_off_last_year_workdays': 81.30534351145039,
    'meta.avg_passengers_off_last_year_saturdays': 22.26923076923077,
    'meta.avg_passengers_off_last_year_sundays': 21.98076923076923,
    'meta.avg_passengers_off_last_year_weekends': 22.125,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 3.66412213740458,
    'meta.avg_validations_last_year_saturdays': 1.2884615384615385,
    'meta.avg_validations_last_year_sundays': 1.1346153846153846,
    'meta.avg_validations_last_year_weekends': 1.2115384615384615,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
  {
    'meta.name': 'Stålverksgatan',
    'meta.zone': '6011',
    'meta.location.latitude': 59.595529887537005,
    'meta.location.longitude': 16.48950249177136,
    'meta.id': '360014-B',
    'meta.group_id': '360014',
    'meta.location.x': 1538652,
    'meta.location.y': 6608579,
    'inventory.7': 3,
    'inventory.6': 17,
    'inventory.1': true,
    'inventory.2': true,
    'inventory.3': 'Ficka',
    'inventory.4': true,
    'inventory.5': 'Betong',
    'inventory.8': 'Hållplatsstöd',
    'inventory.9': 17,
    'inventory.10': 'Delvis',
    'inventory.11': 'Ja',
    'inventory.12': true,
    'inventory.13': false,
    'inventory.14': 'GC',
    'inventory.15': false,
    'inventory.16': 0,
    'inventory.17': 0,
    'inventory.18': 0,
    'inventory.19': 0,
    'inventory.20': false,
    'inventory.21': false,
    'inventory.22': false,
    'inventory.23': 0,
    'inventory.24': false,
    'inventory.25': false,
    'inventory.26': false,
    'inventory.27': true,
    'inventory.28': true,
    'inventory.29': true,
    'inventory.30': 'Ingen',
    'inventory.31': 'Nej',
    'inventory.32': true,
    'inventory.33': 'CC 3',
    'inventory.34': 'Elanslutning',
    'inventory.35': 'In/Ut',
    'inventory.36': 'Nej',
    'inventory.37': 'Fast',
    'inventory.38': true,
    'inventory.39': false,
    'inventory.40': 'Nej',
    'inventory.41': true,
    'inventory.42': false,
    'inventory.43': false,
    'inventory.44': false,
    'inventory.45': false,
    'inventory.46': '0 st',
    'inventory.47': '2 st',
    'inventory.48': '1 st',
    'inventory.49': '1 st',
    'inventory.50': true,
    'inventory.51': '1 st',
    'inventory.52': true,
    'inventory.61': '08fc9440-ea5e-43dd-a494-a6231fdb4a1b',
    'inventory.63': '210b1a5a-3af0-4a6b-90f2-cc34c69a2285',
    'inventory.62': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'inventory.64': '23b1d14e-c0b0-40ac-aa5c-a5867579fa7d',
    'meta.class': '2',
    'derived.last_inventory': '2018-01-15T12:26:52.939Z',
    'meta.county.code': 19,
    'meta.county.name': 'Västmanlands län',
    'meta.municipality.code': 1980,
    'meta.municipality.name': 'Västerås',
    'meta.location_diff': 0,
    'meta.passing_routes': ['1'],
    'meta.avg_passengers_on_last_week_workdays': 0,
    'meta.avg_passengers_on_last_week_saturdays': 0,
    'meta.avg_passengers_on_last_week_sundays': 0,
    'meta.avg_passengers_on_last_week_weekends': 0,
    'meta.avg_passengers_off_last_week_workdays': 0,
    'meta.avg_passengers_off_last_week_saturdays': 0,
    'meta.avg_passengers_off_last_week_sundays': 0,
    'meta.avg_passengers_off_last_week_weekends': 0,
    'meta.avg_passengers_on_last_month_workdays': 0,
    'meta.avg_passengers_on_last_month_saturdays': 0,
    'meta.avg_passengers_on_last_month_sundays': 0,
    'meta.avg_passengers_on_last_month_weekends': 0,
    'meta.avg_passengers_off_last_month_workdays': 0,
    'meta.avg_passengers_off_last_month_saturdays': 0,
    'meta.avg_passengers_off_last_month_sundays': 0,
    'meta.avg_passengers_off_last_month_weekends': 0,
    'meta.avg_passengers_on_last_year_workdays': 68.45419847328245,
    'meta.avg_passengers_on_last_year_saturdays': 52.53846153846154,
    'meta.avg_passengers_on_last_year_sundays': 30.923076923076923,
    'meta.avg_passengers_on_last_year_weekends': 41.73076923076923,
    'meta.avg_passengers_off_last_year_workdays': 11.721374045801527,
    'meta.avg_passengers_off_last_year_saturdays': 10.23076923076923,
    'meta.avg_passengers_off_last_year_sundays': 7,
    'meta.avg_passengers_off_last_year_weekends': 8.615384615384615,
    'meta.avg_validations_last_week_workdays': 0,
    'meta.avg_validations_last_week_saturdays': 0,
    'meta.avg_validations_last_week_sundays': 0,
    'meta.avg_validations_last_week_weekends': 0,
    'meta.avg_validations_last_month_workdays': 0,
    'meta.avg_validations_last_month_saturdays': 0,
    'meta.avg_validations_last_month_sundays': 0,
    'meta.avg_validations_last_month_weekends': 0,
    'meta.avg_validations_last_year_workdays': 72.12213740458016,
    'meta.avg_validations_last_year_saturdays': 54.80769230769231,
    'meta.avg_validations_last_year_sundays': 31.346153846153847,
    'meta.avg_validations_last_year_weekends': 43.07692307692308,
  },
];

export const tdEntityIds: string[] = entityProps.map(() => v4());

const checkinDates: string[] = [
  DateTime.fromFormat('2021-08-03', dateFormat).toISO(),
  DateTime.fromFormat('2020-07-20', dateFormat).toISO(),
  DateTime.fromFormat('2021-04-20', dateFormat).toISO(),
  DateTime.fromFormat('2021-04-21', dateFormat).toISO(),
  DateTime.fromFormat('2021-04-21', dateFormat).toISO(),
  DateTime.fromFormat('2021-04-20', dateFormat).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 2 }).toISO(),
  DateTime.now().minus({ days: 3 }).toISO(),
  DateTime.now().minus({ days: 3 }).toISO(),
  DateTime.now().minus({ days: 3 }).toISO(),
  DateTime.now().minus({ days: 3 }).toISO(),
  DateTime.now().minus({ days: 3 }).toISO(),
  DateTime.now().minus({ years: 1 }).toISO(),
  DateTime.now().minus({ years: 1 }).toISO(),
  DateTime.now().minus({ years: 1 }).toISO(),
];

const tdGetEntities = (): ApiEntity[] => {
  if (!entities) {
    entities = entityProps.map<ApiEntity>((props, i) => {
      const new_props = {
        ...props,
      };
      if (props['meta.location.longitude'] && props['meta.location.latitude']) {
        new_props['meta.location'] = [
          props['meta.location.longitude'] as number,
          props['meta.location.latitude'] as number,
        ];
      }
      const changesets = tdGetEntityChangesets(tdEntityIds[i], new_props);
      const ApiEntity: ApiEntity = {
        id: tdEntityIds[i],
        tenant_id: 'vl',
        properties: new_props,
        derived_properties: {},
        meta: {
          created_at: DateTime.now().toISO(),
          created_by: 'auth0|6172bc995cd96b0070339cc7',
          updated_at: DateTime.now().toISO(),
        },
        entity_type_id: 'stop',
        source_id: props['meta.id'] as string,
        changeset_head: changesets
          ? changesets[changesets.length - 1].id
          : undefined,
        checkins: {
          admin: Array(randInt(0, 2))
            .fill(null)
            .map(() => ({
              timestamp: checkinDates[randInt(0, checkinDates.length)],
              user_id: tdUsers[randInt(0, tdUsers.length)].user_id,
            })),
          contractor: Array(randInt(0, 6))
            .fill(null)
            .map(() => ({
              timestamp: checkinDates[randInt(0, checkinDates.length)],
              user_id: tdUsers[randInt(0, tdUsers.length)].user_id,
            })),
        },
      };

      return ApiEntity;
    });
  }
  return entities;
};

export { tdGetEntities };
