import { defineMessages } from '@formatjs/intl';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { MapMarker } from '../components/Map';
import { MapData, MapDataUpdate, MarkerData } from '../store/map';
import { filterMapMarkerFilenames, unmatchedMapMarkerFilename } from './colors';

const messages = defineMessages({
  stop: {
    id: 'stop',
    defaultMessage: 'Stop',
  },
  station: {
    id: 'station',
    defaultMessage: 'Station',
  },
  work_order: {
    id: 'work_order',
    defaultMessage: 'Work order',
  },
  inactive: {
    id: 'inactive',
    default: 'Inactive',
  },
});

export const locationPropertyByTenant = (tenant: string): string => {
  switch (tenant) {
    case 'region-vastmanland':
      return 'meta.location';
    default:
      return 'inventory.location';
  }
};

export type MarkerEventCB = (
  eventType: MarkerEventType,
  markerData: MarkerData,
  mousePos?:
    | {
        x: number;
        y: number;
      }
    | undefined,
) => void;
export type MarkerEventType = 'click' | 'enter' | 'leave';

const createEventCB = (
  type: MarkerEventType,
  cb: MarkerEventCB,
  markerData: MarkerData,
): ((
  markerInstance: google.maps.Marker,
  mouseEvent: google.maps.MapMouseEvent,
) => void) => {
  //TODO: Use MouseEvent to get latlng and convert to screenX/Y.
  // return (markerInstance, mouseEvent) => cb(type, markerData, { x: 0, y: 0 });
  return () => cb(type, markerData, { x: 0, y: 0 });
};

export const getCoordinatesFromEntities = (
  tenant: string,
  entities: Entity[],
): Record<string, [number, number]> => {
  return entities.reduce<Record<string, [number, number]>>(
    (coordByEntityID, e) => {
      const location =
        (e.properties[locationPropertyByTenant(tenant)] as [number, number]) ??
        (e.properties['meta.location'] as [number, number]);
      coordByEntityID[e.id] = location;
      return coordByEntityID;
    },
    {},
  );
};

export const getCoordinatesFromWorkorderChangesets = (
  workorder?: WorkOrder,
): Record<string, [number, number]> => {
  return !workorder
    ? {}
    : Object.values(workorder.entity_changesets)
        .filter((cs) => {
          return (
            !!cs.properties['meta.location'] ||
            !!cs.properties['inventory.location']
          );
        })
        .reduce<Record<string, [number, number]>>((coordByEntityID, cs) => {
          const location =
            (cs.properties['inventory.location'] as [number, number]) ??
            (cs.properties['meta.location'] as [number, number]);
          coordByEntityID[cs.entity_id] = location;
          return coordByEntityID;
        }, {});
};

export const updateMarkers = (
  map: google.maps.Map,
  newData: MapData['markers'],
  staticMarkerRefs: Record<string, MapMarker>,
  markerEventCB: MarkerEventCB,
): void => {
  //Loop all markers in store and update markers if necessary.
  Object.values(newData).forEach((newMarkerData) => {
    const existing = staticMarkerRefs[newMarkerData.id];
    if (!existing) {
      //Marker is new, never been added to map.
      const style = {
        ...newMarkerData.style,
        position: newMarkerData.position,
        title: newMarkerData.title,
      };
      const newMarker = new google.maps.Marker(style);
      newMarker.set('id', newMarkerData.id);
      newMarker.setMap(map);
      //No, it's not really deprecated?
      newMarker.addListener(
        'click',
        createEventCB('click', markerEventCB, newMarkerData),
      );
      newMarker.addListener(
        'mouseover',
        createEventCB('enter', markerEventCB, newMarkerData),
      );
      newMarker.addListener(
        'mouseout',
        createEventCB('leave', markerEventCB, newMarkerData),
      );
      staticMarkerRefs[newMarkerData.id] = {
        markerData: newMarkerData,
        gmapsMarker: newMarker,
      };
      return;
    }
    if (existing.markerData !== newMarkerData) {
      //Marker has changed.
      const style = {
        ...newMarkerData.style,
        position: newMarkerData.position,
      };
      existing.gmapsMarker.setOptions(style);
      staticMarkerRefs[newMarkerData.id] = {
        ...existing,
        markerData: newMarkerData,
      };
      return;
    }
    //Unchanged
  });

  //Loop all existing markers and remove any that are no longer in store.
  Object.entries(staticMarkerRefs).forEach(([markerId, mapMarker]) => {
    if (!newData[markerId]) {
      //This marker no longer exist in store. Remove it.
      mapMarker.gmapsMarker.unbindAll();
      mapMarker.gmapsMarker.setMap(null);
      delete staticMarkerRefs[markerId];
    }
  });

  return;
};

const iconUrls = filterMapMarkerFilenames.map(
  (name) => `/map_icons/${name}.png`,
);

const iconUrlsInactive = filterMapMarkerFilenames.map(
  (name) => `/map_icons/${name}-inactive.png`,
);

const unmatchedIconUrl = `/map_icons/${unmatchedMapMarkerFilename}.png`;
const unmatchedInactiveIconUrl = `/map_icons/${unmatchedMapMarkerFilename}-inactive.png`;

const workOrderTimeIcons = {
  inactive: `/map_icons/inactive.png`,
  good: `/map_icons/wo_ok.png`,
  warning: `/map_icons/wo_warning.png`,
  final: `/map_icons/wo_final.png`,
  bad: `/map_icons/bad.png`,
};

export const mapMarkersFromWorkOrders = (
  workOrders: WorkOrder[],
  visibleWorkOrderIds: string[],
  entityLookup: Record<string, Entity>,
  intl: IntlShape,
  existingMarkers: Record<string, MarkerData> = {},
  deleteUnusedExisting = true,
): Record<string, MarkerData> => {
  const markers: Record<string, MarkerData> = {};

  for (const wo of workOrders) {
    const daysRemaining =
      wo.due_at && Math.floor(wo.due_at.diffNow('days').days);
    let iconUrl = workOrderTimeIcons.good;
    if (wo.state === 'completed') {
      iconUrl = workOrderTimeIcons.good;
    } else if (wo.state === 'cancelled') {
      iconUrl = workOrderTimeIcons.bad;
    } else if (wo.state === 'approved') {
      iconUrl = workOrderTimeIcons.final;
    } else if (
      typeof daysRemaining === 'number' &&
      daysRemaining <= 3 &&
      daysRemaining >= 0
    ) {
      iconUrl = workOrderTimeIcons.warning;
    } else if (typeof daysRemaining === 'number' && daysRemaining < 0) {
      iconUrl = workOrderTimeIcons.bad;
    }
    for (const entityId of wo.entities) {
      const entity = entityLookup[entityId];
      if (!entity) {
        continue;
      }
      const id = wo.id + ':' + entityId;
      const existing = existingMarkers[id];
      const [lng, lat] =
        (entity.properties['inventory.location'] as [number, number]) ??
        (entity.properties['meta.location'] as [number, number]);
      if (
        !existing ||
        existing.style.icon !== iconUrl ||
        existing.position.lat !== lat ||
        existing.position.lng !== lng ||
        existing.style.visible !== visibleWorkOrderIds.includes(wo.id)
      ) {
        //No existing marker or the wo has changed after the
        //marker was created, update marker data.
        markers[id] = {
          style: {
            icon: iconUrl,
            draggable: false,
            clickable: true,
            visible: visibleWorkOrderIds.includes(wo.id),
          },
          id: id,
          type: 'workorder',
          updated_at: DateTime.now(),
          title: `${intl.formatMessage(messages.stop)}: ${
            entity.full_name
          }\n${intl.formatMessage(messages.work_order)}: ${wo.title}#${
            wo.title_suffix
          }`,
          position: {
            lat,
            lng,
          },
        };
        continue;
      } else if (existing) {
        //The existing marker data is up to date, pass it along untouched.
        markers[id] = existing;
        continue;
      }
    }
  }

  if (!deleteUnusedExisting) {
    Object.values(existingMarkers).forEach((existing) => {
      if (!markers[existing.id]) {
        markers[existing.id] =
          existing.style.visible === true
            ? {
                ...existing,
                style: {
                  ...existing.style,
                  visible: false,
                },
              }
            : existing;
      }
    });
  }

  return markers;
};

export const mapMarkersFromEntities = (
  intl: IntlShape,
  tenant: string,
  entities: Entity[],
  entityCoords: Record<string, [number, number]>,
  workorderCoords: Record<string, [number, number]>,
  existingMarkers: Record<string, MarkerData>,
  showStopLetter = true,
  deleteUnusedExisting = false,
): Record<string, MarkerData> => {
  const markers: Record<string, MarkerData> = {};

  for (const entity of entities) {
    const isInactive = entity.properties['meta.active'] === false;
    const entityMarkerID = entity.id;
    const workOrderMarkerID = entity.id + ':workorder';
    const existingEntityMarker = existingMarkers[entityMarkerID];
    const existingWorkOrderMarker = existingMarkers[workOrderMarkerID];
    const [lng, lat] = entityCoords[entityMarkerID]
      ? [entityCoords[entityMarkerID][0], entityCoords[entityMarkerID][1]]
      : (entity.properties[locationPropertyByTenant(tenant)] as [
          number,
          number,
        ]) ?? (entity.properties['meta.location'] as [number, number]);
    if (!workorderCoords[entity.id]) {
      if (
        !existingEntityMarker ||
        existingEntityMarker.position.lat !== lat ||
        existingEntityMarker.position.lng !== lng
      ) {
        //No existing marker or the wo has changed after the
        //marker was created, update marker data.
        markers[entityMarkerID] = {
          style: {
            icon: {
              url: isInactive
                ? workOrderTimeIcons.inactive
                : workOrderTimeIcons.warning,
            },
            draggable: false,
            clickable: true,
            visible: true,
            label: showStopLetter ? entity.stop_letter : undefined,
          },
          id: entityMarkerID,
          type: entity.entity_type_id === 'station' ? 'entityGroup' : 'entity',
          updated_at: DateTime.now(),
          title: isInactive
            ? `${entity.full_name} [${intl.formatMessage(messages.inactive)}]`
            : entity.full_name,
          position: {
            lat,
            lng,
          },
        };
      } else if (existingEntityMarker) {
        //The existing marker data is up to date, pass it along untouched.
        markers[entityMarkerID] = existingEntityMarker;
      }
    } else {
      const [wlng, wlat] = [
        workorderCoords[entityMarkerID][0],
        workorderCoords[entityMarkerID][1],
      ];
      if (
        !existingWorkOrderMarker ||
        existingWorkOrderMarker.position.lat !== wlat ||
        existingWorkOrderMarker.position.lng !== wlng
      ) {
        //No existing marker or the wo has changed after the
        //marker was created, update marker data.
        markers[workOrderMarkerID] = {
          style: {
            icon: {
              url: isInactive
                ? workOrderTimeIcons.inactive
                : workOrderTimeIcons.warning,
            },
            draggable: false,
            clickable: true,
            visible: true,
            label: showStopLetter ? entity.stop_letter : undefined,
          },
          id: workOrderMarkerID,
          type: entity.entity_type_id === 'station' ? 'entityGroup' : 'entity',
          updated_at: DateTime.now(),
          title: entity.full_name,
          position: {
            lat: wlat,
            lng: wlng,
          },
        };
      } else if (existingWorkOrderMarker) {
        //The existing marker data is up to date, pass it along untouched.
        markers[workOrderMarkerID] = existingWorkOrderMarker;
      }
    }
  }

  if (!deleteUnusedExisting) {
    Object.values(existingMarkers).forEach((existing) => {
      if (!markers[existing.id]) {
        markers[existing.id] =
          existing.style.visible === true
            ? {
                ...existing,
                style: {
                  ...existing.style,
                  visible: false,
                },
              }
            : existing;
      }
    });
  }

  return markers;
};

export const mapDataFromFilterResults = (
  intl: IntlShape,
  tenant: string,
  results: ExtendedFilterQueryResults,
  mapId: string,
  existingMarkers: Record<string, MarkerData> = {},
  deleteUnusedExisting = false,
): MapDataUpdate => {
  const markers: Record<string, MarkerData> = {};

  const lastActiveFilterIndex = results.steps.findLastIndex(
    (s) => s.filter.active,
  );
  const firstActiveFilterIndex = results.steps.findIndex(
    (s) => s.filter.active,
  );

  const resultsToShow = [
    {
      entities: results.final,
      filterIndex: lastActiveFilterIndex,
    },
  ];
  if (results.showUnmatched && results.steps.length) {
    results.steps.forEach((step, i) => {
      if (!step.filter.active) {
        return;
      }
      if (i === firstActiveFilterIndex) {
        resultsToShow.push({ filterIndex: -1, entities: step.unmatched });
      }
      resultsToShow.push({
        filterIndex: i,
        entities: step.matched,
      });
    });
  }

  resultsToShow.forEach(({ entities, filterIndex }) => {
    for (const e of entities) {
      const existing = existingMarkers[e.id];
      const isInactive = e.properties['meta.active'] === false;
      const iconUrl =
        filterIndex === -1
          ? isInactive
            ? unmatchedInactiveIconUrl
            : unmatchedIconUrl
          : isInactive
          ? iconUrlsInactive[filterIndex]
          : iconUrls[filterIndex];
      if (
        !existing ||
        existing.style.icon !== iconUrl ||
        existing.style.visible === false
      ) {
        const loc =
          (e.properties[locationPropertyByTenant(tenant)] as [
            number,
            number,
          ]) ?? (e.properties['meta.location'] as [number, number]);
        const lng = loc[0];
        const lat = loc[1];
        markers[e.id] = {
          id: e.id,
          type: 'entity',
          title: isInactive
            ? `${e.full_name} [${intl.formatMessage(messages.inactive)}]`
            : e.full_name,
          position: {
            lng,
            lat,
          },
          style: {
            opacity: 1,
            icon: iconUrl,
            draggable: false,
            clickable: true,
            visible: true,
            zIndex: 10000 + filterIndex,
          },
          updated_at: DateTime.now(),
        };
      } else if (existing) {
        //Marker has not changed.
        markers[e.id] = existing;
        markers[e.id].style.zIndex = 10000 + filterIndex;
      }
    }
  });
  if (!deleteUnusedExisting) {
    Object.values(existingMarkers).forEach((existing) => {
      if (!markers[existing.id]) {
        markers[existing.id] = {
          ...existing,
          style: {
            ...existing.style,
            visible: false,
          },
        };
      }
    });
  }

  return {
    id: mapId,
    markers,
  };
};
