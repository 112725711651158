import React, { useCallback } from 'react';
import {
  Box,
  Button,
  ListItem,
  Popover,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';
import { ExitToApp, Replay } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import useUserStore from '../../store/user';
import { useUserProfiles } from '../../hooks/useUserProfiles';
import { Person } from '../../icons';
import { Projection, projectionsWithLabels } from '../../helpers/math_helpers';
import useOrganizations from '../../hooks/useOrganizations';
import { Locale, localeNames, useLocale } from '../../store/locale';

const locales = Object.keys(localeNames).sort((a, b) =>
  a.localeCompare(b, 'sv'),
) as Locale[];

const UserMenu: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { lookup: orgs } = useOrganizations();
  const { user, orgId } = useUserStore();
  const {
    profile: { data: profile },
    setProfile: { mutate: setProfile },
  } = useUserProfiles();

  const { setLocale } = useLocale();

  const onProjectionChange = useCallback(
    (event: any, value: string) => {
      if (!profile || !projectionsWithLabels[value as Projection]) {
        return;
      }
      const updatedProfile: Partial<UserProfile> = {
        ...profile,
        projection: value as Projection,
      };
      delete updatedProfile['meta'];
      const updatedProfileNoMeta = updatedProfile as Omit<UserProfile, 'meta'>;
      setProfile({ profile: updatedProfileNoMeta });
    },
    [profile, setProfile],
  );

  const onLanguageChange = useCallback(
    (event: any, value: string) => {
      if (!profile || !locales.includes(value as Locale)) {
        return;
      }
      setLocale(value as Locale);
    },
    [profile, setLocale],
  );

  const onChangeOrganization = useCallback(() => {
    window.localStorage.removeItem('orgId');
    onLogout();
  }, [onLogout]);

  const org = orgId && orgs[orgId];
  return (
    <>
      <Tooltip label={user.name}>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Person />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 3 }}>
          <Box ml={4} mr={4}>
            <Typography variant="overline">
              <FormattedMessage id="user_menu.user" defaultMessage="User" />
            </Typography>
            <Typography variant="subtitle1">{user.name}</Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          {org && (
            <Box ml={4} mr={4}>
              <Typography variant="overline">
                <FormattedMessage
                  id="user_menu.organization"
                  defaultMessage="Organization"
                />
              </Typography>
              <Typography variant="subtitle1">{org.name}</Typography>
            </Box>
          )}
          <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="overline">
              <FormattedMessage id="language" defaultMessage="Language" />
            </Typography>
            {!profile && <Skeleton />}
            {profile && (
              <>
                <ToggleButtonGroup
                  onChange={onLanguageChange}
                  size="small"
                  value={profile.language}
                  exclusive
                  fullWidth
                >
                  {locales.map((l) => (
                    <ToggleButton key={l} value={l}>
                      {localeNames[l]}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </>
            )}
          </ListItem>
          <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="overline">
              <FormattedMessage
                id="coordinate_system"
                defaultMessage="Coordinate system"
              />
            </Typography>
            {!profile && <Skeleton />}
            {profile && (
              <>
                <ToggleButtonGroup
                  onChange={onProjectionChange}
                  size="small"
                  value={profile.projection}
                  exclusive
                  fullWidth
                >
                  {Object.values(projectionsWithLabels).map((proj) => {
                    return (
                      <ToggleButton
                        value={proj.projection}
                        key={proj.projection}
                      >
                        {proj.label}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </>
            )}
          </ListItem>
          <Box
            sx={{ height: '1px', backgroundColor: 'rgba(0,0,0,0.1)', mt: 2 }}
          />
          <Box sx={{ display: 'flex', gap: 2, m: 4 }}>
            <Button
              variant="outlined"
              color="dark"
              startIcon={<Replay />}
              onClick={onChangeOrganization}
            >
              <FormattedMessage
                id="user_menu.change_organization"
                defaultMessage="Change organization"
              />
            </Button>
            <Button
              variant="contained"
              startIcon={<ExitToApp />}
              color="dark"
              onClick={onLogout}
            >
              <FormattedMessage
                id="user_menu.logout"
                defaultMessage="Log out"
              />
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default UserMenu;
