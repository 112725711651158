import React, { FC, useCallback } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { useErrorReportsMutations } from '../../hooks/useErrorReports';
import { Handyman } from '@mui/icons-material';
import { dateFormat } from '../../helpers/datetime_helpers';
import { useUserLookup } from '../../hooks/useUserLookup';
const messages = defineMessages({
  unknown_user: {
    id: 'error_report.unknown_user',
    defaultMessage: 'Unknown user',
  },
});
interface ErrorReportDetailsProps {
  errorReport: ErrorReport;
  entityLookup: Record<string, Entity>;
  schemaDefinition: EntitySchemaDefinition;
  onClose: () => void;
  onClear: () => void;
}

const ErrorReportDetails: FC<ErrorReportDetailsProps> = ({
  errorReport,
  entityLookup,
  schemaDefinition,
  onClear,
  onClose,
}) => {
  const intl = useIntl();
  const { userLookup } = useUserLookup();
  const {
    remove: {
      mutateAsync: removeErrorReport,
      isLoading: removingErrorReportInProgress,
    },
  } = useErrorReportsMutations();

  const onRemove = useCallback(() => {
    void removeErrorReport(errorReport.id).then(() => {
      onClear();
    });
  }, [errorReport.id, onClear, removeErrorReport]);

  const entity = entityLookup[errorReport.entity_id];
  const prop = schemaDefinition.propertiesLookup[errorReport.property_key];
  if (!prop || !entity) {
    return null;
  }
  const user = userLookup(errorReport.meta.created_by);
  const userName =
    user && !user.meta.deleted_at
      ? user.name
      : intl.formatMessage(messages.unknown_user);
  const { stop_letter } = entity;
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flexGrow={1}>
        <Typography variant="h3" mb={2}>
          <FormattedMessage
            id="error_report.error_report_for_x"
            defaultMessage="Error report for stop {stop_letter}"
            values={{
              stop_letter,
            }}
          />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="error_report.error_report_regards"
            defaultMessage="Error report regards"
          />
        </Typography>
        <Typography variant="body1" mb={2}>
          {prop.name}
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="error_report.error_report_created"
            defaultMessage="Error report created"
          />
        </Typography>
        <Typography variant="body1" mb={2}>
          <FormattedMessage
            id="error_report.at_by"
            defaultMessage="{createdAt} by {userName}"
            values={{
              createdAt: errorReport.meta.created_at.toFormat(dateFormat),
              userName,
            }}
          />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="error_report.error_report_opened_to"
            defaultMessage="Error report opened to"
          />
        </Typography>
        <Typography variant="body1" mb={2}>
          {errorReport.destination}
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            id="error_report.comment"
            defaultMessage="Comment"
          />
        </Typography>
        <Typography variant="body1" mb={2}>
          {errorReport.message}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        pt={3}
        gap={3}
      >
        <Tooltip
          title={
            <FormattedMessage
              id="error_report.confirm_repairs_tooltip"
              defaultMessage="Click to confirm repairs have been completed. This will clear the error report."
            />
          }
          arrow
          placement="left"
        >
          <Box>
            <LoadingButton
              loading={removingErrorReportInProgress}
              startIcon={<Handyman />}
              disabled={removingErrorReportInProgress}
              onClick={onRemove}
              variant="contained"
              color="secondary"
            >
              <FormattedMessage
                id="error_report.confirm_repairs"
                defaultMessage="Confirm repairs"
              />
            </LoadingButton>
          </Box>
        </Tooltip>
        <Button
          disabled={removingErrorReportInProgress}
          onClick={onClose}
          variant="contained"
        >
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorReportDetails;
