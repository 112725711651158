import { ConfigState } from './store/config';

const default_config: ConfigState = {
  loaded: false,
  baseUrl: 'https://api.mobilix.dev.allbin.se',
  attachmentUrl: 'https://storage.googleapis.com/mobilix-dev',
  auth0: {
    domain: 'allbin.eu.auth0.com',
    clientId: '7kaD6bB7JeVe6iEmT7bpOtw9FN6MKoqP',
    audience: 'https://api.mobilix.dev.allbin.se',
    organization: 'org_6VQOVdnHclkpg9HN',
  },
  noHookApiCalls: false,
  policeReportUrl: 'https://etjanster.polisen.se/eanmalan/skadegorelse/',
  locale: 'sv-SE',
  mapDefaultCenter: {
    lat: 59.7696304,
    lng: 17.53084788,
  },
};

export default default_config;
