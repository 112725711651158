import React, { FC, useMemo } from 'react';
import { alpha, InputBase, InputProps } from '@mui/material';
import { useIntl, defineMessages } from 'react-intl';
import { FilterAlt } from '@mui/icons-material';
import { Search } from '../../icons';

interface RoundedInputProps extends InputProps {
  /** Sets icon and placeholder text. */
  type?: 'filter' | 'search';
}

const messages = defineMessages({
  search: { id: 'rounded_input.search', defaultMessage: 'Search' },
  filter: { id: 'rounded_input.filter', defaultMessage: 'Filter' },
});

const RoundedInput: FC<RoundedInputProps> = ({ type, ...props }) => {
  const intl = useIntl();

  const modifiedProps = useMemo(() => {
    const modifiedProps = { ...props };
    if (type) {
      modifiedProps.startAdornment =
        props.startAdornment ||
        (type === 'search' ? (
          <Search sx={{ ml: 0, mr: 1 }} />
        ) : (
          <FilterAlt sx={{ ml: 0, mr: 1 }} />
        ));
      modifiedProps.placeholder =
        props.placeholder ||
        `${intl.formatMessage(
          type === 'search' ? messages.search : messages.filter,
        )}...`;
    }
    return modifiedProps;
  }, [type, props, intl]);

  return (
    <InputBase
      {...modifiedProps}
      sx={{
        borderRadius: '60px',
        padding: 2,
        backgroundColor: ({ palette }) => alpha(palette.common.black, 0.04),
        '&:hover': {
          backgroundColor: ({ palette }) => alpha(palette.common.black, 0.08),
        },
        marginTop: ({ spacing }) => spacing(2),
        marginBottom: ({ spacing }) => spacing(2),
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
          width: '100%',
        },
        ...modifiedProps.sx,
      }}
    />
  );
};

export default RoundedInput;
