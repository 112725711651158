/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScriptCache from '../../helpers/ScriptCache';
import mapStore from '../../store/map';
import { MarkerData } from '../../store/map';
import { MarkerEventCB, updateMarkers } from '../../helpers/map_helpers';
import { MapMarker } from '.';
import styling from './styling';

type StaticRef =
  | { [key: string]: boolean }
  | { [key: string]: MapMarker }
  | { [key: string]: number }
  | { [key: string]: string }
  | boolean
  | google.maps.DirectionsRenderer
  | MapMarker
  | number
  | string;

export type Statics = { [key: string]: StaticRef };
interface MapBaseProps {
  mapId: string;
  /** If paused callbacks will not be triggered. */
  paused?: boolean;
  onZoomCenterChange?: (
    zoom: number,
    center: google.maps.LatLngLiteral,
    bounds?: google.maps.LatLngBoundsLiteral,
  ) => void;
  onMarkerClick?: (
    markerId: MarkerData['id'],
    type: MarkerData['type'],
  ) => void;
  onMarkerUpdate?: (
    map: google.maps.Map,
    services: Services,
    markers: { [id: string]: MapMarker },
    /** Store anything which needs to be referenced for as long as the map is mounted. */
    staticRefs: { [key: string]: StaticRef },
  ) => void;
  gmapsOptions?: google.maps.MapOptions;
}
export interface Services {
  directions: google.maps.DirectionsService;
}
interface StaticCallbackRefs {
  onMarkerClick?: MapBaseProps['onMarkerClick'];
  onZoomCenterChange?: MapBaseProps['onZoomCenterChange'];
}

const uri =
  'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,places&key=AIzaSyA0tp0r6ImLSnn9vy4zXjZWar1F3U5eOaY';
const gmapsDefaultOptions: google.maps.MapOptions = {
  styles: styling,
  gestureHandling: 'greedy',
};

const MapBase: FC<MapBaseProps> = ({
  mapId,
  onZoomCenterChange,
  onMarkerClick,
  onMarkerUpdate,
  paused,
  gmapsOptions,
}) => {
  gmapsOptions = gmapsOptions || {};
  const htmlElementRef = useRef<HTMLDivElement>(null);
  const staticCallbacksRef = useRef<StaticCallbackRefs>({
    onMarkerClick,
  });
  const staticMarkerRefs = useRef<Record<string, MapMarker>>({});
  const statics = useRef<{ [key: string]: StaticRef }>({});
  const [map, setMap] = useState<google.maps.Map>();
  const [services, setServices] = useState<Services | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [zoom, setZoom] = useState<number>(10);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [bounds, setBounds] = useState<
    google.maps.LatLngBoundsLiteral | undefined
  >();

  const { mapById } = mapStore();
  const mapData = useMemo(() => mapById[mapId], [mapById, mapId]);

  const [script_cache] = useState<any>(
    ScriptCache({
      google: uri,
    }),
  );

  const markerEventCB: MarkerEventCB = useCallback(
    (eventType: 'click' | 'enter' | 'leave', markerData: MarkerData) => {
      switch (eventType) {
        case 'click': {
          if (staticCallbacksRef.current.onMarkerClick) {
            staticCallbacksRef.current.onMarkerClick(
              markerData.id,
              markerData.type,
            );
          }
          break;
        }
        default:
          break;
      }
    },
    [],
  );

  const handleZoomCenterChange = useCallback(
    (map: google.maps.Map) => {
      if (!map || !onZoomCenterChange) {
        return;
      }
      onZoomCenterChange(
        map.getZoom(),
        map.getCenter().toJSON(),
        map.getBounds()?.toJSON(),
      );
    },
    [onZoomCenterChange],
  );

  useEffect(() => {
    if (!mapData || map || !htmlElementRef.current || initialized) {
      //If there is no mapData or we have already initialized a map; early out.
      return;
    }

    const { center, zoom, bounds } = mapData;
    const currentRef = htmlElementRef.current;

    if (
      !script_cache ||
      !script_cache.google ||
      typeof script_cache.google.onLoad !== 'function'
    ) {
      throw new Error('Script cache for google not initialized correctly.');
    }
    setInitialized(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    script_cache.google.onLoad(() => {
      const mapConfig = {
        ...gmapsDefaultOptions,
        ...gmapsOptions,
        ...{
          center: new window.google.maps.LatLng(center.lat, center.lng),
          zoom: zoom,
        },
      };
      const maps = window.google.maps;
      const initial_map = new maps.Map(currentRef, mapConfig);
      if (bounds) {
        initial_map.fitBounds(bounds);
      }
      setMap(initial_map);
      if (initial_map) {
        window.google.maps.event.addListener(initial_map, 'zoom_changed', () =>
          handleZoomCenterChange(initial_map),
        );
        window.google.maps.event.addListener(
          initial_map,
          'center_changed',
          () => handleZoomCenterChange(initial_map),
        );
        window.google.maps.event.addListener(
          initial_map,
          'bounds_changed',
          () => handleZoomCenterChange(initial_map),
        );
      }
    });
    return () => {
      if (map) {
        window.google.maps.event.clearInstanceListeners(map);
      }
    };
  }, [
    map,
    mapData,
    mapId,
    script_cache,
    script_cache.google,
    initialized,
    gmapsOptions,
    handleZoomCenterChange,
  ]);

  useEffect(() => {
    if (!map) {
      return;
    }

    setServices({
      directions: new window.google.maps.DirectionsService(),
    });
  }, [map]);

  useEffect(() => {
    if (!mapData || !map || !services) {
      return;
    }
    updateMarkers(
      map,
      mapData.markers,
      staticMarkerRefs.current,
      markerEventCB,
    );
    onMarkerUpdate &&
      !paused &&
      onMarkerUpdate(map, services, staticMarkerRefs.current, statics.current);
  }, [map, mapData, markerEventCB, onMarkerUpdate, paused, services]);

  //Map center trigger.
  useEffect(() => {
    if (!mapData || !map) {
      return;
    }
    if (
      mapData.center !== center &&
      (!mapData.bounds || mapData.bounds === bounds)
    ) {
      // console.log('center changed');
      map.setCenter(mapData.center);
    }
    setCenter(mapData.center);
  }, [map, mapData, center, setCenter, bounds]);

  //Map zoom trigger.
  useEffect(() => {
    if (!mapData || !map) {
      return;
    }
    if (
      mapData.zoom !== zoom &&
      (!mapData.bounds || mapData.bounds === bounds)
    ) {
      // console.log('zoom changed');
      map.setZoom(mapData.zoom);
    }
    setZoom(mapData.zoom);
  }, [map, mapData, zoom, setZoom, bounds]);

  //Map bounds trigger.
  useEffect(() => {
    if (!mapData || !map) {
      return;
    }
    if (mapData.bounds !== bounds) {
      // console.log('bounds changed', mapData.bounds);
      if (mapData.bounds) {
        map.fitBounds(mapData.bounds);
      }
      setBounds(mapData.bounds);
    }
  }, [map, mapData, bounds, setBounds]);

  //Rebind events.
  useEffect(() => {
    staticCallbacksRef.current.onMarkerClick = onMarkerClick;
  }, [onMarkerClick]);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      {!mapData && (
        <div className="flex h-full w-full animate-pulse bg-gray-light" />
      )}
      <div
        ref={htmlElementRef}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
        }}
      />
    </div>
  );
};

export default MapBase;
