import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useUserProfiles } from '../hooks/useUserProfiles';
import { useCallback } from 'react';

export type Locale = 'en-US' | 'sv-SE';
export type LocaleNames = { [key in Locale]: string };

export const localeNames: LocaleNames = {
  'en-US': 'English',
  'sv-SE': 'Svenska',
};

interface LocaleState {
  locale: Locale;
  setLocale: (locale: Locale) => void;
}

export const useLocaleStore = create<LocaleState>()(
  devtools(
    persist(
      (set) => ({
        locale: 'sv-SE',
        setLocale: (locale) => {
          set({ locale });
        },
      }),
      {
        name: 'locale',
      },
    ),
  ),
);

interface UseLocale {
  locale: Locale;
  setLocale: (locale: Locale) => void;
}
export const useLocale = (): UseLocale => {
  const { locale, setLocale: setStoreLocale } = useLocaleStore();
  const {
    profile: { data: profile },
    setProfile: { mutate: setProfile },
  } = useUserProfiles();

  const setLocale = useCallback(
    (locale: Locale) => {
      setStoreLocale(locale);
      const { meta, ...updatedProfile } = profile || {};
      setProfile({
        profile: { ...updatedProfile, language: locale },
      });
    },
    [profile, setProfile, setStoreLocale],
  );

  return { locale, setLocale };
};
