import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import guideTexts, { RawSubject } from '../guideTexts';

export interface Subject {
  id: string;
  title: string;
  chapters: Chapter[];
}
export interface Chapter {
  id: string;
  heading: string;
  paragraphs: Paragraph[];
}
export type Paragraph = ParagraphMultiSegment | ParagraphSingleSegment;
export type ParagraphMultiSegment = (TextSegment | LinkSegment)[];
export type ParagraphSingleSegment = TextSegment | LinkSegment | LiSegment;
interface TextSegment {
  type: 'text';
  text: string;
}
interface LinkSegment {
  type: 'link';
  text: string;
  linkTo: string;
}
interface LiSegment {
  type: 'li';
  text: string;
}

interface GuideStore {
  subjects: Subject[];
  open: boolean;
  selectedSubjectId: string | null;
  selectedChapterId: string | null;
  /** Chapters of the selected subject. */
  selectedChapters?: Chapter[];
  /** Closes the guuide dialog. */
  setClosed: () => void;
  /** When null is provided the selections are reset. */
  openGuide: (subjectId?: string | null, chapterId?: string | null) => void;
}

const linkRegex = /<a#(\w+)>([\w\såäöÅÄÖ]+)<\/a>/g;
const reviveTexts = (rawSubjects: RawSubject[]): Subject[] => {
  const splitTexts = (text: string): string[] =>
    text.split(/\r?\n/).map((part) => part.trim());
  const convertTextToParagraph = (text: string): Paragraph => {
    const links = [...text.matchAll(linkRegex)];
    if (links.length > 0) {
      const segments: (TextSegment | LinkSegment)[] = [];
      let index = 0;
      links.forEach((l, i) => {
        const [full, targetId, linkText] = l;
        const matchIndex = text.indexOf(full, index);
        if (matchIndex !== index) {
          //There was text after last index and before the links index.
          segments.push({
            type: 'text',
            text: text.substring(index, matchIndex),
          });
        }
        segments.push({
          type: 'link',
          text: linkText,
          linkTo: targetId,
        });
        if (i === links.length - 1) {
          //Last link detected, add final segment.
          segments.push({
            type: 'text',
            text: text.substring(matchIndex + full.length),
          });
        }
        index = matchIndex + 1;
      });
      return segments;
    } else if (text.length > 2 && text.startsWith('- ')) {
      return {
        type: 'li',
        text: text.substring(2),
      };
    }
    return {
      type: 'text',
      text: text,
    };
  };

  return rawSubjects
    .map(
      (s) =>
        ({
          ...s,
          chapters: s.chapters.map((c) => ({
            id: c.id,
            heading: c.heading,
            paragraphs: splitTexts(c.text).map(convertTextToParagraph),
          })),
        } as Subject),
    )
    .sort((a, b) => (a.title > b.title ? 1 : -1));
};

const texts = reviveTexts(guideTexts);

const chaptersBySubjectId = texts.reduce<Record<string, Chapter[]>>(
  (acc, curr) => {
    acc[curr.id] = curr.chapters;
    return acc;
  },
  {},
);

const useGuideStore = create<GuideStore>()(
  devtools(
    (set, get) => ({
      open: false,
      subjects: texts,
      openGuide: (subjectId, chapterId) => {
        if (subjectId && !chaptersBySubjectId[subjectId]) {
          console.log('GUIDE: No chapters found for subject: ', subjectId);
        }
        set({
          open: true,
          selectedChapters: subjectId
            ? chaptersBySubjectId[subjectId]
            : undefined,
          selectedSubjectId:
            subjectId !== undefined ? subjectId : get().selectedSubjectId,
          selectedChapterId:
            chapterId !== undefined ? chapterId : get().selectedChapterId,
        });
        return undefined;
      },
      selectedChapterId: null,
      selectedSubjectId: null,
      selectedChapters: undefined,
      setClosed: () => set({ open: false }),
    }),
    { name: 'guide-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useGuideStore;
