import React from 'react';
import { ApiUser } from '@allbin/mobilix-api-client';

import { reviveMetadata } from '../helpers/revivers';
import { useApiClient } from './useApiClient';
import { useUserLookupStore } from '../store/userLookup';

export const reviveUser: ReviverFn<ApiUser, User> = (user) => ({
  ...user,
  meta: reviveMetadata(user.meta),
});

interface IUseUserLookup {
  userLookup: (id: string) => User;
}

const userIdsToFetch = new Set<string>();
let timer: ReturnType<typeof setTimeout> | undefined = undefined;

export const useUserLookup = (): IUseUserLookup => {
  const apiClient = useApiClient();
  const { users, mergeUsers } = useUserLookupStore();

  const fetchUser = React.useCallback(
    (id: string) => {
      userIdsToFetch.add(id);

      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }

      timer = setTimeout(() => {
        if (userIdsToFetch.size === 0) {
          return;
        }
        const req = apiClient.users.list(Array.from(userIdsToFetch));
        userIdsToFetch.clear();

        void req
          .then((users) => {
            mergeUsers(users.map((u) => reviveUser(u)));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 300);
    },
    [apiClient, mergeUsers],
  );

  const userLookup = React.useCallback(
    (id: string) => {
      if (!users[id]) {
        fetchUser(id);
        return {
          id,
          name: `...`,
          user_id: id,
          email: 'loading@example.com',
        } as User;
      }
      return users[id];
    },
    [users, fetchUser],
  );

  return {
    userLookup,
  };
};
