import { List } from '@mui/material';
import React, { FC, useCallback } from 'react';
import CheckInDnDItem from './CheckInDnDItem';

interface CheckInDnDContainerProps {
  entities: Entity[];
  onReorder: (entities: Entity[]) => void;
}

const CheckInDnDContainer: FC<CheckInDnDContainerProps> = ({
  entities,
  onReorder,
}) => {
  const onMove = useCallback(
    (id: string, entityIndex: number, toIndex: number) => {
      const clone = [...entities];
      if (typeof entityIndex !== 'number') {
        return;
      }

      const removed = clone.splice(entityIndex, 1);
      if (removed.length !== 1) {
        return;
      }
      clone.splice(toIndex, 0, removed[0]);
      onReorder(clone);
    },
    [entities, onReorder],
  );

  return (
    <List dense>
      {entities.map((entity, i) => (
        <CheckInDnDItem
          key={entity.id}
          hoverIndex={i}
          id={entity.id}
          label={entity.full_name}
          subText={entity.properties['meta.id'] as string}
          onMove={onMove}
        />
      ))}
    </List>
  );
};

export default CheckInDnDContainer;
