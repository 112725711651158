import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, WorkOrder, Entities, QuestionMark, Search } from '../../icons';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import UserMenu from './UserMenu';
import { useAuth0 } from '@auth0/auth0-react';
import MainMenu from './MainMenu';
import useSearch from '../../hooks/useSearch';
import { useTopBar } from '../../store/topbar';
import useGuideStore from '../../store/guide';
import useConfig from '../../store/config';
import { classNames } from '../../classNames';
import NavButton from './NavButton';
import { Routes } from '../../hooks/useRoutes';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { IconCommunity } from '@allbin/icons';

const TopBar: FC = () => {
  const { logout } = useAuth0();
  const { get: getLocalStorage } = useLocalStorage();
  const { doSearch } = useSearch();
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const { title, subtitle, color, guideSubjectId } = useTopBar();
  const { openGuide } = useGuideStore();
  const {
    auth0: { clientId },
  } = useConfig();

  return (
    <>
      <MainMenu
        open={!!mainMenuAnchorEl}
        anchorEl={mainMenuAnchorEl}
        onClose={() => setMainMenuAnchorEl(null)}
      />
      <header
        className={classNames(
          'relative z-10 max-h-12 min-h-[48px] shadow-toolbar',
          color === 'primary'
            ? 'bg-workorder text-workorder-contrastText'
            : 'bg-[#FFD950] text-gray-dark',
        )}
      >
        <div className="absolute inset-0 flex items-center justify-center gap-1 py-1">
          <h1 className="whitespace-nowrap h3">{title}</h1>
          {subtitle && <h2 className="whitespace-nowrap">{subtitle}</h2>}
        </div>
        <div className="absolute inset-y-0 left-4 flex items-center gap-4 py-1">
          <Tooltip
            label={
              <FormattedMessage
                id="top_bar.main_menu"
                defaultMessage="Main menu"
              />
            }
          >
            <IconButton
              onClick={(event) => setMainMenuAnchorEl(event.currentTarget)}
            >
              <Menu />
            </IconButton>
          </Tooltip>
          <NavButton
            tooltip={
              <FormattedMessage
                id="top_bar.workorders"
                defaultMessage="Work orders"
              />
            }
            to={Routes.workOrderOverviewState}
            params={{
              state: getLocalStorage(
                'WorkOrderOverview.stateFilter',
                'created',
              ),
            }}
            icon={<WorkOrder />}
          />
          <NavButton
            tooltip={
              <FormattedMessage id="top_bar.stops" defaultMessage="Stops" />
            }
            to={Routes.stationOverview}
            icon={<Entities />}
          />
          <NavButton
            tooltip={
              <FormattedMessage
                id="top_bar.usermanagement"
                defaultMessage="usermanagement"
              />
            }
            to={Routes.UserManagementOverview}
            icon={<IconCommunity className="h-6 w-6" />}
          />
          <Tooltip
            label={
              <FormattedMessage id="top_bar.search" defaultMessage="Search" />
            }
          >
            <IconButton color="inherit" onClick={() => doSearch('')}>
              <Search />
            </IconButton>
          </Tooltip>
        </div>
        <div className="absolute inset-y-0 right-4 flex items-center gap-4 py-1">
          <UserMenu
            onLogout={() =>
              logout({
                logoutParams: {
                  client_id: clientId,
                  returnTo: window.location.origin,
                },
              })
            }
          />
          <Tooltip
            label={
              <FormattedMessage id="top_bar.guide" defaultMessage="Guide" />
            }
          >
            <IconButton onClick={() => openGuide(guideSubjectId)}>
              <QuestionMark />
            </IconButton>
          </Tooltip>
        </div>
      </header>
    </>
  );
};

export default TopBar;
