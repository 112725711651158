import { DateTime } from 'luxon';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

/** The entire MarkerData object will be in the OnMarkerClick and OnMarkerHover callbacks of the Map component. */
export interface MarkerData {
  id: string;
  type: 'entity' | 'workorder' | 'entityGroup';
  style: google.maps.MarkerOptions;
  position: google.maps.LatLngLiteral;
  updated_at: DateTime;
  title?: string;
}

export interface MapDataUpdate {
  /** UUID */
  id: string;
  markers?: Record<string, MarkerData>;
  /** Zoom level, changing this value will force-zoom the map.
   * If bounds are set at the same time the map will ignore zoom.
   * (https://developers.google.com/maps/documentation/javascript/overview#zoom-levels). */
  zoom?: number;
  /** Center coordinate, changing this will force-pan the map.
   * If bounds are set any center is ignored for that render.
   */
  center?: google.maps.LatLngLiteral;
  bounds?: google.maps.LatLngBoundsLiteral;
}
export interface MapData {
  /** UUID */
  id: string;
  markers: { [id: string]: MarkerData };
  zoom: number;
  center: google.maps.LatLngLiteral;
  bounds?: google.maps.LatLngBoundsLiteral;
}

interface MapStore {
  /** Set will update any existing MapData of the same id. */
  setMap: (map: MapData) => void;
  setPartial: (map: MapDataUpdate) => void;
  removeMap: (id: string) => void;
  mapById: {
    [id: string]: MapData;
  };
}

const mapStore = create<MapStore>()(
  devtools(
    (set, get) => ({
      mapById: {},
      setPartial: (map) => {
        const { id } = map;
        if (!id) {
          throw new Error('No map id provided in partial map update.');
        }
        set((state) => {
          if (!state.mapById[id]) {
            throw new Error(
              "Cannot set partial map, no map defined for id '" + id + "'",
            );
          }
          const newMap = { ...state.mapById[id], ...map };
          return {
            mapById: {
              ...state.mapById,
              [id]: newMap,
            },
          };
        });
      },
      setMap: (map) => {
        // console.log('setMap', map);
        // const err = new Error();
        // console.log(err.stack);
        if (!map.center) {
          throw new Error(`Error setting map data; missing center coordinate.`);
        }
        if (!map.zoom) {
          throw new Error(`Error setting map data; missing zoom level.`);
        }
        set((state) => {
          return {
            mapById: { ...state.mapById, [map.id]: map },
          };
        });
      },
      removeMap: (id) => {
        const clone = { ...get().mapById };
        if (!clone[id]) {
          throw new Error(
            `Error removing map data; mapId '${id}' not found in store.`,
          );
        }
        delete clone[id];
        set({
          mapById: clone,
        });
      },
    }),
    { name: 'map-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default mapStore;
