import React, { FC, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SingleSegmentParagraph from './SingleSegmentParagraph';
import MultiSegmentParagraph from './MultiSegmentParagraph';
import useGuideStore from '../../store/guide';
import { Close } from '@mui/icons-material';

const Guide: FC = () => {
  const {
    open,
    setClosed,
    subjects,
    selectedSubjectId,
    openGuide,
    selectedChapters,
  } = useGuideStore();

  const onLinkClick = useCallback(
    (targetId: string) => {
      openGuide(targetId);
    },
    [openGuide],
  );

  return (
    <Dialog
      open={open}
      onClose={setClosed}
      fullWidth
      maxWidth="lg"
      sx={{ minHeight: '90vh' }}
    >
      <DialogTitle>
        <FormattedMessage id="guide.guide" defaultMessage="Guide" />
        <div>
          <IconButton color="inherit" onClick={setClosed}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          minHeight="78vh"
          maxHeight="78vh"
          flexDirection="row"
          alignItems="stretch"
        >
          <Box
            minHeight="78vh"
            maxHeight="78vh"
            width="300px"
            p={2}
            pr={0}
            overflow="auto"
          >
            <Typography variant="h5" ml={4} mt={4}>
              <FormattedMessage id="guide.subjects" defaultMessage="Subjects" />
            </Typography>
            <List>
              {subjects.map((s) => (
                <ListItem key={s.id} disablePadding>
                  <ListItemButton
                    autoFocus={s.id === selectedSubjectId}
                    selected={s.id === selectedSubjectId}
                    onClick={() => openGuide(s.id)}
                  >
                    <ListItemText
                      primary={s.title}
                      sx={{ overflowWrap: 'break-word' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            flex={1}
            p={4}
            pt={2}
            minHeight="78vh"
            maxHeight="78vh"
            overflow="auto"
            sx={{ backgroundColor: 'background.dark' }}
          >
            {!selectedChapters && (
              <Typography variant="body2">
                <FormattedMessage
                  id="guide.select_subject"
                  defaultMessage="Select subject on the left"
                />
              </Typography>
            )}
            {selectedChapters &&
              selectedChapters.map((c) => (
                <Box key={c.id}>
                  <Typography variant="h5" mt={4} mb={1}>
                    {c.heading}
                  </Typography>
                  {c.paragraphs.map((p, i) => {
                    if (Array.isArray(p)) {
                      return (
                        <MultiSegmentParagraph
                          key={c.id + i}
                          segments={p}
                          onLinkClick={onLinkClick}
                        />
                      );
                    }
                    return (
                      <SingleSegmentParagraph
                        key={c.id + i}
                        segment={p}
                        onLinkClick={onLinkClick}
                      />
                    );
                  })}
                </Box>
              ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Guide;
