import { setupWorker, rest, RequestHandler, DefaultBodyType } from 'msw';
import {
  ApiUserInvitationRequest,
  ApiUserProfileRequest,
} from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';

import { tdApiCheckInPlans } from '../testdata/checkins';
import { tdApiRecurringPlans } from '../testdata/recurring';
import { tdApiTemplates } from '../testdata/templates';
import { tdApiWorkorders, tdWorkorderTags } from '../testdata/workorders';
import { tdContractorAgents, tdContractors } from '../testdata/contractors';
import { tdEntityEvents, tdGetApiWorkOrderEvents } from '../testdata/events';
import { tdEntityTypes, tdGetEntities } from '../testdata/entities';
import { tdErrorReports } from '../testdata/errorreports';
import { tdFilterSets } from '../testdata/filter';
import { tdGetEntityChangesets } from '../testdata/changesets';
import { tdInvitations } from '../testdata/invitations';
import { tdSchema } from '../testdata/schema';
import { tdUsers } from '../testdata/users';
import { tdAllLicensesGroup } from '../testdata/licenses';
import { tdTenants } from '../testdata/tenants';

let profile = {};

const rests: RequestHandler[] = [
  // rest.get('https://allbin.eu.auth0.com/*', (_req, res, ctx) => {
  //   return res(ctx.json({}));
  // }),
  rest.get('https://api.mobilix.dev.allbin.se/tags', (_req, res, ctx) => {
    return res(ctx.json(tdWorkorderTags));
  }),
  rest.get('https://api.mobilix.dev.allbin.se/workorders', (_req, res, ctx) => {
    return res(ctx.json(tdApiWorkorders));
  }),
  rest.get(
    'https://api.mobilix.dev.allbin.se/workorders/:workOrderId',
    (_req, res, ctx) => {
      const wo = tdApiWorkorders.find(
        (wo) => wo.id === _req.params.workOrderId,
      ) as any;
      if (!wo) {
        return res(ctx.status(404));
      }
      return res(ctx.json(wo));
    },
  ),
  rest.get('https://api.mobilix.dev.allbin.se/entities', (_req, res, ctx) => {
    return res(ctx.json(tdGetEntities()));
  }),
  rest.post(
    'https://api.mobilix.dev.allbin.se/entities/:entityId/events',
    (_req, res, ctx) => {
      const events = tdEntityEvents.filter(
        (ev) => ev.entity_id === _req.params.entityId,
      );
      if (events.length === 0) {
        return res(ctx.status(500));
      }
      return res(ctx.json(events[0]));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/entity_types',
    (_req, res, ctx) => {
      return res(ctx.json(tdEntityTypes));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/contractors',
    (_req, res, ctx) => {
      return res(ctx.json(tdContractors));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/contractors/:contractorId',
    (_req, res, ctx) => {
      return res(
        ctx.json(tdContractors.find((c) => c.id === _req.params.contractorId)),
      );
    },
  ),
  rest.get('https://api.mobilix.dev.allbin.se/tenants', (_req, res, ctx) => {
    return res(ctx.json(tdTenants));
  }),
  rest.get(
    'https://api.mobilix.dev.allbin.se/contractor_agents',
    (_req, res, ctx) => {
      return res(
        ctx.json(
          tdContractorAgents.filter(
            (a) =>
              a.contractor_id === _req.url.searchParams.get('contractor_id'),
          ),
        ),
      );
    },
  ),
  rest.get('https://api.mobilix.dev.allbin.se/users', (_req, res, ctx) => {
    return res(ctx.json(tdUsers));
  }),
  rest.get(
    'https://api.mobilix.dev.allbin.se/entity_schemas',
    (_req, res, ctx) => {
      return res(ctx.json([tdSchema]));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/feature_licenses',
    (_req, res, ctx) => {
      return res(ctx.json([tdAllLicensesGroup]));
    },
  ),
  rest.get<DefaultBodyType, { entityId: string }>(
    'https://api.mobilix.dev.allbin.se/entities/:entityId/changesets',
    (_req, res, ctx) => {
      const { entityId } = _req.params;
      const entity = tdGetEntities().find((e) => e.id === entityId);
      if (!entity) {
        return res(ctx.status(404));
      }
      const changesets = tdGetEntityChangesets(
        _req.params.entityId,
        entity.properties,
      );
      if (!changesets) {
        return res(ctx.status(404));
      }
      return res(ctx.json(changesets));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/entities/:entityId/events',
    (_req, res, ctx) => {
      console.log('tdEntityEvents', tdEntityEvents);
      const events = tdEntityEvents.filter(
        (ev) => ev.entity_id === _req.params.entityId,
      );
      return res(ctx.json(events));
    },
  ),
  rest.get<DefaultBodyType, { workOrderId: string }>(
    'https://api.mobilix.dev.allbin.se/workorders/:workOrderId/events',
    (_req, res, ctx) => {
      const { workOrderId } = _req.params;
      const events = tdGetApiWorkOrderEvents(workOrderId);
      return res(ctx.json(events));
    },
  ),
  rest.get('https://api.mobilix.dev.allbin.se/filtersets', (_req, res, ctx) => {
    return res(ctx.json(tdFilterSets));
  }),
  rest.get('https://api.mobilix.dev.allbin.se/invitations', (req, res, ctx) => {
    return res(ctx.json(tdInvitations));
  }),
  rest.get(
    'https://api.mobilix.dev.allbin.se/user_profile',
    (req, res, ctx) => {
      return res(
        ctx.json({
          meta: {
            created_at: DateTime.now().toISO(),
            created_by: 'auth0|6138bc01fc2828006ad2fa43',
            updated_at: DateTime.now().toISO(),
          },
          profile,
        }),
      );
    },
  ),
  rest.put<ApiUserProfileRequest>(
    'https://api.mobilix.dev.allbin.se/user_profile',
    (req, res, ctx) => {
      const { body } = req;
      profile = body ? body.profile : profile;
      return res(
        ctx.json({
          meta: {
            created_at: DateTime.now().toISO(),
            created_by: 'auth0|6138bc01fc2828006ad2fa43',
            updated_at: DateTime.now().toISO(),
          },
          profile,
        }),
      );
    },
  ),
  rest.post(
    'https://api.mobilix.dev.allbin.se/invitations',
    (req, res, ctx) => {
      const created: ApiUserInvitationRequest = {
        role: 'contractor-agent',
        email: 'invitee3@example.org',
        contractor_id: '6895ad98-51e7-4f99-a5e2-d5bad92adf88',
      };
      ctx.status(201);
      return res(ctx.json(created));
    },
  ),
  rest.post(
    'https://api.mobilix.dev.allbin.se/error_reports',
    (_req, res, ctx) => {
      // return res(ctx.json(tdErrorReports[0]));
      return res(ctx.status(500));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/error_reports',
    (_req, res, ctx) => {
      return res(
        ctx.json(
          tdErrorReports.filter((er) => er.meta.deleted_at === undefined),
        ),
      );
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/error_reports/:id',
    (_req, res, ctx) => {
      const er = tdErrorReports.find((er) => er.id === _req.params.id);
      if (!er) {
        return res(ctx.status(404));
      }
      return res(ctx.json(er));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/checkin_plans/:id',
    (_req, res, ctx) => {
      const plan = tdApiCheckInPlans.find((plan) => plan.id === _req.params.id);
      if (!plan) {
        return res(ctx.status(404));
      }
      return res(ctx.json(plan));
    },
  ),
  rest.post(
    'https://api.mobilix.dev.allbin.se/checkin_plans',
    (_req, res, ctx) => {
      return res(ctx.status(500));
    },
  ),
  rest.put(
    'https://api.mobilix.dev.allbin.se/checkin_plans/:id',
    (_req, res, ctx) => {
      return res(ctx.status(404));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/checkin_plans',
    (_req, res, ctx) => {
      return res(ctx.json(tdApiCheckInPlans));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/recurring_workorder_plans/:id',
    (_req, res, ctx) => {
      const plan = tdApiRecurringPlans.find(
        (plan) => plan.id === _req.params.id,
      );
      if (!plan) {
        return res(ctx.status(404));
      }
      return res(ctx.json(plan));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/recurring_workorder_plans',
    (_req, res, ctx) => {
      return res(ctx.json(tdApiRecurringPlans));
    },
  ),
  rest.get(
    'https://api.mobilix.dev.allbin.se/workorder_instructions',
    (_req, res, ctx) => {
      return res(ctx.json(tdApiTemplates));
    },
  ),
];

export const mswWorker = setupWorker(
  // Provide request handlers
  ...rests,
);
