import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface LoadingStore {
  addLoading: (nonBlocking?: boolean) => void;
  removeLoading: (nonBlocking?: boolean) => void;
  loading: number;
  nonBlockingLoading: number;
}

const useTableStore = create<LoadingStore>()(
  devtools(
    (set, get) => ({
      loading: 0,
      nonBlockingLoading: 0,
      addLoading: (nonBlocking) => {
        const { loading, nonBlockingLoading } = get();
        if (nonBlocking) {
          set({
            nonBlockingLoading: nonBlockingLoading + 1,
          });
          return;
        }
        set({
          loading: loading + 1,
        });
      },
      removeLoading: (nonBlocking) => {
        const { loading, nonBlockingLoading } = get();
        if (nonBlocking) {
          set({
            nonBlockingLoading: nonBlockingLoading - 1,
          });
          return;
        }
        set({
          loading: loading - 1,
        });
      },
    }),
    { name: 'loading-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useTableStore;
