import React, { FC } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  ListItem,
  ListItemText,
  MenuList,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { Edit, Visibility } from '@mui/icons-material';

interface CheckInPlanListProps {
  plans: CheckInPlan[];
  onClose: () => void;
  isProcessing: boolean;
  onSelect: (id: string) => void;
  onView: (id: string) => void;
  selectedPlanId?: string;
  onNewPlan: () => void;
}

const CheckInPlanList: FC<CheckInPlanListProps> = ({
  plans,
  isProcessing,
  onClose,
  onSelect,
  onView,
  selectedPlanId,
  onNewPlan,
}) => {
  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="row" alignItems="stretch">
          <Box flexGrow={1} flexBasis={0}>
            <MenuList dense>
              {plans.length === 0 && (
                <ListItem>
                  <Typography>
                    <FormattedMessage
                      id="check_in_plan_management.no_check_in_plans_created"
                      defaultMessage="No check-in plans created."
                    />
                  </Typography>
                </ListItem>
              )}
              {plans.map((plan) => {
                return (
                  <ListItem key={plan.id} selected={plan.id === selectedPlanId}>
                    <ListItemText
                      primary={plan.title}
                      sx={{ marginRight: 2 }}
                    />
                    <IconButton onClick={() => onSelect(plan.id)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onView(plan.id)}>
                      <Visibility />
                    </IconButton>
                  </ListItem>
                );
              })}
            </MenuList>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={2}
            flexBasis={0}
            borderLeft="1px solid grey"
          >
            <Typography>
              <FormattedMessage
                id="check_in_plan_management.select_plan_to_the_left_or_create_new"
                defaultMessage="Select check-in plan in the left list or create a new plan."
              />
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={onNewPlan}
            >
              <FormattedMessage
                id="check_in_plan_management.new_check_in_plan"
                defaultMessage="New check-in plan"
              />
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          loading={isProcessing}
          onClick={onClose}
        >
          <FormattedMessage id="close" defaultMessage="Close" />
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CheckInPlanList;
