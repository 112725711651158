import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface TopBarStore {
  title: string;
  subtitle?: string;
  color: 'primary' | 'secondary';
  guideSubjectId: string;
  setTopBar: (topBar: {
    title: string;
    subtitle?: string;
    color: 'primary' | 'secondary';
    guideSubjectId: string;
  }) => void;
}

export const useTopBar = create<TopBarStore>()(
  devtools(
    (set) => ({
      title: 'Mobilix',
      color: 'primary',
      guideSubjectId: '',
      setTopBar: ({ title, subtitle, color, guideSubjectId }) => {
        set(() => ({
          title,
          subtitle,
          color,
          guideSubjectId,
        }));
      },
    }),
    { name: 'topbar-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);
