import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ColorProps } from '../../providers/ThemeProvider';
import Button from '../Inputs/Button';

interface UnsavedChangesDialogProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  palette?: ColorProps;
}

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  open,
  onOk,
  onCancel,
  palette,
}) => {
  const theme = useTheme();
  const colors = useMemo(() => palette || theme.palette.wo, [palette, theme]);

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{ backgroundColor: colors.main, color: colors.contrastText }}
      >
        <FormattedMessage
          id="unsaved_changes_dialog.you_have_unsaved_changes"
          defaultMessage="You have unsaved changes"
        />
      </DialogTitle>
      <DialogContent sx={{ minWidth: '450px' }}>
        <Box pt={4} display="flex" alignItems="center">
          <Typography>
            <FormattedMessage
              id="unsaved_changes_dialog.continue_anyway"
              defaultMessage="Your changes will not be saved."
            />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onCancel()} palette={colors}>
          <FormattedMessage
            id="unsaved_changes_dialog.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button variant="contained" onClick={() => onOk()} palette={colors}>
          <FormattedMessage
            id="unsaved_changes_dialog.ok"
            defaultMessage="Ok"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
