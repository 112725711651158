import { useMemo } from 'react';
import { useEntities } from './useEntities';

interface UseEntityGroups {
  lookup: Record<string, EntityGroup>;
  list: EntityGroup[];
}

const useEntityGroups = (): UseEntityGroups => {
  const { list: entitiesList, isLoading: entitiesIsLoading } = useEntities();

  const entityGroups = useMemo(() => {
    if (entitiesIsLoading) {
      return {
        list: [],
        lookup: {},
      };
    }
    const entityGroupsById: Record<string, EntityGroup> = {};
    const entityGroupCoords: Record<
      string,
      { north: number; south: number; west: number; east: number }
    > = {};

    entitiesList.forEach((entity) => {
      const entityGroupId = entity.properties['meta.group_id'] as string;
      const loc = entity.properties['meta.location'] as [number, number];
      const lng = loc[0];
      const lat = loc[1];
      if (
        entityGroupId.length === 0 ||
        typeof lng !== 'number' ||
        typeof lat !== 'number'
      ) {
        //Malformed stop entity, ignore.
        return;
      }

      if (entityGroupsById[entityGroupId]) {
        //Station already found, update stationCoords.
        const { east, west, north, south } = entityGroupCoords[entityGroupId];
        entityGroupCoords[entityGroupId].east = lng < east ? lng : east;
        entityGroupCoords[entityGroupId].west = lng > west ? lng : west;
        entityGroupCoords[entityGroupId].north = lat > north ? lat : north;
        entityGroupCoords[entityGroupId].south = lat < south ? lat : south;
        entityGroupsById[entityGroupId].entities.push(entity);
      } else {
        //New station required.
        entityGroupsById[entityGroupId] = {
          id: entityGroupId,
          source_id: entityGroupId,
          entities: [entity],
          full_name: entity.properties['meta.name'] as string,
          properties: {
            'meta.name': entity.properties['meta.name'],
            'meta.id': entityGroupId,
            'meta.location': [0, 0],
            'meta.zone': entity.properties['meta.zone'],
            'meta.municipality.name':
              entity.properties['meta.municipality.name'],
          },
          entity_type_id: 'station',
          meta: { ...entity.meta },
        };
        entityGroupCoords[entityGroupId] = {
          north: lat,
          south: lat,
          east: lng,
          west: lng,
        };
      }
    });

    const entityGroups = Object.values(entityGroupsById).map((s) => {
      const { east, west, north, south } = entityGroupCoords[s.id];
      s.properties['meta.location'] = [
        east + (west - east) / 2,
        south + (north - south) / 2,
      ];
      return s;
    });

    return {
      list: entityGroups,
      lookup: entityGroups.reduce<Record<string, EntityGroup>>((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {}),
    };
  }, [entitiesIsLoading, entitiesList]);

  return entityGroups;
};

export default useEntityGroups;
