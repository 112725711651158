import { FilterConditionDateTime } from '@allbin/entity-logic';
import { DateTime } from 'luxon';

export const tdFilterConditionBoolean: FilterCondition = {
  type: 'boolean',
  field: 'inventory.2',
  operator: 'false',
};

export const tdFilterConditionEnum: FilterCondition = {
  type: 'enum',
  field: 'inventory.40',
  operator: 'known',
};

export const tdFilterConditionStringA: FilterCondition = {
  type: 'string',
  field: 'meta.name',
  operator: 'eq',
  value: 'Fallänge',
};
export const tdFilterConditionStringB: FilterCondition = {
  type: 'string',
  field: 'meta.municipality.name',
  operator: 'not_matches',
  value: 'Sala',
};
export const tdFilterConditionNumberA: FilterCondition = {
  type: 'number',
  field: 'meta.municipality.code',
  operator: 'gt',
  value: 1981,
};
export const tdFilterConditionStringNoArg: FilterCondition = {
  type: 'string',
  field: 'meta.zone',
  operator: 'known',
};

export const tdFilterConditionDateTimeA: FilterConditionDateTime = {
  type: 'date',
  field: 'meta.last_inventory_taken',
  operator: 'between',
  value: [DateTime.now(), DateTime.now()],
};

export const tdFilterConditionDateTimeB: FilterConditionDateTime = {
  type: 'date',
  field: 'meta.last_inventory_taken',
  operator: 'before',
  value: DateTime.now(),
};

export const tdFilterA: ExecutionFilter = {
  active: true,
  filter: [
    tdFilterConditionBoolean,
    tdFilterConditionStringA,
    tdFilterConditionEnum,
    tdFilterConditionStringNoArg,
    tdFilterConditionNumberA,
  ],
};

export const tdFilterD: ExecutionFilter = {
  active: true,
  filter: [
    tdFilterConditionBoolean,
    tdFilterConditionStringA,
    tdFilterConditionEnum,
    tdFilterConditionStringNoArg,
    tdFilterConditionNumberA,
    tdFilterConditionStringB,
    tdFilterConditionStringA,
    tdFilterConditionEnum,
    tdFilterConditionStringA,
    tdFilterConditionEnum,
    tdFilterConditionStringA,
    tdFilterConditionEnum,
  ],
};

export const tdFilterB: ExecutionFilter = {
  active: true,
  filter: [tdFilterConditionStringB],
};
export const tdFilterC: ExecutionFilter = {
  active: true,
  filter: [tdFilterConditionNumberA],
};

export const tdFilterSets: FilterSet[] = [
  {
    id: '1a4243cf-9686-4f89-9089-4b7580e7f3e8',
    name: 'Björnligans filter',
    entity_type_id: 'stop',
    filters: [tdFilterA.filter, tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
  {
    id: 'a640b0eb-e6a6-4947-88f6-bec4192c1770',
    name: 'Mitt andra filter',
    entity_type_id: 'stop',
    filters: [tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
  {
    id: 'f496603c-c610-44b4-8d8b-d53c7ae75f18',
    name: 'Arnes filterset',
    entity_type_id: 'stop',
    filters: [tdFilterA.filter, tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
  {
    id: '41e1315a-68ae-4c01-965f-678d0d2f9d59',
    name: 'Ej handikappanpassade',
    entity_type_id: 'stop',
    filters: [tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
  {
    id: '44bbc229-c1c2-4c7e-90cf-099b7866e39b',
    name: 'Norr om åsen, med vindskydd',
    entity_type_id: 'stop',
    filters: [tdFilterA.filter, tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
  {
    id: 'dfe74c58-6169-47ac-a5c7-fabf9c3f0db4',
    name: 'Risk för skador vid dåligt väder',
    entity_type_id: 'stop',
    filters: [tdFilterB.filter],
    meta: {
      created_at: DateTime.now(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.now(),
    },
  },
];
