import React, { useCallback, useState } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
} from '@mui/material';
import Permissions from '../auth/Permissions';
import { FormattedMessage } from 'react-intl';
import {
  DriveFolderUpload,
  EventNote,
  Help,
  LabelOutlined,
  ListAlt,
  Preview,
  Replay,
  Work,
} from '@mui/icons-material';
import ImportRebusDataDialog from '../ImportRebusDataDialog/ImportRebusDataDialog';
import useSchemaManagementDialogStore from '../../store/schemaManagementDialog';
import useEditWorkOrderDialogStore from '../../store/editWorkOrderDialog';
import useCheckInManagementDialogStore from '../../store/checkInManagementDialog';
import useRecurringWorkOrderPlanManagementDialogStore from '../../store/recurringWOPlanManagementDialog';
import useWOInstructionManagementDialog from '../../store/woInstructionManagementDialog';
import useGuideStore from '../../store/guide';
import useTagManagementDialog from '../../store/tagManagementDialog';
import FeatureLicense from '../auth/FeatureLicense';

const MainMenu: React.FC<
  Pick<PopoverProps, 'open' | 'anchorEl' | 'onClose'>
> = (props) => {
  const { open: openEditWorkOrderDialog } = useEditWorkOrderDialogStore();
  const { open: openTagManagementDialog } = useTagManagementDialog();
  const { open: openCheckInManagementDialog } =
    useCheckInManagementDialogStore();
  const { open: openWOInstructionManagementDialog } =
    useWOInstructionManagementDialog();
  const { open: openRecurringWorkOrderPlanManagementDialog } =
    useRecurringWorkOrderPlanManagementDialogStore();
  const [importRebusDataOpen, setImportRebusDataOpen] = useState(false);
  const { setShow: setSchemaManagementDialogOpen } =
    useSchemaManagementDialogStore();

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose({}, 'backdropClick');
    }
  }, [props]);

  const { openGuide } = useGuideStore();

  return (
    <>
      <ImportRebusDataDialog
        open={importRebusDataOpen}
        onClose={() => setImportRebusDataOpen(false)}
      />
      <Popover
        {...props}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List dense>
          {/* <ListSubheader>
              <FormattedMessage
                id="main_menu.last_visited"
                defaultMessage="Last visited"
              />
            </ListSubheader>
            <ListItem button>
              <ListItemIcon>
                <DirectionsBus />
              </ListItemIcon>
              <ListItemText primary="Linje <placeholder>" secondary="test" />
            </ListItem> */}
          <Permissions has="workorders:create">
            <ListItem
              button
              onClick={() => {
                openEditWorkOrderDialog();
                onClose();
              }}
            >
              <ListItemIcon>
                <Work />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.create_work_order"
                    defaultMessage="Create work order"
                  />
                }
              />
            </ListItem>
            <Divider />
          </Permissions>
          <Divider />
          <Permissions has="entities:create">
            <ListItem
              button
              onClick={() => {
                setSchemaManagementDialogOpen(true);
                onClose();
              }}
            >
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.manage_schema"
                    defaultMessage="Manage schema"
                  />
                }
              />
            </ListItem>
          </Permissions>
          <Permissions oneOf={['recurring-workorder-plans:create']}>
            <ListItem
              button
              onClick={() => {
                openRecurringWorkOrderPlanManagementDialog();
                onClose();
              }}
            >
              <ListItemIcon>
                <Replay />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.manage_recurring_plans"
                    defaultMessage="Manage recurring work order plans"
                  />
                }
              />
            </ListItem>
          </Permissions>
          <Permissions oneOf={['checkin-plan:create']}>
            <ListItem
              button
              onClick={() => {
                openCheckInManagementDialog();
                onClose();
              }}
            >
              <ListItemIcon>
                <Preview />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.manage_check_in_plans"
                    defaultMessage="Manage check-in plans"
                  />
                }
              />
            </ListItem>
          </Permissions>
          <Permissions has="tags:update">
            <ListItem
              button
              onClick={() => {
                openTagManagementDialog();
                onClose();
              }}
            >
              <ListItemIcon>
                <LabelOutlined />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.manage_tags"
                    defaultMessage="Manage tags"
                  />
                }
              />
            </ListItem>
          </Permissions>
          <Permissions has="instructions:create">
            <ListItem
              button
              onClick={() => {
                openWOInstructionManagementDialog();
                onClose();
              }}
            >
              <ListItemIcon>
                <EventNote />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.manage_wo_templates"
                    defaultMessage="Manage work order templates"
                  />
                }
              />
            </ListItem>
          </Permissions>
          <Divider />
          {/* <ListSubheader>
              <FormattedMessage
                id="main_menu.export_data"
                defaultMessage="Export data"
              />
            </ListSubheader> */}
          {/* <ListItem button>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.export_to_rebus"
                    defaultMessage="Export to Rebus"
                  />
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.export_stop_pictures"
                    defaultMessage="Export stop pictures"
                  />
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="main_menu.export_report"
                    defaultMessage="Export report"
                  />
                }
              />
            </ListItem> */}
          {/* <ListItem
            button
            onClick={() => {
              generateExport('entityTable');
              onClose();
            }}
          >
            <ListItemIcon>
              <ImportExport />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage
                  id="main_menu.export_tables"
                  defaultMessage="Export tables"
                />
              }
            />
          </ListItem> */}

          <Permissions oneOf={['role:admin']}>
            <FeatureLicense has="rebus_import">
              <Divider />
              <ListItem button onClick={() => setImportRebusDataOpen(true)}>
                <ListItemIcon>
                  <DriveFolderUpload />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="main_menu.import_rebus_data"
                      defaultMessage="Import rebus data"
                    />
                  }
                />
              </ListItem>
            </FeatureLicense>
          </Permissions>
          <Divider />
          <ListItem
            button
            onClick={() => {
              openGuide();
              onClose();
            }}
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage id="main_menu.guide" defaultMessage="Help" />
              }
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default MainMenu;
