import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import useSearch from '../../hooks/useSearch';
import { SearchResult } from '../../store/search';
import SearchField from './SearchField';
import ResultCard from './ResultCard';
import ResultColumnHeader from './ResultColumnHeader';
import { useRoutes } from '../../hooks/useRoutes';
import useContractorManagementDialog from '../../store/contractorManagementDialog';
import useFiltering from '../../hooks/useFiltering';
import { useEntitySchema } from '../../hooks/useEntitySchema';
import { useFilterSets } from '../../hooks/useFilterSets';

interface SearchResultsContainerProps {
  /** Overrides default behavior of showing the results container when store has results. */
  show?: boolean;
  /** Clear the search results when a result is clicked. Defaults to true. */
  clearOnClick?: boolean;
}

const resultColumnOrder: SearchResult['type'][] = [
  'entityGroup',
  'workorder',
  'contractor',
  'filterSet',
];

const SearchResultContainer: FC<SearchResultsContainerProps> = ({
  show,
  clearOnClick,
}) => {
  const { results, doSearch, searchTerm, clearSearch } = useSearch();
  const {
    setShow: setContractorManagementDialogOpen,
    setSelectedContractorId,
  } = useContractorManagementDialog();
  const [input, setInput] = useState(searchTerm || '');
  const { filter } = useFiltering();
  const { data: schema } = useEntitySchema();
  const { lookup: filterSetLookup } = useFilterSets();
  const route = useRoutes();

  const onChange = useCallback(
    (value: string) => {
      setInput(value);
      doSearch(value);
    },
    [doSearch],
  );
  const onClearSearch = useCallback(() => {
    clearSearch();
    setInput('');
  }, [clearSearch]);

  const status = useMemo<'zero_results' | 'no_search' | 'success'>(() => {
    if (!results || input.trim().length === 0) {
      return 'no_search';
    } else if (
      results.contractor.length === 0 &&
      results.filterSet.length === 0 &&
      results.entityGroup.length === 0 &&
      results.workorder.length === 0
    ) {
      return 'zero_results';
    }
    return 'success';
  }, [input, results]);

  const onResultClick = useCallback(
    (result: SearchResult, action?: string) => {
      const type = result.type;
      if (clearOnClick !== false) {
        onClearSearch();
      }
      if (type === 'entityGroup') {
        route.goToStationDetails(result.id);
      } else if (type === 'workorder') {
        route.goToWorkOrderDetails(result.id);
      } else if (type === 'contractor') {
        if (action === 'manage') {
          onClearSearch();
          setSelectedContractorId(result.id);
          setContractorManagementDialogOpen(true);
        } else {
          route.goToWorkOrderOverview();
        }
      } else if (type === 'filterSet') {
        route.goToRoot();
        const filterSet = filterSetLookup[result.id];
        if (filterSet && schema) {
          filter(
            schema,
            filterSet.filters.map((f) => ({ active: true, filter: f })),
            false,
          );
        }
      } else {
        console.warn(
          `Click on '${result.type}' search result not implemented.`,
        );
      }
    },
    [
      clearOnClick,
      onClearSearch,
      route,
      setSelectedContractorId,
      setContractorManagementDialogOpen,
      filterSetLookup,
      schema,
      filter,
    ],
  );

  useEffect(() => {
    if (results && searchTerm) {
      setInput(searchTerm || '');
    }
  }, [results, searchTerm]);

  const onKeyDown = useCallback(
    (key: string) => {
      if (key === 'Escape') {
        onClearSearch();
      }
    },
    [onClearSearch],
  );

  if (show === false || searchTerm === null) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        minHeight: '100vh',
        padding: '0 3%',
        zIndex: 100000,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(230, 230, 230, 0.8)',
        overflowX: 'scroll',
        overflowY: 'hidden',
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClearSearch();
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <SearchField
            sx={{
              width: '50vw',
              backgroundColor: ({ palette }) =>
                alpha(palette.common.white, 0.9),
              '&:hover': {
                backgroundColor: ({ palette }) =>
                  alpha(palette.common.white, 0.95),
              },
            }}
            value={input}
            onChange={onChange}
            onKeyDown={onKeyDown}
            focus
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
          <Typography textAlign="center">
            <FormattedMessage
              id="search.how_to_close_info"
              defaultMessage="Press Escape or click anywhere on the background to cancel searching."
            />
          </Typography>
        </Box>
        {status === 'zero_results' && (
          <Typography variant="h3" mt={5} color="InfoText" component="span">
            <FormattedMessage
              id="search.zero_results"
              defaultMessage="The search has returned no results"
            />
          </Typography>
        )}
        {status === 'no_search' && (
          <Typography variant="h3" mt={5} color="InfoText" component="span">
            <FormattedMessage
              id="search.zero_results"
              defaultMessage="Use the search box above"
            />
          </Typography>
        )}
      </Box>
      {status === 'success' && (
        <Box display="flex" flexDirection="row" alignContent="space-between">
          {resultColumnOrder.map((colType) => {
            const res = results?.[colType] || [];
            return (
              <Box
                display="flex"
                flexDirection="column"
                m={3}
                key={colType}
                sx={{ width: '22vw' }}
              >
                <Box sx={{ width: '22vw' }}>
                  <ResultColumnHeader type={colType} empty={res.length === 0} />
                </Box>
                {res.slice(0, 6).map((result, i) => (
                  <Box key={result.type + '-' + result.id} mb={3}>
                    <ResultCard
                      result={result}
                      onClick={onResultClick}
                      noTopRadius={i === 0}
                    />
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SearchResultContainer;
