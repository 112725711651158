import { theme } from '../providers/ThemeProvider';

export const filterColors: string[] = [
  theme.palette.filter0,
  theme.palette.filter1,
  theme.palette.filter2,
  theme.palette.filter3,
  theme.palette.filter4,
];

export const unmatchedColor = '#cccccc';
export const unmatchedMapMarkerFilename = 'unmatched';

export const filterMapMarkerFilenames = [
  'filter0',
  'filter1',
  'filter2',
  'filter3',
  'filter4',
];

export const getFilterColor = (index: number): string => {
  //The index is -1 when a table row or marker represent an unmatched entity.
  if (index === -1) {
    return unmatchedColor;
  }
  return filterColors[index % filterColors.length];
};
