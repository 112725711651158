import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import decode, { JwtPayload } from 'jwt-decode';
import { ApiPermission } from '@allbin/mobilix-api-client';
import useUserStore from '../store/user';

interface JwtPayloadWithPermissions extends JwtPayload {
  permissions?: ApiPermission[];
  org_id?: string;
}

interface useGetAccessToken {
  getAccessToken: () => Promise<string>;
}

const useGetAccessToken = (): useGetAccessToken => {
  const { getAccessTokenSilently } = useAuth0();
  const { setOrgId, setPermissions, setUser } = useUserStore();

  const getAccessToken = useCallback(() => {
    // console.log('getAccessToken');

    return getAccessTokenSilently()
      .then((token) => {
        const decoded = decode<JwtPayloadWithPermissions>(token);
        setPermissions(decoded.permissions || []);
        return token;
      })
      .catch((err) => {
        setOrgId();
        setPermissions([]);
        setUser();
        window.localStorage.removeItem('orgId');
        console.error(err);
        return '';
      });
  }, [getAccessTokenSilently, setOrgId, setPermissions, setUser]);

  return { getAccessToken };
};

export default useGetAccessToken;
