import React from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ErrorReportListProps {
  errorReports: ErrorReport[];
  selectedErrorReport?: ErrorReport;
  setSelectedErrorReport: (errorReport: ErrorReport) => void;
  schemaDefinition: EntitySchemaDefinition;
  entityLookup: Record<string, Entity>;
}

const ErrorReportList: React.FC<ErrorReportListProps> = ({
  errorReports,
  setSelectedErrorReport,
  selectedErrorReport,
  schemaDefinition,
  entityLookup,
}) => {
  return (
    <List>
      {errorReports.length === 0 && (
        <Typography variant="body2">
          <FormattedMessage
            id="error_report.no_current_error_reports"
            defaultMessage="No current error reports"
          />
        </Typography>
      )}
      {errorReports.map((er) => {
        const { property_key, id, entity_id } = er;
        const entity = entityLookup[entity_id];
        const prop = schemaDefinition.propertiesLookup[property_key];
        if (!prop || !entity) {
          return null;
        }
        const { name } = prop;
        const { stop_letter } = entity;
        return (
          <ListItemButton
            key={id}
            selected={selectedErrorReport === er}
            onClick={() => setSelectedErrorReport(er)}
            dense
          >
            <ListItemText
              primary={name}
              secondary={
                <FormattedMessage
                  id="error_report.stop_x"
                  defaultMessage="Stop {stop_letter}"
                  values={{ stop_letter }}
                />
              }
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default ErrorReportList;
