import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface NewCheckInPlanData {
  entities: string[];
  route_plan: string[];
  periodicity: Periodicity;
  title: string;
  duration: number;
  id?: string;
  contractor_id?: string;
}

interface CheckInManagementDialogStore {
  show: boolean;
  /** Will clear all state variables, but will not close the dialog. */
  clear: (onlyNewPlanData?: true) => void;
  selectedCheckInPlanId?: string;
  inspectCheckInPlanId?: string;
  setInspectCheckInPlanId: (id?: string) => void;
  setSelectedCheckInPlanId: (selectedId: string | undefined) => void;
  /**
   * Uses provided props, otherwise uses previously set props, otherwise defaults.
   * Use clear function to reset data.
   */
  setNewCheckInPlanData: (data: Partial<NewCheckInPlanData>) => void;
  newCheckInPlanData?: NewCheckInPlanData;
  open: () => void;
  /** If clear is true the dialog state will be emptied. */
  close: (clear?: boolean) => void;
}

const useCheckInManagementDialogStore = create<CheckInManagementDialogStore>()(
  devtools(
    (set, get) => ({
      show: false,
      inspectCheckInPlanId: undefined,
      setInspectCheckInPlanId: (id) => {
        set({
          inspectCheckInPlanId: id,
          show: true,
          newCheckInPlanData: undefined,
          selectedCheckInPlanId: undefined,
        });
      },
      open: () => {
        set({ show: true });
      },
      clear: (onlyForm) => {
        set({
          newCheckInPlanData: undefined,
          inspectCheckInPlanId: undefined,
          selectedCheckInPlanId: onlyForm
            ? get().selectedCheckInPlanId
            : undefined,
        });
      },
      close: (clear) => {
        if (clear) {
          set({
            show: false,
            newCheckInPlanData: undefined,
            inspectCheckInPlanId: undefined,
            selectedCheckInPlanId: undefined,
          });
          return;
        }
        set({ show: false });
      },
      setNewCheckInPlanData: (data) => {
        const { newCheckInPlanData } = get();
        const newData: NewCheckInPlanData = {
          entities: [],
          periodicity: {
            type: 'yearly',
            occurrences: [],
          },
          duration: 7,
          route_plan: [],
          title: '',
          ...newCheckInPlanData,
          ...data,
        };
        set({
          newCheckInPlanData: newData,
          inspectCheckInPlanId: undefined,
          show: true,
        });
      },
      setSelectedCheckInPlanId: (selectedId) => {
        set({ selectedCheckInPlanId: selectedId });
      },
    }),
    {
      name: 'checkin-managament-dialog-store',
      enabled: process.env.NODE_ENV !== 'test',
    },
  ),
);

export default useCheckInManagementDialogStore;
