import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import {
  ApiErrorReport,
  ApiErrorReportRequest,
} from '@allbin/mobilix-api-client';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useApiClient } from './useApiClient';
import { useCallback } from 'react';

export const reviveErrorReport = (report: ApiErrorReport): ErrorReport => ({
  ...report,
  meta: reviveMetadata(report.meta),
});

const useErrorReportsListAndLookup = createListAndLookupStore(
  reviveErrorReport,
  'errorReports',
);

export const useErrorReports = (): UseListAndLookupQuery<
  ApiErrorReport,
  ErrorReport
> => {
  const { set, list, lookup } = useErrorReportsListAndLookup(
    useCallback((props) => props, []),
  );

  const apiClient = useApiClient();
  const query = useQuery<ApiErrorReport[], Error>(
    ['errorReport', 'list'],
    () => apiClient.errorReports.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

export const useErrorReport = (
  id: string,
): UseQueryResult<ErrorReport | undefined, Error> => {
  const client = useApiClient();
  return useQuery<ErrorReport | undefined, Error>(
    ['errorReport', 'get', id],
    () =>
      id ? client.errorReports.get(id).then(reviveErrorReport) : undefined,
  );
};

interface UseErrorReportsMutations {
  create: UseMutationResult<ApiErrorReport, Error, ApiErrorReportRequest>;
  remove: UseMutationResult<ApiErrorReport, Error, ErrorReport['id']>;
}

export const useErrorReportsMutations = (): UseErrorReportsMutations => {
  const client = useApiClient();
  const { merge, clear } = useErrorReportsListAndLookup(
    useCallback((props) => props, []),
  );

  const create: UseErrorReportsMutations['create'] = useMutation(
    ['errorReport', 'create'],
    (request) => client.errorReports.create(request),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseErrorReportsMutations['remove'] = useMutation(
    ['errorReport', 'remove'],
    (id) => client.errorReports.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return {
    create,
    remove,
  };
};
