import React, { useCallback } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Box, Chip, TextField } from '@mui/material';
import { sanitizedParseFloat } from '../../../helpers/math_helpers';

type StringProps = {
  type: 'string';
  value: string[];
  onChange: (value: any[]) => void;
};

type NumberProps = {
  type: 'number';
  value: number[];
  onChange: (value: any[]) => void;
};

type MultiTextFieldProps = StringProps | NumberProps;

const MultiTextField: React.FC<
  MultiTextFieldProps & Omit<TextFieldProps, 'type' | 'value' | 'onChange'>
> = ({ value, onChange, type, ...props }) => {
  const [text, setText] = React.useState('');

  const handleDelete = (i: number): void => {
    onChange([...value.slice(0, i), ...value.slice(i + 1)]);
  };

  const handleAdd = useCallback(
    (text: string) => {
      if (type === 'number') {
        const num = sanitizedParseFloat(text);
        if (isFinite(num)) {
          onChange([...value, num]);
        }
      } else {
        onChange([...value, text.trim()]);
      }

      setText('');
    },
    [onChange, type, value],
  );

  return (
    <Box>
      <Box>
        {value.map((item, i) => (
          <Chip
            key={`chip-${i}`}
            label={item}
            onDelete={() => handleDelete(i)}
            sx={{ marginRight: 1, marginBottom: 1 }}
          />
        ))}
      </Box>
      <TextField
        {...props}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          const trimmed = text.trim();
          if (e.key === 'Enter' && trimmed.length > 0) {
            if (type === 'number') {
              const parsed = sanitizedParseFloat(trimmed);
              if (!isFinite(parsed) || value.includes(parsed)) {
                setText('');
                return;
              }
            } else if (value.includes(trimmed)) {
              setText('');
              return;
            }
            handleAdd(trimmed);
          }
        }}
      />
    </Box>
  );
};

export default MultiTextField;
