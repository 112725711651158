import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Popover, TextField, Typography, useTheme } from '@mui/material';
import CaretDown from '../../icons/CaretDown';
import SquareChecked from '../../icons/SquareChecked';
import SquareUnchecked from '../../icons/SquareUnChecked';
import { useIntl } from 'react-intl';
import CircleChecked from '../../icons/CircleChecked';
import CircleUnchecked from '../../icons/CircleUnchecked';
import Fuse from 'fuse.js';
import { ColorProps } from '../../providers/ThemeProvider';
import InputContainer, { InputContainerProps } from './InputContainer';

interface DropdownProps extends Partial<InputContainerProps> {
  alternatives: { id: string; label: string }[];
  selectedIds?: string[];
  /** Defaults to false. */
  multiselect?: boolean;
  onSelect: (selections: string[]) => void;
  placeholder?: ReactNode;
  noAlternativesText?: ReactNode;
  /** The component will trigger `onSelect` callback immediately on every change instead of on component close. */
  immediateSelectMode?: boolean;
  /** The dropdown will close when the user has selected an alternative, defaults to true in `immediateSelectMode`, otherwise false. */
  closeOnSelect?: boolean;
  onClose?: () => void;
  /**
   * The number of alternatives to display in the dropdown. Defaults to 10. Must be a value greater than 0.
   *
   * If the number of alternatives is less than or equal to `displayCount` the filter field is disabled.
   */
  displayCount?: number;
  /** Controls display filter input field. Defaults to false. */
  enableFilterField?: boolean;
  /** Text displayed in the filter field when no text has been input. */
  filterInputPlaceholder?: string;
  /** Defaults to dark palette. */
  palette?: ColorProps;
  /** Do not sort alternatives array. Defaults to false. */
  noSort?: boolean;
  /**
   * When enabled will make the dropdown immeditaley close on select, ignore `selectedIds` and always show `placeholder` text in the input. No end-adornment is shown.
   *
   * `multiselect` is ignored and `immediateSelect` and `closeOnSelect` are enabled.
   */
  noSelectMode?: boolean;
}

interface DropdownEndAdornmentProps {
  noSelectMode?: boolean;
  isMulti?: boolean;
  isSelected?: boolean;
}
const DropdownEndAdornment: FC<DropdownEndAdornmentProps> = ({
  noSelectMode,
  isMulti,
  isSelected,
}) => {
  if (noSelectMode) {
    return null;
  }
  if (isMulti) {
    return isSelected ? <SquareChecked /> : <SquareUnchecked />;
  }
  return isSelected ? <CircleChecked /> : <CircleUnchecked />;
};

const Dropdown: FC<DropdownProps> = ({
  alternatives,
  displayCount,
  enableFilterField,
  filterInputPlaceholder,
  immediateSelectMode,
  closeOnSelect,
  multiselect,
  noAlternativesText,
  onClose,
  onSelect,
  placeholder,
  selectedIds,
  palette,
  noSort,
  noSelectMode,
  ...containerProps
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const colors = palette || theme.palette.dark;

  const [open, setOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(350);

  const [localIds, setLocalIds] = useState<string[]>(
    noSelectMode ? [] : selectedIds || [],
  );
  const [input, setInput] = useState<string | undefined>(undefined);

  const popoverAnchor = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();

  const localAlternatives = useMemo(() => {
    const seenIds: string[] = [];
    const filtered = alternatives.filter((a) => {
      if (seenIds.includes(a.id)) {
        throw new Error(
          'Duplicate alternatives sent to Dropdown component; An id duplicate was encountered.',
        );
      }
      seenIds.push(a.id);
      return true;
    });
    const sorted = noSort
      ? filtered
      : filtered.sort((a, b) =>
          a.label.localeCompare(b.label, intl.locale.split('-')[0]),
        );
    return {
      sorted,
      lookup: sorted.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.id] = curr.label;
        return acc;
      }, {}),
    };
  }, [alternatives, intl.locale, noSort]);

  const isMulti = useMemo(() => {
    if (noSelectMode) {
      return false;
    }
    return typeof multiselect === 'boolean' ? multiselect : false;
  }, [multiselect, noSelectMode]);
  const isCloseOnSelect = useMemo(() => {
    if (noSelectMode) {
      return true;
    }
    return typeof closeOnSelect === 'boolean'
      ? closeOnSelect
      : immediateSelectMode;
  }, [immediateSelectMode, closeOnSelect, noSelectMode]);

  const { altCount, showFilterField } = useMemo(() => {
    const count =
      typeof displayCount === 'number' && displayCount > 0 ? displayCount : 10;
    return {
      altCount:
        count >= localAlternatives.sorted.length
          ? localAlternatives.sorted.length
          : count,
      showFilterField:
        localAlternatives.sorted.length <= count ||
        typeof enableFilterField !== 'boolean'
          ? false
          : enableFilterField,
    };
  }, [localAlternatives.sorted.length, enableFilterField, displayCount]);

  const fuse = useMemo(() => {
    return new Fuse(localAlternatives.sorted, {
      threshold: 0.3,
      fieldNormWeight: 0,
      keys: ['label'],
    });
  }, [localAlternatives.sorted]);

  /** If array is empty an input was entered but there were no matches. */
  const hits = useMemo(() => {
    const trimmed = input ? input.trim() : '';
    if (trimmed.length > 0) {
      return fuse.search(trimmed).map((r) => r.item);
    }

    return !showFilterField
      ? localAlternatives.sorted
      : [...localAlternatives.sorted].sort((a, b) =>
          localIds.includes(a.id) && !localIds.includes(b.id) ? -1 : 1,
        );
    //This is disabled because we do not want to trigger sorting immediately when a user selects or deselects an alternative.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuse, input, localAlternatives.sorted, showFilterField]);

  const openPopover = useCallback(() => {
    setOpen(true);
    setInput(undefined);
    if (popoverAnchor.current) {
      setWidth(popoverAnchor.current.getBoundingClientRect().width);
    }
    setTimeout(() => {
      //Timeout required because the popover children is not mounted yet.
      //And even if using popover.keepMounted the input is not visible yet
      //and will not accept the focus command. :(
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, []);

  const onPopoverClose = useCallback(() => {
    //Flip type of input value so that all useMemo are run when closing the popover.
    setInput(typeof input === 'string' ? undefined : '');
    setOpen(false);
    !immediateSelectMode && onSelect(localIds);
    onClose && onClose();
  }, [immediateSelectMode, input, localIds, onClose, onSelect]);

  const onAlternativeClick = useCallback(
    (clickedId: string) => {
      if (noSelectMode) {
        onSelect([clickedId]);
        //Flip type of input value so that all useMemo are run when closing the popover.
        setInput(typeof input === 'string' ? undefined : '');
        setOpen(false);
        onClose && onClose();
        return;
      }
      if (!isMulti) {
        const newIds = [clickedId];
        setLocalIds(newIds);
        immediateSelectMode && onSelect(newIds);
        isCloseOnSelect && onPopoverClose();
        return;
      }
      const newIds = [...localIds];
      const idx = newIds.findIndex((id) => id === clickedId);
      if (idx > -1) {
        newIds.splice(idx, 1);
      } else {
        newIds.push(clickedId);
      }
      setLocalIds(newIds);
      immediateSelectMode && onSelect(newIds);
      isCloseOnSelect && onPopoverClose();
    },
    [
      noSelectMode,
      isMulti,
      localIds,
      immediateSelectMode,
      onSelect,
      isCloseOnSelect,
      onPopoverClose,
      input,
      onClose,
    ],
  );

  useEffect(() => {
    setLocalIds(
      !selectedIds || noSelectMode
        ? []
        : selectedIds.filter(
            (id) => localAlternatives.lookup[id] !== undefined,
          ),
    );
  }, [localAlternatives.lookup, selectedIds, noSelectMode]);

  const heights = useMemo(() => {
    const inputHeight = 36;
    const altHeight = 40;
    return {
      input: inputHeight,
      alternative: altHeight,
      popover:
        altCount === 0
          ? 0
          : (showFilterField ? inputHeight : 0) + altHeight * altCount,
      alternatives: altHeight * altCount,
    };
  }, [altCount, showFilterField]);

  return (
    <InputContainer palette={colors} {...containerProps}>
      <Box ref={popoverAnchor} position="relative">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            backgroundColor: open ? colors.a20 : 'unset',
            minWidth: 0,
            height: '40px',
            alignItems: 'center',
            borderRadius: 1,
            border: '1px solid ' + colors.medium,
            cursor: 'pointer',
            color: localIds.length > 0 ? colors.dark : colors.medium,
            p: 1,
            pl: 4,
            pr: 4,
            '&:hover': {
              backgroundColor: colors.a20,
              color: colors.dark,
            },
            '& path': {
              stroke: colors.dark,
            },
          }}
          onClick={() => openPopover()}
        >
          <Box minWidth={0} overflow="hidden">
            <Typography
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              variant="body2"
            >
              {localIds.length > 0
                ? localIds.map((id) => localAlternatives.lookup[id]).join(', ')
                : placeholder}
            </Typography>
          </Box>
          <CaretDown />
        </Box>
        <Popover
          anchorEl={popoverAnchor.current}
          open={!!popoverAnchor.current && open}
          onClose={onPopoverClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          PaperProps={{
            style: {
              borderRadius: '4px',
              overflow: 'hidden',
              height: heights.popover === 0 ? 'unset' : heights.popover + 'px',
            },
          }}
        >
          <Box
            sx={{
              minWidth: width + 'px',
              height: '100%',
              '& .alternative': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                height: heights.alternative + 'px',
                overflow: 'hidden',
                pl: 4,
                pr: 4,
                '&:hover': {
                  backgroundColor: colors.light,
                  color: colors.textOnLight,
                  '& path': {
                    stroke: colors.textOnLight,
                  },
                },
              },
            }}
          >
            {showFilterField && (
              <TextField
                inputRef={inputRef}
                fullWidth
                value={input || ''}
                variant="standard"
                placeholder={filterInputPlaceholder}
                onChange={(e) => setInput(e.currentTarget.value)}
                InputProps={{
                  sx: {
                    height: heights.input + 'px',
                    borderBottomColor: colors.main,
                    pl: 4,
                    '&:hover': {
                      color: colors.main,
                    },
                  },
                }}
              />
            )}
            {localAlternatives.sorted.length === 0 && noAlternativesText && (
              <Box p={3} maxWidth={width + 'px'}>
                <Typography variant="body2">{noAlternativesText}</Typography>
              </Box>
            )}
            <Box
              sx={{
                height: heights.alternatives + 'px',
                overflow: 'auto',
              }}
            >
              {hits.map((a) => (
                <Box
                  key={a.id}
                  className="alternative"
                  onClick={() => onAlternativeClick(a.id)}
                >
                  <Typography>{a.label}</Typography>
                  <DropdownEndAdornment
                    noSelectMode={noSelectMode}
                    isMulti={isMulti}
                    isSelected={localIds.includes(a.id)}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Popover>
      </Box>
    </InputContainer>
  );
};

export default Dropdown;
