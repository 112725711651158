import React, { ReactElement, useState, useCallback } from 'react';
import {
  alpha,
  Autocomplete,
  Checkbox,
  FilterOptionsState,
  InputBase,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

interface SearchFieldProps<T extends { id: string }> {
  value: Array<T['id']>;
  onChange: (value: Array<T['id']>) => void;
  onInputChange?: (value: string) => void;
  options: Array<T['id']>;
  filterOptions: (
    options: T['id'][],
    state: FilterOptionsState<T['id']>,
  ) => T['id'][];
  renderOption: (id: T['id']) => ReactElement | string | undefined;
  noDeleteOnBackspace?: boolean;
  /** Defaults to true. */
  disablePortal?: boolean;
}

const messages = defineMessages({
  search: { id: 'searchfield.search', defaultMessage: 'Search' },
});

function SearchField<T extends { id: string }>({
  value,
  onChange,
  onInputChange,
  options,
  filterOptions,
  renderOption,
  noDeleteOnBackspace,
  disablePortal,
}: SearchFieldProps<T>): JSX.Element {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, value: string, reason: string): void => {
      if (event && event.type === 'blur') {
        setInputValue('');
        onInputChange && onInputChange('');
      } else if (reason !== 'reset') {
        setInputValue(value);
        onInputChange && onInputChange(value);
      }
    },
    [setInputValue, onInputChange],
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent, value: Array<T['id']>, reason: string): void => {
      if (noDeleteOnBackspace && reason === 'removeOption') {
        return;
      }
      onChange(value);
    },
    [onChange, noDeleteOnBackspace],
  );

  return (
    <Autocomplete<T['id'], true>
      multiple
      disableCloseOnSelect
      disablePortal={disablePortal !== undefined ? disablePortal : true}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      noOptionsText={
        <FormattedMessage
          id="search_field.no_search_results"
          defaultMessage="No search results"
        />
      }
      onInputChange={handleInputChange}
      renderInput={(params) => {
        return (
          <InputBase
            id={params.id}
            ref={params.InputProps.ref}
            placeholder={`${intl.formatMessage(messages.search)}...`}
            startAdornment={<Search sx={{ ml: 0, mr: 1 }} />}
            inputProps={params.inputProps}
            sx={{
              borderRadius: ({ shape }) => shape.borderRadius,
              padding: 2,
              backgroundColor: ({ palette }) =>
                alpha(palette.common.black, 0.04),
              '&:hover': {
                backgroundColor: ({ palette }) =>
                  alpha(palette.common.black, 0.08),
              },
              marginTop: ({ spacing }) => spacing(2),
              marginBottom: ({ spacing }) => spacing(2),
              color: 'inherit',
              width: '100%',
              '& .MuiInputBase-input': {
                width: '100%',
              },
            }}
          />
        );
      }}
      renderTags={() => null}
      options={options}
      filterOptions={filterOptions}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option}>
            <Checkbox checked={selected} />
            {renderOption(option)}
          </li>
        );
      }}
    />
  );
}

export default SearchField;
