import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface Version {
  version?: string;
}

interface UseSentry {
  loaded: boolean;
}

export const useSentry = (): UseSentry => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    void axios
      .get<Version>('/version.json')
      .then(({ data }) => data.version)
      .then((version) => {
        Sentry.init({
          enabled: !!version && !version.includes('internal'),
          dsn: 'https://0d7e07028d4542d297d8979182202b56@o1409683.ingest.sentry.io/4504484945526784',
          tunnel: 'https://sentry.allbin.se',
          integrations: [
            new BrowserTracing({
              routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
              ),
            }),
          ],
          environment: import.meta.env.VITE_SENTRY_ENV,

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,
        });
        setLoaded(true);
      });
  }, []);

  return { loaded };
};
