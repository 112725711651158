import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useUserProfiles } from '../../hooks/useUserProfiles';
import { useLocaleStore } from '../../store/locale';

const UserProfile: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    profile: { data: profile },
  } = useUserProfiles();
  const setLocale = useLocaleStore(
    useCallback(({ setLocale }) => setLocale, []),
  );

  useEffect(() => {
    if (profile) {
      setLocale(profile.language);
    }
  }, [setLocale, profile]);

  if (!profile) {
    return null;
  }

  return <>{children}</>;
};

export default UserProfile;
