import { DateTime } from 'luxon';
import { createLongNameId } from 'mnemonic-id';
import { v4 } from 'uuid';
import { tdFilterSets } from './filter';
import { dateFormat } from '../helpers/datetime_helpers';

export const tdApiRecurringPlans: ApiRecurringWorkOrderPlan[] = [
  {
    id: v4(),
    title: createLongNameId(),
    valid_from: DateTime.now().toISO(),
    tags: [],
    periodicity: {
      type: 'yearly',
      occurrences: [{ date: 1, month: 2 }],
    },
    meta: {
      created_at: DateTime.fromFormat('2021-08-11', dateFormat).toISO(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.fromFormat('2021-08-14', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    creation_offset: 10,
    description: `Description with newline.\nThis plan has a valid_to date! This plan has a valid_to date!\n\nDo thing A and B and then C and A again. Repeat.`,
    entity_type_id: 'stop',
    filterset_id: tdFilterSets[0].id,
    valid_to: DateTime.now().plus({ months: 10 }).toISO(),
  },
  {
    id: v4(),
    title: createLongNameId(),
    valid_from: DateTime.now().minus({ days: 10 }).toISO(),
    tags: [],
    periodicity: {
      type: 'monthly',
      occurrences: [{ date: 15 }],
    },
    meta: {
      created_at: DateTime.fromFormat('2021-09-01', dateFormat).toISO(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.fromFormat('2022-01-03', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    creation_offset: 30,
    description: `Description with newline.\nMissing valid_to!! !! !! !! !!\n\nDo thing A and B and then C and A again. Repeat.`,
    entity_type_id: 'stop',
    filterset_id: tdFilterSets[1].id,
  },
  {
    id: v4(),
    title: createLongNameId(),
    valid_from: DateTime.now().minus({ days: 30 }).toISO(),
    tags: [],
    periodicity: {
      type: 'yearly',
      occurrences: [
        { date: 1, month: 2 },
        { date: 1, month: 8 },
      ],
    },
    meta: {
      created_at: DateTime.fromFormat('2021-09-01', dateFormat).toISO(),
      created_by: 'auth0|6138bc01fc2828006ad2fa43',
      updated_at: DateTime.fromFormat('2022-01-03', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    creation_offset: 5,
    description: `Description. Work work work work work. Then work some more. å ä ö`,
    entity_type_id: 'stop',
    filterset_id: tdFilterSets[2].id,
  },
];
