import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Box, IconButton, Popover, Typography } from '@mui/material';

import { ColorProps } from '../../providers/ThemeProvider';
import { Clear } from '@mui/icons-material';
import Dropdown from '../Inputs/Dropdown';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import DateRangeInput, { DateRangeInputProps } from '../Inputs/DateRangeInput';
import { dateFormat } from '../../helpers/datetime_helpers';

export interface TopListDataSettings {
  dateRange: TopListSettingsDateRangeType;
  minDate: DateTime;
  maxDate: DateTime;
  entryCount: number;
}

interface TopListSettingsProps {
  anchorEl: Element;
  open: boolean;
  onClose: (settings: TopListDataSettings) => void;
  colors: ColorProps;
  title: ReactNode;
  settings: TopListDataSettings;
}

const countAlternatives = Array(10)
  .fill(null)
  .map((_, i) => ({
    id: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

export type TopListSettingsDateRangeType =
  | 'last_week'
  | 'previous_7_days'
  | 'previous_14_days'
  | 'previous_30_days'
  | 'last_month'
  | 'date_range';

const minSelectableDate = DateTime.fromFormat('2010-01-01', dateFormat);

const setMidnight = (date: DateTime): DateTime =>
  date.set({ hour: 23, minute: 59, second: 59 });

const messages = defineMessages({
  lastWeek: {
    id: 'top_list_settings.last_week',
    defaultMessage: 'Last week',
  },
  lastMonth: {
    id: 'top_list_settings.last_month',
    defaultMessage: 'Last month',
  },
  previous7Days: {
    id: 'top_list_settings.previous_7_days',
    defaultMessage: 'Previous 7 days',
  },
  previous14Days: {
    id: 'top_list_settings.previous_14_days',
    defaultMessage: 'Previous 14 days',
  },
  previous30Days: {
    id: 'top_list_settings.previous_30_days',
    defaultMessage: 'Previous 30 days',
  },
  dateRange: {
    id: 'top_list_settings.date_range',
    defaultMessage: 'Specific dates',
  },
});

const TopListSettings: FC<TopListSettingsProps> = ({
  open,
  onClose,
  anchorEl,
  colors,
  title,
  settings,
}) => {
  const intl = useIntl();

  const [dateRange, setDateRange] = useState<TopListSettingsDateRangeType>(
    settings.dateRange,
  );
  const [entryCount, setEntryCount] = useState<string[]>([
    settings.entryCount.toString(),
  ]);
  const [minDate, setMinDate] = useState<DateTime | undefined>(
    settings.minDate,
  );
  const [maxDate, setMaxDate] = useState<DateTime | undefined>(
    settings.maxDate,
  );
  const [isValid, setIsValid] = useState<boolean>(true);

  const dateRangeAlternatives = useMemo(() => {
    return [
      {
        id: 'last_week',
        label: intl.formatMessage(messages.lastWeek),
      },
      {
        id: 'last_month',
        label: intl.formatMessage(messages.lastMonth),
      },
      {
        id: 'previous_7_days',
        label: intl.formatMessage(messages.previous7Days),
      },
      {
        id: 'previous_14_days',
        label: intl.formatMessage(messages.previous14Days),
      },
      {
        id: 'previous_30_days',
        label: intl.formatMessage(messages.previous30Days),
      },
      {
        id: 'date_range',
        label: intl.formatMessage(messages.dateRange),
      },
    ];
  }, [intl]);

  const tryClose = useCallback(() => {
    if (!isValid || !minDate || !maxDate || typeof entryCount[0] !== 'string') {
      return;
    }
    const count = parseInt(entryCount[0], 10);
    if (!isFinite(count) || count < 0 || count > countAlternatives.length) {
      return;
    }
    let min: DateTime;
    let max: DateTime;
    const yesterday = DateTime.now()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .minus({ day: 1 });

    switch (dateRange) {
      case 'last_month': {
        min = yesterday.set({ day: 1 }).minus({ month: 1 });
        max = min.set({ day: min.daysInMonth });
        break;
      }
      case 'last_week': {
        min = yesterday.minus({ days: DateTime.now().weekday - 1 + 6 });
        max = min.plus({ days: 6 });
        break;
      }
      case 'previous_7_days': {
        min = yesterday.minus({ days: 7 });
        max = yesterday;
        break;
      }
      case 'previous_14_days': {
        min = yesterday.minus({ days: 14 });
        max = yesterday;
        break;
      }
      case 'previous_30_days': {
        min = yesterday.minus({ days: 30 });
        max = yesterday;
        break;
      }
      case 'date_range': {
        min = minDate;
        max = maxDate;
        break;
      }
      default:
        throw new Error('Unknown dateRange type in TopListSettings.');
    }

    onClose({
      dateRange,
      entryCount: count,
      maxDate: setMidnight(max),
      minDate: min,
    });
  }, [onClose, dateRange, entryCount, minDate, maxDate, isValid]);

  const onDateRangeChange: DateRangeInputProps['onChange'] = useCallback(
    (minOrMax, value) => {
      if (minOrMax === 'min') {
        setMinDate(value);
        return;
      }
      setMaxDate(value);
    },
    [],
  );

  const yesterday = DateTime.now()
    .set({ minute: 0, hour: 0, second: 0 })
    .minus({ days: 1 });

  return (
    <Popover
      open={open}
      PaperProps={{ sx: { width: '400px' } }}
      onClose={tryClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorEl={anchorEl}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '40px',
          p: 2,
          pl: 6,
          pr: 3,
          alignItems: 'center',
          backgroundColor: colors.main,
          color: colors.contrastText,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton sx={{ color: 'inherit' }} onClick={tryClose}>
          <Clear />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '100%',
          p: 6,
          pt: 4,
          pb: 4,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Dropdown
          label={
            <FormattedMessage
              defaultMessage="Count"
              id="top_list_settings.count_label"
            />
          }
          palette={colors}
          alternatives={countAlternatives}
          selectedIds={entryCount}
          immediateSelectMode
          noSort
          displayCount={countAlternatives.length}
          onSelect={setEntryCount}
        />
        <Dropdown
          label={
            <FormattedMessage
              defaultMessage="Date range"
              id="top_list_settings.date_range_label"
            />
          }
          palette={colors}
          alternatives={dateRangeAlternatives}
          selectedIds={[dateRange]}
          immediateSelectMode
          displayCount={dateRangeAlternatives.length}
          onSelect={(ids) =>
            setDateRange(ids[0] as TopListSettingsDateRangeType)
          }
        />
        {dateRange === 'date_range' && (
          <DateRangeInput
            label={
              <FormattedMessage
                defaultMessage="Fetch data between"
                id="top_list_settings.date_range_input_label"
              />
            }
            minDate={minSelectableDate}
            maxDate={yesterday}
            palette={colors}
            minValue={minDate}
            maxValue={maxDate}
            onValidityCheck={setIsValid}
            onChange={onDateRangeChange}
          />
        )}
      </Box>
    </Popover>
  );
};

export default TopListSettings;
