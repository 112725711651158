import React, { FC, useState } from 'react';
import { Box, Button } from '@mui/material';
import EntityImageUploadDialog from '../../../components/EntityGroup/EntityImageUploadDialog/EntityImageUploadDialog';
import { reviveEntity } from '../../../hooks/useEntities';
import { tdGetEntities } from '../../../testdata/entities';
import { reviveEntitySchema } from '../../../hooks/useEntitySchema';
import { tdSchema } from '../../../testdata/schema';

const tdEntities = tdGetEntities();

const GEntityImageUploadDialog: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const schema = reviveEntitySchema(tdSchema);
  const entities = tdEntities.slice(0, 2).map((e) => reviveEntity(e, schema));
  return (
    <Box>
      <Button
        sx={{ mb: 10 }}
        onClick={() => setOpen(!open)}
        variant="contained"
      >
        Open dialog
      </Button>
      <EntityImageUploadDialog
        open={open}
        entities={entities}
        schema={schema}
        onClose={() => setOpen(false)}
        onSave={(entity, propKey, file) =>
          console.log('EntityImageUploadDialog: OnSave:', entity, propKey, file)
        }
      />
    </Box>
  );
};

export default GEntityImageUploadDialog;
