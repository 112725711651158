import React, { FC, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SnackbarProvider } from 'notistack';

import { ConfigProvider } from './ConfigProvider';
import { LocaleProvider } from './LocaleProvider';
import ThemeProvider from './ThemeProvider';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import * as Tooltip from '@radix-ui/react-tooltip';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
    },
  },
});
const Providers: FC<{ children: ReactNode; includeAuth: boolean }> = ({
  children,
  includeAuth,
}) => {
  return (
    <ConfigProvider>
      <ThemeProvider>
        <Tooltip.Provider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Auth0ProviderWithHistory enabled={includeAuth}>
                <LocaleProvider>
                  <DndProvider backend={HTML5Backend}>
                    <SnackbarProvider
                      maxSnack={3}
                      autoHideDuration={3000}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      {children}
                    </SnackbarProvider>
                  </DndProvider>
                </LocaleProvider>
              </Auth0ProviderWithHistory>
            </QueryClientProvider>
          </BrowserRouter>
        </Tooltip.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default Providers;
