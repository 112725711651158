import React, { FC, useCallback, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Tab from '../../components/Tabs/Tab';
import { Help } from '@mui/icons-material';
import { theme } from '../../providers/ThemeProvider';

const cb = (id: string): void => console.log('Tab: onClick', id);
const injectionArgs = {
  id: 'injection-tab',
  onClick: cb,
  endAdornment: (
    <IconButton>
      <Help />
    </IconButton>
  ),
  startAdornment: <Help />,
};
const trafficArgs = {
  id: 'traffic-tab',
  onClick: cb,
  palette: theme.palette.traffic,
};
const darkArgs = {
  id: 'dark-tab',
  onClick: cb,
  palette: theme.palette.dark,
};
const entityArgs = {
  id: 'entity-tab',
  onClick: cb,
  palette: theme.palette.entity,
};
const GTab: FC = () => {
  const [labels, setLabels] = useState<{ [key: string]: string }>({
    tab0: 'I am a tab',
    tab1: 'Disabled tab',
    tab2: 'I am selected',
    tab3: 'This is a tab with a long text',
    tab4: 'Tab',
  });
  const onRename = useCallback(
    (id: string, label: string) => {
      setLabels({ ...labels, [id]: label });
    },
    [labels],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
      }}
    >
      <Box>
        Default tab
        <Box display="flex">
          <Tab id="gallery-tab" onClick={cb} label={'I am a tab'} />
          <Tab id="gallery-tab" onClick={cb} disabled label={'Disabled tab'} />
          <Tab id="gallery-tab" onClick={cb} selected label={'I am selected'} />
          <Tab
            id="gallery-tab"
            onClick={cb}
            label={'This is a tab with a long text'}
          />
          <Tab id="gallery-tab" onClick={cb} label={'Tab'} />
        </Box>
      </Box>
      <Box>
        Tabs with injected startAdornment icon and endAdornment icon-button. The
        icon button will need to be styled before injecting.
        <Box display="flex">
          <Tab {...injectionArgs} label={'I am a tab'} />
          <Tab {...injectionArgs} disabled label={'Disabled tab'} />
          <Tab {...injectionArgs} selected label={'I am selected'} />
          <Tab {...injectionArgs} label={'This is a tab with a long text'} />
          <Tab {...injectionArgs} label={'Tab'} />
        </Box>
      </Box>
      <Box>
        Tabs with traffic palette.
        <Box display="flex">
          <Tab {...trafficArgs} label={'I am a tab'} />
          <Tab {...trafficArgs} disabled label={'Disabled tab'} />
          <Tab {...trafficArgs} selected label={'I am selected'} />
          <Tab {...trafficArgs} label={'This is a tab with a long text'} />
          <Tab {...trafficArgs} label={'Tab'} />
        </Box>
      </Box>
      <Box>
        Tabs with dark palette.
        <Box display="flex">
          <Tab {...darkArgs} label={'I am a tab'} />
          <Tab {...darkArgs} disabled label={'Disabled tab'} />
          <Tab {...darkArgs} selected label={'I am selected'} />
          <Tab {...darkArgs} label={'This is a tab with a long text'} />
          <Tab {...darkArgs} label={'Tab'} />
        </Box>
      </Box>
      <Box>
        Tabs with entity palette.
        <Box display="flex">
          <Tab {...entityArgs} label={'I am a tab'} />
          <Tab {...entityArgs} disabled label={'Disabled tab'} />
          <Tab {...entityArgs} selected label={'I am selected'} />
          <Tab {...entityArgs} label={'This is a tab with a long text'} />
          <Tab {...entityArgs} label={'Tab'} />
        </Box>
      </Box>
      <Box>
        Renameable tabs (only a selected tab is renameable!)
        <Box display="flex">
          <Tab
            {...entityArgs}
            id="tab0"
            label={labels['tab0']}
            onRename={onRename}
          />
          <Tab
            {...entityArgs}
            disabled
            id="tab1"
            label={labels['tab1']}
            onRename={onRename}
          />
          <Tab
            {...entityArgs}
            selected
            id="tab2"
            label={labels['tab2']}
            onRename={onRename}
          />
          <Tab
            {...entityArgs}
            id="tab3"
            label={labels['tab3']}
            onRename={onRename}
          />
          <Tab
            {...entityArgs}
            id="tab4"
            label={labels['tab4']}
            onRename={onRename}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GTab;
