import { Help, Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';
import { useEntities } from '../../hooks/useEntities';
import { useEntitySchema } from '../../hooks/useEntitySchema';
import { calculateFilteredEntities } from '../../hooks/useFiltering';
import { useFilterSets } from '../../hooks/useFilterSets';
import {
  useRecurringWOPlans,
  useRecurringWOPlanMutations,
  reviveRecurringWOPlan,
} from '../../hooks/useRecurringWorkOrderPlans';
import useGuideStore from '../../store/guide';
import useRecurringWorkOrderPlanManagementDialogStore from '../../store/recurringWOPlanManagementDialog';
import useUserStore from '../../store/user';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import EditRecurringWOPlanForm from './EditRecurringWOPlanForm';
import RecurringWOPlanList from './RecurringWOPlanList';
import Permissions from '../auth/Permissions';
import { DateTime } from 'luxon';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';
import { useUserLookup } from '../../hooks/useUserLookup';

export type PlanValidityResults = Partial<Record<keyof RecurringPlan, string>>;

const messages = defineMessages({
  update_success: {
    id: 'recurring_plan_dialog.update_success',
    defaultMessage: 'Recurring work order plan has been updated.',
  },
  delete_success: {
    id: 'recurring_plan_dialog.delete_success',
    defaultMessage: 'Recurring work order plan has been removed.',
  },
  invalid_title: {
    id: 'recurring_plan_dialog.invalid_title',
    defaultMessage: 'Title must be at least one character.',
  },
  invalid_description: {
    id: 'recurring_plan_dialog.invalid_description',
    defaultMessage: 'Description must be at least one character.',
  },
  invalid_creation_offset: {
    id: 'recurring_plan_dialog.invalid_creation_offset',
    defaultMessage: 'Creation offset must be a positive value greater than 0.',
  },
  invalid_filterset_id: {
    id: 'recurring_plan_dialog.invalid_filterset_id',
    defaultMessage: 'A filterset must be chosen.',
  },
  invalid_periodicity: {
    id: 'recurring_plan_dialog.invalid_periodicity',
    defaultMessage: 'At least one ocurrence must be added.',
  },
  invalid_valid_from: {
    id: 'recurring_plan_dialog.invalid_valid_from',
    defaultMessage: 'A start date must be chosen for the plan.',
  },
});

const checkRecurringPlanValidity = (
  {
    title,
    creation_offset,
    filterset_id,
    periodicity,
    description,
    valid_from,
  }: Partial<RecurringPlan>,
  intl: IntlShape,
): PlanValidityResults => {
  const results: PlanValidityResults = {};
  if (!title || title.length < 1) {
    results.title = intl.formatMessage(messages.invalid_title);
  }
  if (!description || description.length < 1) {
    results.description = intl.formatMessage(messages.invalid_title);
  }
  if (typeof creation_offset !== 'number' || creation_offset < 1) {
    results.creation_offset = intl.formatMessage(
      messages.invalid_creation_offset,
    );
  }
  if (!filterset_id || filterset_id.length < 1) {
    results.filterset_id = intl.formatMessage(messages.invalid_filterset_id);
  }
  if (!periodicity || periodicity.occurrences.length === 0) {
    results.periodicity = intl.formatMessage(messages.invalid_periodicity);
  }
  if (!valid_from) {
    results.valid_from = intl.formatMessage(messages.invalid_valid_from);
  }
  return results;
};

const defaultDialogState = {
  showSuccess: '',
  showCancelFormDialog: false,
  showConfirmationDialog: false,
  showRemoveConfirmation: false,
};

const RecurringWOPlanManagementDialog: FC = () => {
  const intl = useIntl();
  const { list: filterSets, lookup: filterSetLookup } = useFilterSets();
  const { list: entities, lookup: entityLookup } = useEntities();
  const schema = useEntitySchema();
  const { show, close, selectedPlan, setSelectedPlan } =
    useRecurringWorkOrderPlanManagementDialogStore();
  const { user: authUser } = useUserStore();
  const { list: recurringPlans, lookup: recurringPlansLookup } =
    useRecurringWOPlans();
  const { userLookup } = useUserLookup();

  const {
    create: {
      mutateAsync: createRecurringWOPlan,
      isLoading: createRecurringWOPlanInProgress,
    },
    update: {
      mutateAsync: updateRecurringWOPlan,
      isLoading: updateRecurringWOPlanInProgress,
    },
    remove: {
      mutateAsync: removeRecurringWOPlan,
      isLoading: removeRecurringWOPlanInProgress,
    },
  } = useRecurringWOPlanMutations();
  const { openGuide } = useGuideStore();

  const [formData, setFormData] = useState<Partial<RecurringPlan> | null>(null);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);

  const [dialogs, setDialogs] = useState(defaultDialogState);

  const user = useMemo(() => {
    if (authUser && authUser.sub) {
      return userLookup(authUser.sub);
    }
    return undefined;
  }, [authUser, userLookup]);

  useEffect(() => {
    if (selectedPlan && !formData) {
      setFormData({ ...selectedPlan });
    }
  }, [formData, selectedPlan]);

  const filteredEntityNames = useMemo(() => {
    if (!formData?.filterset_id || !schema.data) {
      return [];
    }
    const filterSet = filterSetLookup[formData.filterset_id];
    if (!filterSet) {
      return [];
    }
    const results = calculateFilteredEntities(
      entities,
      entityLookup,
      filterSet.filters.map((f) => ({ active: true, filter: f })),
      schema.data,
      true,
    );
    return results.final.map((e) => e.full_name);
  }, [
    entities,
    entityLookup,
    filterSetLookup,
    formData?.filterset_id,
    schema.data,
  ]);

  const formValidity = useMemo(() => {
    if (!formData) {
      return {};
    }
    return checkRecurringPlanValidity(formData, intl);
  }, [formData, intl]);

  const onClose = (): void => {
    setDialogs(defaultDialogState);
    setShowUnsavedChanges(false);
    close();
    setFormData(null);
    setSelectedPlan(undefined);
  };

  const tryClose = (): void => {
    if (showUnsavedChanges) {
      setDialogs({ ...dialogs, showConfirmationDialog: true });
      return;
    }
    onClose();
  };

  const onSelect = useCallback(
    (planId: string) => {
      const plan = recurringPlansLookup[planId];
      if (!plan) {
        throw new Error('Could not find selected recurring plan.');
      }
      setSelectedPlan(plan);
    },
    [recurringPlansLookup, setSelectedPlan],
  );

  const onNewPlan = useCallback(() => {
    if (formData) {
      throw new Error(
        'Tried to create new recurring plan with an occupied form.',
      );
    }
    if (!user) {
      throw new Error(
        'Tried to create new recurring plan without a user loaded.',
      );
    }
    let contractors: string[] = [];
    if (!user.admin && user.contractors) {
      contractors = user.contractors.filter((c) => c.admin).map((c) => c.id);
      if (contractors.length === 0) {
        throw new Error(
          'Tried to create new recurring plan with non-admin user and which is not admin of any contractor.',
        );
      }
    } else if (!user.admin && !user.contractors) {
      throw new Error(
        'Tried to create new recurring plan with non-admin user without contractors assigned.',
      );
    }
    setFormData({
      periodicity: {
        type: 'yearly',
        occurrences: [{ month: 1, date: 1 }],
      },
      creation_offset: 7,
      contractors,
      valid_from: DateTime.now(),
      tags: [],
    });
  }, [formData, user]);

  const onCreatePlan = useCallback(() => {
    if (!formData) {
      throw new Error('Tried to create new recurring plan with empty form.');
    }
    if (Object.keys(formValidity).length > 0) {
      throw new Error(
        'Tried to create new recurring plan with invalid form data.',
      );
    }
    createRecurringWOPlan(formData as Omit<RecurringPlan, 'meta'>)
      .then(() => {
        setSelectedPlan(undefined);
        setFormData(null);
      })
      .catch((err) => {
        throw err;
      });
  }, [createRecurringWOPlan, formData, formValidity, setSelectedPlan]);
  const onUpdatePlan = useCallback(() => {
    if (!formData || !formData.id) {
      throw new Error(
        'Tried to update recurring plan with empty form or missing id.',
      );
    }
    if (Object.keys(formValidity).length > 0) {
      throw new Error('Tried to update recurring plan with invalid form data.');
    }
    updateRecurringWOPlan(formData as Omit<RecurringPlan, 'meta'>)
      .then((plan) => {
        setFormData(null);
        setSelectedPlan(reviveRecurringWOPlan(plan));
        setDialogs({
          ...dialogs,
          showRemoveConfirmation: false,
          showSuccess: intl.formatMessage(messages.update_success),
        });
      })
      .catch((err) => {
        throw err;
      });
  }, [
    formData,
    formValidity,
    intl,
    setSelectedPlan,
    updateRecurringWOPlan,
    dialogs,
  ]);
  const onSave = useCallback(() => {
    if (!formData) {
      throw new Error('Tried to save recurring plan with empty form.');
    }
    if (formData.id) {
      onUpdatePlan();
    } else {
      onCreatePlan();
    }
  }, [formData, onCreatePlan, onUpdatePlan]);

  const onTryRemove = useCallback(() => {
    setDialogs({ ...dialogs, showRemoveConfirmation: true });
  }, [dialogs]);

  const onRemove = useCallback(() => {
    if (!formData || !formData.id) {
      throw new Error('Tried to remove recurring plan without plan selected.');
    }
    removeRecurringWOPlan(formData.id)
      .then(() => {
        setDialogs({
          ...dialogs,
          showRemoveConfirmation: false,
          showSuccess: intl.formatMessage(messages.delete_success),
        });
        setSelectedPlan(undefined);
        setFormData(null);
      })
      .catch((err) => {
        throw err;
      });
  }, [formData, intl, removeRecurringWOPlan, setSelectedPlan, dialogs]);

  const onCancelForm = useCallback(() => {
    setDialogs(defaultDialogState);
    setFormData(null);
    setSelectedPlan(undefined);
    setShowUnsavedChanges(false);
  }, [setSelectedPlan]);

  const onTryCancelForm = useCallback(() => {
    if (showUnsavedChanges) {
      setDialogs({ ...dialogs, showCancelFormDialog: true });
      return;
    }
    onCancelForm();
  }, [dialogs, onCancelForm, showUnsavedChanges]);

  const onFormChange = useCallback(
    (form: Partial<RecurringPlan>) => {
      setFormData({
        ...formData,
        ...form,
      });
      setShowUnsavedChanges(true);
    },
    [formData],
  );

  const isProcessing = useMemo(() => {
    return (
      createRecurringWOPlanInProgress ||
      updateRecurringWOPlanInProgress ||
      removeRecurringWOPlanInProgress
    );
  }, [
    createRecurringWOPlanInProgress,
    removeRecurringWOPlanInProgress,
    updateRecurringWOPlanInProgress,
  ]);

  if (!user) {
    return (
      <Dialog open={show} onClose={tryClose} maxWidth="lg" fullWidth>
        <Typography variant="body2" color="GrayText">
          <FormattedMessage id="loading" defaultMessage="Loading..." />
        </Typography>
      </Dialog>
    );
  }

  return (
    <Dialog open={show} onClose={tryClose} maxWidth="lg" fullWidth>
      <Permissions noneOf={['recurring-workorder-plans:create']}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '150px' }}
        >
          <Typography variant="h2" color="error" sx={{ width: '80%' }}>
            <FormattedMessage
              id="check_in_plan_management.missing_permissions"
              defaultMessage="Your user does not have permission to use this feature. Please contact your administrator for more information."
            />
          </Typography>
        </Box>
      </Permissions>
      <Permissions oneOf={['recurring-workorder-plans:create']}>
        <ConfirmDialog
          open={!!dialogs.showSuccess}
          onConfirm={() => {
            setDialogs({
              ...dialogs,
              showRemoveConfirmation: false,
              showSuccess: '',
            });
          }}
          confirmLabel={<FormattedMessage id="ok" defaultMessage="Ok" />}
          titleLabel={
            <FormattedMessage id="success" defaultMessage="Success" />
          }
        >
          {dialogs.showSuccess}
        </ConfirmDialog>
        <UnsavedChangesDialog
          open={dialogs.showConfirmationDialog}
          onCancel={() =>
            setDialogs({ ...dialogs, showConfirmationDialog: false })
          }
          onOk={onClose}
        />
        <UnsavedChangesDialog
          open={dialogs.showCancelFormDialog}
          onCancel={() =>
            setDialogs({ ...dialogs, showCancelFormDialog: false })
          }
          onOk={() => onCancelForm()}
        />
        <ConfirmDialog
          open={dialogs.showRemoveConfirmation}
          onCancel={() =>
            setDialogs({ ...dialogs, showRemoveConfirmation: false })
          }
          onConfirm={() => onRemove()}
        >
          <FormattedMessage
            id="recurring_plan_dialog.remove_confirmation"
            defaultMessage="Are you sure you want to remove the plan?"
          />
        </ConfirmDialog>
        <DialogTitle>
          {!formData && !selectedPlan && (
            <FormattedMessage
              id="recurring_plan_dialog.title"
              defaultMessage="Recurring work order plan management"
            />
          )}
          {formData && selectedPlan && (
            <FormattedMessage
              id="recurring_plan_dialog.edit_plan_title"
              defaultMessage="Edit recurring work order plan: {planTitle}"
              values={{
                planTitle: selectedPlan.title,
              }}
            />
          )}
          {formData && !selectedPlan && (
            <FormattedMessage
              id="recurring_plan_dialog.create_new_recurring_plan"
              defaultMessage="Create new recurring work order plan"
            />
          )}
          <div>
            <IconButton
              color="inherit"
              onClick={() =>
                openGuide('RecurringWorkOrderPlanManagementDialog')
              }
            >
              <Help />
            </IconButton>
            <IconButton color="inherit" onClick={tryClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        {!formData ? (
          <RecurringWOPlanList
            plans={recurringPlans}
            onSelect={onSelect}
            onNewPlan={onNewPlan}
            isProcessing={isProcessing}
            onClose={tryClose}
          />
        ) : (
          <EditRecurringWOPlanForm
            formData={formData}
            onSave={onSave}
            validity={formValidity}
            isProcessing={isProcessing}
            onRemove={onTryRemove}
            onCancel={onTryCancelForm}
            onChange={onFormChange}
            filterSets={filterSets}
            filteredEntityNames={filteredEntityNames}
          />
        )}
      </Permissions>
    </Dialog>
  );
};

export default RecurringWOPlanManagementDialog;
