import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { tdGetEntities } from './entities';
import { dateFormat } from '../helpers/datetime_helpers';

const entities = tdGetEntities();

export const tdApiCheckInPlans: ApiCheckInPlan[] = [
  {
    duration: 10,
    periodicity: {
      type: 'yearly',
      occurrences: [{ date: DateTime.now().day, month: DateTime.now().month }],
    },
    entities: entities.slice(0, 4).map((e) => e.id),
    id: v4(),
    meta: {
      created_at: DateTime.fromFormat('2021-08-11', dateFormat).toISO(),
      created_by: 'auth0|6138bc7a898692006a243d1b',
      updated_at: DateTime.fromFormat('2021-08-14', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    title: 'My first check in plan, no route plan',
    contractor_id: 'e819f564-d873-4e86-ad91-fdff2b74ee08',
  },
  {
    duration: 14,
    periodicity: {
      type: 'monthly',
      occurrences: [{ date: 15 }],
    },
    entities: entities.slice(4, 20).map((e) => e.id),
    id: v4(),
    meta: {
      created_at: DateTime.fromFormat('2021-09-01', dateFormat).toISO(),
      created_by: 'auth0|6138bc632c6a45006940ff70',
      updated_at: DateTime.fromFormat('2022-01-03', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    title: 'Many entities, with route plan',
    route_plan: entities
      .slice(4, 20)
      .reverse()
      .map((e) => e.id),
    contractor_id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0',
  },
  {
    duration: 14,
    periodicity: {
      type: 'yearly',
      occurrences: [{ date: 1, month: 10 }],
    },
    entities: [],
    id: v4(),
    meta: {
      created_at: DateTime.fromFormat('2022-01-03', dateFormat).toISO(),
      created_by: 'auth0|6138bc632c6a45006940ff70',
      updated_at: DateTime.fromFormat('2022-01-03', dateFormat).toISO(),
    },
    tenant_id: 'whatever',
    title: 'Invalid plan, simulating removed entities',
    route_plan: entities
      .slice(4, 20)
      .reverse()
      .map((e) => e.id),
    contractor_id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0',
  },
];
