import { DateTime } from 'luxon';

export const tdSchema: ApiEntitySchema = {
  id: 'my_schema',
  tenant_id: 'vl',
  entity_type_id: 'stop',
  meta: {
    created_at: DateTime.now().toISO(),
    created_by: 'auth0|6172bc995cd96b0070339cc7',
    updated_at: DateTime.now().toISO(),
  },
  extras: {
    'meta.location_diff': {
      width: 60,
      adornment: 'm',
    },
    'inventory.6': {
      width: 60,
      adornment: 'm',
    },
    'inventory.7': {
      width: 60,
      adornment: 'm',
    },
    'inventory.9': {
      width: 60,
      adornment: 'cm',
      help_text: `Höjd från körbanan till kantstens ståyta.
Mätt med en meters mellanrun längs med hållplatsen och de lägsta värdet rapporterat.

This is fourth row. This is a help text for inventory.9. This is a help text for inventory.9.`,
      support_urls: {
        default: 'http://www.trafikverket.se',
      },
    },
    'inventory.3': {
      support_urls: {
        default: 'example@example.com',
        Körbana: 'http://www.trafikverket.se',
      },
    },
  },
  definition: {
    groups: [
      { id: 3, name: 'Län' },
      { id: 4, name: 'Kommun' },
      { id: 5, name: 'Plattform/Ståyta' },
      { id: 6, name: 'Pendlarparkering Bil' },
      { id: 7, name: 'Pendlarparkering Cykel' },
      { id: 8, name: 'Väderskydd' },
      { id: 9, name: 'Snittpåstigande föregående vecka' },
      { id: 10, name: 'Snittpåstigande föregående månad' },
      { id: 11, name: 'Snittpåstigande föregående år' },
      { id: 12, name: 'Snittavstigande föregående vecka' },
      { id: 13, name: 'Snittavstigande föregående månad' },
      { id: 14, name: 'Snittavstigande föregående år' },
      { id: 15, name: 'Snittviseringar föregående vecka' },
      { id: 16, name: 'Snittviseringar föregående månad' },
      { id: 17, name: 'Snittviseringar föregående år' },
    ],
    properties: [
      { key: 'meta.id', type: 'string', name: 'Id', modifiable: false },
      { key: 'meta.name', type: 'string', name: 'Namn', modifiable: false },
      { key: 'meta.class', type: 'string', name: 'Klass', modifiable: false },
      { key: 'meta.zone', type: 'string', name: 'Zon', modifiable: false },
      {
        key: 'meta.location',
        type: 'location',
        name: 'Koordinat',
      },
      {
        key: 'meta.county.code',
        group_id: 3,
        type: 'number',
        name: 'Kod',
        modifiable: false,
      },
      {
        key: 'meta.county.name',
        group_id: 3,
        type: 'string',
        name: 'Namn',
        modifiable: false,
      },
      {
        key: 'meta.municipality.code',
        group_id: 4,
        type: 'number',
        name: 'Kod',
        modifiable: false,
      },
      {
        key: 'meta.municipality.name',
        group_id: 4,
        type: 'string',
        name: 'Namn',
        modifiable: false,
      },
      {
        key: 'meta.last_inventory_taken',
        type: 'date',
        name: 'Senast inventerad',
        modifiable: false,
      },
      {
        key: 'meta.location_diff',
        type: 'number',
        name: 'Meter mellan angiven och inmätt position',
        modifiable: false,
      },
      {
        key: 'meta.passing_routes',
        type: 'array:string',
        name: 'Passerande linjer',
        modifiable: true,
      },
      {
        key: 'inventory.1',
        type: 'boolean',
        name: 'Rätt hållplatsnamn',
        modifiable: true,
      },
      {
        key: 'inventory.2',
        type: 'boolean',
        name: 'Linjenummer finns',
        modifiable: true,
      },
      {
        key: 'inventory.3',
        type: 'enum',
        name: 'Typ av hållplats',
        modifiable: true,
        alternatives: [
          'Ficka',
          'Glugg',
          'Körbana',
          'Vägren',
          'Klack',
          'Enkel',
          'Avskild',
          'Vändplan',
          'Timglas',
          'Annan',
        ],
      },
      {
        key: 'inventory.4',
        group_id: 5,
        type: 'boolean',
        name: 'Skild från väg',
        modifiable: true,
      },
      {
        key: 'inventory.5',
        group_id: 5,
        type: 'enum',
        name: 'Underlag',
        modifiable: true,
        alternatives: [
          'Asfalt',
          'Grus',
          'Betong',
          'Plattor',
          'Metall',
          'Annat',
          'Väg/Utfart',
        ],
      },
      {
        key: 'inventory.6',
        group_id: 5,
        type: 'number',
        name: 'Längd',
        modifiable: true,
      },
      {
        key: 'inventory.7',
        group_id: 5,
        type: 'number',
        name: 'Bredd',
        modifiable: true,
      },
      {
        key: 'inventory.8',
        group_id: 5,
        type: 'enum',
        name: 'Kantsten Typ',
        alternatives: ['Ingen', 'Hållplatsstöd', 'Betong', 'Granit', 'Annat'],
        modifiable: true,
      },
      {
        key: 'inventory.9',
        group_id: 5,
        type: 'number',
        name: 'Kantsten Höjd',
        modifiable: true,
      },
      {
        key: 'inventory.10',
        group_id: 5,
        type: 'enum',
        name: 'Taktila plattor',
        alternatives: ['Ja', 'Nej', 'Delvis'],
        modifiable: true,
      },
      {
        key: 'inventory.11',
        group_id: 5,
        type: 'enum',
        name: 'Kontrastmarkering',
        alternatives: ['Ja', 'Nej', 'Delvis'],
        modifiable: true,
      },
      {
        key: 'inventory.12',
        group_id: 5,
        type: 'boolean',
        name: 'Kupolplatta',
        modifiable: true,
      },
      {
        key: 'inventory.13',
        group_id: 5,
        type: 'boolean',
        name: 'Valplatta',
        modifiable: true,
      },
      {
        key: 'inventory.14',
        group_id: 5,
        type: 'enum',
        name: 'Gånganslutning',
        alternatives: ['Körbana', 'Vägren', 'GC', 'Gång'],
        modifiable: true,
      },
      {
        key: 'inventory.15',
        group_id: 6,
        type: 'boolean',
        name: 'Finns',
        modifiable: true,
      },
      {
        key: 'inventory.16',
        group_id: 6,
        type: 'number',
        name: 'Antal platser',
        modifiable: true,
      },
      {
        key: 'inventory.17',
        group_id: 6,
        type: 'number',
        name: 'Laddstolpe',
        modifiable: true,
      },
      {
        key: 'inventory.18',
        group_id: 6,
        type: 'number',
        name: 'Motorvärmare',
        modifiable: true,
      },
      {
        key: 'inventory.19',
        group_id: 6,
        type: 'number',
        name: 'Handikapplats',
        modifiable: true,
      },
      {
        key: 'inventory.20',
        group_id: 6,
        type: 'boolean',
        name: 'Tak',
        modifiable: true,
      },
      {
        key: 'inventory.21',
        group_id: 6,
        type: 'boolean',
        name: 'Inhängnad',
        modifiable: true,
      },
      {
        key: 'inventory.22',
        group_id: 7,
        type: 'boolean',
        name: 'Finns',
        modifiable: true,
      },
      {
        key: 'inventory.23',
        group_id: 7,
        type: 'number',
        name: 'Antal platser',
        modifiable: true,
      },
      {
        key: 'inventory.24',
        group_id: 7,
        type: 'boolean',
        name: 'Möjlighet att låsa fast',
        modifiable: true,
      },
      {
        key: 'inventory.25',
        group_id: 7,
        type: 'boolean',
        name: 'Tak',
        modifiable: true,
      },
      {
        key: 'inventory.26',
        group_id: 7,
        type: 'boolean',
        name: 'Inhängnad',
        modifiable: true,
      },
      {
        key: 'inventory.27',
        type: 'boolean',
        name: 'Belysning hållplatsområde',
        modifiable: true,
      },
      {
        key: 'inventory.28',
        type: 'boolean',
        name: 'Belysning GC',
        modifiable: true,
      },
      {
        key: 'inventory.29',
        type: 'boolean',
        name: 'Belysning Körbana',
        modifiable: true,
      },
      {
        key: 'inventory.30',
        type: 'enum',
        name: 'Hållplatsstolpe',
        alternatives: ['Ingen', 'Stolpe', 'Pelare'],
        modifiable: true,
      },
      {
        key: 'inventory.31',
        type: 'enum',
        name: 'Färg på hållplatsskylt',
        alternatives: ['Svart', 'Blå', 'Nej'],
        modifiable: true,
      },
      {
        key: 'inventory.32',
        group_id: 8,
        type: 'boolean',
        name: 'Finns',
        modifiable: true,
      },
      {
        key: 'inventory.33',
        group_id: 8,
        type: 'enum',
        name: 'Typ',
        modifiable: true,
        alternatives: [
          'TB C90 3 Normal',
          'TB C90 4 Normal',
          'TB C90 5 Normal',
          'TB F 3 Normal',
          'TB F 4 Normal',
          'TB F 5 Normal',
          'TB F 3 Mellan',
          'TB F 4 Mellan',
          'TB F 5 Mellan',
          'TB F 3 Smal',
          'TB F 4 Smal',
          'TB F 5 Smal',
          'TB FV 3',
          'TB FV 4',
          'TB C90V 3',
          'TB C90V 4',
          'TB C90V 5',
          'TB C90V Övrig',
          'Tejbrant Country 2',
          'Tejbrant Country 3',
          'Tejbrant Country 4',
          'Tejbrant Concreta 3',
          'Alustop 3',
          'CC 3',
          'CC 4',
          'CC 5',
          'CC 3 Smal',
          'KNM Vemo 1',
          'KNM Vemo 2',
          'KNM Vemo 3',
          'KNM Vemo 4',
          'Annat väderskydd',
        ],
      },
      {
        key: 'inventory.34',
        group_id: 8,
        type: 'enum',
        name: 'Belysning',
        modifiable: true,
        alternatives: ['Nej', 'Elanslutning', 'Solcell'],
      },
      {
        key: 'inventory.35',
        group_id: 8,
        type: 'enum',
        name: 'Annonsplats Gavel',
        modifiable: true,
        alternatives: ['Nej', 'In', 'Ut', 'In/Ut'],
      },
      {
        key: 'inventory.36',
        group_id: 8,
        type: 'enum',
        name: 'Annonsplats Rygg',
        modifiable: true,
        alternatives: ['Nej', 'In', 'Ut', 'In/Ut'],
      },
      {
        key: 'inventory.37',
        type: 'enum',
        name: 'Typ av sittbänk',
        modifiable: true,
        alternatives: ['Nej', 'Fast', 'Fristående'],
      },
      {
        key: 'inventory.38',
        type: 'boolean',
        name: 'Armstöd på sittbänk',
        modifiable: true,
      },
      {
        key: 'inventory.39',
        type: 'boolean',
        name: 'Lutbräda',
        modifiable: true,
      },
      {
        key: 'inventory.40',
        type: 'enum',
        name: 'Uppvärmning',
        alternatives: ['Nej', 'Platta', 'Mark', 'Infra', 'Sittbänk'],
        modifiable: true,
      },
      {
        key: 'inventory.41',
        type: 'boolean',
        name: 'Papperskorg',
        modifiable: true,
      },
      {
        key: 'inventory.42',
        type: 'boolean',
        name: 'Realtidsinformation',
        modifiable: true,
      },
      {
        key: 'inventory.43',
        type: 'boolean',
        name: 'Högtalare',
        modifiable: true,
      },
      {
        key: 'inventory.44',
        type: 'boolean',
        name: 'Klocka',
        modifiable: true,
      },
      {
        key: 'inventory.45',
        type: 'boolean',
        name: 'Biljettmaskin',
        modifiable: true,
      },
      {
        key: 'inventory.46',
        type: 'enum',
        name: 'Kassetter A3',
        modifiable: true,
        alternatives: [
          '0 st',
          '1 st',
          '2 st',
          '3 st',
          '4 st',
          '5 st',
          '6 st',
          '7 st',
          '8 st',
          '9 st',
          '10 st',
        ],
      },
      {
        key: 'inventory.47',
        type: 'enum',
        name: 'Informationstavlor',
        alternatives: [
          '0 st',
          '1 st',
          '2 st',
          '3 st',
          '4 st',
          '5 st',
          '6 st',
          '7 st',
          '8 st',
          '9 st',
          '10 st',
        ],
      },
      {
        key: 'inventory.48',
        type: 'enum',
        name: 'Tidtabeller',
        alternatives: [
          '0 st',
          '1 st',
          '2 st',
          '3 st',
          '4 st',
          '5 st',
          '6 st',
          '7 st',
          '8 st',
          '9 st',
          '10 st',
        ],
      },
      {
        key: 'inventory.49',
        type: 'enum',
        name: 'Kartor A3',
        alternatives: [
          '0 st',
          '1 st',
          '2 st',
          '3 st',
          '4 st',
          '5 st',
          '6 st',
          '7 st',
          '8 st',
          '9 st',
          '10 st',
        ],
      },
      {
        key: 'inventory.50',
        type: 'boolean',
        name: 'Kartor Stora',
      },
      {
        key: 'inventory.51',
        type: 'enum',
        name: 'Trafikinformation',
        alternatives: [
          '0 st',
          '1 st',
          '2 st',
          '3 st',
          '4 st',
          '5 st',
          '6 st',
          '7 st',
          '8 st',
          '9 st',
          '10 st',
        ],
      },
      {
        key: 'inventory.52',
        type: 'boolean',
        name: 'Blindskrift',
      },
      {
        key: 'inventory.53',
        type: 'string',
        name: 'Anmärkning',
      },
      { key: 'inventory.61', type: 'photo', name: 'In-bild' },
      { key: 'inventory.62', type: 'photo', name: 'Ut-bild' },
      { key: 'inventory.63', type: 'photo', name: 'Platsbild' },
      { key: 'inventory.64', type: 'photo', name: 'Extrabild' },
      { key: 'inventory.65', type: 'array:number', name: 'Lista av nummer' },
      { key: 'inventory.66', type: 'array:string', name: 'Lista av strängar' },
      {
        key: 'derived.last_inventory',
        type: 'date',
        name: 'Senast inventerad',
      },
    ],
  },
};
