import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type TagManagementTab = 'new' | 'edit';

interface TagManagementDialog {
  show: boolean;
  open: (tab?: TagManagementTab, selectedTagId?: string) => void;
  /** If clear is true the dialog state will be emptied. */
  close: (clear?: boolean) => void;
  selectedTagId: string;
  setSelectedTagId: (id: string) => void;
  selectedTab: TagManagementTab;
  setSelectedTab: (tab: TagManagementTab) => void;
}

const useTagManagementDialog = create<TagManagementDialog>()(
  devtools(
    (set) => ({
      show: false,
      selectedTagId: '',
      selectedTab: 'edit',
      setSelectedTab: (tab) => {
        set({
          selectedTab: tab,
        });
      },
      open: () => {
        set({ show: true });
      },
      close: (clear) => {
        if (clear) {
          set({
            show: false,
            selectedTagId: '',
          });
          return;
        }
        set({ show: false });
      },
      setSelectedTagId: (id) => {
        set({ selectedTagId: id });
      },
    }),
    {
      name: 'tag-management-dialog-store',
      enabled: process.env.NODE_ENV !== 'test',
    },
  ),
);

export default useTagManagementDialog;
