import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface WOInstructionManagementDialog {
  show: boolean;
  open: (templateId?: string) => void;
  close: () => void;
  /** The id of currently selected instruction in manager. */
  selectedInstructionId: string | null;
  setSelectedInstructionId: (instructionId: string | null) => void;
}

const useWOInstructionManagementDialog =
  create<WOInstructionManagementDialog>()(
    devtools(
      (set) => ({
        show: false,
        selectedInstructionId: null,
        open: (instructionId) => {
          set({
            show: true,
            selectedInstructionId: instructionId || null,
          });
        },
        close: () => {
          set({ show: false });
        },
        setSelectedInstructionId: (instructionId) => {
          set({
            selectedInstructionId: instructionId,
          });
        },
      }),
      {
        name: 'work-order-instruction-store',
        enabled: process.env.NODE_ENV !== 'test',
      },
    ),
  );

export default useWOInstructionManagementDialog;
