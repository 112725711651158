import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { User } from '@auth0/auth0-react';
import { ApiFeatureLicense, ApiPermission } from '@allbin/mobilix-api-client';

interface UserStore {
  setOrgId: (orgId?: string) => void;
  setPermissions: (permissions: ApiPermission[]) => void;
  setUser: (user?: User, orgId?: string) => void;
  setLicenses: (licenses: ApiFeatureLicense[]) => void;
  permissions: ApiPermission[];
  licenses: ApiFeatureLicense[];
  user: User;
  orgId?: string;
  tenant: string;
}

const useUserStore = create<UserStore>()(
  devtools(
    (set, get) => ({
      permissions: [],
      licenses: [],
      user: {},
      tenant: '',
      orgId: window.localStorage.getItem('orgId') || undefined,
      setOrgId(orgId) {
        if (orgId === get().orgId) {
          return;
        }
        if (!orgId) {
          window.localStorage.removeItem('orgId');
        } else {
          window.localStorage.setItem('orgId', orgId);
        }
        set({ orgId });
      },
      setUser: (user) => {
        set({
          user: user || {},
          tenant: user
            ? (user['https://mobilix.allbin.se/tenant'] as string) ?? ''
            : '',
          orgId: user ? (user.org_id as string) : undefined,
        });
      },
      setPermissions: (permissions) => {
        const state = get();
        if (
          permissions.length !== state.permissions.length ||
          permissions.some((p, i) => p !== state.permissions[i])
        ) {
          set({ permissions });
        }
      },
      setLicenses: (licenses) => set({ licenses }),
    }),
    { name: 'user-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useUserStore;
