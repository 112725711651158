/* eslint-disable @typescript-eslint/no-inferrable-types */
import { EntitySchemaPropType } from '@allbin/entity-logic';
import { DateTime } from 'luxon';
import { defineMessages, IntlShape } from 'react-intl';
import { proj, Projection, round } from './math_helpers';
import { dateFormat, dateTimeFormat } from './datetime_helpers';

const messages = defineMessages({
  true: {
    id: 'formatting.true',
    defaultMessage: 'True',
  },
  false: {
    id: 'formatting.false',
    defaultMessage: 'False',
  },
  'wo_state.created': {
    id: 'wo_state.created',
    defaultMessage: 'Created',
  },
  'wo_state.blocked': {
    id: 'wo_state.blocked',
    defaultMessage: 'Blocked',
  },
  'wo_state.accepted': {
    id: 'wo_state.accepted',
    defaultMessage: 'Accepted',
  },
  'wo_state.completed': {
    id: 'wo_state.completed',
    defaultMessage: 'Completed',
  },
  'wo_state.rejected': {
    id: 'wo_state.rejected',
    defaultMessage: 'Rejected',
  },
  'wo_state.approved': {
    id: 'wo_state.approved',
    defaultMessage: 'Approved',
  },
  'wo_state.cancelled': {
    id: 'wo_state.cancelled',
    defaultMessage: 'Cancelled',
  },
  exists: { id: 'exists', defaultMessage: 'Exists' },
});

export const translateState = (
  intl: IntlShape,
  state: WorkOrder['state'],
): string => {
  switch (state) {
    case 'accepted':
      return intl.formatMessage(messages['wo_state.accepted']);
    case 'approved':
      return intl.formatMessage(messages['wo_state.approved']);
    case 'blocked':
      return intl.formatMessage(messages['wo_state.blocked']);
    case 'cancelled':
      return intl.formatMessage(messages['wo_state.cancelled']);
    case 'completed':
      return intl.formatMessage(messages['wo_state.completed']);
    case 'created':
      return intl.formatMessage(messages['wo_state.created']);
    case 'rejected':
      return intl.formatMessage(messages['wo_state.rejected']);

    default:
      throw new Error('Unknwon state provided to translateState.');
  }
};

const formatValue = (
  value: EntityPropValue,
  intl: IntlShape,
  type: EntitySchemaPropType,
  profile: UserProfile,
  formatOpts: SchemaExtrasFormatOptions,
): string => {
  switch (type) {
    case 'photo': {
      return value.toString();
    }
    case 'array:string': {
      return value && Array.isArray(value) ? value.join(', ') : '';
    }
    case 'enum':
    case 'string': {
      return value.toString();
    }
    case 'date': {
      if (formatOpts && formatOpts.format) {
        if (formatOpts.format === 'date') {
          return DateTime.fromISO(value as string).toFormat(dateFormat);
        } else {
          return DateTime.fromISO(value as string).toFormat(dateTimeFormat);
        }
      }
      return value.toString();
    }
    case 'array:number': {
      return value &&
        Array.isArray(value) &&
        !value.some((x) => typeof x !== 'number' || Number.isNaN(x))
        ? value.map((x) => formatNumber(x as number, formatOpts)).join(', ')
        : '';
    }
    case 'number': {
      return formatNumber(value as number, formatOpts);
    }
    case 'boolean': {
      return value === true
        ? intl.formatMessage(messages.true)
        : intl.formatMessage(messages.false);
    }
    case 'location': {
      return Array.isArray(value) &&
        value.length === 2 &&
        !value.some((x) => typeof x !== 'number' || Number.isNaN(x))
        ? locationPropertyToString(
            value as [number, number],
            profile.projection,
          )
        : value.toString();
    }
    default:
      throw new Error(
        `Error formatting entity prop value, unknown type '${
          type as unknown as string
        }'`,
      );
  }
};

export interface FormatNumberOpts {
  /** Group digits to triplets before decimal point. */
  grouping?: boolean;
  /** Number of digits after the decimal points to show.
   *
   * Will pad with 0 if longer than rounded value.
   *
   * Will _NOT_ round. */
  toFixed?: number;
  /**
   * Positive value will round to specified number of decimals, negative value will round to specified number of integers.
   *
   * Ex:
   *
   * ```
   * roundTo:  0, 123.55 -> 124,
   * roundTo:  1, 123.55 -> 123.6
   * roundTo:  2, 123.55 -> 123.55
   * roundTo: -1, 123.55 -> 120
   * roundTo: -2, 100 -> 100
   * ```
   */
  roundTo?: number;
}

/** Utility to format number. The value will be formatted in the order: 1.roundTo, 2. toFixed, 3. grouping and all options can be mixed. */
export const formatNumber = (
  value: number,
  opts: FormatNumberOpts = {},
): string => {
  const { grouping = true, roundTo, toFixed } = opts;

  if (typeof toFixed === 'number' && (toFixed < 0 || toFixed > 20)) {
    throw new Error('toFixed is expected to be in range 1 to 20.');
  }

  let formattedValue = value.toString();
  let rounded = value;

  if (typeof roundTo === 'number') {
    rounded = round(value, roundTo);
    formattedValue = rounded.toString();
  }

  if (typeof toFixed === 'number') {
    const decimalPointSplit = formattedValue.split('.');

    formattedValue =
      toFixed === 0
        ? decimalPointSplit[0]
        : decimalPointSplit[0] +
          '.' +
          (
            (decimalPointSplit[1] ? decimalPointSplit[1] : '') +
            '00000000000000000000'
          ).substring(0, toFixed);
  }

  if (grouping) {
    const isNegative = value < 0;
    const decimalPointSplit = formattedValue.replace('-', '').split('.');

    let str_arr = decimalPointSplit[0].split('');
    let str = '';
    while (str_arr.length > 3) {
      str =
        ' ' +
        str_arr[str_arr.length - 3] +
        str_arr[str_arr.length - 2] +
        str_arr[str_arr.length - 1] +
        str;
      str_arr = str_arr.slice(0, str_arr.length - 3);
    }
    const joined_arr = str_arr.join('');
    formattedValue = joined_arr.length > 0 ? joined_arr + str : str;

    if (isNegative) {
      //Add minus sign if negative.
      formattedValue = '-' + formattedValue;
    }
    // If decimal exist, concat the values after the decimal
    if (decimalPointSplit[1]) {
      formattedValue = `${formattedValue}.${decimalPointSplit[1]}`;
    }
  }

  return formattedValue.length > 0 ? formattedValue : rounded.toString();
};

export const formatEntityPropValue = (
  value: EntityPropValue,
  intl: IntlShape,
  type: EntitySchemaPropType,
  profile: UserProfile,
  formatOpts?: SchemaExtrasFormatOptions,
): string => {
  if (
    (type === 'array:number' || type === 'array:string') &&
    Array.isArray(value)
  ) {
    return value
      .map((v) =>
        formatValue(
          v,
          intl,
          type === 'array:string' ? 'string' : 'number',
          profile,
          formatOpts || {},
        ),
      )
      .join(', ');
  } else {
    return formatValue(value, intl, type, profile, formatOpts || {});
  }
};

export const locationPropertyToString = (
  location: [number, number],
  toProjection: Projection,
  fromProjection: Projection = 'WGS84',
  precision?: number,
): string => {
  if (typeof precision !== 'number') {
    if (toProjection === 'WGS84') {
      precision = 5;
    } else if (toProjection === 'EPSG:3021') {
      precision = 0;
    }
  }
  if (toProjection !== fromProjection) {
    location = proj(fromProjection, toProjection, location);
  }
  return `${location[0].toFixed(precision)}, ${location[1].toFixed(precision)}`;
};

export const readableProjection = (projection: Projection): string => {
  switch (projection) {
    case 'EPSG:3006': {
      return 'SWEREF99';
    }
    case 'EPSG:3021': {
      return 'RT90';
    }
    case 'WGS84': {
      return 'WGS84';
    }

    default: {
      console.warn(
        `Unknown projection to make human readable '${
          projection as unknown as string
        }'.`,
      );
      return projection;
    }
  }
};
