import React, { FC } from 'react';
import { Link, Typography, Box } from '@mui/material';
import { ParagraphSingleSegment } from '../../store/guide';

interface SingleSegmentParagraphProps {
  segment: ParagraphSingleSegment;
  onLinkClick: (targetId: string) => void;
}

const SingleSegmentParagraph: FC<SingleSegmentParagraphProps> = ({
  segment,
  onLinkClick,
}) => {
  if (segment.type === 'link') {
    return (
      <Box mt={2}>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            onLinkClick(segment.linkTo);
          }}
          underline="always"
        >
          {segment.text}
        </Link>
      </Box>
    );
  }
  if (segment.type === 'li') {
    return (
      <Box component="li" ml={3}>
        <Typography variant="caption">{segment.text}</Typography>
      </Box>
    );
  }
  return (
    <Typography variant="body2" mt={2}>
      {segment.text}
    </Typography>
  );
};

export default SingleSegmentParagraph;
