import React, { FC, useEffect, useRef, useState } from 'react';
import { CalendarMonth } from '@mui/icons-material';
import { Box, IconButton, Popover } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import TextInput, { TextInputProps } from './TextInput';
import { useIntl } from 'react-intl';
import { dateFormat } from '../../helpers/datetime_helpers';

interface DateInputProps
  extends Omit<
    TextInputProps,
    'valid' | 'value' | 'endAdornment' | 'onChange'
  > {
  value?: DateTime;
  /**
   * If enabled the onChange will trigger with `value: undefined` if the user empties the input field.
   * Defaults to false.
   */
  allowEmpty?: boolean;
  /**
   * If enabled and the user has entered an invalid value the onChange will trigger with `value: undefined`.
   * Defaults to false.
   */
  onValidityCheck?: (valid: boolean) => void;
  undefinedOnInvalid?: boolean;
  onChange: (value: DateTime | undefined) => void;
  minDate?: DateTime;
  maxDate?: DateTime;
}

const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  allowEmpty,
  onValidityCheck,
  undefinedOnInvalid,
  minDate,
  maxDate,
  disabled,
  ...textInputProps
}) => {
  const pickerAnchorRef = useRef<HTMLDivElement>();
  const intl = useIntl();
  const [textValue, setTextValue] = useState<string>(
    value ? value.toFormat(dateFormat) : '',
  );
  const [input, setInput] = useState<string>(textValue);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  useEffect(() => {
    onValidityCheck && onValidityCheck(isValid);
  }, [onValidityCheck, isValid]);

  useEffect(() => {
    const trimmed = input.trim();
    if (textValue === trimmed) {
      return;
    }
    if (input.trim().length === 0) {
      setIsValid(true);
      if (allowEmpty) {
        onChange(undefined);
      }
      return;
    }

    const date = DateTime.fromFormat(input.trim(), dateFormat);
    if (date.isValid) {
      setIsValid(true);
      onChange(date);
    } else {
      setIsValid(false);
      if (undefinedOnInvalid) {
        onChange(undefined);
      }
    }
    //Intentionally disabled, because value reference will be updated
    //when input changes, and this should not run when value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, onChange, allowEmpty, undefinedOnInvalid]);

  useEffect(() => {
    const tv = value ? value.toFormat(dateFormat) : '';
    setTextValue(tv);
    if (tv !== input) {
      setInput(tv);
    }
    //Intentionally disabled, because input reference will be updated
    //when value changes, and this should not run when input changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <TextInput
        value={input}
        valid={isValid}
        onChange={setInput}
        disabled={disabled}
        endAdornment={
          <Box ref={pickerAnchorRef}>
            <IconButton
              disabled={disabled}
              size="small"
              onClick={() => !disabled && setDatePickerOpen(true)}
            >
              <CalendarMonth />
            </IconButton>
          </Box>
        }
        {...textInputProps}
      />
      {pickerAnchorRef.current && (
        <Popover
          anchorEl={pickerAnchorRef.current}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <LocalizationProvider
            adapterLocale={intl.locale}
            dateAdapter={AdapterLuxon}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={value || null}
              renderInput={() => <div />}
              onChange={(v) => {
                setInput(!v ? '' : v.toFormat(dateFormat));
                setDatePickerOpen(false);
              }}
              minDate={minDate}
              maxDate={maxDate}
            />
          </LocalizationProvider>
        </Popover>
      )}
    </>
  );
};

export default DateInput;
