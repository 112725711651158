import React, { FC, useMemo } from 'react';
import { Box, Collapse } from '@mui/material';
import TagForm, { TagFormProps } from './TagForm';
import TagSelector from '../TagSelector';

interface EditTagFormProps extends TagFormProps {
  selectedTagId?: string;
  setSelectedTagId: (id: string) => void;
}

const EditTagForm: FC<EditTagFormProps> = ({
  selectedTagId,
  setSelectedTagId,
  ...formProps
}) => {
  const selectedTagIds = useMemo(() => {
    return selectedTagId ? [selectedTagId] : [];
  }, [selectedTagId]);

  return (
    <>
      <TagSelector
        sx={{ padding: 4 }}
        excludeUneditable
        selectedTags={selectedTagIds}
        onTagClick={(tagId, selected) => !selected && setSelectedTagId(tagId)}
      />
      <Collapse
        in={!!selectedTagId}
        mountOnEnter
        unmountOnExit
        sx={{ flexGrow: 1, flexBasis: 0, display: 'flex' }}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          p={4}
          sx={{ backgroundColor: 'background.dark' }}
        >
          <TagForm {...formProps} />
        </Box>
      </Collapse>
    </>
  );
};

export default EditTagForm;
