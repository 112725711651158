import React from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import MobilixLogo from './MobilixLogo';
import { FormattedMessage } from 'react-intl';

const LoadingScreen: React.FC<{ src?: string }> = ({ src }) => {
  return (
    <Stack
      direction="column"
      sx={{ width: '100%', height: '90%' }}
      p={4}
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <Box>
        <MobilixLogo />
      </Box>
      <Box sx={{ maxWidth: 600 }}>
        <LinearProgress sx={{ width: 300 }} />
      </Box>
      <Typography variant="overline">
        <FormattedMessage
          id="loading_screen.loading"
          defaultMessage="Loading..."
        />
        {src}
      </Typography>
    </Stack>
  );
};

export default LoadingScreen;
