import React, { FC, ReactNode } from 'react';
import {
  createTheme,
  PaletteColor,
  ThemeProvider as TProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import '@fontsource/open-sans';

export type ColorProps = PaletteColor & {
  medium: string;
  /** Textcolor on the Light color. */
  textOnLight: string;
  /** Textcolor on the Alpha colors. */
  textOnAlpha: string;
  a40: string;
  a20: string;
};

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    wo: ColorProps;
    entity: ColorProps;
    traffic: ColorProps;
    dark: ColorProps;
    light: ColorProps;
    filter0: string;
    filter1: string;
    filter2: string;
    filter3: string;
    filter4: string;
  }

  interface PaletteOptions {
    wo: ColorProps;
    entity: ColorProps;
    traffic: ColorProps;
    dark: ColorProps;
    light: ColorProps;
    filter0: string;
    filter1: string;
    filter2: string;
    filter3: string;
    filter4: string;
    statesBlackOverlay: PaletteOptions['primary'];
    statesWhiteOverlay: PaletteOptions['primary'];
    background?: Partial<TypeBackground>;
  }

  interface TypeBackground {
    dark: string;
  }

  interface SimplePaletteColorOptions {
    hover?: string;
    focus?: string;
    pressed?: string;
    dragged?: string;
    selected?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    wo: true;
    entity: true;
    traffic: true;
    dark: true;
    light: true;
    filter0: true;
    filter1: true;
    filter2: true;
    filter3: true;
    filter4: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    display: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true;
  }
}

export let theme = createTheme({
  spacing: 4,
  palette: {
    filter0: '#3785D3',
    filter1: '#EF476F',
    filter2: '#07C593',
    filter3: '#7B66FF',
    filter4: '#D766FF',
    wo: {
      main: 'rgba(73,95,153,1.0)',
      a40: 'rgba(73,95,153,0.40)',
      a20: 'rgba(73,95,153,0.20)',
      dark: 'rgba(55,71,115,1.0)',
      medium: 'rgba(109,127,173,1.0)',
      light: 'rgba(146,159,194,1.0)',
      contrastText: '#fff',
      textOnAlpha: '#121626',
      textOnLight: '#fff',
    },
    entity: {
      main: 'rgba(234,179,8,1.0)',
      a40: 'rgba(234,179,8,0.40)',
      a20: 'rgba(234,179,8,0.20)',
      dark: 'rgba(187,143,6,1.0)',
      medium: 'rgba(238,194,57,1.0)',
      light: 'rgba(242,209,107,1.0)',
      contrastText: '#fff',
      textOnAlpha: '#121626',
      textOnLight: '#fff',
    },
    traffic: {
      main: '#C75F76',
      // main: 'rgba(143,87,99,1.0)',
      a40: 'rgba(143,87,99,0.40)',
      a20: 'rgba(143,87,99,0.20)',
      dark: 'rgba(159,76,94,1.0)',
      medium: 'rgba(210,127,145,1.0)',
      light: 'rgba(221,159,173,1.0)',
      contrastText: '#fff',
      textOnAlpha: '#121626',
      textOnLight: '#fff',
    },
    dark: {
      main: 'rgba(18,22,38,1.0)',
      a40: 'rgba(18,22,38,0.40)',
      a20: 'rgba(18,22,38,0.20)',
      dark: 'rgba(18,22,38,1.0)',
      medium: 'rgba(113,115,125,1.0)',
      light: 'rgba(196,197,201,1.0)',
      contrastText: '#fff',
      textOnLight: '#121626',
      textOnAlpha: '#121626',
    },
    light: {
      main: 'rgba(255,255,255,1.0)',
      a40: 'rgba(255,255,255,0.40)',
      a20: 'rgba(255,255,255,0.20)',
      dark: 'rgba(237,237,238,1.0)',
      medium: 'rgba(255,255,255,1.0)',
      light: 'rgba(255,255,255,1.0)',
      contrastText: '#121626',
      textOnLight: '#121626',
      textOnAlpha: '#121626',
    },
    primary: {
      main: '#485696',
      light: '#7882c7',
      dark: '#142e68',
      hover: 'rgba(72,86,150,0.04)',
      focus: 'rgba(72,86,150,0.12)',
      pressed: 'rgba(72,86,150,0.1)',
      dragged: 'rgba(72,86,150,0.08)',
      selected: 'rgba(72,86,150,0.08)',
    },
    secondary: {
      main: '#FFD950',
      light: '#FCE58F',
      dark: '#B17900',
    },
    success: {
      main: '#189B4D',
    },
    warning: {
      main: '#FFD950',
    },
    error: {
      main: '#F44336',
      light: '#F88E86',
    },
    text: {
      primary: '#121626',
      secondary: '#696c77',
      disabled: '#adaeb1',
    },
    background: {
      default: '#F8F9FC',
      dark: '#F1F2F7',
      // default: '#00F900',
      // dark: '#F10000',
    },
    statesBlackOverlay: {
      main: 'black',
      hover: 'rgba(0,0,0,0.04)',
      focus: 'rgba(0,0,0,0.12)',
      pressed: 'rgba(0,0,0,0.1)',
      dragged: 'rgba(0,0,0,0.08)',
      selected: 'rgba(0,0,0,0.08)',
    },
    statesWhiteOverlay: {
      main: 'white',
      hover: 'rgba(255,255,255,0.04)',
      focus: 'rgba(255,255,255,0.12)',
      pressed: 'rgba(255,2550,255,0.1)',
      dragged: 'rgba(255,255,255,0.08)',
      selected: 'rgba(255,255,255,0.08)',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    display: {
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '64px',
      fontSize: '64px',
      letterSpacing: '0px',
    },
    h1: {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '1.5px',
    },
    h2: {
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '1.5px',
    },
    h3: {
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '1px',
    },
    h4: {
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '1px',
    },
    h5: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '20px',
      lineHeight: '24px',
    },
    h6: {
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: '20px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
    },
    button: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
    caption: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    overline: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
  },
});

theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: 48,
            boxShadow:
              '0px 9px 12px rgba(152, 154, 164, 0.14), 0px 6px 32px rgba(152, 154, 164, 0.12), 0px 10px 12px rgba(152, 154, 164, 0.2)',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            lineHeight: 'unset',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '44px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '1em',
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {},
        styleOverrides: {
          root: {
            ...theme.typography.h5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: theme.spacing(10),
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable',
        },
        styleOverrides: {
          root: {
            background: theme.palette.primary.main,
            '& .Mui-selected': {
              background: theme.palette.primary.light,
            },
          },
          scrollButtons: {
            color: 'white',
          },
          indicator: {
            background: theme.palette.secondary.main,
            height: '5px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: 'unset',
            color: '#FFFFFFBD',
            textTransform: 'none',
            flexDirection: 'row',
            '& > :not(:last-child)': {
              marginRight: theme.spacing(2),
            },
            '&.Mui-selected': {
              color: 'white',
            },
          },
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            ...theme.typography.subtitle2,
            color: '#989AA4',
          },
        },
      },
      MuiTableRow: {
        defaultProps: {
          hover: true,
        },
        styleOverrides: {
          root: {
            '&:nth-of-type(even)': {
              backgroundColor: '#0000001A',
            },
            // hide last border
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          enterDelay: 400, // time in ms
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            boxShadow:
              '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
          },
        },
      },
    },
  },
  theme,
);

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <TProvider theme={theme}>
      <CssBaseline />
      {children}
    </TProvider>
  );
};

export default ThemeProvider;
