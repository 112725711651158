import {
  IMobilixApiClient,
  MobilixApiClient,
} from '@allbin/mobilix-api-client';
import { useMemo } from 'react';
import { useConfigStore } from '../store/config';
import useGetAccessToken from './useGetAccessToken';

export const mock = {
  mock: false,
};

export const useApiClient = (): IMobilixApiClient => {
  const { baseUrl } = useConfigStore();
  const { getAccessToken } = useGetAccessToken();

  return useMemo(() => {
    const token = mock.mock ? undefined : getAccessToken;

    return MobilixApiClient({
      baseUrl,
      token,
    });
  }, [baseUrl, getAccessToken]);
};

export const usePublicApiClient = (): IMobilixApiClient => {
  const { baseUrl } = useConfigStore();

  return useMemo(() => {
    return MobilixApiClient({
      baseUrl,
      token: undefined,
    });
  }, [baseUrl]);
};
