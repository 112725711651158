import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type SearchType = 'entityGroup' | 'workorder' | 'filterSet' | 'contractor';

export interface SearchResult {
  id: string;
  type: SearchType;
  title: string;
  subtitle?: string;
}
export type SearchResults = Record<SearchType, SearchResult[]>;

interface SearchStore {
  searchTerm: string | null;
  clearSearch: () => void;
  results?: SearchResults;
  setResults: (searchTerm: string, results: SearchResults) => void;
  setSearchTerm: (searchTerm: string) => void;
}

const useSearchStore = create<SearchStore>()(
  devtools(
    (set) => ({
      searchTerm: null,
      results: undefined,
      clearSearch: () =>
        set({
          searchTerm: null,
          results: undefined,
        }),
      setSearchTerm: (searchTerm) => set({ searchTerm }),
      setResults: (searchTerm, results) =>
        set({
          results,
          searchTerm,
        }),
    }),
    { name: 'search-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useSearchStore;
