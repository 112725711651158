import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useApiClient } from './useApiClient';
import {
  ApiWorkOrder,
  ApiWorkOrderEvent,
  ApiWorkOrderRequest,
} from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';
import {
  createListAndLookupStore,
  reviveMetadata,
  toListAndLookup,
} from '../helpers/revivers';
import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useEntitySchema } from './useEntitySchema';
import { serializeEntityProps, unserializeEntityProps } from './useEntities';

export const reviveWorkOrderChangesetRequest: ReviverFn<
  ApiEntityChangeSetRequest,
  EntityChangesetRequest
> = (changeset, schema) => {
  const props = unserializeEntityProps(
    schema as EntitySchema,
    changeset.properties,
  );
  return {
    ...changeset,
    properties: props,
  };
};

export const reviveWorkOrderChangesetRequests: ReviverFn<
  Record<string, ApiEntityChangeSetRequest>,
  Record<string, EntityChangesetRequest>
> = (changeset, schema): Record<string, EntityChangesetRequest> =>
  Object.entries(changeset).reduce<Record<string, EntityChangesetRequest>>(
    (cs, [key, value]) => {
      cs[key] = reviveWorkOrderChangesetRequest(value, schema);
      return cs;
    },
    {},
  );

export const reviveWorkOrder: ReviverFn<ApiWorkOrder, WorkOrder> = (
  workOrder,
  schema?,
) => ({
  ...workOrder,
  title_suffix: workOrder.id.slice(0, 6),
  due_at: workOrder.due_at ? DateTime.fromISO(workOrder.due_at) : undefined,
  meta: reviveMetadata(workOrder.meta),
  entity_changesets: reviveWorkOrderChangesetRequests(
    workOrder.entity_changesets,
    schema,
  ),
});

export const workOrderToRequest = ({
  id,
  description,
  state,
  tags,
  title,
  entities,
  entity_changesets,
  contractors,
  assignee,
  due_at,
  entity_type_id,
  location,
}: WorkOrder): WorkOrderRequest => ({
  id,
  description,
  state,
  tags,
  title,
  entities,
  entity_changesets,
  contractors,
  assignee,
  due_at,
  entity_type_id,
  location,
});

const packEntityChangesetRequests = (
  changesets: Record<string, EntityChangesetRequest>,
  schema: EntitySchema,
): Record<string, ApiEntityChangeSetRequest> => {
  return Object.entries(changesets).reduce<
    Record<string, ApiEntityChangeSetRequest>
  >((cs, [key, value]) => {
    const props = serializeEntityProps(schema, value.properties);
    cs[key] = { ...value, properties: props };
    return cs;
  }, {});
};

export const packWorkOrderRequest = (
  workOrder: WorkOrderRequest,
  schema: EntitySchema,
): ApiWorkOrderRequest => {
  return {
    ...workOrder,
    due_at: workOrder.due_at?.toISO(),
    entity_changesets: packEntityChangesetRequests(
      workOrder.entity_changesets,
      schema,
    ),
  };
};

const reviveWorkOrderEvent = (event: ApiWorkOrderEvent): WorkOrderEvent => ({
  ...event,
  sourceType: 'workorder',
  meta: reviveMetadata(event.meta),
});

const useListAndLookup = createListAndLookupStore(
  reviveWorkOrder,
  'workOrders',
);

export const useWorkOrders = (): UseListAndLookupQuery<
  ApiWorkOrder,
  WorkOrder
> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );
  const client = useApiClient();
  const { data: schema } = useEntitySchema();
  const query = useQuery<ApiWorkOrder[], Error>(
    ['workOrders', 'list'],
    () => client.workOrders.list(),
    {
      onSuccess: (data) => {
        set(data, schema);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseWorkOrderMutations {
  create: UseMutationResult<
    ApiWorkOrder[],
    Error,
    { request: WorkOrderRequest; autoAssign: boolean }
  >;
  update: UseMutationResult<
    ApiWorkOrder,
    Error,
    { id: string; request: WorkOrderRequest }
  >;
  remove: UseMutationResult<ApiWorkOrder, Error, WorkOrder['id']>;
}

export const useWorkOrderMutations = (): UseWorkOrderMutations => {
  const queryClient = useQueryClient();
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const client = useApiClient();
  const { data: schema } = useEntitySchema();
  const create: UseWorkOrderMutations['create'] = useMutation(
    ['workOrders', 'create'],
    ({ request: workOrder, autoAssign: auto_assign }) =>
      client.workOrders.create(
        packWorkOrderRequest(workOrder, schema as EntitySchema),
        { auto_assign },
      ),
    {
      onSuccess: (data) => {
        merge(data, schema);
      },
    },
  );

  const update: UseWorkOrderMutations['update'] = useMutation(
    ['workOrders', 'update'],
    ({ id, request }) =>
      client.workOrders.update(
        id,
        packWorkOrderRequest(request, schema as EntitySchema),
      ),
    {
      onSuccess: async (data, { id }) => {
        merge([data], schema);
        await queryClient.invalidateQueries(['workOrders', 'get', id]);
        await queryClient.invalidateQueries(['workOrders', 'listEvents', id]);
      },
      onError: async (error: AxiosError) => {
        if (error.response?.status === 409) {
          await queryClient.invalidateQueries(['entities', 'list']);
          await queryClient.invalidateQueries(['entities', 'listEvents']);
        }
      },
    },
  );

  const remove: UseWorkOrderMutations['remove'] = useMutation(
    ['workOrders', 'remove'],
    (id) => client.workOrders.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return { create, update, remove };
};

interface UseWorkOrder {
  workOrder: UseQueryResult<ApiWorkOrder, Error>;
  events: UseListAndLookupQuery<ApiWorkOrderEvent, WorkOrderEvent>;
  createEvent: UseMutationResult<
    WorkOrderEvent,
    Error,
    { event: WorkOrderEventClientRequest; files?: File[] }
  >;
}

export const useWorkOrder = (workOrderId: string): UseWorkOrder => {
  const client = useApiClient();
  const { merge } = useListAndLookup(useCallback((props) => props, []));
  const queryClient = useQueryClient();
  const { data: schema } = useEntitySchema();

  const workOrder = useQuery<ApiWorkOrder, Error>(
    ['workOrders', 'get', workOrderId],
    ({ queryKey }) => client.workOrders.get(queryKey[2] as string),
    {
      onSuccess: (data) => {
        merge([data], schema);
      },
    },
  );

  const eventsQuery = useQuery<ApiWorkOrderEvent[], Error>(
    ['workOrders', 'listEvents', workOrderId],
    ({ queryKey }) => client.workOrders.listEvents(queryKey[2] as string),
  );

  const events = useMemo(
    () => toListAndLookup(eventsQuery.data, reviveWorkOrderEvent),
    [eventsQuery.data],
  );

  const createEvent: UseWorkOrder['createEvent'] = useMutation(
    ['workOrders', 'createEvent', workOrderId],
    ({ event, files }) =>
      client.workOrders
        .createEvent(workOrderId, event, files)
        .then(reviveWorkOrderEvent),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          'workOrders',
          'listEvents',
          workOrderId,
        ]),
    },
  );

  return { workOrder, events: { ...eventsQuery, ...events }, createEvent };
};
