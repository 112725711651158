import { ApiFeatureLicense } from '@allbin/mobilix-api-client';
import React, { FC, ReactNode } from 'react';
import { useQuery } from 'react-query';
import LoadingScreen from '../components/auth/LoadingScreen';
import { useApiClient } from '../hooks/useApiClient';
import useUserStore from '../store/user';

export const useLicensePermission = (license: ApiFeatureLicense): boolean => {
  const { licenses } = useUserStore();
  return licenses.includes(license);
};

const LicensePermProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { setLicenses } = useUserStore();

  const client = useApiClient();
  const licensesQuery = useQuery<ApiFeatureLicense[]>(
    ['licenses', 'list'],
    client.featureLicenses.list,
    {
      onSuccess: (res) => {
        setLicenses(res);
      },
    },
  );

  if (licensesQuery.isLoading) {
    return <LoadingScreen />;
  }
  return <>{children}</>;
};

export default LicensePermProvider;
