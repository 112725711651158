import React, { FC, ReactNode, useMemo } from 'react';
import { Box, Tooltip, Typography, SxProps, useTheme } from '@mui/material';
import { ColorProps } from '../../providers/ThemeProvider';

interface ChipProps {
  name: string;
  onClick?: () => void;
  description?: string;
  /**
   * If false will set bg to transparent and color to wo.
   *
   * Defaults to true.
   */
  filled?: boolean;
  selected?: boolean;
  /** Color palette to use, defaults to WO. */
  palette?: ColorProps;
  endAdornment?: ReactNode;
  sx?: SxProps;
}

const Chip: FC<ChipProps> = ({
  selected,
  name,
  filled,
  onClick,
  description,
  palette,
  endAdornment,
  sx,
}) => {
  const theme = useTheme();
  const colors = palette || theme.palette.wo;
  filled = typeof filled === 'boolean' ? filled : true;

  const internalSx = useMemo<SxProps>(() => {
    const hover: SxProps = onClick
      ? {
          backgroundColor: colors.light,
          color: colors.textOnLight,
        }
      : {};
    return {
      display: 'inline-flex',
      backgroundColor: colors.main,
      color: 'background.paper',
      border: '1px solid rgba(0,0,0,0)',
      borderColor: colors.main,
      cursor: onClick ? 'pointer' : 'default',
      textTransform: 'none',
      margin: 1,
      borderRadius: '50px',
      padding: 0,
      height: '32px',
      alignItems: 'center',
      paddingLeft: 3,
      paddingRight: 3,
      ...sx,
      '&.selected': {
        backgroundColor: colors.dark,
        color: colors.contrastText,
      },
      '&.unfilled': {
        backgroundColor: 'unset',
        color: colors.medium,
        borderColor: colors.light,
      },
      '&.unfilled.selected': {
        backgroundColor: 'unset',
        color: colors.main,
        borderColor: colors.main,
      },
      '&:hover, &.unfilled:hover, &.unfilled.selected:hover': hover,
    };
  }, [sx, colors, onClick]);

  return (
    <Tooltip
      title={description || ``}
      PopperProps={{
        style: { whiteSpace: 'pre-line' },
      }}
      arrow
    >
      <Box
        sx={internalSx}
        className={`${filled ? 'filled' : 'unfilled'}${
          selected ? ' selected' : ''
        }`}
        onClick={onClick}
      >
        <Typography variant="button" sx={{ textTransform: 'none' }}>
          {name}
        </Typography>
        {endAdornment}
      </Box>
    </Tooltip>
  );
};

export default Chip;
