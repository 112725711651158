import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ContractorManagementDialogStore {
  show: boolean;
  selectedEntityIds: string[];
  setSelectedEntityIds: (selectedEntityIds: string[]) => void;
  open: () => void;
  close: () => void;
  workOrderToEdit?: WorkOrder;
  editWorkOrder: (workOrder: WorkOrder) => void;
}

const useEditWorkOrderDialogStore = create<ContractorManagementDialogStore>()(
  devtools(
    (set) => ({
      show: false,
      selectedEntityIds: [],
      workOrderToEdit: undefined,

      editWorkOrder: (workOrder) => {
        set({
          show: true,
          workOrderToEdit: workOrder,
          selectedEntityIds: workOrder.entities,
        });
      },
      open: () => {
        set({ show: true });
      },
      close: () => {
        set({
          show: false,
          selectedEntityIds: [],
          workOrderToEdit: undefined,
        });
      },
      setSelectedEntityIds: (selectedEntityIds) => set({ selectedEntityIds }),
    }),
    {
      name: 'edit-work-order-dialog-store',
      enabled: process.env.NODE_ENV !== 'test',
    },
  ),
);

export default useEditWorkOrderDialogStore;
