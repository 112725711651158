import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface FilterStore {
  executionFilters: ExecutionFilter[];
  setExecutionFilters: (filters: ExecutionFilter[]) => void;
  setShowUnmatched: (include: boolean) => void;
  setShowInactive: (include: boolean) => void;
  showUnmatched: boolean;
  showInactive: boolean;
  hiddenFilters: number[];
  setHiddenFilters: (indexes: number[]) => void;
  results: ExtendedFilterQueryResults;
  setResults: (results: ExtendedFilterQueryResults) => void;
}

const useFilterStore = create<FilterStore>()(
  devtools(
    (set) => ({
      executionFilters: [],
      setExecutionFilters: (filters) => set({ executionFilters: filters }),
      showUnmatched: false,
      showInactive: false,
      setShowUnmatched: (show) => set({ showUnmatched: show }),
      setShowInactive: (show) => set({ showInactive: show }),
      results: {
        final: [],
        steps: [],
        showUnmatched: true,
        showInactive: false,
      },
      setResults: (results) => set({ results }),
      setHiddenFilters: (hiddenIndexes) =>
        set({ hiddenFilters: hiddenIndexes }),
      hiddenFilters: [],
    }),
    { name: 'filter-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useFilterStore;
