import React, { FC } from 'react';
import { Column } from '.';
import { getFilterColor } from '../../helpers/colors';
import { FilledMapPin } from '../../icons';

const cellStyle = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
};

interface FilterIndicatorProps {
  colDef: Column<Entity>;
  data: Entity;
  value: number;
}

const FilterIndicator: FC<FilterIndicatorProps> = ({
  data: rowData,
  value,
  colDef: { onClick },
}) => {
  return (
    <div
      style={{
        ...cellStyle,
        color: getFilterColor(value),
        padding: 0,
      }}
      onClick={(e) => {
        if (onClick) {
          onClick(rowData);
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <FilledMapPin />
    </div>
  );
};

export default FilterIndicator;
