import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ContractorManagementDialogStore {
  show: boolean;
  selectedContractorId?: string;
  setSelectedContractorId: (selectedContractorId?: string) => void;
  setShow: (show: boolean) => void;
}

const useContractorManagementDialogStore =
  create<ContractorManagementDialogStore>()(
    devtools(
      (set) => ({
        show: false,
        selectedContractorId: undefined,

        setShow: (show) => set({ show }),
        setSelectedContractorId: (selectedContractorId) =>
          set({ selectedContractorId }),
      }),
      {
        name: 'contractor-management-dialog-store',
        enabled: process.env.NODE_ENV !== 'test',
      },
    ),
  );

export default useContractorManagementDialogStore;
