import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Close, Help } from '@mui/icons-material';
import { createNameId } from 'mnemonic-id';
import { useSnackbar } from 'notistack';

import { useEntityTypes } from '../../hooks/useEntityTypes';
import { useFilterSets } from '../../hooks/useFilterSets';
import { useEntities } from '../../hooks/useEntities';
import { useErrorReports } from '../../hooks/useErrorReports';
import { useRoutes } from '../../hooks/useRoutes';
import useEditWorkOrderDialogStore from '../../store/editWorkOrderDialog';
import {
  useWorkOrderMutations,
  workOrderToRequest,
} from '../../hooks/useWorkOrders';
import { useContractors } from '../../hooks/useContractors';
import useGuideStore from '../../store/guide';
import { useWorkOrderInstructions } from '../../hooks/useWorkOrderInstructions';
import { calculateFilteredEntities } from '../../hooks/useFiltering';
import { useEntitySchema } from '../../hooks/useEntitySchema';
import EntityListItem from '../EntityListItem/EntityListItem';
import TagSelector from '../Tags/TagSelector';
import StopSearch from '../Inputs/StopSearch';
import DateInput from '../Inputs/DateInput';
import Dropdown from '../Inputs/Dropdown';
import TextInput from '../Inputs/TextInput';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';
import Button from '../Inputs/Button';

const VISIBLE_ENTITIES_LIMIT = 100;

const createDefaultWorkOrder = (entityTypeId: string): WorkOrderRequest => {
  return {
    entity_type_id: entityTypeId,
    state: 'created',
    contractors: [],
    entity_changesets: {},
    description: '',
    tags: [],
    title: createNameId(),
    entities: [],
  };
};

const messages = defineMessages({
  searchLabel: {
    id: 'edit_workorder_dialog.search_label',
    defaultMessage: 'Search stops to add',
  },
  workInstructions: {
    id: 'edit_workorder_dialog.work_instructions',
    defaultMessage: 'Enter work instructions here...',
  },
});

const EditWorkOrderDialog: React.FC = () => {
  const { data: schema } = useEntitySchema();
  const { goToWorkOrderDetails } = useRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { stop } = useEntityTypes();
  const { openGuide } = useGuideStore();
  const { list: entities, lookup: entitiesLookup } = useEntities();
  const { list: filterSets } = useFilterSets();
  const { list: instructions } = useWorkOrderInstructions();
  const { list: errorReports } = useErrorReports();
  const {
    show: showEditWorkOrderDialog,
    close: closeEditWorkOrderDialog,
    selectedEntityIds,
    setSelectedEntityIds,
    workOrderToEdit,
  } = useEditWorkOrderDialogStore();
  const {
    create: { mutateAsync: createWorkOrder, isLoading: isCreating },
    update: { mutateAsync: updateWorkOrder },
  } = useWorkOrderMutations();
  const { list: contractors } = useContractors();

  const [templateAnchor, setTemplateAnchor] = useState<null | HTMLElement>(
    null,
  );
  const [confirmDirty, setConfirmDirty] = useState<boolean>(false);
  const [changes, setChanges] = useState<boolean>(false);
  const [autoAssign, setAutoAssign] = useState<boolean>(false);

  const [localWorkOrder, setLocalWorkOrder] = useState<WorkOrderRequest>(
    workOrderToEdit
      ? workOrderToRequest(workOrderToEdit)
      : createDefaultWorkOrder(stop?.id || ''),
  );

  const activeErrorReportsByEntityId = useMemo(() => {
    return errorReports.reduce<Record<string, ErrorReport[]>>(
      (erbe, report) => {
        erbe[report.entity_id] = erbe[report.entity_id] || [];
        erbe[report.entity_id].push(report);
        return erbe;
      },
      {},
    );
  }, [errorReports]);

  useEffect(() => {
    setLocalWorkOrder(
      workOrderToEdit
        ? workOrderToRequest(workOrderToEdit)
        : createDefaultWorkOrder(stop?.id || ''),
    );
  }, [stop, workOrderToEdit]);

  const setLocalWorkOrderAndFlagChanges = useCallback(
    (wo: WorkOrderRequest) => {
      setChanges(true);
      setLocalWorkOrder(wo);
    },
    [setLocalWorkOrder],
  );

  const updateSelectedEntityIds = useCallback(
    (entityIds: string[]) => {
      setChanges(true);
      setSelectedEntityIds(entityIds);
    },
    [setSelectedEntityIds],
  );

  const onAddFilterSet = useCallback(
    (filterSetIds: string[]) => {
      if (!schema) {
        return false;
      }
      const filterSet = filterSets.find((f) => filterSetIds.includes(f.id));
      if (!filterSet) {
        return;
      }
      const results = calculateFilteredEntities(
        entities,
        entitiesLookup,
        filterSet.filters.map((f) => ({
          active: true,
          filter: f,
        })),
        schema,
        true,
      );
      const seenIds: string[] = [];
      const newEntityIds = [
        ...selectedEntityIds,
        ...results.final.map((e) => e.id),
      ].filter((entityId) => {
        if (seenIds.includes(entityId)) {
          return false;
        }
        seenIds.push(entityId);
        return true;
      });
      updateSelectedEntityIds(newEntityIds);
    },
    [
      entities,
      entitiesLookup,
      filterSets,
      schema,
      selectedEntityIds,
      updateSelectedEntityIds,
    ],
  );

  const closeDialog = useCallback(() => {
    setLocalWorkOrder(createDefaultWorkOrder(stop?.id || ''));
    setConfirmDirty(false);
    setChanges(false);
    setAutoAssign(false);
    closeEditWorkOrderDialog();
  }, [closeEditWorkOrderDialog, stop]);

  const handleSave = useCallback(
    (open?: boolean) => {
      const localWOWithEntities = {
        ...localWorkOrder,
        entities: selectedEntityIds,
      };
      if (workOrderToEdit) {
        updateWorkOrder({
          id: workOrderToEdit.id,
          request: localWOWithEntities,
        })
          .then(() => {
            closeDialog();
            open && goToWorkOrderDetails(workOrderToEdit.id);
            enqueueSnackbar(
              <FormattedMessage
                defaultMessage="Work order updated"
                id="edit_workorder_dialog.work_order_updated"
              />,
              { variant: 'success' },
            );
          })
          .catch((err) => {
            throw err;
          });
      } else {
        createWorkOrder({
          request: localWOWithEntities,
          autoAssign: autoAssign,
        })
          .then((wo) => {
            closeDialog();
            open && wo.length === 1 && goToWorkOrderDetails(wo[0].id);
            enqueueSnackbar(
              <FormattedMessage
                defaultMessage="Work order created"
                id="edit_workorder_dialog.work_order_created"
              />,
              { variant: 'success' },
            );
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    [
      localWorkOrder,
      selectedEntityIds,
      workOrderToEdit,
      updateWorkOrder,
      closeDialog,
      goToWorkOrderDetails,
      enqueueSnackbar,
      createWorkOrder,
      autoAssign,
    ],
  );

  const tryClose = useCallback(() => {
    if (changes) {
      setConfirmDirty(true);
    } else {
      closeDialog();
    }
  }, [changes, closeDialog]);

  const contractorAlternatives = useMemo(
    () => contractors.map((c) => ({ id: c.id, label: c.name })),
    [contractors],
  );
  const filtersetAlternatives = useMemo(
    () => filterSets.map((f) => ({ id: f.id, label: f.name })),
    [filterSets],
  );

  return (
    <>
      <UnsavedChangesDialog
        open={confirmDirty}
        onOk={() => {
          setConfirmDirty(false);
          setChanges(false);
          closeDialog();
        }}
        onCancel={() => setConfirmDirty(false)}
      />
      <Dialog open={showEditWorkOrderDialog} onClose={tryClose} maxWidth="lg">
        <DialogTitle>
          <FormattedMessage
            id="edit_workorder_dialog.edit_workorder"
            defaultMessage="Edit workorder"
          />
          <div>
            <IconButton
              color="inherit"
              onClick={() => openGuide('EditWorkOrderDialog')}
            >
              <Help />
            </IconButton>
            <IconButton color="inherit" onClick={tryClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: ({ palette }) => palette.background.default,
          }}
        >
          <Box m={0} pt={6}>
            <Grid container spacing={2} columnSpacing={8}>
              <Grid item xs={6}>
                <TextInput
                  value={localWorkOrder.title}
                  onChange={(value) =>
                    setLocalWorkOrderAndFlagChanges({
                      ...localWorkOrder,
                      title: value,
                    })
                  }
                  label={
                    <FormattedMessage
                      id="edit_workorder_dialog.title"
                      defaultMessage="Title"
                    />
                  }
                  endAdornment={
                    <Typography color="text.secondary">
                      {(localWorkOrder?.id &&
                        `#${localWorkOrder.id.slice(0, 6)}`) ||
                        ''}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <DateInput
                  value={localWorkOrder?.due_at}
                  onChange={(endDate) => {
                    setLocalWorkOrderAndFlagChanges({
                      ...localWorkOrder,
                      due_at: endDate,
                    });
                  }}
                  label={
                    <FormattedMessage
                      id="edit_workorder_dialog.due_date"
                      defaultMessage="Due date"
                    />
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Box width="100%">
                  <Dropdown
                    multiselect
                    label={
                      <FormattedMessage
                        id="edit_workorder_dialog.contractor"
                        defaultMessage="Contractor"
                      />
                    }
                    placeholder={
                      <FormattedMessage
                        id="edit_workorder_dialog.no_contractor"
                        defaultMessage="No contractor"
                      />
                    }
                    alternatives={contractorAlternatives}
                    selectedIds={localWorkOrder.contractors}
                    onSelect={(ids) => {
                      setLocalWorkOrderAndFlagChanges({
                        ...localWorkOrder,
                        contractors: ids.includes('') ? [] : ids,
                      });
                    }}
                  />
                </Box>
                {!workOrderToEdit && (
                  <Box paddingTop={4} display="flex" alignItems="center">
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="edit_workorder_dialog.auto_assign_desc"
                          defaultMessage="Automatically distribute created workorders among assigned contractors based on contractor filtersets. If no contractors are assigned, distribute among all of them."
                        />
                      }
                    >
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(_, checked) => setAutoAssign(checked)}
                        />
                        <Typography noWrap={true}>
                          <FormattedMessage
                            id="edit_workorder_dialog.auto_assign"
                            defaultMessage="Auto-assign"
                          />
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  label={
                    <FormattedMessage
                      id="edit_workorder_dialog.filterset"
                      defaultMessage="Filterset"
                    />
                  }
                  placeholder={
                    <FormattedMessage
                      id="edit_workorder_dialog.add_stops_from_a_filterset"
                      defaultMessage="Add stops from a filterset"
                    />
                  }
                  noSelectMode
                  alternatives={filtersetAlternatives}
                  onSelect={onAddFilterSet}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  value={localWorkOrder.description}
                  onChange={(description) =>
                    setLocalWorkOrderAndFlagChanges({
                      ...localWorkOrder,
                      description,
                    })
                  }
                  multiline
                  rows={10}
                  label={
                    <FormattedMessage
                      id="edit_workorder_dialog.work_instructions"
                      defaultMessage="Work instructions"
                    />
                  }
                  placeholder={intl.formatMessage(messages.workInstructions)}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                  mb={4}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setLocalWorkOrderAndFlagChanges({
                        ...localWorkOrder,
                        description: '',
                      })
                    }
                  >
                    <FormattedMessage
                      id="edit_workorder_dialog.clear"
                      defaultMessage="Clear"
                    />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) => setTemplateAnchor(e.currentTarget)}
                  >
                    <FormattedMessage
                      id="edit_workorder_dialog.load_template"
                      defaultMessage="Load template"
                    />
                  </Button>
                  <Menu
                    open={!!templateAnchor}
                    anchorEl={templateAnchor}
                    onClose={() => setTemplateAnchor(null)}
                  >
                    {instructions.length === 0 && (
                      <MenuItem>
                        <ListItemText
                          primary={
                            <FormattedMessage
                              id="no_templates_created"
                              defaultMessage="No templates created"
                            />
                          }
                        />
                      </MenuItem>
                    )}
                    {instructions.map((instruction) => {
                      return (
                        <MenuItem
                          key={instruction.id}
                          onClick={() =>
                            setLocalWorkOrderAndFlagChanges({
                              ...localWorkOrder,
                              description: localWorkOrder.description
                                ? localWorkOrder.description +
                                  `\n` +
                                  instruction.texts.join(`\n`)
                                : instruction.texts.join(`\n`),
                              tags: Array.from(
                                new Set([
                                  ...localWorkOrder.tags,
                                  ...(instruction.tags || []),
                                ]),
                              ),
                            })
                          }
                        >
                          <ListItemText primary={instruction.title} />
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
                <Typography variant="overline" color="text.secondary">
                  <FormattedMessage
                    id="edit_workorder_dialog.tags"
                    defaultMessage="Tags"
                  />
                </Typography>
                <Box sx={{ height: '25vh', display: 'flex' }}>
                  <TagSelector
                    onChange={(selectedTags) =>
                      setLocalWorkOrderAndFlagChanges({
                        ...localWorkOrder,
                        tags: selectedTags,
                      })
                    }
                    selectedTags={localWorkOrder?.tags || []}
                    automaticSearchMode={12}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2} mt={3}>
                  <StopSearch
                    value={selectedEntityIds}
                    onChange={updateSelectedEntityIds}
                    entities={entities}
                    entitiesLookup={entitiesLookup}
                  />
                </Box>
                <Box mb={2} flex={1} flexDirection="column">
                  <Typography variant="overline" color="text.secondary">
                    <Box>
                      <FormattedMessage
                        id="edit_workorder_dialog.stops"
                        defaultMessage="Stops"
                      />
                      : {selectedEntityIds.length}
                    </Box>
                  </Typography>
                  <Typography variant="overline" color="text.secondary">
                    <FormattedMessage
                      id="edit_workorder_dialog.stops_remove_help"
                      defaultMessage="(click on the trashcan of a stop to remove it)"
                    />
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  maxHeight="50vh"
                  minHeight="50vh"
                  overflow="auto"
                  alignItems="flex-start"
                  alignContent="flex-start"
                >
                  {selectedEntityIds
                    .slice(0, VISIBLE_ENTITIES_LIMIT)
                    .map((id) => entitiesLookup[id])
                    .map((entity) =>
                      entity === undefined ? null : (
                        <EntityListItem
                          key={entity.id}
                          entity={entity}
                          errorReports={activeErrorReportsByEntityId[entity.id]}
                          onRemoveClick={() =>
                            updateSelectedEntityIds(
                              selectedEntityIds.filter(
                                (id) => id !== entity.id,
                              ),
                            )
                          }
                        />
                      ),
                    )}
                  {selectedEntityIds.length > 100 && (
                    <Box key="maximum_entities_rendered">
                      <Typography
                        sx={{
                          color: '#888',
                          fontStyle: 'italic',
                        }}
                      >
                        <FormattedMessage
                          id="edit_workorder_dialog.maximum_entities_rendered"
                          defaultMessage="... (only showing the first 100 items)"
                        />
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={tryClose}>
            <FormattedMessage
              id="edit_workorder_dialog.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="contained"
            disabled={
              isCreating ||
              (autoAssign === true && selectedEntityIds.length === 0)
            }
            sx={{ minWidth: '180px' }}
            onClick={() => handleSave()}
          >
            {isCreating ? (
              <CircularProgress size={16} />
            ) : workOrderToEdit ? (
              <FormattedMessage
                id="edit_workorder_dialog.save_workorder"
                defaultMessage="Save workorder"
              />
            ) : (
              <FormattedMessage
                id="edit_workorder_dialog.create_workorder"
                defaultMessage="Create workorder"
              />
            )}
          </Button>
          {!workOrderToEdit && (
            <Button
              tooltip={
                selectedEntityIds.length > VISIBLE_ENTITIES_LIMIT ||
                autoAssign === true ? (
                  <FormattedMessage
                    id="edit_workorder_dialog.create_and_open_disabled_help"
                    defaultMessage="Disabled when request might lead to the creation of more than one workorder."
                  />
                ) : undefined
              }
              disabled={
                selectedEntityIds.length > VISIBLE_ENTITIES_LIMIT ||
                autoAssign === true
              }
              variant="contained"
              onClick={() => handleSave(true)}
            >
              <FormattedMessage
                id="edit_workorder_dialog.create_and_open_workorder"
                defaultMessage="Create and open workorder"
              />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditWorkOrderDialog;
