import React, { FC, useCallback, useMemo } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  ApiPeriodicityMonthlyOccurrence,
  ApiPeriodicityYearlyOccurrence,
} from '@allbin/mobilix-api-client';
import { Add, Delete } from '@mui/icons-material';
import Dropdown from '../Inputs/Dropdown';

interface PeriodicityControlProps {
  periodicity: Periodicity;
  onChange: (periodicity: Periodicity) => void;
}

const messages = defineMessages({
  jan: {
    id: 'january',
    defaultMessage: 'January',
  },
  feb: {
    id: 'february',
    defaultMessage: 'February',
  },
  mar: {
    id: 'march',
    defaultMessage: 'March',
  },
  apr: {
    id: 'april',
    defaultMessage: 'April',
  },
  may: {
    id: 'may',
    defaultMessage: 'May',
  },
  jun: {
    id: 'june',
    defaultMessage: 'June',
  },
  jul: {
    id: 'july',
    defaultMessage: 'July',
  },
  aug: {
    id: 'august',
    defaultMessage: 'August',
  },
  sep: {
    id: 'september',
    defaultMessage: 'September',
  },
  oct: {
    id: 'october',
    defaultMessage: 'October',
  },
  nov: {
    id: 'november',
    defaultMessage: 'November',
  },
  dec: {
    id: 'december',
    defaultMessage: 'December',
  },
  yearly: {
    id: 'check_in_plan_management.yearly',
    defaultMessage: 'Yearly',
  },
  monthly: {
    id: 'check_in_plan_management.monthly',
    defaultMessage: 'Monthly',
  },
});

const dateOptions = Array(31)
  .fill(null)
  .map((v, i) => {
    const date = (i + 1).toString();
    return {
      id: date,
      label: date,
    };
  });

const PeriodicityControl: FC<PeriodicityControlProps> = ({
  periodicity,
  onChange,
}) => {
  const intl = useIntl();
  const { type, occurrences } = periodicity;

  const periodicityOptions = useMemo(
    () => [
      {
        id: 'yearly',
        label: intl.formatMessage(messages.yearly),
      },
      {
        id: 'monthly',
        label: intl.formatMessage(messages.monthly),
      },
    ],
    [intl],
  );
  const monthOptions = useMemo(
    () => [
      {
        id: '1',
        label: intl.formatMessage(messages.jan),
      },
      {
        id: '2',
        label: intl.formatMessage(messages.feb),
      },
      {
        id: '3',
        label: intl.formatMessage(messages.mar),
      },
      {
        id: '4',
        label: intl.formatMessage(messages.apr),
      },
      {
        id: '5',
        label: intl.formatMessage(messages.may),
      },
      {
        id: '6',
        label: intl.formatMessage(messages.jun),
      },
      {
        id: '7',
        label: intl.formatMessage(messages.jul),
      },
      {
        id: '8',
        label: intl.formatMessage(messages.aug),
      },
      {
        id: '9',
        label: intl.formatMessage(messages.sep),
      },
      {
        id: '10',
        label: intl.formatMessage(messages.oct),
      },
      {
        id: '11',
        label: intl.formatMessage(messages.nov),
      },
      {
        id: '12',
        label: intl.formatMessage(messages.dec),
      },
    ],
    [intl],
  );

  const onYearlyOccChange = useCallback(
    (occIndex: number, date: number, month: number) => {
      const clone = [...occurrences] as ApiPeriodicityYearlyOccurrence[];
      if (clone.length > occIndex) {
        clone.splice(occIndex, 1, { date, month });
      } else {
        clone.push({ date, month });
      }
      onChange({
        type: 'yearly',
        occurrences: clone,
      });
    },
    [occurrences, onChange],
  );
  const onMonthlyOccChange = useCallback(
    (occIndex: number, date: number) => {
      const clone = [...occurrences] as ApiPeriodicityMonthlyOccurrence[];
      if (clone.length > occIndex) {
        clone.splice(occIndex, 1, { date });
      } else {
        clone.push({ date });
      }
      onChange({
        type: 'monthly',
        occurrences: clone,
      });
    },
    [occurrences, onChange],
  );
  const onNewOccurrence = useCallback(() => {
    if (type === 'yearly') {
      onYearlyOccChange(9999, 1, 1);
      return;
    }
    onMonthlyOccChange(9999, 1);
  }, [onMonthlyOccChange, onYearlyOccChange, type]);

  return (
    <Box
      sx={{
        width: '100%',
        pl: 2,
        borderLeft: '1px solid black',
        borderColor: 'wo.light',
      }}
    >
      <Dropdown
        label={
          <FormattedMessage
            id="check_in_plan_management.periodicity_input_label"
            defaultMessage="Periodicity"
          />
        }
        alternatives={periodicityOptions}
        selectedIds={[type]}
        immediateSelectMode
        onSelect={(ids) => {
          const id = ids[0];
          if (id !== periodicity.type) {
            if (id === 'yearly') {
              onChange({
                type: id as 'yearly',
                occurrences: [{ month: 1, date: 1 }],
              });
            } else {
              onChange({
                type: id as 'monthly',
                occurrences: [{ date: 1 }],
              });
            }
          }
        }}
      />
      <Box>
        {type === 'yearly' &&
          occurrences.map((occ, i) => (
            <Box
              key={i + '_' + occ.month + '_' + occ.date}
              display="flex"
              flexDirection="row"
              gap={2}
              justifyItems="flex-end"
              alignItems="flex-end"
            >
              <Box flexBasis={0} flexGrow={1}>
                <Dropdown
                  label={
                    <FormattedMessage
                      id="check_in_plan_management.date"
                      defaultMessage="Date"
                    />
                  }
                  alternatives={dateOptions}
                  selectedIds={[occ.date.toString()]}
                  immediateSelectMode
                  onSelect={(ids) => {
                    onYearlyOccChange(i, parseInt(ids[0], 10), occ.month);
                  }}
                />
              </Box>
              <Box flexBasis={0} flexGrow={1}>
                <Dropdown
                  label={
                    <FormattedMessage
                      id="check_in_plan_management.month"
                      defaultMessage="Month"
                    />
                  }
                  alternatives={monthOptions}
                  selectedIds={[occ.month.toString()]}
                  immediateSelectMode
                  onSelect={(ids) => {
                    onYearlyOccChange(i, occ.date, parseInt(ids[0], 10));
                  }}
                />
              </Box>
              <Box pb={1} mr={i === 0 ? 10 : 0}>
                {i !== 0 && (
                  <IconButton
                    onClick={() => {
                      const clone = [
                        ...occurrences,
                      ] as ApiPeriodicityYearlyOccurrence[];
                      clone.splice(i, 1);
                      onChange({
                        type: 'yearly',
                        occurrences: clone,
                      });
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        {type === 'monthly' &&
          occurrences.map((occ, i) => (
            <Box
              key={i + '_' + occ.date}
              display="flex"
              flexDirection="row"
              gap={2}
              justifyItems="flex-end"
              alignItems="flex-end"
            >
              <Box flexBasis={0} flexGrow={1}>
                <Dropdown
                  label={
                    <FormattedMessage
                      id="check_in_plan_management.date"
                      defaultMessage="Date"
                    />
                  }
                  alternatives={dateOptions}
                  noSort
                  selectedIds={[occ.date.toString()]}
                  immediateSelectMode
                  onSelect={(ids) => {
                    onMonthlyOccChange(i, parseInt(ids[0], 10));
                  }}
                />
              </Box>
              <Box pb={1}>
                <IconButton
                  onClick={() => {
                    const clone = [
                      ...occurrences,
                    ] as ApiPeriodicityMonthlyOccurrence[];
                    clone.splice(i, 1);
                    onChange({
                      type: 'monthly',
                      occurrences: clone,
                    });
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))}
        <Button
          variant="contained"
          size="small"
          sx={{ width: '100%', marginTop: 2 }}
          endIcon={<Add />}
          onClick={onNewOccurrence}
        >
          <FormattedMessage
            id="check_in_plan_management.new_occurrence"
            defaultMessage="New occurrence"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default PeriodicityControl;
