import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333 7.333 22 12Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Eye',
);

const Icon: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default Icon;
