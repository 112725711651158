import { EntityLogic } from '@allbin/entity-logic';

export function executeFilters(
  schema: EntitySchemaDefinition,
  entities: Entity[],
  executionFilters: ExecutionFilter[],
): FilterQueryResults {
  const logic = EntityLogic(schema);

  return executionFilters.reduce<FilterQueryResults>(
    (qr, ef, i) => {
      const last_step =
        i === 0 ? { matched: qr.final, unmatched: [] } : qr.steps[i - 1];

      if (!ef.active) {
        qr.steps.push({
          filter: ef,
          matched: last_step.matched,
          unmatched: [],
        });
        return qr;
      }
      const step = logic.executeWithSeparatedResults<Entity>(
        last_step.matched,
        logic.unserializeFilter(ef.filter),
      );
      qr.steps.push({
        filter: ef,
        ...step,
      });
      qr.final = step.matched;
      return qr;
    },
    {
      final: entities,
      steps: [],
    },
  );
}

export const equalPropValues = (
  a: EntityChangeset['properties'][0] | undefined,
  b: EntityChangeset['properties'][0] | undefined,
): boolean => {
  if (a === b) {
    return true;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (
      (a as any[]).every((v) => (b as any[]).includes(v)) &&
      (b as any[]).every((v) => (a as any[]).includes(v))
    ) {
      return true;
    }
  }
  return false;
};
