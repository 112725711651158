import { useCallback } from 'react';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { useApiClient } from './useApiClient';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';

const reviveInstruction: ReviverFn<
  ApiWorkOrderInstruction,
  WorkOrderInstruction
> = (apiTemplate) => ({
  ...apiTemplate,
  meta: reviveMetadata(apiTemplate.meta),
});

const packInstruction = (
  instruction:
    | WorkOrderInstruction
    | Omit<WorkOrderInstruction, 'meta'>
    | Omit<WorkOrderInstruction, 'id' | 'meta'>,
): ApiWorkOrderInstructionRequest => {
  return {
    title: instruction.title,
    tags: instruction.tags,
    texts: instruction.texts,
  };
};

const useListAndLookup = createListAndLookupStore(
  reviveInstruction,
  'WOInstructions',
);

export const useWorkOrderInstructions = (): UseListAndLookupQuery<
  ApiWorkOrderInstruction,
  WorkOrderInstruction
> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );
  const client = useApiClient();
  const query = useQuery<ApiWorkOrderInstruction[], Error>(
    ['workOrderInstruction', 'list'],
    () => client.workOrderInstructions.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseWOInstructionMutations {
  create: UseMutationResult<
    ApiWorkOrderInstruction,
    Error,
    Omit<WorkOrderInstruction, 'id' | 'meta'>
  >;
  update: UseMutationResult<
    ApiWorkOrderInstruction,
    Error,
    Omit<WorkOrderInstruction, 'meta'>
  >;
  remove: UseMutationResult<
    ApiWorkOrderInstruction,
    Error,
    WorkOrderInstruction['id']
  >;
}

export const useWOInstructionMutations = (): UseWOInstructionMutations => {
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const client = useApiClient();
  const create: UseWOInstructionMutations['create'] = useMutation(
    ['workOrderInstruction', 'create'],
    (checkInPlan) =>
      client.workOrderInstructions.create(packInstruction(checkInPlan)),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const update: UseWOInstructionMutations['update'] = useMutation(
    ['workOrderInstruction', 'update'],
    (instruction) =>
      client.workOrderInstructions.update(
        instruction.id,
        packInstruction(instruction),
      ),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseWOInstructionMutations['remove'] = useMutation(
    ['workOrderInstruction', 'remove'],
    (id) => client.workOrderInstructions.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return { create, remove, update };
};
