import { BusAlert } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { FC, useMemo, useState } from 'react';
import TopList from '../../../components/TopList/TopList';
import { TopListEntryData } from '../../../components/TopList/TopListEntry';
import { TopListDataSettings } from '../../../components/TopList/TopListSettings';
import { formatNumber } from '../../../helpers/formatting_helpers';
import { randInt } from '../../../helpers/math_helpers';
import { GalleryToggle } from '../../GalleryToggle';
const getEntries = (count: number): TopListEntryData[] => {
  const labels = [
    'A',
    'D',
    'C',
    'Centralstationen Bussterminal F',
    'Linje 555 mot Annan stad',
    'Resecentrum',
    'Blåbärsstigen B',
    'Ölandsleden 5',
    'Ostvägen O',
  ];

  const entries = Array(count)
    .fill(null)
    .map<TopListEntryData>((v, i) => {
      return {
        label: labels[randInt(0, labels.length)],
        id: i.toString(),
        value: randInt(0, 9999999),
        startAdornment: i % randInt(3, count) === 0 ? <BusAlert /> : undefined,
      };
    });
  if (count > 2) {
    entries[randInt(0, Math.ceil(count / 2))].value = 9999999;
    entries[randInt(Math.ceil(count / 2), count)].value = 0;
  }
  return entries;
};
const yesterday = DateTime.now()
  .set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  .minus({ day: 1 });
const onClick = (x: string): void => console.log('TopList: onClick:', x);

const GTopList: FC = () => {
  const theme = useTheme();
  const [bg, setBg] = useState<boolean>(true);
  const [border, setBorder] = useState<boolean>(true);
  const [settings, setSettings] = useState<TopListDataSettings>({
    dateRange: 'previous_7_days',
    entryCount: 5,
    minDate: yesterday.minus({ days: 7 }),
    maxDate: yesterday,
  });
  const entries = useMemo(
    () => getEntries(settings.entryCount),
    [settings.entryCount],
  );

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        '& .c': {
          width: '450px',
          background: bg ? 'white' : 'unset',
          p: 4,
          m: 2,
          '& > div': {
            border: border ? '1px dashed lightgrey' : '1px solid transparent',
          },
        },
      }}
    >
      The data is intentionally not sorted, to show that the toplist DOES NOT
      sort or modify entries!
      <br />
      The background and border is only present in Gallery, implementing parent
      component is responsible for margins, borders, backgrounds.
      <Box display="flex" mb={3} gap={2}>
        <GalleryToggle
          label="background"
          active={bg}
          onClick={() => setBg(!bg)}
        />
        <GalleryToggle
          label="Border"
          active={border}
          onClick={() => setBorder(!border)}
        />
      </Box>
      <Box className="c">
        Without info, custom tooltip, no onClick
        <Box>
          <TopList
            title="Toplist title"
            settings={settings}
            onSettingsChange={setSettings}
            tooltipFormatter={(id, value) =>
              formatNumber(value, { roundTo: -2, toFixed: 1, grouping: true })
            }
            entries={entries}
            palette={theme.palette.traffic}
          />
        </Box>
      </Box>
      <Box className="c">
        With info, long title
        <Box>
          <TopList
            title="Snittpåstigande per dag (30 dagar)"
            settings={settings}
            onSettingsChange={setSettings}
            entries={entries}
            palette={theme.palette.entity}
            info={{
              heading: 'Info om snittpåstigande',
              body: 'Lite beskrivande text som förklarar toplisten.',
            }}
            onClick={onClick}
          />
        </Box>
      </Box>
      <Box className="c">
        With info, custom divisor
        <Box>
          <TopList
            title="This is a long toplist title"
            settings={settings}
            onSettingsChange={setSettings}
            entries={entries}
            palette={theme.palette.wo}
            info={{
              heading: 'Info om en lång toplista',
              body: 'Lite beskrivande text som förklarar toplisten.',
            }}
            onClick={onClick}
            divisor={125}
          />
        </Box>
      </Box>
      <Box className="c">
        With info, custom small divisor
        <Box>
          <TopList
            title="This is a long toplist title"
            settings={settings}
            onSettingsChange={setSettings}
            entries={entries}
            palette={theme.palette.wo}
            info={{
              heading: 'Info om en lång toplista med jä',
              body: (
                <ul>
                  <li>Ett</li>
                  <li>Två</li>
                  <li>Tre</li>
                  <li>Fyra</li>
                  <li>Fem</li>
                </ul>
              ),
            }}
            onClick={onClick}
            divisor={20}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GTopList;
