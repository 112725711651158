import { useApiClient } from './useApiClient';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useCallback } from 'react';
import { ApiFilterSetRequest } from '@allbin/mobilix-api-client';

export const reviveFilterSet: ReviverFn<ApiFilterSet, FilterSet> = (
  filterSet,
) => ({
  ...filterSet,
  meta: reviveMetadata(filterSet.meta),
});

const useListAndLookup = createListAndLookupStore(
  reviveFilterSet,
  'filterSets',
);

export const useFilterSets = (
  entityTypeId?: string,
): UseListAndLookupQuery<ApiFilterSet, FilterSet> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );

  const client = useApiClient();
  const query = useQuery<ApiFilterSet[], Error>(
    ['filterSets', 'list', entityTypeId],
    ({ queryKey }) => client.filterSets.list(queryKey[2] as string),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseFilterSetMutations {
  create: UseMutationResult<ApiFilterSet, Error, ApiFilterSetRequest>;
  update: UseMutationResult<
    ApiFilterSet,
    Error,
    { id: ApiFilterSet['id']; filterSet: ApiFilterSetRequest }
  >;
  remove: UseMutationResult<ApiFilterSet, Error, ApiFilterSet['id']>;
}

export const useFilterSetMutations = (): UseFilterSetMutations => {
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const client = useApiClient();

  const create: UseFilterSetMutations['create'] = useMutation(
    ['filterSets', 'create'],
    (filterSet) => client.filterSets.create(filterSet),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const update: UseFilterSetMutations['update'] = useMutation(
    ['filterSets', 'update'],
    ({ id, filterSet }) => client.filterSets.update(id, filterSet),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseFilterSetMutations['remove'] = useMutation(
    ['filterSet', 'remove'],
    (id) => client.filterSets.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return {
    create,
    update,
    remove,
  };
};
