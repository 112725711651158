import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.414 20.9L17.657 16.657C18.7758 15.5382 19.5377 14.1127 19.8463 12.5609C20.155 11.009 19.9965 9.40051 19.391 7.93873C18.7855 6.47694 17.7601 5.22754 16.4445 4.3485C15.1289 3.46947 13.5822 3.00029 12 3.00029C10.4178 3.00029 8.87107 3.46947 7.55548 4.3485C6.2399 5.22754 5.21452 6.47694 4.60901 7.93873C4.00349 9.40051 3.84504 11.009 4.15369 12.5609C4.46234 14.1127 5.22422 15.5382 6.343 16.657L10.587 20.9C10.962 21.2746 11.4704 21.4851 12.0005 21.4851C12.5306 21.4851 13.039 21.2746 13.414 20.9ZM9.5 11C9.5 9.61929 10.6193 8.5 12 8.5C13.3807 8.5 14.5 9.61929 14.5 11C14.5 12.3807 13.3807 13.5 12 13.5C10.6193 13.5 9.5 12.3807 9.5 11ZM12 7.5C10.067 7.5 8.5 9.067 8.5 11C8.5 12.933 10.067 14.5 12 14.5C13.933 14.5 15.5 12.933 15.5 11C15.5 9.067 13.933 7.5 12 7.5Z"
      fill="currentColor"
    />
  </svg>,
  'FilledMapPin',
);

const Icon: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default Icon;
