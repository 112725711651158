import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Typography,
} from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Permissions from '../auth/Permissions';
import { LoadingButton } from '@mui/lab';
import { useErrorReportsMutations } from '../../hooks/useErrorReports';
import useSchemaManagementDialog from '../../store/schemaManagementDialog';
import { Add } from '@mui/icons-material';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';

const messages = defineMessages({
  choose: { id: 'choose', defaultMessage: 'Choose...' },
  choose_property: {
    id: 'error_report.choose_property',
    defaultMessage: 'Choose property',
  },
  error_description: {
    id: 'error_report.error_description',
    defaultMessage: 'Error description',
  },
  enter_error_description: {
    id: 'error_report.enter_error_description',
    defaultMessage: 'Enter error description...',
  },
});

interface NewErrorFormProps {
  availableProps:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  toggles: Record<'label' | 'id', string>[];
  schema: EntitySchema;
  setSelectedEntityId: (id: string | null) => void;
  selectedEntityId: string | null;
  onClose: () => void;
  entityName: string;
}

const createErrorMailTo = (
  destination: string,
  propName: string,
  entityName: string,
  message?: string,
): string => {
  const subject = encodeURIComponent(`${entityName}: ${propName}`);
  let link = `mailto:${destination}?subject=${subject}`;
  if (message) {
    link += '&body=' + encodeURIComponent(message);
  }
  return link;
};

const NewErrorForm: React.FC<NewErrorFormProps> = ({
  availableProps,
  toggles,
  schema,
  setSelectedEntityId,
  selectedEntityId,
  onClose,
  entityName,
}) => {
  const {
    create: {
      mutateAsync: createErrorReport,
      isLoading: creatingErrorReportInProgress,
    },
  } = useErrorReportsMutations();
  const isMounted = useRef(false);
  const intl = useIntl();
  const { show: showSchemaManagement, setShow: setShowSchemaManagement } =
    useSchemaManagementDialog();
  const [message, setMessage] = useState('');
  const [showLink, setShowLink] = useState<
    { link: string; label: string } | undefined
  >(undefined);
  const [selectedProp, setSelectedProp] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const defaultPropValue = useMemo(
    () => ({
      id: 'default_enum_value',
      name: intl.formatMessage(messages.choose),
    }),
    [intl],
  );

  const [selectedPropValue, setSelectedPropValue] = useState<{
    id: string;
    name: string;
  }>(defaultPropValue);

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onMessageChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(e.target.value || '');
    },
    [setMessage],
  );
  const onPropChange = useCallback((p: { id: string; name: string }) => {
    setSelectedProp(p);
  }, []);
  const onPropValueChange = useCallback((v: { id: string; name: string }) => {
    setSelectedPropValue(v);
  }, []);

  const availableEnumOptions = useMemo(() => {
    if (!selectedProp) {
      return undefined;
    }
    const prop = schema.definition.propertiesLookup[selectedProp.id];
    if (!prop || prop.type !== 'enum' || !prop.alternatives) {
      return undefined;
    }
    const extras = schema.extras[prop.key];
    const { support_urls } = extras;
    if (!support_urls) {
      return undefined;
    }
    return [
      defaultPropValue,
      ...prop.alternatives
        .filter((a) => !!support_urls[a])
        .map((alternative) => ({
          id: alternative,
          name: alternative,
        })),
    ];
  }, [selectedProp, schema, defaultPropValue]);

  const onLinkOk = useCallback(() => {
    setShowLink(undefined);
    setMessage('');
    setSelectedEntityId(null);
    setSelectedProp(null);
  }, [setSelectedEntityId]);

  const onErrorConfirm = useCallback(() => {
    setMessage('');
    setShowError(false);
  }, [setShowError]);

  const onSave = useCallback(() => {
    if (!selectedEntityId || !selectedProp) {
      return;
    }
    const { support_urls } = schema.extras[selectedProp.id];
    if (!support_urls) {
      return;
    }
    let destination = support_urls.default;
    if (selectedPropValue.id !== 'default_enum_value') {
      destination = support_urls[selectedPropValue.id] || destination;
    }
    createErrorReport({
      message: message.length > 0 ? message : undefined,
      entity_id: selectedEntityId,
      destination: destination,
      property_key: selectedProp.id,
      property_value:
        selectedPropValue.id !== 'default_enum_value'
          ? selectedPropValue.id
          : undefined,
    })
      .catch((err) => {
        const status = err.status as number | undefined;
        if (!status || (isFinite(status) && status > 404)) {
          if (isMounted.current) {
            setShowError(true);
            return true;
          } else {
            throw err;
          }
        }
      })
      .then((errorDetected) => {
        if (typeof errorDetected === 'object' && isMounted.current) {
          const link = destination.startsWith('http')
            ? destination
            : createErrorMailTo(
                destination,
                selectedProp.name,
                entityName,
                message,
              );
          setShowLink({ link: link, label: destination });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [
    createErrorReport,
    entityName,
    message,
    schema.extras,
    selectedEntityId,
    selectedProp,
    selectedPropValue.id,
  ]);

  const renderSelectValue: SelectMenuProps<{
    id: string;
    name: string;
  }>['renderValue'] = useCallback(
    (o) => (o ? o.name : intl.formatMessage(messages.choose_property)),
    [intl],
  );

  if (!availableProps) {
    return (
      <>
        <Typography variant="body2" sx={{ marginTop: 3 }}>
          <FormattedMessage
            id="error_report.no_keys_available"
            defaultMessage="No contact information specified for reporting faults and errors."
          />
        </Typography>
        <Permissions has="entities:create">
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            <FormattedMessage
              id="error_report.click_to_view_contact_info_in_schema_manager"
              defaultMessage="Click below to view schema manager and add contact information for error reporting."
            />
          </Typography>
          <Button
            variant="contained"
            onClick={() => setShowSchemaManagement(!showSchemaManagement)}
          >
            <FormattedMessage
              id="error_report.go_to_schema_manager"
              defaultMessage="Go to schema manager"
            />
          </Button>
        </Permissions>
        <Permissions noneOf={['entities:create']}>
          <Typography variant="body2" sx={{ marginTop: 3, marginBottom: 1 }}>
            <FormattedMessage
              id="error_report.contact_admin_to_add_error_reporting_contact_information"
              defaultMessage="Contact admin to add error reporting contact information."
            />
          </Typography>
        </Permissions>
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Dialog open={!!showLink}>
        <DialogTitle>
          <FormattedMessage
            id="error_report.external_support"
            defaultMessage="External support"
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mt={3}>
            <FormattedMessage
              id="error_report.file_report_with_contact_external_support"
              defaultMessage="Please use the link below to file a report with external support."
            />
          </Typography>
          <a href={showLink?.link} target="_blank" rel="noreferrer">
            <Typography variant="body1">{showLink?.label}</Typography>
          </a>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onLinkOk}>
            <FormattedMessage id="ok" defaultMessage="Ok" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showError}>
        <DialogTitle>
          <FormattedMessage
            id="error_report.connection_error"
            defaultMessage="Connection error"
          />
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mt={3}>
            <FormattedMessage
              id="error_report.could_not_create_error_report"
              defaultMessage="Something went wrong, please check your connection and try again.{br}
              The external report link will only be shown after successfully creating a report in Mobilix.{br}
              If the problem persists, please contact support@allbinary.se."
              values={{ br: <br /> }}
            />
          </Typography>
          <a href={showLink?.link} target="_blank" rel="noreferrer">
            <Typography variant="body1">{showLink?.label}</Typography>
          </a>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onErrorConfirm}>
            <FormattedMessage id="ok" defaultMessage="Ok" />
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h3">
        <FormattedMessage
          id="error_report.new_error_report"
          defaultMessage="New error report"
        />
      </Typography>
      <Typography variant="body2" sx={{ marginTop: 3 }}>
        <FormattedMessage
          id="error_report.choose_a_stop"
          defaultMessage="Choose a stop"
        />
      </Typography>
      <Box>
        {toggles.map((t) => {
          return (
            <Fab
              color={t.id === selectedEntityId ? 'secondary' : 'inherit'}
              key={t.id}
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={() => setSelectedEntityId(t.id)}
            >
              {t.label}
            </Fab>
          );
        })}
      </Box>
      <Typography variant="body2" sx={{ marginTop: 3 }}>
        <FormattedMessage
          id="error_report.property"
          defaultMessage="Property"
        />
      </Typography>
      <SelectMenu
        options={availableProps}
        onChange={onPropChange}
        value={selectedProp}
        renderValue={renderSelectValue}
      />
      {availableEnumOptions && (
        <>
          <Typography variant="body2" sx={{ marginTop: 3 }}>
            <FormattedMessage
              id="error_report.value_optional"
              defaultMessage="Value (optional)"
            />
          </Typography>
          <SelectMenu
            options={availableEnumOptions}
            onChange={onPropValueChange}
            value={selectedPropValue}
            renderValue={renderSelectValue}
            disabled={!selectedEntityId}
          />
        </>
      )}
      <Box
        pt={3}
        display="flex"
        flexDirection="column"
        gap={3}
        mt={5}
        flexGrow={1}
      >
        <TextField
          label={intl.formatMessage(messages.error_description)}
          multiline
          disabled={!selectedEntityId}
          rows={4}
          placeholder={intl.formatMessage(messages.enter_error_description)}
          value={message}
          onChange={onMessageChange}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        pt={3}
        gap={3}
      >
        <Button
          disabled={creatingErrorReportInProgress}
          onClick={onClose}
          variant="outlined"
        >
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
        <LoadingButton
          loading={creatingErrorReportInProgress}
          startIcon={<Add />}
          disabled={!selectedProp || !selectedEntityId || !message}
          onClick={onSave}
          variant="contained"
          color="secondary"
        >
          <FormattedMessage
            id="error_report.create"
            defaultMessage="Create error report"
          />
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default NewErrorForm;
