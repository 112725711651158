import React, { FC } from 'react';
import { Link, Typography, Box } from '@mui/material';
import { ParagraphMultiSegment } from '../../store/guide';

interface MultiSegmentParagraphProps {
  segments: ParagraphMultiSegment;
  onLinkClick: (targetId: string) => void;
}

const MultiSegmentParagraph: FC<MultiSegmentParagraphProps> = ({
  segments,
  onLinkClick,
}) => {
  return (
    <Box mt={2}>
      {segments.map((segment) => {
        if (segment.type === 'link') {
          return (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                onLinkClick(segment.linkTo);
              }}
              key={segment.text}
              underline="always"
            >
              {segment.text}
            </Link>
          );
        }
        return (
          <Typography variant="body2" key={segment.text} component="span">
            {segment.text}
          </Typography>
        );
      })}
    </Box>
  );
};

export default MultiSegmentParagraph;
