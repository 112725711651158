import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import default_config from '../default_config';
import { Locale } from './locale';

interface StateAuth0 {
  domain: string;
  clientId: string;
  audience: string;
  organization?: string;
}

export interface ConfigState {
  loaded: boolean;
  error?: Error;
  baseUrl: string;
  attachmentUrl: string;
  auth0: StateAuth0;
  noHookApiCalls: boolean;
  locale: Locale;
  policeReportUrl: string;
  mapDefaultCenter: { lat: number; lng: number };
}

export interface PartialState extends Partial<Omit<ConfigState, 'auth0'>> {
  auth0?: Partial<StateAuth0>;
}

export function mergeState(a: ConfigState, b: PartialState): ConfigState {
  return { ...a, ...b, auth0: { ...a.auth0, ...b.auth0 } };
}

interface ConfigStore extends ConfigState {
  setConfig: (state: PartialState) => void;
}

export const useConfigStore = create<ConfigStore>()(
  devtools(
    (set) => ({
      ...default_config,
      loaded: false,
      setConfig: (config) => set((state) => mergeState(state, config)),
    }),
    { name: 'config', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useConfigStore;
