import React, { FC, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Box, IconButton, ListItem, Typography } from '@mui/material';
import { Room, DragHandle } from '@mui/icons-material';

export interface CheckInDnDItemProps {
  id: string;
  label: string;
  subText?: string;
  hoverIndex: number;
  onMove: (id: string, dragIndex: number, hoverIndex: number) => void;
}

export interface DnDItem {
  index: number;
  id: string;
  type: string;
}

const CheckInDnDItem: FC<CheckInDnDItemProps> = ({
  id,
  label,
  hoverIndex,
  subText,
  onMove,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'stop',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DnDItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Check that the mouse has crossed half of the item height in up or down direction
      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      // Time to actually perform the action
      onMove(id, dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'stop',
    item: () => {
      return { id, hoverIndex };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));
  return (
    <ListItem ref={ref} key={id} data-handler-id={handlerId}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={3}
        width="100%"
        sx={{
          opacity,
          cursor: 'move',
          // '&:hover': {
          //   backgroundColor: 'rgba(0,0,0,0.1)',
          // },
        }}
      >
        <Room color="secondary" />
        <Typography variant="subtitle1" component="span">
          {label}
        </Typography>
        <Typography variant="body1" component="span">
          {subText}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
          }}
        >
          <IconButton size="small">
            <DragHandle />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
    // <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
    //   {label}
    // </div>
  );
};

export default CheckInDnDItem;
