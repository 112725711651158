import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UserLookupState {
  users: Record<string, User>;
}

interface UserLookupStore extends UserLookupState {
  mergeUsers: (user: User[]) => void;
}

export const useUserLookupStore = create<UserLookupStore>()(
  devtools(
    (set) => ({
      users: {},
      mergeUsers: (users) =>
        set((state) => ({
          ...state,
          users: [...Object.values(state.users), ...users].reduce<
            Record<string, User>
          >((ubi, user) => {
            ubi[user.id] = user;
            return ubi;
          }, {}),
        })),
    }),
    { name: 'user-lookup' },
  ),
);
