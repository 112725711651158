import axios from 'axios';

import { PartialState, useConfigStore } from '../store/config';

interface UseConfig {
  loadConfig: () => Promise<void>;
}

export const useConfig = (): UseConfig => {
  const { setConfig } = useConfigStore();
  return {
    loadConfig: () => {
      return axios
        .get<PartialState>('/config.json')
        .then(({ data }) => {
          setConfig({ ...data, loaded: true });
        })
        .catch((e) => {
          setConfig({ error: e, loaded: true });
        });
    },
  };
};
