import { DateTime } from 'luxon';

export const tdApiTemplates: ApiWorkOrderInstruction[] = [
  {
    id: 'this-is-template-uuid1',
    texts: [''],
    title: 'Tags only',
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
      '47486810-a3ec-43e4-ab6e-772048a212db',
      '38bded42-cae4-4815-a89b-ce273b2cfb2f',
    ],
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: 'whatever',
  },
  {
    id: 'this-is-template-uuid2',
    texts: [
      'Glaskross behöver städas och glasruta bytas ut.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris odio sapien, euismod nec eros eu, feugiat euismod sem. Fusce vitae dui dolor. Ut dignissim eget lectus aliquet aliquet. Vivamus porta quam gravida porta varius. Integer luctus tincidunt rutrum. Maecenas eu volutpat libero. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed iaculis ante vel ornare volutpat.',
    ],
    title: 'No tags',
    tags: [],
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: 'whatever',
  },
  {
    id: 'this-is-template-uuid3',
    texts: [
      'Glaskross behöver städas och glasruta bytas ut.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris odio sapien, euismod nec eros eu, feugiat euismod sem. Fusce vitae dui dolor. Ut dignissim eget lectus aliquet aliquet. Vivamus porta quam gravida porta varius. Integer luctus tincidunt rutrum. Maecenas eu volutpat libero. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed iaculis ante vel ornare volutpat.',
    ],
    title: 'Tags and description',
    tags: [
      '47486810-a3ec-43e4-ab6e-772048a212db',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    ],
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: 'whatever',
  },
];
