import React, { FC, ReactNode, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useConfigStore } from '../store/config';
import { useConfig } from '../hooks/useConfig';

const ConfigProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { loaded, error } = useConfigStore();
  const { loadConfig } = useConfig();

  useEffect(() => {
    if (loaded) {
      return;
    }
    void loadConfig();
  }, [loaded, loadConfig, error]);

  if (!loaded) {
    return null;
  }

  if (error) {
    return (
      <>
        <Typography variant="h6" color="error">
          Unable to load configuration
        </Typography>
        <Typography variant="subtitle1">{error.message}</Typography>
      </>
    );
  }

  return <>{children}</>;
};

export { ConfigProvider };
