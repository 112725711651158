import { useCallback, useMemo } from 'react';
import Fuse from 'fuse.js';
import useSearchStore, { SearchResult, SearchResults } from '../store/search';
import { useWorkOrders } from './useWorkOrders';
import { useContractors } from './useContractors';
import { useFilterSets } from './useFilterSets';
import useEntityGroups from './useEntityGroups';

interface UseSearch {
  doSearch: (input: string) => void;
  results?: SearchResults;
  searchTerm: string | null;
  clearSearch: () => void;
}

const useSearch = (): UseSearch => {
  const { clearSearch, results, setResults, searchTerm, setSearchTerm } =
    useSearchStore();
  const { list: contractors } = useContractors();
  const { list: entityGroups } = useEntityGroups();
  const { list: filterSets } = useFilterSets();
  const { list: workOrders } = useWorkOrders();

  const contractorFuse = useMemo(() => {
    return new Fuse(contractors, {
      keys: ['name', 'phone'],
    });
  }, [contractors]);

  const entityGroupsFuse = useMemo(() => {
    return new Fuse(entityGroups, {
      keys: [
        ['properties', 'meta.name'],
        ['properties', 'meta.id'],
      ],
    });
  }, [entityGroups]);

  const workOrderFuse = useMemo(() => {
    return new Fuse(workOrders, {
      keys: ['title', 'id'],
    });
  }, [workOrders]);

  const filterSetFuse = useMemo(() => {
    return new Fuse(filterSets, {
      keys: ['name'],
    });
  }, [filterSets]);

  const doSearch: UseSearch['doSearch'] = useCallback(
    (input) => {
      const trimmedInput = input.toString().trim();
      if (trimmedInput.length < 1) {
        setSearchTerm('');
        return;
      }

      const contractor = contractorFuse
        .search(trimmedInput)
        .map<SearchResult>((res) => ({
          id: res.item.id,
          type: 'contractor',
          title: res.item.name,
          subtitle: res.item.phone,
        }));

      const entityGroup = entityGroupsFuse
        .search(trimmedInput)
        .map<SearchResult>((res) => ({
          id: res.item.id,
          type: 'entityGroup',
          title: res.item.properties['meta.name']?.toString() || '',
          subtitle: res.item.properties['meta.id']?.toString() || '',
        }));

      const workorder = workOrderFuse
        .search(trimmedInput)
        .map<SearchResult>((res) => ({
          id: res.item.id,
          type: 'workorder',
          title: res.item.title + '#' + res.item.title_suffix,
          subtitle: res.item.due_at?.toFormat('yyyy-LL-dd'),
        }));

      const filterSet = filterSetFuse
        .search(trimmedInput)
        .map<SearchResult>((res) => ({
          id: res.item.id,
          type: 'filterSet',
          title: res.item.name,
        }));

      const res: SearchResults = {
        contractor,
        filterSet,
        entityGroup,
        workorder,
      };
      setResults(input, res);
    },
    [
      contractorFuse,
      entityGroupsFuse,
      workOrderFuse,
      filterSetFuse,
      setResults,
      setSearchTerm,
    ],
  );

  return {
    searchTerm,
    doSearch,
    results,
    clearSearch,
  };
};

export default useSearch;
