import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
  forwardRef,
} from 'react';
import { Tooltip } from 'react-tooltip';
import { classNames } from '../../classNames';

interface Props {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  tooltip?: string;
  invalid?: string;
  className?: string;
}

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  rows?: never;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

interface InputWrapperProps extends Props {
  htmlFor: string;
  children: ReactNode;
}

function InputWrapper({
  htmlFor,
  children,
  label,
  icon,
  tooltip,
  invalid,
  endIcon,
}: InputWrapperProps): React.ReactElement {
  return (
    <div className="group relative grow">
      {label && (
        <label
          htmlFor={htmlFor}
          className="block text-xs font-medium leading-6 text-gray-medium"
        >
          {label}
        </label>
      )}
      {htmlFor ? <Tooltip id={htmlFor} delayShow={300} delayHide={1} /> : null}
      <div
        className="relative grow"
        data-tooltip-id={htmlFor}
        data-tooltip-content={tooltip}
      >
        {icon && (
          <div
            className={classNames(
              'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-workorder group-focus-within:text-workorder-dark group-hover:text-workorder',
              invalid && 'text-error',
            )}
          >
            {icon}
          </div>
        )}
        {children}
        {endIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-workorder">
            {endIcon}
          </div>
        )}
      </div>
      {invalid && <div className="mt-1 text-sm text-error">{invalid}</div>}
    </div>
  );
}
export const TextArea = forwardRef<HTMLTextAreaElement, Props & TextAreaProps>(
  (
    { id, label, icon, endIcon, tooltip, invalid, className, ...props },
    ref,
  ) => {
    const inputClassName = classNames(
      'block w-full rounded-md border-0 border-secondary-500 bg-background-50 px-2 py-1.5 ring-1 ring-inset ring-secondary-300 placeholder:text-text-700',
      'hover:ring-primary-600 focus:ring-0',
      'disabled:bg-workorder/50 disabled:text-text-black/50 disabled:ring-workorder-light',
      invalid &&
        'text-red-700 ring-1 ring-red-600 hover:ring-red-800 focus:outline-red-600',
      icon && 'pl-10',
      className,
    );

    if (id) {
      return (
        <InputWrapper
          htmlFor={id}
          label={label}
          icon={icon}
          endIcon={endIcon}
          tooltip={tooltip}
          invalid={invalid}
          className={className}
        >
          <textarea ref={ref} className={inputClassName} {...props} />
        </InputWrapper>
      );
    }

    return <textarea ref={ref} className={inputClassName} {...props} />;
  },
);

export const Input = forwardRef<HTMLInputElement, Props & InputProps>(
  (
    { id, label, icon, endIcon, tooltip, invalid, className, ...props },
    ref,
  ) => {
    const inputClassName = classNames(
      'input',
      invalid && 'text-error border-error hover:border-error',
      icon && 'pl-10',
      className,
    );

    if (id) {
      return (
        <InputWrapper
          htmlFor={id}
          label={label}
          icon={icon}
          endIcon={endIcon}
          tooltip={tooltip}
          invalid={invalid}
          className={className}
        >
          <input
            ref={ref}
            className={inputClassName}
            {...(props as InputProps)}
          />
        </InputWrapper>
      );
    }

    return (
      <input ref={ref} className={inputClassName} {...(props as InputProps)} />
    );
  },
);

export default Input;
