import React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { classNames } from '../classNames';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({ label, children }) => {
  return (
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={classNames(
            'data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade',
            'z-[9999] select-none rounded-[4px] bg-gray-medium/90 px-[15px] py-[10px] text-[15px] leading-none text-white',
            'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]',
          )}
          sideOffset={5}
        >
          {label}
          <RadixTooltip.Arrow className="fill-gray-medium/90" />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

export default Tooltip;
