import React, { useCallback, useEffect, useState } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';

import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';

import Button from '../Inputs/Button';

import SchemaManagementPropertyList from './SchemaManagementPropertyList';
import SchemaManagementPropertyEditor from './SchemaManagementPropertyEditor';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';

import {
  serializeEntitySchemaExtra,
  useEntitySchema,
  useEntitySchemaMutations,
} from '../../hooks/useEntitySchema';

import useSchemaManagementDialogStore, {
  createTree,
} from '../../store/schemaManagementDialog';
import { Help, Close } from '@mui/icons-material';
import useGuideStore from '../../store/guide';

const messages = defineMessages({
  newProperty: {
    id: 'schema_management_dialog.new_property',
    defaultMessage: 'New property',
  },
});

const dirtyScroll = (): void => {
  setTimeout(() => {
    const scrollTarget = document.getElementById('bottom-scroll-target');
    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
  }, 200);
};

const SchemaManagementDialog: React.FC = () => {
  const intl = useIntl();
  const { openGuide } = useGuideStore();
  const {
    show,
    setShow,
    hasChanges,
    setHasChanges,
    tree,
    setTree,
    setSelectedTreeNode,

    createContext,
    propsLookup,
    groupsLookup,
    extrasLookup,

    addGroup,
    removeGroup,
    addProperty,
    removeProperty,
  } = useSchemaManagementDialogStore();
  const { data: schema } = useEntitySchema();
  const {
    update: { mutateAsync: updateSchema, isLoading: isSaving },
  } = useEntitySchemaMutations();

  const colors = useTheme().palette.entity;

  const [confirmDirty, setConfirmDirty] = useState(false);
  const [localSchema, setLocalSchema] = useState<EntitySchema | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!show) {
      setLocalSchema(undefined);
      setHasChanges(false);
      return;
    }
    if (show && schema && !localSchema) {
      setLocalSchema(schema);
    }
  }, [show, schema, localSchema, setHasChanges]);

  useEffect(() => {
    if (show && localSchema) {
      setSelectedTreeNode(undefined);
      createContext(localSchema);
      const createdTree = createTree(localSchema);
      if (createdTree.length !== 0) {
        setTree(createdTree);
      }
    }
  }, [show, localSchema, setTree, setSelectedTreeNode, createContext]);

  const handleCancel = useCallback(() => {
    setShow(false);
    setTree([]);
  }, [setTree, setShow]);

  const handleClose = useCallback(() => {
    if (hasChanges) {
      setConfirmDirty(true);
    } else {
      setShow(false);
      setTree([]);
    }
  }, [hasChanges, setConfirmDirty, setTree, setShow]);

  const addNewGroupToTree = useCallback(
    (name: string) => {
      addGroup(name);
      dirtyScroll();
    },
    [addGroup],
  );

  const addNewPropertyToTree = useCallback(() => {
    addProperty(intl.formatMessage(messages.newProperty));
    dirtyScroll();
  }, [intl, addProperty]);

  const handleSave = useCallback(() => {
    if (!localSchema) {
      return;
    }
    const schema: ApiEntitySchemaRequest = {
      entity_type_id: localSchema.entity_type_id,
      definition: {
        groups: [],
        properties: [],
      },
      extras: {},
    };

    tree.forEach((node) => {
      switch (node.type) {
        case 'property': {
          const prop = propsLookup[node.id];
          const extras = extrasLookup[node.id];
          if (prop) {
            delete prop.group_id;
            schema.definition.properties.push(prop);
            if (extras) {
              schema.extras = schema.extras || {};
              schema.extras[node.id] = serializeEntitySchemaExtra(extras);
            }
          }
          break;
        }
        case 'group': {
          if (node.children.length) {
            const group_id = node.id.split('.')[1];
            const group = groupsLookup[group_id];
            if (group) {
              schema.definition.groups.push(group);
            }
            if (node.children) {
              node.children.forEach((node) => {
                const prop = propsLookup[node.id];
                const extras = extrasLookup[node.id];
                if (prop) {
                  prop.group_id = group.id;
                  schema.definition.properties.push(prop);
                  if (extras) {
                    schema.extras = schema.extras || {};
                    schema.extras[node.id] = serializeEntitySchemaExtra(extras);
                  }
                }
              });
            }
          }
          break;
        }
      }
    });

    void updateSchema({ id: localSchema.id, schema }).then(() => {
      setHasChanges(false);
      handleCancel();
    });
  }, [
    tree,
    propsLookup,
    groupsLookup,
    extrasLookup,
    localSchema,
    updateSchema,
    setHasChanges,
    handleCancel,
  ]);

  return (
    <>
      <UnsavedChangesDialog
        open={confirmDirty}
        onCancel={() => setConfirmDirty(false)}
        onOk={() => {
          setConfirmDirty(false);
          handleCancel();
        }}
        palette={colors}
      />
      <Dialog
        open={show}
        onClose={handleClose}
        maxWidth={false}
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '640px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: colors.main }}>
          <FormattedMessage
            id="schema_management_dialog.manage_schema"
            defaultMessage="Manage schema"
          />
          <div>
            <IconButton
              color="inherit"
              onClick={() => openGuide('SchemaManagementDialog')}
            >
              <Help />
            </IconButton>
            <IconButton color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <Stack direction="row" flexGrow={1}>
          <Box flex={1}>
            {tree && (
              <SchemaManagementPropertyList
                onAddGroup={addNewGroupToTree}
                onRemoveGroup={removeGroup}
                onAddProperty={addNewPropertyToTree}
                onRemoveProperty={removeProperty}
              />
            )}
          </Box>
          <Box flex={3}>
            <SchemaManagementPropertyEditor />
          </Box>
        </Stack>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} palette={colors}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={isSaving || !hasChanges}
            variant="contained"
            palette={colors}
            onClick={handleSave}
            loading={isSaving}
          >
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SchemaManagementDialog;
