import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { ArrowBack, ArrowForward, Delete, Save } from '@mui/icons-material';
import PeriodicityControl from './PeriodicityControl';
import CheckInDnDContainer from './CheckInDnDContainer';
import MapBase from '../Map';
import { Services, Statics } from '../Map/MapBase';
import { useContractors } from '../../hooks/useContractors';
import SelectMenu from '../SelectMenu/SelectMenu';
import Permissions from '../auth/Permissions';
import StopSearch from '../Inputs/StopSearch';
import mapStore from '../../store/map';
import useConfig from '../../store/config';
import { useErrorReports } from '../../hooks/useErrorReports';
import EntityListItem from '../EntityListItem/EntityListItem';
const messages = defineMessages({
  choose: {
    id: 'choose',
    defaultMessage: 'Choose...',
  },
});
interface EditCheckInPlanFormProps {
  resolvedPlan: CheckInPlanResolved;
  entities: ListAndLookup<Entity>;
  isProcessing: boolean;
  onRemovePlan?: () => void;
  onBack: () => void;
  onChange: (updatedPlan: Partial<CheckInPlan>) => void;
  onSave: (clearRoutePlan?: boolean) => void;
}

const maxWayPoints = 20;
const minimumTimeBetweenRequests = 3000;

const EditCheckInPlanForm: FC<EditCheckInPlanFormProps> = ({
  resolvedPlan,
  isProcessing,
  onRemovePlan,
  onBack,
  onChange,
  onSave,
  entities,
}) => {
  const intl = useIntl();
  const { mapDefaultCenter } = useConfig();
  const { list: contractors } = useContractors();
  const [showRoutePlanning, setShowRoutePlanning] = useState<boolean>(false);
  const { setMap, mapById } = mapStore();
  const routeRequestTimeout = useRef<{
    requestId: null | string;
    timer: null | NodeJS.Timeout;
    lastRequest: number;
    mounted: boolean;
    mapStatics: Statics | null;
  }>({
    requestId: null,
    timer: null,
    lastRequest: 0,
    mounted: true,
    mapStatics: null,
  });
  const { list: errorReports } = useErrorReports();

  const errorReportsByEntityId = useMemo(() => {
    return errorReports.reduce<Record<string, ErrorReport[]>>((acc, er) => {
      const byId = acc[er.entity_id] || [];
      byId.push(er);
      acc[er.entity_id] = byId;
      return acc;
    }, {});
  }, [errorReports]);

  useEffect(() => {
    const timeouts = routeRequestTimeout.current;
    timeouts.mounted = true;
    return () => {
      timeouts.mounted = false;
      timeouts.mapStatics?.renderer &&
        (timeouts.mapStatics.renderer as google.maps.DirectionsRenderer).setMap(
          null,
        );
    };
  }, []);

  useEffect(() => {
    if (mapById['checkInRoutePlanner']) {
      return;
    }
    setMap({
      id: 'checkInRoutePlanner',
      center: mapDefaultCenter,
      markers: {},
      zoom: 14,
    });
  }, [mapById, mapDefaultCenter, setMap]);

  const isValid = useMemo(() => {
    return Object.keys(resolvedPlan.invalid_inputs).length === 0;
  }, [resolvedPlan.invalid_inputs]);
  const onDoUpdate = useCallback(
    (key: string, val: number | string | string[] | undefined) => {
      const update = { [key]: val };
      onChange(update);
    },
    [onChange],
  );
  const onPeriodicityChange = useCallback(
    (periodicity: Periodicity) => {
      onChange({
        periodicity,
      });
    },
    [onChange],
  );

  const routePlan = useMemo(() => {
    return resolvedPlan.route_plan_entities.length > 0
      ? resolvedPlan.route_plan_entities
      : resolvedPlan.entities;
  }, [resolvedPlan.entities, resolvedPlan.route_plan_entities]);

  const contractorOptions = useMemo(() => {
    return contractors.map(({ id, name }) => ({
      id,
      name,
    }));
  }, [contractors]);

  const onRouteRequest = useCallback(
    (map: google.maps.Map, services: Services, statics: Statics) => {
      routeRequestTimeout.current.mapStatics = statics;
      if (!services.directions || routePlan.length < 2) {
        return;
      }
      const routeId = routePlan.map((e) => e.id).join('_');
      if (statics.currentRouteId === routeId) {
        return;
      }
      statics.currentRouteId = routeId;

      if (!statics.renderer) {
        const newRenderer = new google.maps.DirectionsRenderer();
        newRenderer.setMap(map);
        statics.renderer = newRenderer;
      }
      const renderer = statics.renderer as google.maps.DirectionsRenderer;
      //Create route request.
      const routeCoordsClone = [...resolvedPlan.route_plan_coords];
      const first = routeCoordsClone.shift();
      const last = routeCoordsClone.pop();
      if (!first || !last) {
        return;
      }
      const routeRequest: google.maps.DirectionsRequest = {
        origin: first,
        destination: last,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      if (routeCoordsClone.length > 0) {
        routeRequest.waypoints = routeCoordsClone
          .slice(0, maxWayPoints - 1)
          .map((point) => ({
            stopover: true,
            location: new google.maps.LatLng(point.lat, point.lng),
          }));
      }
      const timeouts = routeRequestTimeout.current;
      const request = (): void => {
        if (!timeouts.mounted) {
          return;
        }
        timeouts.lastRequest = Date.now().valueOf();
        services.directions.route(routeRequest, (results) => {
          if (!timeouts.mounted) {
            return;
          }
          renderer.setDirections(results);
        });
      };

      const timeSinceLastRequest = Date.now().valueOf() - timeouts.lastRequest;
      if (timeSinceLastRequest < minimumTimeBetweenRequests) {
        //Too early, we need to wait to do the request.
        if (timeouts.timer) {
          clearTimeout(timeouts.timer);
          timeouts.timer = null;
        }
        timeouts.timer = setTimeout(
          request,
          minimumTimeBetweenRequests - timeSinceLastRequest,
        );
      } else {
        //Free to do a request.
        request();
        timeouts.lastRequest = Date.now().valueOf();
        //If there is a timer clean it up.
        if (timeouts.timer) {
          clearTimeout(timeouts.timer);
          timeouts.timer = null;
        }
      }
    },
    [resolvedPlan, routePlan],
  );

  const onRemoveEntity = useCallback(
    (entityIndex: number, entityId: string) => {
      const entityIdsClone = [...resolvedPlan.entity_ids];
      entityIdsClone.splice(entityIndex, 1);
      const routeClone = resolvedPlan.route_plan
        ? [...resolvedPlan.route_plan]
        : [];

      const routeIndex = routeClone.findIndex(
        (routedEntityId) => routedEntityId === entityId,
      );
      if (routeIndex > -1) {
        routeClone.splice(routeIndex, 1);
      }

      onChange({
        entities: entityIdsClone,
        route_plan: routeClone,
      });
    },
    [onChange, resolvedPlan.entity_ids, resolvedPlan.route_plan],
  );

  const onReorderRoutePlan = useCallback(
    (reorderedPlan: Entity[]) => {
      onChange({ route_plan: reorderedPlan.map((e) => e.id) });
    },
    [onChange],
  );

  const onAddEntity = useCallback(
    (entityId: string) => {
      onChange({
        entities: [...resolvedPlan.entity_ids, entityId],
        route_plan:
          resolvedPlan.route_plan && resolvedPlan.route_plan.length > 0
            ? [...resolvedPlan.route_plan, entityId]
            : [...resolvedPlan.entity_ids, entityId],
      });
    },
    [onChange, resolvedPlan.entity_ids, resolvedPlan.route_plan],
  );

  const onStopSearchChange = useCallback(
    (ids: string[]) => {
      ids.forEach(
        (id) => !resolvedPlan.entity_ids.includes(id) && onAddEntity(id),
      );
    },
    [onAddEntity, resolvedPlan.entity_ids],
  );

  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="row" gap={12} pt={2}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              gap: 2,
              position: showRoutePlanning ? 'initial' : 'absolute',
              visibility: showRoutePlanning ? 'initial' : 'hidden',
              height: '500px',
            }}
          >
            <Box flexGrow={1} flexBasis={0}>
              <Typography
                variant="overline"
                component="p"
                sx={{ marginLeft: 5 }}
              >
                <FormattedMessage
                  id="check_in_plan_management.stops"
                  defaultMessage="Stops"
                />
              </Typography>
              <CheckInDnDContainer
                entities={routePlan}
                onReorder={onReorderRoutePlan}
              />
            </Box>
            <Box
              flexGrow={1}
              flexBasis={0}
              sx={{ minHeight: '500px', height: '100%' }}
            >
              <MapBase
                mapId="checkInRoutePlanner"
                paused={!showRoutePlanning}
                onMarkerUpdate={(map, services, markers, statics) => {
                  onRouteRequest(map, services, statics);
                }}
              />
            </Box>
          </Box>
          {!showRoutePlanning && (
            <>
              <Box flexGrow={1} flexBasis={0} sx={{ height: '500px' }}>
                <Typography variant="overline" component="p">
                  <FormattedMessage
                    id="check_in_plan_management.title_input_label"
                    defaultMessage="Title"
                  />
                </Typography>
                <TextField
                  value={resolvedPlan.title || ''}
                  type="text"
                  size="small"
                  onChange={(ev) => onDoUpdate('title', ev.target.value)}
                  sx={{ marginBottom: 2 }}
                />
                <Permissions has="role:admin">
                  <Typography variant="overline" component="p">
                    <FormattedMessage
                      id="check_in_plan_management.contractor_input_label"
                      defaultMessage="Contractor"
                    />
                  </Typography>
                  <SelectMenu
                    options={contractorOptions}
                    value={resolvedPlan.contractor_id}
                    sx={{ width: '100%', marginBottom: 2 }}
                    renderValue={(value) => {
                      if (!value) {
                        return intl.formatMessage(messages.choose);
                      }
                      return value.name
                        ? value.name
                        : intl.formatMessage(messages.choose);
                    }}
                    onChange={(v) => {
                      if (v.id !== resolvedPlan.contractor_id) {
                        onDoUpdate('contractor_id', v.id);
                      }
                    }}
                  />
                </Permissions>
                <Typography variant="overline" component="p">
                  <FormattedMessage
                    id="check_in_plan_management.duration_input_label"
                    defaultMessage="Duration"
                  />
                </Typography>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage
                      id="check_in_plan_management.duration_input_tooltip"
                      defaultMessage="The number of days before occurrence the check-in will be visible in the Mobilix mobile app."
                    />
                  }
                >
                  <TextField
                    value={resolvedPlan.duration || ''}
                    type="number"
                    size="small"
                    onChange={(ev) => {
                      const v = ev.target.value
                        ? parseInt(ev.target.value, 10)
                        : undefined;
                      if (!v || !isFinite(v) || v < 0) {
                        onDoUpdate('duration', undefined);
                        return;
                      }
                      onDoUpdate('duration', v);
                    }}
                    sx={{ marginBottom: 2 }}
                  />
                </Tooltip>
                <PeriodicityControl
                  onChange={onPeriodicityChange}
                  periodicity={resolvedPlan.periodicity}
                />
              </Box>
              <Box flexGrow={1} flexBasis={0}>
                <Typography
                  variant="overline"
                  component="p"
                  sx={{ marginLeft: 5 }}
                >
                  <FormattedMessage
                    id="check_in_plan_management.stops"
                    defaultMessage="Stops"
                  />
                </Typography>
                <StopSearch
                  value={resolvedPlan.entity_ids}
                  onChange={onStopSearchChange}
                  entities={entities.list}
                  entitiesLookup={entities.lookup}
                />
                <Box>
                  {resolvedPlan.invalid_inputs.entities && (
                    <Typography>
                      {resolvedPlan.invalid_inputs.entities}
                    </Typography>
                  )}
                  {resolvedPlan.entities.map((e, i) => {
                    return (
                      <EntityListItem
                        key={e.id}
                        entity={e}
                        errorReports={errorReportsByEntityId[e.id]}
                        onRemoveClick={() => onRemoveEntity(i, e.id)}
                      />
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          gap={2}
          flexDirection="row"
          width="100%"
          alignItems="center"
        >
          {showRoutePlanning ? (
            <Button
              startIcon={<ArrowBack />}
              onClick={() => setShowRoutePlanning(false)}
            >
              <FormattedMessage
                id="check_in_plan_management.to_plan_details"
                defaultMessage="Back to check-in plan details"
              />
            </Button>
          ) : (
            <Button
              startIcon={<ArrowBack />}
              disabled={isProcessing}
              onClick={onBack}
            >
              <FormattedMessage
                id="check_in_plan_management.back_button_label"
                defaultMessage="Back to existing plans"
              />
            </Button>
          )}
          {onRemovePlan ? (
            <Box flexGrow={1} display="flex" justifyContent="center">
              <Tooltip
                arrow
                placement="top"
                title={
                  <FormattedMessage
                    id="check_in_plan_management.remove_tooltip"
                    defaultMessage="Deletes the selected check-in plan."
                  />
                }
              >
                <LoadingButton
                  variant="outlined"
                  color="error"
                  loading={isProcessing}
                  onClick={onRemovePlan}
                  endIcon={<Delete />}
                >
                  <FormattedMessage id="remove" defaultMessage="Remove" />
                </LoadingButton>
              </Tooltip>
            </Box>
          ) : (
            <Box flexGrow={1} />
          )}
          {!showRoutePlanning && (
            <Tooltip
              arrow
              placement="top"
              title={
                isValid ? (
                  <FormattedMessage
                    id="check_in_plan_management.skip_route_planning_tooltip"
                    defaultMessage="Save without route planning. Route planning can be updated later."
                  />
                ) : (
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <FormattedMessage
                      id="check_in_plan_management.skip_route_planning_disabled_tooltip"
                      defaultMessage="Save without route planning. Route planning can be updated later. Disabled because some details are missing."
                    />
                    {resolvedPlan.invalid_inputs.duration &&
                      `\n- ` + resolvedPlan.invalid_inputs.duration}
                    {resolvedPlan.invalid_inputs.entities &&
                      `\n- ` + resolvedPlan.invalid_inputs.entities}
                    {resolvedPlan.invalid_inputs.contractor &&
                      `\n- ` + resolvedPlan.invalid_inputs.contractor}
                    {resolvedPlan.invalid_inputs.periodicity &&
                      `\n- ` + resolvedPlan.invalid_inputs.periodicity}
                    {resolvedPlan.invalid_inputs.title &&
                      `\n- ` + resolvedPlan.invalid_inputs.title}
                  </div>
                )
              }
            >
              <div>
                <LoadingButton
                  loading={isProcessing}
                  disabled={!isValid}
                  onClick={() => onSave(true)}
                >
                  <FormattedMessage
                    id="check_in_plan_management.skip_route_planning"
                    defaultMessage="Skip route planning"
                  />
                </LoadingButton>
              </div>
            </Tooltip>
          )}
          {!showRoutePlanning && (
            <Tooltip
              arrow
              placement="top"
              title={
                <FormattedMessage
                  id="check_in_plan_management.to_route_planning_tooltip"
                  defaultMessage="Go to route planning"
                />
              }
            >
              <LoadingButton
                variant="contained"
                loading={isProcessing}
                onClick={() => setShowRoutePlanning(true)}
                endIcon={<ArrowForward />}
              >
                <FormattedMessage
                  id="check_in_plan_management.to_route_planning"
                  defaultMessage="To route planning"
                />
              </LoadingButton>
            </Tooltip>
          )}

          {showRoutePlanning && onRemovePlan && (
            <Tooltip
              arrow
              placement="top"
              title={
                isValid ? (
                  <FormattedMessage
                    id="check_in_plan_management.save_tooltip"
                    defaultMessage="Save check-in plan"
                  />
                ) : (
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <FormattedMessage
                      id="check_in_plan_management.save_disabled_tooltip"
                      defaultMessage="Save check-in plan. Disabled because some details are missing or incorrect."
                    />
                    {resolvedPlan.invalid_inputs.duration &&
                      `\n- ` + resolvedPlan.invalid_inputs.duration}
                    {resolvedPlan.invalid_inputs.entities &&
                      `\n- ` + resolvedPlan.invalid_inputs.entities}
                    {resolvedPlan.invalid_inputs.periodicity &&
                      `\n- ` + resolvedPlan.invalid_inputs.periodicity}
                    {resolvedPlan.invalid_inputs.title &&
                      `\n- ` + resolvedPlan.invalid_inputs.title}
                  </div>
                )
              }
            >
              <div>
                <LoadingButton
                  variant="contained"
                  loading={isProcessing}
                  disabled={!isValid}
                  onClick={() => onSave()}
                  startIcon={<Save />}
                >
                  <FormattedMessage
                    id="check_in_plan_management.save_check_in_plan"
                    defaultMessage="Save check-in plan"
                  />
                </LoadingButton>
              </div>
            </Tooltip>
          )}
          {showRoutePlanning && !onRemovePlan && (
            <Tooltip
              arrow
              placement="top"
              title={
                isValid ? (
                  <FormattedMessage
                    id="check_in_plan_management.create_tooltip"
                    defaultMessage="Create new check-in plan"
                  />
                ) : (
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <FormattedMessage
                      id="check_in_plan_management.create_disabled_tooltip"
                      defaultMessage="Create check-in plan. Disabled because some details are missing or incorrect:"
                    />
                    {resolvedPlan.invalid_inputs.duration &&
                      `\n- ` + resolvedPlan.invalid_inputs.duration}
                    {resolvedPlan.invalid_inputs.entities &&
                      `\n- ` + resolvedPlan.invalid_inputs.entities}
                    {resolvedPlan.invalid_inputs.periodicity &&
                      `\n- ` + resolvedPlan.invalid_inputs.periodicity}
                    {resolvedPlan.invalid_inputs.title &&
                      `\n- ` + resolvedPlan.invalid_inputs.title}
                  </div>
                )
              }
            >
              <div>
                <LoadingButton
                  variant="contained"
                  loading={isProcessing}
                  disabled={!isValid}
                  onClick={() => onSave()}
                  startIcon={<Save />}
                >
                  <FormattedMessage
                    id="check_in_plan_management.create_check_in_plan"
                    defaultMessage="Create new check-in plan"
                  />
                </LoadingButton>
              </div>
            </Tooltip>
          )}
        </Box>
      </DialogActions>
    </>
  );
};

export default EditCheckInPlanForm;
