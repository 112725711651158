import React, { useMemo } from 'react';
import { getPeriodStatus, PeriodicityPeriods } from '@allbin/mobilix-utils';
import { getPeriodStatusColorIcon } from '../../helpers/periodicity_helpers';
import { Grid } from '@mui/material';
import EntityListItem from '../EntityListItem/EntityListItem';

interface EntityCheckInRowProps {
  entity: Entity;
  periods: PeriodicityPeriods;
  even?: boolean;
}

const EntityCheckInRow: React.FC<EntityCheckInRowProps> = ({
  entity,
  periods: { periods, activePeriodIndex },
  even,
}) => {
  const className = useMemo(() => (even ? 'even' : 'odd'), [even]);

  const previousStatus = useMemo(
    () =>
      activePeriodIndex === 0
        ? null
        : getPeriodStatus(
            periods[activePeriodIndex - 1],
            entity.checkins.contractor,
          ),
    [periods, activePeriodIndex, entity.checkins.contractor],
  );

  const previousStatusColor = useMemo(() => {
    if (!previousStatus) return null;

    // console.log('previousStatus', previousStatus);
    return getPeriodStatusColorIcon(previousStatus);
  }, [previousStatus]);

  const activeStatus = useMemo(
    () =>
      getPeriodStatus(periods[activePeriodIndex], entity.checkins.contractor),
    [periods, activePeriodIndex, entity.checkins.contractor],
  );

  const activeStatusColor = useMemo(() => {
    // console.log('activeStatus', activeStatus);
    return getPeriodStatusColorIcon(activeStatus);
  }, [activeStatus]);

  if (!entity) {
    return null;
  }

  return (
    <>
      <Grid item xs={6} className={className}>
        <EntityListItem entity={entity} />
      </Grid>
      <Grid item xs={3} className={className}>
        {previousStatusColor}
      </Grid>
      <Grid item xs={3} className={className}>
        {activeStatusColor}
      </Grid>
    </>
  );
};

export default EntityCheckInRow;
