import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface OrganizationManagementDialogStore {
  show: boolean;
  setShow: (show: boolean) => void;
}

const useOrganizationManagementDialogStore =
  create<OrganizationManagementDialogStore>()(
    devtools(
      (set) => ({
        show: false,
        setShow: (show) => set({ show }),
      }),
      {
        name: 'organization-management-dialog-store',
        enabled: process.env.NODE_ENV !== 'test',
      },
    ),
  );

export default useOrganizationManagementDialogStore;
