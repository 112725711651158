import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useApiClient } from './useApiClient';
import { reviveMetadata } from '../helpers/revivers';
import useUserStore from '../store/user';

export const defaultProfile: UserProfile = {
  projection: 'EPSG:3021',
  language: 'sv-SE',
  meta: {} as Metadata,
  visible_columns: [],
};

interface UseProfile {
  profile: UseQueryResult<UserProfile, Error>;
  setProfile: UseMutationResult<UserProfile, Error, UserProfileRequest>;
}

const reviveUserProfile = (apiProfile: ApiUserProfile<any>): UserProfile => {
  const customProps = (apiProfile as ApiUserProfile<UserProfileProps>).profile;
  return {
    ...customProps,
    projection: customProps.projection || defaultProfile.projection,
    language: customProps.language || defaultProfile.language,
    visible_columns:
      customProps.visible_columns || defaultProfile.visible_columns,
    meta: reviveMetadata(apiProfile.meta),
  };
};

export const useUserProfiles = (): UseProfile => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  const { user } = useUserStore();

  const profile = useQuery<UserProfile, Error>({
    queryKey: ['userProfiles', 'profile'],
    queryFn: () => client.userProfiles.get().then(reviveUserProfile),
    enabled: !!user.name,
  });

  const setProfile = useMutation<UserProfile, Error, UserProfileRequest>(
    ['userProfiles', 'set'],
    (profile) => client.userProfiles.update(profile).then(reviveUserProfile),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['userProfiles', 'profile']),
    },
  );

  return { profile, setProfile };
};
