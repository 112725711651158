import { Chapter, Subject } from './store/guide';

export const example: RawSubject[] = [
  {
    id: 'EntityGroupOverview',
    title: 'Hållplatsöversikt',
    chapters: [
      {
        id: 'LeftPanel-RecentlyViewed',
        heading: 'Senast besökta listan i vänster panelen',
        text: `
          My text in paragraph 1
          Paragraph 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris odio sapien, euismod nec eros eu, feugiat euismod sem. Fusce vitae dui dolor. Ut dignissim eget lectus aliquet aliquet. Vivamus porta quam gravida porta varius. Integer luctus tincidunt rutrum. Maecenas eu volutpat libero. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed iaculis ante vel ornare volutpat.
          Paragraph 3
        `,
      },
      {
        id: 'song',
        heading: 'Awesome song',
        text: `
          Läs mer om <a#subject2>In your arms</a>, text efter länk
          - Tachno
          - Arctic Empire
          - ILLENIUM & X Ambassadors
        `,
      },
    ],
  },
  {
    id: 'subject2', //This id is what you put in <a#[id]>link text</a> to make links!
    title: 'In your arms',
    chapters: [
      {
        id: 'monalisa',
        heading: 'Mona Lisa',
        text: `
          Never seen Mona Lisa up close,
          but I've seen perfection ...
        `,
      },
      {
        id: 'chorus',
        heading: 'Chorus',
        text: `
          If I died in your arms tonight,
          its right where I'm supposed to be.
          You know I'm not afraid
          as long as you're next to me.
        `,
      },
    ],
  },
];
// Titlar som ska göras //
// Hållplatsöversikt Kartvy // klar
// Hållplatsöversikt Tabellvy // lägga in länkar + export-format
// Hållplatsdetaljer // // Länkar och tilläggsinfo //
// Ärendehantering //Filterfunktion ärendehantering kanske behöver ändras//
// Arbetsorder // Lägga till funktion för arbetsinstruktioner //
// Skapa/redigera Arbetsorder // Skapa Länkar
// Sökfunktion // skriven men kanske behöver justeras
// Hantera entreprenörer // klar
// Linjeöversikt // Ska inte va med nu//
// Ruttplanering // Ska inte va med nu//
// Filter // KLAR //
// Felanmälan // KLAR //
// Polisanmälan // KLAR //
// Taggar // Ska göras eget avsnitt//
// Hantera arbetsordermallar //
// Inventeringsvärdena //
// Konflikthantering //
// Fälthantering //
// Hantera organisation //

const texts: RawSubject[] = [
  {
    id: 'stopOverviewMap',
    title: 'Lägesöversikt Kartvy', //KLAR//
    chapters: [
      {
        id: 'stationOverViewMap',
        heading: 'Hållplatsöversikt Kartvy',
        text: `
        I hållplatsöversikten kan man se de hållplatser som tillhör organisationen. De presenteras antingen som kartvy eller via en tabell. 
        Ovanför kartan eller tabellen finns en rad där man kan lägga till och hantera filter och filterset. Läs mer om Filter under fliken <a#filter>Filter</a>. 
        I raden under väljer man mellan kart- och tabellvyn samt knappar för att exportera tabellen, öppna <a#TableColumnManagement>Kolumnhanteraren</a> samt skapa en arbetsorder för de hållplatslägen som har filtrerats ut.
        `,
      },
      /*
      {
        id: 'leftPanelRecentlyViewed',
        heading: 'Senast besökta',
        text: `
        En lista av de hållplatser du senast besökt visas i vänsterpanelen. Klicka på hållplatsnamnet för att öppna detaljerna för vald hållplats.
        Klicka på hållplatsnamnet för att öppna detaljerna för vald hållplats.
        `,
      },
      {
        id: 'topBarActiveFilter',
        heading: 'Filter',
        text: `
        I överkant visas vilka filter som är aktivt för kartvyn och tabellen. Färgen indikerar vilket filter som är aktivt för hållplatserna som visas.
        Klicka på reglage symbolen uppe i höger hörna för att öppna filterinställningarna.
        Läs mer om Filter under fliken <a#filter>Filter</a>,
        `,
      },
      */
      {
        id: 'topBarMapView',
        heading: 'Kartvy',
        text: `
        I under fliken ”Karta” visas alla hållplatser som trafikeras av organisationen, enligt de filterset som valts. Hovra med markören över en hållplats så dyker dess namn upp. 
        Klicka på hållplatsmarkören för att öppna detaljerna för vald hållplats.
        `,
      },
      {
        id: 'topBarTableView',
        heading: 'Tabellvy',
        text: `
        I tabellvyn visas de hållplatser som trafikeras av organisationen, enligt de filterset som valts. I tabellen visas attributen för alla enskilda hållplatslägen. 
        Vill man begränsa vad man ser kan man använda filterset för att få färre hållplatslägen, samt kolumnhanteraren för att begränsa antalet attribut som visas.
        `,
      },
    ],
  },
  /*
  {
    id: 'stopOverviewTable',
    title: 'Lägesöversikt Tabellvy', //KLAR//
    chapters: [
      {
        id: 'stationOverViewTable',
        heading: 'Hållplatsöversikt Tabellvy',
        text: `
        Hovra med markören över vald hållplats så dyker information upp om den hållplatsen.
        Hovra med markören över valfritt “i” för att hitta information om den data som visas. (Tex. Rebus Data 2021-03-20)
        Klicka på hållplatsnamnet för att öppna detaljerna för vald hållplats.
        `,
      },
      {
        id: 'leftPanelRecentlyViewed',
        heading: 'Senast besökta',
        text: `
        En lista av de hållplatser du senast besökt visas i vänsterpanelen. Klicka på hållplatsnamnet för att öppna detaljerna för vald hållplats.
        Klicka på hållplatsnamnet för att öppna detaljerna för vald hållplats.
        `,
      },
      {
        id: 'topBarActiveFilter',
        heading: 'Filter',
        text: `
        I överkant visas vilka filter som är aktivt för kartvyn och tabellen. Färgen indikerar vilket filter som är aktivt för hållplatserna som visas.
        Klicka på reglage symbolen uppe i höger hörna för att öppna filterinställningarna.
        Läs mer om Filter under fliken <a#filterSet>Filter</a>
        `,
      },
      {
        id: 'topBarMapView',
        heading: 'Kartvy',
        text: `
        Klicka på Karta i högra hörnet för att byta vy till <a#stopOverviewMap>Kartvy</a>.
        `,
      },
      {
        id: 'topBarTableView',
        heading: 'Tabellvy',
        text: `
        Klicka på Tabell i högra hörnet för att byta vy till Tabell.
        `,
      },
      {
        id: 'bottomBarTabelSettings',
        heading: 'Tabell inställningar',
        text: `
        Klicka på Kugghjulssymbolen längst ner till vänster för att få en tabelllista för att klicka i vilka kolumner som ska synas i tabellen. "ID, Klass, Namn osv".
        Läs mer om Tabellinställningar under fliken <a#TableSettings>Tabell inställningar</a>.
        `,
      },
      {
        id: `bottomBarExportTable`, // kommer förändra hela bottomBar//
        heading: `Exportera Tabell`,
        text: `
        Klicka på pappers symbolen för att Exportera tabellen i csv eller xls (excel) format.
        `,
      },
    ],
  },
  */
  {
    id: 'stationDetails',
    title: 'Hållplatsdetaljer', //KLAR// karta kommer senare//
    chapters: [
      {
        id: 'stationDetailsDescription',
        heading: 'Hållplatsdetaljer förklaring',
        text: `
        I denna vyn finns information och funktioner för enskilda hållplatser.
        `,
      },
      {
        id: 'directionIdications',
        heading: 'Lägesindikator',
        text: `
        Uppe i vänster hörn, vid hållplatsnamnet ovanför bilderna, visas vilka lägen som är tillgängliga för vald hållplats.
        Genom att klicka på (A-Ö) visas information dessa lägen i tabellen nedan för.
        `,
      },
      {
        id: 'workOrderInformationTab',
        heading: 'Arbetsordrar',
        text: `
        Till höger om tabellen i hållplatsdetaljer visas arbetsordrar för vad hållplats.
        Genom att klicka på en av dessa öppnas detaljerna om den valda arbetsordern.
        `,
      },
      /*
      {
        id: 'picturecarusell',
        heading: 'Bildkarusell',
        text: `
        Mellan lägesindikatorn och tabellerna hittas en karusell där bilder visas. Här hamnar biler som är relaterade till den specifika hållplatsen och hållplatsens lägen.
        Genom att klicka på bildsymbolen som finns ovanför bilderna till höger, ges möjligheten att ladda upp bilder.
        Godkännda bildformat är .jepg och .png.
        `,
      },
      {
        id: 'reportIndicatorNoAction',
        heading: 'Felanmälan inte åtgärdad',
        text: `
        Om det visas en "röd flagg" symbol ovanför bilderna finns det en felanmälan som inte är åtgärdad.
        Klicka på symbolen för att öppna de aktuella felanmälningar som inte är åtgärdade.
        Klicka på "Åtgärdad" i rutan "Felanmälan" för att markera att felet är åtgärdat.
        Läs mer om Felanmälan under fliken <a#issueReports>Felanmälan</a>.
        `,
      },
      */
      {
        id: 'reportIssue',
        heading: 'Felanmälan',
        text: `
        Efter det finns knappen "Felanmälan". Genom att klicka på denna öppnar ni en ruta för att skapa en felanmälan för vald hållplats. 
        Om det visas en "röd flagg" symbol ovanför bilderna finns det en felanmälan som inte är åtgärdad. Läs mer om Felanmälan under fliken <a#issueReports>Felanmälan</a>. (Länk)
        `,
      },
      {
        id: 'policeReport',
        heading: 'Polisanmälan',
        text: `
        Ovanför bilderna i hållplatsdetaljer till höger finns knappen "Polisanmälan". Genom att klicka på den öppnas en dialog där ni skapar ni en polisanmälan.
        Läs mer om Polisanmälan under fliken <a#policeReport>Polisanmälan</a>
        `,
      },
      {
        id: 'commentStation',
        heading: 'Kommentera Läge',
        text: `
        Längds upp till höger finns en symbol för att kommenterar läget/lägena.
        `,
      },
      {
        id: 'addPicture',
        heading: 'Lägg till bild',
        text: `
        Till höger om kommenterasymbolen finns en knapp för att lägga till en bild. Genom att klicka på den öppnas en ruta där ni kan bifoga bilder till vald hållplats. 
        Markera upp vilket läge det gäller samt om det är in-bild, ut-bild, plats-bild eller extra-bild.
        Format som stöds är JPEG, PNG, WebP, AVIF, TIFF, GIF och SVG bilder.
        `,
      },
      {
        id: 'stationDetailsTableView',
        heading: 'Fliken Hållplatslägen',
        text: `
        Under denna fliken visas alla attribut gälland de hållplatslägen man valt längds upp till vänster i vyn.
        `,
      },
      /*
      {
        id: 'stationDetailsGraphView',
        heading: 'Grafvy Hållplatsdetaljer', //kommer inte va med nu//
        text: `
        Ovanför tabellen finns möjligheten att visa informationen på olika sätt.
        Genom att klicka på Graf visas hållplatsdetaljerna i grafer.
        `,
      },
      */
      {
        id: 'stationDetailsHistoryview',
        heading: 'Fliken Händelser',
        text: `
        Händelser är den flik som visar all aktivitet på hållplatsen.
        I listan till vänster visas alla händelser i kronologisk ordning. Listan kan filtreras genom att klicka av eller på knapparna längds upp till höger i listan. 
        Man kan filtrera på ”Statusändringar”, ”Kommentarer”, ”Inventarieändringar”, ”Felrapporter” samt ”Polisanmälningar”.
        Genom att klicka på en av dessa visas information om vald händelse i mitten av skärmen.
        `,
      },
      {
        id: 'createWorkOrderStationDetails',
        heading: 'Skapa arbetsorder',
        text: `
        Klicka på denna knappen för att skapa en arbetsorder gällande den aktuella hållplatsen.
        `,
      },
      {
        id: 'editStationDetails',
        heading: 'Redigera hållplatsattribut',
        text: `
        Pennsymbolen öppnar en dialog där man kan redigera attributen för de valda <a#EditEntityPropertiesDialog>hållplatslägen</a>.
        `,
      },
      {
        id: 'mapStationDetails',
        heading: 'Karta',
        text: `
        Längds ut till vänster visas hållplatsens olika lägen på en karta. 
        `,
      },
    ],
  },
  {
    id: 'workOrderOverview',
    title: 'Ärendehantering', //KLAR//
    chapters: [
      {
        id: 'workOrderOverviewOverview',
        heading: 'Arbetsorderöversikt',
        text: `
        I arbetsorderöversikten har du möjlighet att se alla aktiva arbetsordrar i en tabell.
        Ovanför tabellen finns flikarna "Skapade", ”Blockerade”, "Accepterade", ”Färdigställda, "Avvisade”, ”Godkända” och ”Avbrutna”. 
        Detta är olika filter för att enklare hitta den/de arbetsordrar du söker genom att enbart visa de arbetsordrar som har den status som fliken anger.
        I samma rad till höger finns även en filterfunktion där ni kan filtrera på aktiva ärenden för en/flera entreprenörer samt filtrera på taggar.
        `,
      },
      /*
      {
        id: 'workOrderOverviewMapView',
        heading: 'Ärendehantering Kartvy',
        text: `
        I kartvyn visas alla arbetsordrar enligt valt filter, "Skapade", "Uppdaterade", "Accepterade", "Inskickade", "Avslagna", "Utkast" och "Stängda".
        Med kartvyn visas alla hållplatser på en karta med en pin i olika färger beroende på om det finns en arbetsorder för den hållplatsen.
        Blå = Ingen arbetsorder.
        Gul = Aktiv Arbetsorder.
        Röd = Aktiv arbetsorder som har gått över tiden.
        `,
      },
      */
      {
        id: 'workOrderOverviewTableView',
        heading: 'Tabellvy',
        text: `
        I tabellvyn visas alla arbetsordrar enligt valt filter. Under varje flik dyker då en tabell upp med arbetsordrar med den valda statusen.
        Klickar man på ena arbetsorder i tabellen tas man till den arbetsordern.
        `,
      },
      /*
      {
        id: 'workOrderOverviewFilterFunction',
        heading: 'Filterfunktion ärendehantering',
        text: `
        Uppe till höger finns en rullgardinsmeny där man kan filtrera på entreprenörer, filtrera på taggar associerade med arbetsordrarna samt välja om man enbart vill visa återkommande arbetsordrar.
        `,
      },
      {
        id: 'workOrderOverviewCreated',
        heading: 'Ärendehantering skapade',
        text: `
        Genom att markera fliken "Skapade" visas endast skapade ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewUpdated',
        heading: 'Ärendehantering Uppdaterade',
        text: `
        Genom att markera fliken "Uppdaterade" visas endast uppdaterade ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewAccepted',
        heading: 'Ärendehantering Accepterade',
        text: `
        Genom att markera fliken "Accepterade" visas endast accepterade ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewSent',
        heading: 'Ärendehantering Inskickade',
        text: `
        Genom att markera fliken "Inskickade" visas endast inskickade ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewRejected',
        heading: 'Ärendehantering Avslagna',
        text: `
        Genom att markera fliken "Avslagna" visas endast avslagna ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewDrafts',
        heading: 'Ärendehantering Utkast',
        text: `
        Genom att markera fliken "Utkast" visas endast utkast ärenden i vald vy, karta eller tabell.
        `,
      },
      {
        id: 'workOrderOverviewClosed',
        heading: 'Ärendehantering Stängda',
        text: `
        Genom att markera fliken "Stängda" visas endast stängda ärenden i vald vy, karta eller tabell.
        `,
      },
      */
      {
        id: 'workOrderOverviewReoccuring',
        heading: 'Återkommande arbetsordrar',
        text: `
        I tabellen kan det förekomma en symbol med en pil i en loop. Denna symbol betyder att det är ett återkommande ärende. Detta ärendet avslutas inte utan visar att det är ett ihållande projekt (tex underhåll för en hållplats).
        `,
      },
    ],
  },
  {
    id: 'workOrder',
    title: 'Arbetsorder', //KLAR//
    chapters: [
      {
        id: 'workOrders',
        heading: 'Arbetsorder',
        text: `
        I den översta raden till vänster finns generell information om den valda Arbetsordern.
        Här finns "Entreprenör", "Datum när arbetsordern skapades" och "Datum när arbetsordern stängdes".
        Till höger i den översta raden ges möjligheten att ändra status på arbetsordern genom att klicka på pilen i rutan "Status".
        Till höger om statusknappen finns möjligheten att skriva en kommentar till arbetsordern, redigera den samt ta bort den.
        Uppe till vänster under den generella informationen finns tre flikar; Händelse, Arbetsorder och Inskickade ändringar. Klicka på den flik du vill visa.
        Längds till vänster finns en karta som visar de hållplatslägen som arbetsordern gäller. Under kartan visas arbetsbeskrivningen samt valda taggar.        
        `,
      },
      {
        id: 'workOrderFilter',
        heading: 'Fliken Händelser',
        text: `
        Händelser är den flik som visar all aktivitet på arbetsordern.
        I listan till vänster visas alla händelser i kronologisk ordning. Listan kan filtreras genom att klicka av eller på knapparna längds upp till höger i listan. 
        Man kan filtrera på ”Statusändringar”, ”Kommentarer”, ”Inventarieändringar”, ”Felrapporter” samt ”Polisanmälningar”.
        Genom att klicka på en av dessa visas information om vald händelse i mitten av skärmen.
        `,
      },
      {
        id: 'workOrderTabFilter',
        heading: 'Fliken Arbetsorder',
        text: `
        Detaljerna för vald arbetsorder öppnas på skärmen och visar "Entreprenör", ”Utförare”, Arbetsinstruktioner", "Slutdatum", "Hållplatser" och "Taggar". 
        Denna vyn är enbart informativ och kan ej redigeras direkt.
        `,
      },
      {
        id: 'sentInWorkOrders',
        heading: 'Fliken Inskickade ändringar',
        text: `
        Här visas de ändringar som gjort vid utförandet av arbetsordern. Det listas vilka lägen och fält det gäller, samt dess nuvarande värde och den inskickade värdet.
        `,
      },
      {
        id: 'commentWorkOrder',
        heading: 'Kommentera Arbetsorder',
        text: `
        För att skapa en kommentar om en arbetsorder, klicka på meddelandesymbolen.
        Då öppnas en ny ruta där ni har möjlighet att lägga in text och ladda upp filer som är relevant för den valda arbetsordern. 
        Uppladdade filer listas under ”Valda filer” och kan tas bort igen genom att klicka på soptunnan efter dess namn.
        `,
      },
    ],
  },
  {
    id: 'EditWorkOrderDialog',
    title: 'Skapa/redigera arbetsorder', // KLAR //
    chapters: [
      {
        id: 'createWorkOrder',
        heading: 'Skapa Arbetsorder',
        text: `
        Genom att klicka på ”Skapa arbetsorder” i Hållplatsdetaljerna, eller ”Skapa arbetsorder” i huvudmenyn, ges möjlighet att skapa en ny arbetsorder.
        Här fyller du i "Titel" och "Slutdatum”, väljer en "Entreprenör”, fyller i "Arbetsinstruktioner”, lägger till taggar samt lägger till hållplatser, antingen via "Filterset" eller genom att söka upp hållplatser manuellt via sök. Enskilda hållplatslägen kan tas bort via soptunnesymbolen. För att smidigare skapa dessa arbetsordrar ges det möjlighet att skapa och ladda upp mallar för instruktionerna för arbetsordrar.
        Läs mer om <a#workOrder>Arbetsorder</a>.
        `,
      },
      {
        id: 'createReOccurringWorkOrder',
        heading: 'Skapa återkommande arbetsorder',
        text: `
        För att skapa en återkommande arbetsorder klickar ni på "Hantera återkommande arbetsorderregler".
        Läs mer om hur ni gör för att skapa dessa <a#RecurringWorkOrderPlanManagementDialog>återkommande arbetsordrar</a>.
        `,
      },
      {
        id: 'correctWorkOrder',
        heading: 'Redigera arbetsorder',
        text: `
        Genom att klicka på pennsymbolen i en arbetsordervy ges möjligheten att redigera en arbetsorder.
        Här kan du ändra alla parametrar som skaparen har fyllt i och valt, såsom "Titel", "Slutdatum", "Entreprenör", "Filterset", ”Hållplatser”, ”Arbetsinstruktioner" och "Taggar".
        `,
      },
    ],
  },
  {
    id: 'serachFunction',
    title: 'Sökfunktion', //KLAR//
    chapters: [
      {
        id: 'serachBarFunction',
        heading: 'Sökfunktion',
        text: `
        Sökfunktionen hittar ni längst upp till vänster, under förstoringsglassymbolen.
        Här kan ni söka på alla olika hållplatser, arbetsordrar, entreprenörer och filterset.
        Resultaten visas allteftersom man fyller i sökrutan. Klicka på ett resultat för att ta dig till den vyn, eller tryck Escape eller klicka på bakgrunden för att avbryta.
        `,
      },
    ],
  },
  /*
  {
    id: 'routeOverview',
    title: 'Linjeöversikt', // kommer inte //
    chapters: [
      {
        id: 'routeOverview',
        heading: 'Linjeöversikt',
        text: `
        I Linjeöversikts vyn ser vi alla aktuella linjer.
        I översta raden ovanför kartan finns möjligheterna att söka på en linje med både linjenummer och namn, riktning, tid och datum, från och till, vardagar och helgdagar.
        Längst ut till höger i samma rad finns även möjligheten att ..............
        Genom att välja en linje öppnas information om vald linje. Här hittas information så som, antalresenärer under vald tidsperiod, påstigande per dag, fördelning dagar, avgångar per dag, passeade hållplatser, ankomster i tid samt en utzoomad karta för överblick av linjen. Informationen visas i både grafer och kartvyer.

        Till vänster i linjeöversikten finns även tre kategorier, Senast besökta, bäst punktlighet och sämst punktlighet.
        `,
      },
    ],
  },
  */
  /*
  {
    id: 'routePlanner',
    title: 'Ruttplanering', // kommer inte //
    chapters: [
      {
        id: 'routeOverview',
        heading: 'Ruttplanering',
        text: `
        `,
      },
    ],
  },
  */
  {
    id: 'UserManagementOverview',
    title: 'Användarehantering', //KLAR//
    chapters: [
      {
        id: 'userManagement',
        heading: 'Översikt av Användarhanteringsvyn',
        text: 'På användarhanteringsvyn hittar du två paneler. Den vänstra panelen innehåller två flikar som visar antingen användare eller inbjudningar. I den högra panelen kan du se en lista över Entreprenörer som kan väljas. (Om du är inloggad som entreprenörsadmin ser du bara din egen Entreprenör)',
      },
      {
        id: 'usersAndInvitations',
        heading: 'Söka Användare och Inbjudningar',
        text: 'Både användare och inbjudningar kan sökas via respektive sökfält. Detta gör det enkelt att hitta specifika användare eller inbjudningar.',
      },
      {
        id: 'SystemAdminFunctions',
        heading: 'Systemadministratörsfunktioner',
        text: 'Om du är inloggad som systemadministratör har du möjlighet att sortera på Entreprenörer. Detta ger dig en liknande vy till vad en Entreprenörsadministratör skulle se.',
      },
      {
        id: 'inviteNewUser',
        heading: 'Bjuda in nya användare',
        text: 'För att bjuda in en ny användare, klicka på plusknappen i övre högra hörnet i användarpanelen. Ange användarens e-postadress och välj vilka roller de ska ha. Om du är en Entreprenörsadministratör kan du bara bjuda in användare till din Entreprenör.',
      },
      {
        id: 'contractorManagement',
        heading: 'Hantering av Entreprenörer',
        text: 'Systemadministratörer kan i högerpanelen lägga till nya Entreprenörer och redigera befintliga.',
      },
      {
        id: 'editUser',
        heading: 'Redigering av Användare',
        text: 'I användarlistan kan du redigera namn och roller för Entreprenörs agenter. Du har även möjlighet att ta bort användare om det behövs. Systemadministratörer kan inte redigeras, men kan tas bort av andra systemadministratörer.',
      },
      {
        id: 'invitationManagement',
        heading: 'Hantering av Inbjudningar',
        text: 'I listan för inbjudningar kan du avbryta en inbjudan, skicka om den eller ändra användarens roller. Om en användare har blivit inbjuden som en entreprenörsagent men borde ha varit en entreprenörsadministratör, kan det ändras direkt i inbjudan om den ännu inte har accepterats. Om inbjudan redan har accepterats måste ändringen göras via användarlistan. Om en användare har bjudits in som en entreprenör istället för en systemadministratör eller vice versa, kan det ändras också ändras direkt i inbjudan. Om inbjudan däremot redan har accepterats måste användaren tas bort via användarelistan och sen bjudas in igen med rätt roller.',
      },
      {
        id: 'resendInvitation',
        heading: 'Återinbjudning av Användare',
        text: 'Om en inbjuden inte har accepterats i tid och den har blivit avbruten, kan inbjudan skickas om genom att klicka på knappen för att skicka om inbjudan. Detta skickar en ny inbjudan till användaren via epost.',
      },
    ],
  },
  {
    id: 'filter',
    title: 'Filterset', // KLAR //
    chapters: [
      {
        id: 'filterSet',
        heading: 'Filterset',
        text: `
        Filter kan skapas enskilt eller genom att lägga ihop två eller flera filter. Tillsammans skapar de ett filterset. Detta fungerar på så sätt att det första filtret ligger till grund för nästa filter. Fler filter kan sedan läggas till som följer samma mönster. Fördelen med att skapa ett filterset är att man på detta sättet kan få ut skillnader mellan till exempel olika hållplatslägen inom vissa områden och speciella attribut (Ex. Filter 1 filtrerar ut alla hållplatslägen inom ett visst område och Filter 2 filtrerar ut om de har väderskydd. I det fallet kan man se alla hållplatslägen inom det valda området, samt om de har väderskydd eller inte. Ett enskilt filter med samma filtervillkor hade enbart visat hållplatslägen inom det valda området som har väderskydd.)
        `,
      },
      {
        id: 'addFilter',
        heading: 'Lägg till filter',
        text: `
        Du lägger till ett filter genom att klicka “Nytt filter” ovan för kartan/tabellen. Detta filter kan sedan kombineras med ytterligare filter genom att klicka “Nytt filter” igen efter ett filter är skapat. Dessa filter tillsammans skapar ett filterset. Genom att klicka i ”Visa ej matchande” får man en vy där enbart de hållplatser som faller under de aktiva filtrera syns.`,
      },
      {
        id: 'filterTerms',
        heading: 'Lägg till filtervillkor',
        text: `När ett filter är skapat måste man lägga till minst ett filtervillkor. Från listan som öppnas väljer man ett av de attribut som er organisation har skapat. Beroende på vilket typ av värde just det attributet har väljer man en operand och fyller i önskat värde. Varje filter kan innehålla ett obegränsat antal filtervillkor. I tabellen visas enbart de hållplatser som uppfyller alla tillagda villkor - inte de hållplatser som bara uppfyller vissa av dem. Ett valt filtervillkor kan tas bort genom att klicka på soptunnan efter densamma. Hela filtret kan tas bort genom att klicka på soptunnan i filterdialogens överkant. För att spara och applicera filtret, klicka på "Verkställ"-knappen. För att ångra dina ändringar, klicka på krysset i övre högra hörnet.`,
      },
      {
        id: 'filterList',
        heading: 'Filterlistan',
        text: `Aktiva filter visas som en horisontell lista ovanför tabellen/kartan. Första fältet visat det totala antalet hållplatslägen som finns. Varje filter visar sedan hur många hållplatslägen som “blir kvar” med det filtret pålagt. Man kan även tillfälligt stänga av ett filter i filtersetet genom att klicka på ögat på filtret. Hovrar du över filtret får du upp en sammanfattning av vad det filtret i fråga innehåller. Klickar du kan du lägga till eller ta bort filtervillkor.`,
      },
    ],
  },
  {
    id: 'issueReports',
    title: 'Felanmälan', //KLAR//
    chapters: [
      {
        id: 'issue_Reports',
        heading: 'Felanmälan',
        text: `
        Uppe till höger i hållplatsdetaljer finns knappen "Felanmälan". Genom att klicka på denna öppnar man en ruta för att se och skapa felanmälningar för hållplatslägen. Knappen för felanmälningar har en siffra som visar antalet liggande felanmälningar på den aktuella hållplatsen.
        `,
      },
      {
        id: 'newIssueReport',
        heading: 'Ny felanmälan',
        text: `
        Under överskriften på vänster sida finns knappen för att lägga till en ny felanmälan.
        Genom att klicka på den öppnas ”Skapa ny felanmälan”. Där ges alternativ till höger för att skapa felanmälan för hållplatslägen.
        Först väljer man vilket eller vilka hållplatslägen som felanmälan gäller genom att klicka i knapparna under ”Välj hållplatslägen”. Klicka sedan på rullgardinsmenyn för att välja kategori för felanmälan.
        Kommentera felanmälan, beskriv felet som anmälan gäller så utförligt som möjligt.
        `,
      },
      {
        id: 'newIssueReportstop',
        heading: 'Felanmäl Läge',
        text: `
        Välj vilket läge felanmälan gäller."
        Klicka på rullgardnismenyn för att välja kategori för felanmälan.
        Kommentera felanmälan, beskriv felet mer utförligt som avser denna felanmälan.
        `,
      },
      {
        id: 'occurringReports',
        heading: 'Liggande felanmälnigar',
        text: `
        På vänster sida visas en lista med felanmälningar som inte är åtgärdade ännu. Genom att markera en av dessa visas mer information om vald felanmälan till höger i rutan.
        `,
      },
      {
        id: 'infoReport',
        heading: 'Felanmälansinformation',
        text: `
        När du valt en inte åtgärdad felanmälan visas information om felanmälan till höger.
        Vilket läge som felanmälan gäller, vilket attribut (tex Belysning) som gäller, vem som skapade felanmälan och när, vart felanmälan är skickad samt kommentarer för felanmälan.
        Genom att klicka på "Åtgärdad"-knappen ändras status för den valda felanmälan till åtgärdad.
        Genom att klicka på knappen "Stäng" stängs bara dialogrutan.
        `,
      },
    ],
  },
  {
    id: 'policeReport',
    title: 'Polisanmälan', // KLAR //
    chapters: [
      {
        id: 'policeReports',
        heading: 'Polisanmälan',
        text: `
        Genom att klicka på knappen "Polisanmälan" i hållplatsdetaljer kan en notering i systemet skapas för att indikera att en polisanmälan upprättas. Efter noteringen är skapad visas en länk till polisens anmälningsverktyg och den faktiska anmälan görs där.
        Välj vilka lägen polisrapporten gäller och beskriv i textform vad som hänt.
        Klicka fortsätt så visas länk till polisens formulär för anmälningar.
        `,
      },
    ],
  },
  {
    id: 'FilterManagementDialog',
    title: 'Hantera Filterset', // KLAR //
    chapters: [
      {
        id: 'FilterManagement',
        heading: 'Hantera Filterset',
        text: `
        Med filterhanteraren kan man spara och öppna sparade filterset. Klicka på ”Skapa nytt filterset” och döp ditt filterset till något meningsfullt så att du och andra vet vad det sparade filtersetet innehåller. Klicka sedan spara. 
        Markerar du ett existerade filterset så kan du byta dess namn genom att ändra det i fältet ”Namnge filterset” och sedan klicka ”Byt namn”. Man kan även klicka “Ta bort filterset” för att ta bort det, eller “Öppna” för att öppna det. 
        Om man markerar ett sparat filterset och klickar ”Ersätt filterset” så skrivs det markerade filtersetet över med det aktuella man har öppet.`,
      },
    ],
  },
  {
    id: 'EditEntityPropertiesDialog',
    title: 'Redigera hållplatsattribut', // KLAR //
    chapters: [
      {
        id: 'EditEntityProperties',
        heading: 'Redigera hållplatsattribut',
        text: `Dialogen består av tre kolumner. Första kolumen listar attributen (grupper är stängda när man öppnar dialogen, men de öppnas genom att klicka på pilen till vänster om gruppnamnet), andra kolumnen listar det nuvarande värdet för varje attribut och den tredje kolumnen är en lista där man kan byta ut värdena i attributen. Klicka i rutan som tillhör det attribut för att fylla i ett nytt värde. När det finns förvalda värden visas detta i en rullgardinslista när man klickar, i annat fall skriver man fritt. Lämnas tredje kolumnen tom så sker inga ändringar. När alla ändringar är gjorda sparar man med sparaknappen längds ner till höger.
        `,
      },
    ],
  },
  {
    id: 'ContractorManagementDialog',
    title: 'Hantera entreprenörer', // KLAR //
    chapters: [
      {
        id: 'ContractorManagement',
        heading: 'Hantera entreprenörer',
        text: `
        I hantera entreprenörer ges möjligheten att lägga till de entreprenörer som arbetar med arbetsordrar. I listan till vänster finns alla inlagda entreprenörer.
        `,
      },
      {
        id: 'ContractorManagementInformation',
        heading: 'Entreprenörsinformation',
        text: `
        Genom att klicka på en av entreprenörerna i listan till vänster får man upp dess information i fältet till höger. Där kan man redigera organisationens namn, dess postadress, kontaktperson, telefonnummer, email samt koppla ett filterset till organisationen och skriva eventuella anmärkningar. Klickar man på plussymbolen i västerspalten kan man lägga till en ny entreprenör. Man fyller då i de föregående fälten på högersidan. 
        I botten av dialogen har man möjlighet att ta bort en entreprenör samt spara ändringar samt nya entreprenörer. 
        `,
      },
      {
        id: 'ContractorManagementagent',
        heading: 'Fliken Utförare',
        text: `
        I fliken utförare ser man de utförare som är listade under den aktuella entreprenören. Man kan även söka efter en utförare inom den valda entreprenörsorganisationen. 
        De enskilda utförarna kan tas bort med soptunnan till höger om namnet.
        `,
      },
    ],
  },
  {
    id: 'OrganizationManagementDialog',
    title: 'Hantera organisation', // KLAR //
    chapters: [
      {
        id: 'OrganizationManagement',
        heading: 'Hantera organisation',
        text: `
        I hantera organisation kan man bjuda in nya personer till sin organisation genom att skicka en emailinbjudan. Fyll i emailadressen till den du vill bjuda in i rutan längds upp i vänsterspalten och klicka bjud in. 
        Den skickade inbjudan kommer att listas under med en markör beroende på inbjudans status. Gul markör betyder att inbjudan är skickad men väntar på svar och röd markör betyder att inbjudan inom en dag kommer gå över tiden och försvinna.`,
      },
      {
        id: 'OrganizationManagementMembers',
        heading: 'Nuvarande medlemmar',
        text: `
        Till höger finns en lista med alla nuvarande medlemmar i organisationen. De är listande med det användarnamn de själva valt samt den emailadress som är kopplad till dem. 
        Till höger om namnet finns en box där man kan klicka i om medlemmen ska ha adminrättigheter, samt en soptunna om man vill ta bort medlemmen ur organisationen. Ovanför listan finns även en sökfunktion där man kan söka efter nuvarande medlemmar.`,
      },
    ],
  },
  {
    id: 'SchemaManagementDialog',
    title: 'Attributhanteraren', // KLAR //
    chapters: [
      {
        id: 'ManagementDialog',
        heading: 'Attributhanteraren',
        text: `Attribut kan läggas till, redigeras eller gömmas via attributhanteraren. De befintliga attributen finns listade till vänster. Man kan byta ordningen i listan genom att klicka och dra på punkterna till vänster om attributets namn. Attributen kan även dras in och ut ur grupper.
        `,
      },
      {
        id: 'EditSchemaManagementDialog',
        heading: 'Redigera attribut',
        text: `Om man klickar på ett attribut kan man ändra dess namn, bestämma vilka typ av data attributet visar, bestämma vart felanmälningar på just det attributet ska skickas (felanmälningslänk), skriva en en eventuell hjälptext som förklarar attributet samt ladda upp ett foto som hjälp.
        Attribut kan inte tas bort utan enbart gömmas genom att man klickar i ”Gömd”. 
        I de fall man vill redigera vilka attribut man har kan bara lägg till nya alternativ. Detta för att inte förstöra databasen. Vill man ta bort något attribut bör man istället gömma den och skapa ett nytt med korrekta alternativ.
        `,
      },
      {
        id: 'CreateSchemaManagementDialog',
        heading: 'Skapa grupp',
        text: `Ovanför listan till vänster finns två ikoner. Klickar man på dokument med ett plus skapas en ny grupp för attribut. Man väljer ett gruppnamn och klickar enter. Den nya gruppen skapas längds ner i listan (man tas automatiskt dit).
        `,
      },
      {
        id: 'CreateAttributeManagementDialog',
        heading: 'Skapa nytt attribut',
        text: `Ovanför listan till vänster finns två ikoner. Klickar man på det stora plusset skapas ett nytt attribut. Man väljer då ett namn på det ny attributet till höger, väljer vilken typ av data (operand) som är relaterad till attributet, klickar i om det är gömd eller synlig (attribut kan inte tas bort, enbart gömmas), bestämmer vart felanmälningar på just det attributet ska skickas (felanmälningslänk), skriver en en eventuell hjälptext som förklarar attributet samt eventuellt laddar upp ett foto som hjälp.
        `,
      },
      {
        id: 'DeleteSchemaManagementDialog',
        heading: 'Ta bort grupper',
        text: `Man kan ta bort grupper genom att klicka på soptunnan vid dess namn i listan till vänster. Grupper kan dock endast tas bort då de är tomma.`,
      },
      {
        id: 'deleteAttributeManagementDialog',
        heading: 'Redigera hållplatsattribut',
        text: `Attribut kan enbart tas bort innan de är sparade. Vill man ta bort ett sparat attribut får man istället klicka ”Gömd” i ”redigera hållplatsvyn”.`,
      },
    ],
  },
  {
    id: 'WorkOrderTemplateManager',
    title: 'Arbetsordermallar', // KLAR //
    chapters: [
      {
        id: 'WorkOrderTemplate',
        heading: 'Arbetsordermallar',
        text: `Arbetsordermallar används för att spara instruktioner för arbete som utförs ofta. Genom att skapa en mall behöver man inte skriva om arbetsinstruktioneran och välja taggar varje gång man skapar en liknande arbetsorder. 
        `,
      },
      {
        id: 'EditWorkOrderTemplate',
        heading: 'Hantera arbetsordermallar',
        text: `I listan till vänster finns alla befintliga arbetsordermallar. Klickar man på någon av dem så får man upp all information om den i högerfältet. Klickar man på det stora plusset i toppen av den vänstra listan kan man skapa en ny arbetsordermall. Vill man ta bort en mall klickar man på den och sedan “ta bort mall” nere till höger i dialogen.
        `,
      },
      {
        id: 'CreateNewWorkOrderTemplate',
        heading: 'Skapa ny arbertsordermall',
        text: `När man skapar en ny arbetsordermall bör man först döpa den i mallnamnsfältet till något som är lättförståeligt. Sedan fyller man i arbetsinstruktionerna. Rutan för arbetsinstruktioner har även en rensaknapp som man kan använda för att ta bort allt man skrivit i den rutan. Sedan kan man lägga till (och ta bort) taggar genom att öppna tagghanteraren (länk till tagghanteraren). När arbetsordermallen är färdig klickar man spara.
        `,
      },
    ],
  },
  {
    id: 'TableColumnManagement',
    title: 'Kolumnhanteraren', // KLAR //
    chapters: [
      {
        id: 'TableManagement',
        heading: 'Kolumnhanteraren',
        text: `Kolumnhanteraren används för att gömma eller visa de kolumner som är synliga i hållplatsdetaljerna. Är boxen precis före attributet tickad kommer den kolumnen visas, är den ej tickad kommer den att gömmas. I nederkant av dialogen kan man välja att “visa ingen” (alla attribut blir då ej tickade), “visa alla” (alla attribut blir då tickade) samt “tillämpa inställningar” som aktiverar de val man gjort.
        `,
      },
    ],
  },
  {
    id: 'RecurringWorkOrderPlanManagementDialog', // KLAR //
    title: 'Återkommande arbetsordrar',
    chapters: [
      {
        id: 'RecurringWorkOrder',
        heading: 'Återkommande arbetsordrar',
        text: `När du öppnar återkommande arbetsorderdialogen har du en lista till vänster med befintliga regler och till höger en knapp för att skapa en ny regel. Klickar du på någon av de befintliga reglerna till vänster tas du till detaljerna om den regeln.
        `,
      },
      {
        id: 'CreateNewRecurringWorkOrder',
        heading: 'Skapa ny arbetsorderregel',
        text: `När man klickat på ”Nytt regelverk” kommer man till vyn för detta. Här fyller man i regelns titel och om den ska tillfalla en eller flera entreprenörer. Vidare fyller man i arbetsintruktioner (eller laddar ner en mall för detta). Sedan måste man sätta ett datum då regeln ska börja gälla och eventuellt slutdatum för regeln. Nästa fält att fylla i är tidsfrist, där man bestämmer hur många dagar innan arbetsorderns slutdatum den skapas upp.
        Vidare måste man sätta med vilken periodicitet som arbetsordrarna ska skapas. Först väljer man antingen årlig eller månatlig. Om man valt månatlig väljer man det datum i månaden som arbetsordern ska gälla. Vid årligt väljer man både datum samt månad. Man kan även lägga till fler händelser (i de fall man vill ha fler perioder än en gång om året respektive en gång i månaden) genom att klicka “ny händelse”.
        Sedan väljer man ett befintligt filterset för att länka regeln till specifika hållplatslägen. Detta filterset skapas i filtersetmenyn. När man valt ett filterset kan man förhandsgranska de valda hållplatslägen genom att klicka på ögat till höger om filtersetet. De hållplatslägen som visas är de som i ögonblicket du öppnar dialogen passar in under ett filterset. Detta kan ändras med tiden om attributen på hållplatslägen uppdateras. Det är därför viktigt att ha i åtanke att hållplatslägen inte är fixerade till en regel, utan det är filtersetet (vilket uppdateras konstant mot databasen om det förkommer ändringar) som avgör vilka hållplatslägen som arbetsordrarna skapas för. Slutligen kan man lägga till taggar.
        `,
      },
      {
        id: 'BottomBarRecurringWorkOrder',
        heading: 'Bottennavigering',
        text: `I botten av dialogen finns det till vänster möjlighet att gå tillbaka till befintliga arbetsorderregler, i mitten ta bort existerande regler (om man har klickat in på en sådan) samt till höger skapa en ny återkommande arbetsorderregel med de uppgifter man har fyllt i. Om något saknas eller är felaktigt har man inte möjlighet att klicka på “skapa”-knappen och eventuella fel syns när man hovrar över knappen.
        `,
      },
    ],
  },
  {
    id: 'TagManagementDialog', //Håkan skriver text till denna
    title: 'Taggar',
    chapters: [
      {
        id: 'tags',
        heading: 'Taggar',
        text: `
        Taggar används för att märka upp arbetsordrar så att det senare är lättare att hitta dem samt att få ut statistik om dem. Taggar kan antingen vara skapade av systemet och är då permanenta, eller skapade av användare och kan då även tas bort av användare.
        `,
      },
      {
        id: 'managetags',
        heading: 'Hantera taggar',
        text: `
        I hantera taggarvyn finns ett fält där man kan filtrera existerande taggar. Existerande taggar är listade under fältet. Klickar man på en tagg kan man döpa om den samt lägga in en beskrivning, eller ta bort taggen från systemet (gäller enbart taggar skapade av användare). Under fliken ”Ny tagg” kan man skapa nya taggar. Man namnger taggen och ger den eventuellt en beskrivning innan man klickar på ”Spara tagg”.
        `,
      },
    ],
  },
  {
    id: 'CheckInPlans',
    title: 'Tillsynsplaner',
    chapters: [
      {
        id: 'checkInPlanDescription',
        heading: 'Tillsynsplaner',
        text: `
        Tillsynsplaner är återkommande inspektioner för att se om entreprenörerna utför sitt arbete på ett tillförlitligt sätt.
        `,
      },
      {
        id: 'checkInPlanmanagement',
        heading: 'Tillsynsplanshantering',
        text: `
        Dialogen för tillsynshantering är uppdelad i två delar. Till vänster visas en lista med befintliga tillsynsplaner. Klickar man på pennsymbolen kan man redigera tillsynsplanen och klickar man på ögonsymbolen får man information om tillsynsplanen har blivit utförd korrekt i denna samt föregående period.
        `,
      },
      {
        id: 'createCheckInPlans',
        heading: 'Skapa ny tillsynsplan',
        text: `
        Klickar man på ”Ny tillsynsplan” ändras dialogen till det formulär man använder för att skapa en ny tillsynsplan. Här fyller man i titel, väljer entreprenör och sätter en tidsfrist då tillsynsplanen ska skapas upp.  Vidare måste man sätta med vilken periodicitet som arbetsordrarna ska skapas. Först väljer man antingen årlig eller månatlig. Om man valt månatlig väljer man det datum i månaden som arbetsordern ska gälla. Vid årligt väljer man både datum samt månad. Man kan även lägga till fler händelser (i de fall man vill ha fler perioder än en gång om året respektive en gång i månaden) genom att klicka “ny händelse”. Slutligen väljer man vilka hållplatslägen tillsynsplanen ska gälla.
        Efter att all information har blivit ifylld kan antingen skapa tillsynsplanen som den är genom att klicka ”Hoppa över ruttplanering”, eller fortsätta till ”Ruttplanering”.
        `,
      },
      {
        id: 'routePlaning',
        heading: 'Ruttplanering',
        text: `
        Ruttplanering finns till för att man ska kunna bestämma i vilken ordning tillsynen ska göras. Vyn för ruttplanering är uppdelad i två delar. Till vänster listas alla hållplatslägen som är valda i tillsynsplanen. Ordningen på dessa kan ändras genom att man drar dem med upp eller mer. Till höge visas en karta med den snabbaste vägen mellan de valda hållplatslägen, i den ordningen man valt till vänster. När man har bestämt en rutt klickar man ”Skapa ny tillsynsplan ” för att skapa tillsynsplanen.
        `,
      },
      {
        id: 'editCheckInPlans',
        heading: 'Redigera tillsynsplaner',
        text: `
        Om man klickar på pennsymbolen i listan över befintliga tillsynsplaner så får man samma vy som vid skapa ny tillsynsplan, men med all information ifylld. Är kan man ändra och sedan spara, eller klicka ”Ta bort” för att ta bort tillsyns planen helt och hållet.
        `,
      },
    ],
  },
  {
    id: 'exportTable',
    title: 'Exportera tabell',
    chapters: [
      {
        id: 'tableExport',
        heading: 'Exportera tabell',
        text: `
        Klicka på ”Exportera tabell” för att spara nuvarande tabell på din lokala dator.
        `,
      },
    ],
  },
];

export interface RawSubject extends Omit<Subject, 'chapters'> {
  chapters: RawChapter[];
}

interface RawChapter extends Omit<Chapter, 'paragraphs'> {
  text: string;
}

export default texts;
