import React, { useMemo, useCallback } from 'react';
import { alpha, Box, InputBase, InputBaseProps, Theme } from '@mui/material';
import { Search } from '@mui/icons-material';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  search: {
    id: 'search_field.search',
    defaultMessage: 'Search…',
  },
});

interface SearchFieldProps
  extends Pick<InputBaseProps, 'inputRef' | 'onFocus' | 'onBlur'> {
  label?: string;
  sx?: SxProps<Theme>;
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (key: string) => void;
  /** If true the input will auto focus on mount. */
  focus?: true;
  /** Useful to access click event to prevent bubbling. */
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  label,
  sx,
  value,
  onChange,
  onKeyDown,
  focus,
  inputRef,
  onFocus,
  onBlur,
  onClick,
}) => {
  const intl = useIntl();

  const placeHolder = useMemo(
    () => label || intl.formatMessage(messages.search),
    [intl, label],
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (onChange) {
        onChange(e.currentTarget.value);
      }
    },
    [onChange],
  );

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: ({ shape }) => shape.borderRadius,
        backgroundColor: ({ palette }) => alpha(palette.common.black, 0.04),
        '&:hover': {
          backgroundColor: ({ palette }) => alpha(palette.common.black, 0.08),
        },
        marginTop: ({ spacing }) => spacing(2),
        marginBottom: ({ spacing }) => spacing(2),
        display: 'flex',
        flexGrow: 0.5,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Search sx={{ ml: 3 }} />
        <InputBase
          inputRef={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onInputChange}
          autoFocus={focus}
          onClick={onClick}
          onKeyDown={(x) => onKeyDown && onKeyDown(x.key)}
          sx={{
            color: 'inherit',
            width: '100%',
            '& .MuiInputBase-input': {
              width: '100%',
            },
          }}
          placeholder={placeHolder}
        />
      </Box>
    </Box>
  );
};

export default SearchField;
