import React, { useMemo } from 'react';
import { classNames } from '../classNames';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'filled' | 'outlined';
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ variant, ...props }, ref) => {
    const variantClass = useMemo(() => {
      switch (variant) {
        default:
          return 'hover:bg-gray-dark/10 before:active:bg-white/40';
        case 'filled':
          return 'bg-workorder text-white before:hover:bg-white/10 before:active:bg-white/40';
        case 'outlined':
          return 'border border-workorder text-workorder before:hover:bg-black/10 before:active:bg-black/40';
      }
    }, [variant]);

    return (
      <button
        {...props}
        ref={ref}
        className={classNames(
          'relative flex h-10 w-10 items-center justify-center rounded-full',
          "before:absolute before:inset-0 before:block before:content-['']",
          'before:pointer-events-none before:rounded-full before:active:animate-highlightFromCenter',
          variantClass,
          props.className,
        )}
      />
    );
  },
);

export default IconButton;
