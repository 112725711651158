import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Add, Close, Help } from '@mui/icons-material';
import { DateTime } from 'luxon';
import useGuideStore from '../../store/guide';
import { useWorkOrderInstructions } from '../../hooks/useWorkOrderInstructions';
import useWOInstructionManagementDialog from '../../store/woInstructionManagementDialog';
import { useWOInstructionMutations } from '../../hooks/useWorkOrderInstructions';
import TagSelector from '../Tags/TagSelector';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';

interface TemporaryInstruction
  extends Omit<WorkOrderInstruction, 'id' | 'texts'> {
  id?: string;
  description: string;
}

const messages = defineMessages({
  newTitle: {
    id: 'template_manager.enter_template_title',
    defaultMessage: 'Enter template title',
  },
  workInstructions: {
    id: 'template_manager.work_instructions',
    defaultMessage: 'Enter work instructions here...',
  },
  saveFailed: {
    id: 'template_manager.save_failed',
    defaultMessage: 'Save failed. Please try again.',
  },
  createFailed: {
    id: 'template_manager.create_failed',
    defaultMessage: 'Creating new template failed. Please try again.',
  },
  deleteFailed: {
    id: 'template_manager.delete_failed',
    defaultMessage: 'Deleting template failed. Please try again.',
  },
});

const WorkOrderInstructionManager: React.FC = () => {
  const intl = useIntl();
  const { openGuide } = useGuideStore();

  const { list: instructions } = useWorkOrderInstructions();
  const {
    create: {
      mutateAsync: createInstruction,
      isLoading: createInstructionInProgress,
    },
    update: {
      mutateAsync: updateInstruction,
      isLoading: updateInstructionInProgress,
    },
    remove: {
      mutateAsync: deleteInstruction,
      isLoading: deleteInstructionInProgress,
    },
  } = useWOInstructionMutations();
  const { show, close, selectedInstructionId, setSelectedInstructionId } =
    useWOInstructionManagementDialog();

  const [confirmDirty, setConfirmDirty] = useState(false);
  const [changes, setChanges] = useState(false);

  const [localInstruction, setLocalInstruction] =
    useState<TemporaryInstruction | null>({
      description: '',
      title: '',
      tags: [],
      meta: {
        created_at: DateTime.now(),
        created_by: 'auth0|6172bc995cd96b0070339cc7',
        updated_at: DateTime.now(),
      },
    });
  const titleRef = useRef<HTMLInputElement | null>(null);

  const [updateFailed, setUpdateFailed] = useState('');

  const setLocalInstructionAndFlagChanges = useCallback(
    (instruction: TemporaryInstruction) => {
      setChanges(true);
      setLocalInstruction(instruction);
    },
    [setLocalInstruction],
  );

  const reset = useCallback(() => {
    setChanges(false);
    setConfirmDirty(false);
    setUpdateFailed('');
    setLocalInstruction({
      description: '',
      title: '',
      tags: [],
      meta: {
        created_at: DateTime.now(),
        created_by: 'auth0|6172bc995cd96b0070339cc7',
        updated_at: DateTime.now(),
      },
    });
    setSelectedInstructionId(null);
  }, [setSelectedInstructionId]);

  useEffect(() => {
    if (
      (!localInstruction || localInstruction.id !== selectedInstructionId) &&
      selectedInstructionId
    ) {
      const instruction = instructions.find(
        (t) => t.id === selectedInstructionId,
      );
      if (instruction) {
        setLocalInstruction({
          id: selectedInstructionId,
          description: instruction.texts.join('\n'),
          tags: instruction.tags,
          title: instruction.title,
          meta: instruction.meta,
        });
      }
    }
  }, [localInstruction, selectedInstructionId, instructions]);

  const saveEditedTemplate = useCallback(() => {
    if (localInstruction) {
      const id = localInstruction.id;
      const description = localInstruction.description.split(/\r?\n/);
      if (id) {
        void updateInstruction({
          id,
          ...localInstruction,
          texts: description,
        })
          .catch(() => {
            setUpdateFailed(intl.formatMessage(messages.saveFailed));
          })
          .then(reset);
      } else {
        void createInstruction({ ...localInstruction, texts: description })
          .catch(() => {
            setUpdateFailed(intl.formatMessage(messages.createFailed));
          })
          .then(reset);
      }
    }
  }, [createInstruction, intl, localInstruction, updateInstruction, reset]);

  const deleteAndClear = useCallback(
    (templateId: string) => {
      void deleteInstruction(templateId)
        .catch(() => {
          setUpdateFailed(intl.formatMessage(messages.deleteFailed));
        })
        .then(reset);
    },
    [deleteInstruction, intl, reset],
  );

  const clear = useCallback(() => {
    reset();
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [reset]);

  const processing = useMemo(() => {
    return (
      createInstructionInProgress ||
      updateInstructionInProgress ||
      deleteInstructionInProgress
    );
  }, [
    createInstructionInProgress,
    deleteInstructionInProgress,
    updateInstructionInProgress,
  ]);

  const tryClose = useCallback(() => {
    if (changes) {
      setConfirmDirty(true);
    } else {
      close();
    }
  }, [changes, close]);

  return (
    <>
      <UnsavedChangesDialog
        open={!!confirmDirty}
        onOk={() => {
          setChanges(false);
          setConfirmDirty(false);
          close();
          reset();
        }}
        onCancel={() => setConfirmDirty(false)}
      />

      <Dialog open={show} onClose={tryClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>
          <FormattedMessage
            id="template_manager.template_manager"
            defaultMessage="Template Manager"
          />
          <div>
            <IconButton
              color="inherit"
              onClick={() => openGuide('WorkOrderTemplateManager')}
            >
              <Help />
            </IconButton>
            <IconButton color="inherit" onClick={tryClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box flexDirection="row" display="flex">
            <Box flex={1} mt={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  <FormattedMessage
                    id="template_manager.instructions"
                    defaultMessage="Instructions"
                  />
                </Typography>
                <IconButton color="primary" onClick={clear}>
                  <Add />
                </IconButton>
              </Box>
              <List component="nav">
                {instructions.map((t) => (
                  <ListItem
                    key={t.id}
                    selected={localInstruction?.id === t.id}
                    onClick={() => setSelectedInstructionId(t.id)}
                  >
                    <ListItemText primary={t.title} />
                  </ListItem>
                ))}
              </List>
            </Box>
            {localInstruction && (
              <>
                <Box
                  flex={2}
                  sx={{
                    backgroundColor: ({ palette }) =>
                      palette.background.default,
                  }}
                  p={4}
                >
                  <TextField
                    value={localInstruction.title}
                    inputRef={titleRef}
                    onChange={(e) =>
                      setLocalInstructionAndFlagChanges({
                        ...localInstruction,
                        title: e.target.value,
                      })
                    }
                    label={
                      <FormattedMessage
                        id="template_manager.template_title"
                        defaultMessage="Template title"
                      />
                    }
                    placeholder={intl.formatMessage(messages.newTitle)}
                  />
                  <TextField
                    value={localInstruction.description}
                    onChange={(e) =>
                      setLocalInstructionAndFlagChanges({
                        ...localInstruction,
                        description: e.target.value,
                      })
                    }
                    multiline
                    rows={14}
                    label={
                      <FormattedMessage
                        id="template_manager.work_instructions"
                        defaultMessage="Work instructions"
                      />
                    }
                    sx={{ marginTop: 2 }}
                    placeholder={intl.formatMessage(messages.workInstructions)}
                  />
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        setLocalInstructionAndFlagChanges({
                          ...localInstruction,
                          description: '',
                        })
                      }
                    >
                      <FormattedMessage
                        id="template_manager.clear"
                        defaultMessage="Clear"
                      />
                    </Button>
                  </Box>
                </Box>
                <Box
                  flex={1}
                  sx={{
                    backgroundColor: ({ palette }) =>
                      palette.background.default,
                  }}
                >
                  <Box display="flex" alignItems="center" mt={2} mb={2}>
                    <Typography variant="overline" color="text.secondary">
                      <FormattedMessage
                        id="template_manager.tags_count_selected"
                        defaultMessage="Tags ({count} selected)"
                        values={{ count: localInstruction?.tags?.length || 0 }}
                      />
                    </Typography>
                  </Box>
                  <Box display="flex" flexWrap="wrap">
                    <TagSelector
                      selectedTags={localInstruction?.tags || []}
                      onChange={(tagIds) => {
                        if (localInstruction) {
                          setLocalInstructionAndFlagChanges({
                            ...localInstruction,
                            tags: tagIds,
                          });
                        }
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={!localInstruction || !localInstruction.id || processing}
            onClick={() =>
              localInstruction &&
              localInstruction.id &&
              deleteAndClear(localInstruction.id)
            }
          >
            <FormattedMessage
              id="template_manager.remove_template"
              defaultMessage="Remove template"
            />
          </Button>
          <Button variant="outlined" onClick={() => tryClose()}>
            <FormattedMessage
              id="template_manager.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="contained"
            onClick={() => saveEditedTemplate()}
            disabled={
              !localInstruction || !localInstruction.title || processing
            }
          >
            {localInstruction && localInstruction.id ? (
              <FormattedMessage
                id="template_manager.save_template"
                defaultMessage="Save template"
              />
            ) : (
              <FormattedMessage
                id="template_manager.create_template"
                defaultMessage="Create template"
              />
            )}
          </Button>
        </DialogActions>
        {updateFailed && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {updateFailed}
          </Alert>
        )}
      </Dialog>
    </>
  );
};

export default WorkOrderInstructionManager;
