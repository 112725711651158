import React, { FC } from 'react';
import { Grid } from '@mui/material';
import ChartComponent from '../../components/ChartComponent';
import { defineMessages, useIntl } from 'react-intl';

const totalValidations = {
  hour: {
    datasets: Array.from(Array(24)).map((_, index) => String(index + 1)),
    labels: Array.from(Array(24)).map((_, index) => String(index + 1)),
  },
  week: {
    datasets: Array.from(Array(7)).map((_, index) => String(index + 1)),
    labels: ['V20', 'V21', 'V22', 'V23', 'V24', 'V25', 'V26'],
  },
  weekDay: {
    datasets: Array.from(Array(7)).map((_, index) => String(index + 1)),
    labels: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
  },
  month: {
    datasets: Array.from(Array(12)).map((_, index) => String(index + 1)),
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  },
};

const averageValidations = {
  hour: {
    datasets: Array.from(Array(24)).map((_, index) => String(index + 100)),
    labels: Array.from(Array(24)).map((_, index) => String(index + 1)),
  },
  week: {
    datasets: Array.from(Array(7)).map((_, index) => String(index + 100)),
    labels: ['V20', 'V21', 'V22', 'V23', 'V24', 'V25', 'V26'],
  },
  weekDay: {
    datasets: Array.from(Array(7)).map((_, index) => String(index + 100)),
    labels: ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö'],
  },
  month: {
    datasets: Array.from(Array(12)).map((_, index) => String(index + 100)),
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  },
};

const messages = defineMessages({
  validationCount: {
    id: 'gchart.validation_count',
    defaultMessage: 'Number of validations',
  },
  averageValidations: {
    id: 'gchart.average_validations',
    defaultMessage: 'Average validations',
  },
});

const GChart: FC = () => {
  const intl = useIntl();
  return (
    <Grid container overflow="auto" height="100%">
      <Grid container wrap="nowrap">
        <Grid container width="40vw">
          <ChartComponent
            title={intl.formatMessage(messages.validationCount)}
            chartConfig={totalValidations}
          />
        </Grid>
        <Grid container width="40vw">
          <ChartComponent
            title={intl.formatMessage(messages.averageValidations)}
            chartConfig={totalValidations}
          />
        </Grid>
      </Grid>

      <ChartComponent
        title={intl.formatMessage(messages.averageValidations)}
        chartConfig={averageValidations}
      />
    </Grid>
  );
};

export default GChart;
