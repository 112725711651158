import React, { FC, useEffect, useMemo } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useOrganizations from '../../hooks/useOrganizations';
import MobilixLogo from './MobilixLogo';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface OrgSelectorScreenProps {
  onSelectOrgId: (orgId: string) => void;
}

const OrgSelectorScreen: FC<OrgSelectorScreenProps> = ({ onSelectOrgId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { list: orgs } = useOrganizations();
  const [params] = useSearchParams();
  const errorDescription = useMemo(() => params.get('error'), [params]);

  useEffect(() => {
    if (!errorDescription) {
      return;
    }

    // TODO handle different error types according to
    // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
    console.error(errorDescription);
    enqueueSnackbar(
      <FormattedMessage
        id="org_selector_error"
        defaultMessage="Failed to login"
      />,
      {
        variant: 'error',
        key: 'login_error',
        preventDuplicate: true,
      },
    );
  }, [enqueueSnackbar, errorDescription]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3,
          width: '520px',
        }}
      >
        <Box>
          <MobilixLogo />
        </Box>
        <Typography variant="h3" align="left" width="100%">
          <FormattedMessage
            id="org_selector_heading"
            defaultMessage="Choose organization"
          />
        </Typography>
        <Typography variant="body1" align="left" width="100%">
          <FormattedMessage
            id="org_selector_help_text_1"
            defaultMessage="Choose the organization you wish to log in with. You may only login with organizations where you already have an account."
          />
        </Typography>
        <Typography variant="body1" align="left" width="100%">
          <FormattedMessage
            id="org_selector_help_text_2"
            defaultMessage="The chosen organization is saved for future logins. If you want to change organization use “Change organization” in the account settings."
          />
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '400px',
            gap: 3,
            mt: 4,
          }}
        >
          {(!orgs || orgs.length === 0) && (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          )}
          {orgs &&
            orgs.map((o) => (
              <Button
                fullWidth
                variant="contained"
                key={o.id}
                onClick={() => onSelectOrgId(o.organization_id)}
              >
                {o.name}
              </Button>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default OrgSelectorScreen;
