import React, { FC, ReactNode } from 'react';
import { ApiFeatureLicense } from '@allbin/mobilix-api-client';
import useUserStore from '../../store/user';

interface FeatureLicenseProps {
  has?: ApiFeatureLicense;
  fallback?: ReactNode;
  children: ReactNode;
}

const FeatureLicense: FC<FeatureLicenseProps> = ({
  children,
  fallback,
  has,
}) => {
  const { licenses } = useUserStore();

  if (has && licenses.includes(has)) {
    return <>{children}</>;
  }

  return fallback ? <>{fallback}</> : null;
};

export default FeatureLicense;
