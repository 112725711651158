import { WorkOrderToolbarFilter } from '../components/WorkOrder/WorkOrderOverviewToolbar';

interface LocalStorage {
  'EntityOverview.zoomCenter': {
    zoom?: number;
    center?: google.maps.LatLngLiteral;
  };
  'WorkOrderOverview.stateFilter': WorkOrderToolbarFilter;
  orgId: string;
}

function get(key: string): unknown {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch (error) {
    if (typeof item === 'string') {
      return item;
    } else {
      return null;
    }
  }
}

function getWithFallback<T extends keyof LocalStorage>(
  key: T,
  fallback: LocalStorage[T],
): LocalStorage[T] {
  return (get(key) as LocalStorage[T]) || fallback;
}

function getWithoutFallback<T extends keyof LocalStorage>(
  key: T,
): LocalStorage[T] | null {
  return (get(key) as LocalStorage[T]) || null;
}

function set<T extends keyof LocalStorage>(
  key: T,
  value: LocalStorage[T],
): void {
  localStorage.setItem(key, JSON.stringify(value));
}

interface UseLocalStorage {
  get: typeof getWithFallback;
  getWithoutFallback: typeof getWithoutFallback;
  set: typeof set;
}

export const useLocalStorage = (): UseLocalStorage => {
  return {
    get: getWithFallback,
    set,
    getWithoutFallback,
  };
};
