import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 15H15M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21ZM9 7H15H9ZM9 11H15H9Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'WorkOrder',
);

const WorkOrder: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default WorkOrder;
