export const tdTenants = [
  {
    id: 'region-sormland',
    organization_id: 'org_6VQOVdnHclkpg9HN',
    name: 'Region Sörmland',
  },
  {
    id: 'region-vastmanland',
    organization_id: 'org_bVOEbdluPxgmdYyK',
    name: 'Region Västmanland',
  },
];
