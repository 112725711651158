import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type EntityOverviewTab = 'map' | 'table';

interface EntityOverviewStore {
  tab: EntityOverviewTab;
  setTab: (tab: EntityOverviewTab) => void;
}

const entityOverviewStore = create<EntityOverviewStore>()(
  devtools(
    (set) => ({
      tab: 'table',
      setTab: (tab) => set({ tab }),
    }),
    { name: 'entityOverview-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default entityOverviewStore;
