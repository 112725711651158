import React, { useMemo, ReactNode, useCallback } from 'react';
import {
  Add,
  BarChart,
  Home,
  Map,
  Search,
  TableChart,
} from '@mui/icons-material';
import { defineMessages, useIntl } from 'react-intl';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { TabData } from '../components/Tabs';

type TabIds = 'home' | 'search' | 'add' | 'map' | 'table' | 'statistics';

const permanentTabIds: TabIds[] = ['home', 'search'];
const summaryTabIds: TabIds[] = ['map', 'table', 'statistics'];

const trafficTabTranslationMessages: {
  [key: string]: { defaultMessage: string; id: string };
} = defineMessages({
  map: {
    defaultMessage: 'Map',
    id: 'traffic_tabs_map',
  },
  table: {
    defaultMessage: 'Table',
    id: 'traffic_tabs_table',
  },
  statistics: {
    defaultMessage: 'Statistics',
    id: 'traffic_tabs_statistics',
  },
  search: {
    defaultMessage: 'Search',
    id: 'traffic_tabs_search',
  },
});

export const trafficTabsIconsByTabId: { [key in TabIds]: ReactNode } = {
  add: <Add />,
  home: <Home />,
  search: <Search />,
  map: <Map />,
  table: <TableChart />,
  statistics: <BarChart />,
};

interface TrafficTabsStore {
  currentTab: string;
  customTabs: TabData[];
  addCustomTab: (data: TabData) => void;
  replaceAtIndex: (index: number, data: TabData) => void;
  setCurrentTab: (id: string) => void;
  removeCustomTab: (id: string) => void;
}

const useTrafficTabsStore = create<TrafficTabsStore>()(
  devtools(
    (set, get) => ({
      currentTab: 'home',
      customTabs: [],
      removeCustomTab: (id: string) => {
        set({
          customTabs: get().customTabs.filter((t) => t.id != id),
        });
      },
      replaceAtIndex: (index, data) => {
        const copy = [...get().customTabs];
        copy.splice(index, 1, data);
        set({ customTabs: copy });
      },
      addCustomTab: (data) => set({ customTabs: [...get().customTabs, data] }),
      setCurrentTab: (id) => set({ currentTab: id }),
    }),
    { name: 'traffic-tabs-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

interface UseTrafficTabs {
  currentTab: string;
  addTab: (id: string, label: string, select: boolean) => void;
  setTab: (tabId: string) => void;
  customTabs: TabData[];
  summaryTabs: TabData[];
  permanentTabs: TabData[];
  renameTab: (id: string, newLabel: string, select: boolean) => void;
  removeTab: (id: string) => void;
}

export const useTrafficTabs = (): UseTrafficTabs => {
  const intl = useIntl();
  const {
    currentTab,
    customTabs,
    setCurrentTab,
    addCustomTab,
    replaceAtIndex,
    removeCustomTab,
  } = useTrafficTabsStore();

  /** Combined permanent and custom tabs. */
  const permanentTabs = useMemo<TabData[]>(() => {
    return permanentTabIds.map<TabData>((id) => {
      const label = trafficTabTranslationMessages[id]
        ? intl.formatMessage(trafficTabTranslationMessages[id])
        : '';
      return {
        id,
        label,
        startAdornment: trafficTabsIconsByTabId[id],
      };
    });
  }, [intl]);

  const summaryTabs = useMemo<TabData[]>(() => {
    return summaryTabIds.map((id) => {
      const label = trafficTabTranslationMessages[id]
        ? intl.formatMessage(trafficTabTranslationMessages[id])
        : '';
      return { id, label, startAdornment: trafficTabsIconsByTabId[id] };
    });
  }, [intl]);

  const addTab = useCallback<UseTrafficTabs['addTab']>(
    (id, label, select) => {
      addCustomTab({ id, label });
      select && setCurrentTab(id);
    },
    [addCustomTab, setCurrentTab],
  );
  const renameTab = useCallback<UseTrafficTabs['renameTab']>(
    (id, label, select) => {
      const index = customTabs.findIndex((t) => t.id === id);
      if (index > -1) {
        replaceAtIndex(index, { id, label });
      }
      select && setCurrentTab(id);
    },
    [customTabs, replaceAtIndex, setCurrentTab],
  );

  return {
    setTab: setCurrentTab,
    customTabs,
    currentTab,
    summaryTabs,
    permanentTabs,
    addTab,
    renameTab,
    removeTab: removeCustomTab,
  };
};
