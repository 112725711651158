import React, { FC } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ArrowBack } from '@mui/icons-material';
import { getPeriodicityPeriods } from '@allbin/mobilix-utils';
import EntityCheckInRow from './EntityCheckInRow';

interface CheckInPlanInspectionProps {
  plan: CheckInPlan;
  entitiesLookup: Record<string, Entity>;
  onBack: () => void;
  alternateColor?: string;
}

const CheckInPlanInspection: FC<CheckInPlanInspectionProps> = ({
  plan,
  entitiesLookup,
  onBack,
  alternateColor,
}) => {
  const periods = getPeriodicityPeriods(plan.periodicity, plan.duration);
  return (
    <>
      <DialogContent>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          sx={{
            alignItems: 'stretch',
            paddingTop: 3,
            ' > *': {
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              '&.odd': {
                backgroundColor: alternateColor ? alternateColor : 'unset',
              },
            },
          }}
        >
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              <FormattedMessage
                id="check_in_plan_management.previous_period"
                defaultMessage="Previous period"
              />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              <FormattedMessage
                id="check_in_plan_management.current_period"
                defaultMessage="Current period"
              />
            </Typography>
          </Grid>
          {plan.entities.map((entityId, i) => (
            <EntityCheckInRow
              key={entityId}
              entity={entitiesLookup[entityId]}
              periods={periods}
              even={i % 2 === 0}
            />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button startIcon={<ArrowBack />} onClick={() => onBack()}>
          <FormattedMessage
            id="check_in_plan_management.to_plan_details"
            defaultMessage="Back to check-in plan details"
          />
        </Button>
      </DialogActions>
    </>
  );
};

export default CheckInPlanInspection;
