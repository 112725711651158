import { ApiFeatureLicense } from '@allbin/mobilix-api-client';

export const tdAllLicensesGroup: ApiFeatureLicense[] = [
  'traffic',
  'rebus_import',
];

export const tdTrafficLicenseGroup: ApiFeatureLicense[] = ['traffic'];

export const tdRebusImportLicenseGroup: ApiFeatureLicense[] = ['rebus_import'];
