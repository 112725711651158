import React, { ReactNode, ReactElement, FC } from 'react';
import useUserStore from '../../store/user';
import { ApiPermission } from '@allbin/mobilix-api-client';

interface HasPermission {
  hasAny?: never;
  has: ApiPermission;
  oneOf?: never;
  noneOf?: never;
}

interface NoneOfPermission {
  hasAny?: never;
  noneOf: ApiPermission[];
  oneOf?: never;
  has?: never;
}

interface OneOfPermission {
  hasAny?: never;
  has?: never;
  oneOf: ApiPermission[];
  noneOf?: never;
}

interface HasAnyPermissions {
  hasAny: boolean;
  has?: never;
  oneOf?: never;
  noneOf?: never;
}

interface PermissionsBase {
  fallback?: ReactElement;
}

const Permissions: FC<
  {
    children: ReactNode;
  } & (HasAnyPermissions | HasPermission | OneOfPermission | NoneOfPermission) &
    PermissionsBase
> = ({ hasAny, has, oneOf, noneOf, children, fallback }) => {
  const permissions = useUserStore(({ permissions }) => permissions);

  if (typeof hasAny !== 'undefined' && hasAny !== permissions.length > 0) {
    return fallback || null;
  }

  if (has && !permissions.includes(has)) {
    return fallback || null;
  }

  if (oneOf && !oneOf.some((permission) => permissions.includes(permission))) {
    return fallback || null;
  }

  if (
    noneOf &&
    !noneOf.every((permission) => !permissions.includes(permission))
  ) {
    return fallback || null;
  }

  return <>{children}</>;
};

export default Permissions;
