import React, { FC, Suspense, useEffect } from 'react';
import { AxiosError } from 'axios';
import { defineMessage, defineMessages, useIntl } from 'react-intl';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { Routes as RouteDefs } from './hooks/useRoutes';
import CheckInPlanManagementDialog from './components/CheckInPlanManagement/CheckInPlanManagementDialog';
import EditWorkOrderDialog from './components/WorkOrder/EditWorkOrderDialog';
import FetchIndicator from './components/FetchIndicator';
import Guide from './components/Guide/Guide';
import SplashMessage from './components/auth/SplashMessage';
import Permissions from './components/auth/Permissions';
import RecurringWOPlanManagementDialog from './components/RecurringWorkOrderPlan/RecurringWOPlanManagementDialog';
import RequireLogin from './components/auth/RequireLogin';
import SchemaManagementDialog from './components/SchemaManagement';
import SearchResults from './components/SearchResults';
import TagManagementDialog from './components/Tags/TagManagementDialog/TagManagementDialog';
import TopBar from './components/TopBar/TopBar';
import UserProfile from './components/auth/UserProfile';
import WorkOrderInstructionManager from './components/WorkOrder/WorkOrderInstructionManager';
import FeatureLicenseProvider from './providers/FeatureLicenseProvider';
import MobilixLoadingScreen from './components/MobilixLoadingScreen';
import Callback from './Callback';

const EntityOverview = React.lazy(() => import('./views/EntityOverview'));
const EntityGroupDetails = React.lazy(
  () => import('./views/EntityGroupDetails'),
);
const WorkOrderOverview = React.lazy(() => import('./views/WorkOrderOverview'));
const WorkOrderDetails = React.lazy(() => import('./views/WorkOrderDetails'));
const UserManagementOverview = React.lazy(
  () => import('./views/UserManagementOverview'),
);

defineMessage({
  id: 'eq',
  defaultMessage: 'Equals',
});

const messages = defineMessages({
  splashNewUser: {
    id: 'splash.new_user_instructions',
    defaultMessage:
      "You are seeing this page because the organization that invited you has not yet assigned you any privileges in this system. Please contact them, and let them know that you've successfully signed up.",
  },
  splashNotAdmin: {
    id: 'splash.not_admin',
    defaultMessage:
      'This web application is only meant for administrators. If you are a contractor agent, use the mobile app instead.',
  },
});

const Router: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const intl = useIntl();

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        onError: (error) => {
          enqueueSnackbar(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ((error as AxiosError).response?.data as { message: string })
              .message || (error as Error).message,
            { variant: 'error' },
          );
        },
      },
      mutations: {
        onError: (error) => {
          enqueueSnackbar(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ((error as AxiosError).response?.data as { message: string })
              .message || (error as Error).message,
            { variant: 'error' },
          );
        },
      },
    });
  }, [enqueueSnackbar, queryClient]);

  return (
    <Routes>
      <Route path="/callback" element={<Callback />} />
      <Route
        path="/*"
        element={
          <RequireLogin>
            <FeatureLicenseProvider>
              <UserProfile>
                <Permissions
                  hasAny={true}
                  fallback={
                    <SplashMessage
                      message={intl.formatMessage(messages.splashNewUser)}
                    />
                  }
                >
                  <Permissions
                    oneOf={['role:admin', 'role:contractor-admin']}
                    fallback={
                      <SplashMessage
                        message={intl.formatMessage(messages.splashNotAdmin)}
                      />
                    }
                  >
                    <EditWorkOrderDialog />
                    <SearchResults />
                    <Guide />
                    <CheckInPlanManagementDialog />
                    <RecurringWOPlanManagementDialog />
                    <SchemaManagementDialog />
                    <WorkOrderInstructionManager />
                    <TagManagementDialog />
                    <div className="flex h-full flex-col overflow-hidden">
                      <TopBar />
                      <Suspense fallback={<MobilixLoadingScreen />}>
                        <Outlet />
                      </Suspense>
                    </div>
                    <FetchIndicator />
                  </Permissions>
                </Permissions>
              </UserProfile>
            </FeatureLicenseProvider>
          </RequireLogin>
        }
      >
        <Route
          path={RouteDefs.workOrderDetails.substring(1)}
          element={<WorkOrderDetails />}
        />
        <Route
          path={RouteDefs.stationDetails.substring(1)}
          element={<EntityGroupDetails />}
        />
        <Route
          path={RouteDefs.workOrderOverviewState.substring(1)}
          element={<WorkOrderOverview />}
        />
        <Route
          path={RouteDefs.workOrderOverview.substring(1)}
          element={<WorkOrderOverview />}
        />
        <Route
          path={RouteDefs.UserManagementOverview.substring(1)}
          element={<UserManagementOverview />}
        />
        <Route
          path={RouteDefs.stationOverview.substring(1)}
          element={<EntityOverview />}
        />
        <Route index element={<Navigate to={RouteDefs.stationOverview} />} />
      </Route>
    </Routes>
  );
};

export default Router;
