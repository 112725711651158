import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const Routes = {
  root: '/',
  callback: '/callback',
  workOrderOverviewState: '/workorders/:state',
  workOrderOverview: '/workorders',
  workOrderDetails: '/workorders/id/:id',
  stationOverview: '/stations',
  stationDetails: '/stations/:id',
  UserManagementOverview: '/usermanagement',
};

interface UseRoutes {
  goToRoot: () => void;
  goToWorkOrderOverview: () => void;
  goToWorkOrderOverviewState: (state: WorkOrder['state']) => void;
  goToWorkOrderDetails: (id: string) => void;
  goToStationDetails: (id: string) => void;
  goToUserManagementOverview: () => void;
}

export const useRoutes = (): UseRoutes => {
  const navigate = useNavigate();

  const goToRoot: UseRoutes['goToRoot'] = useCallback(() => {
    navigate(Routes.root);
  }, [navigate]);
  const goToWorkOrderOverview: UseRoutes['goToWorkOrderOverview'] = useCallback(
    () => navigate(Routes.workOrderOverview),
    [navigate],
  );
  const goToWorkOrderOverviewState: UseRoutes['goToWorkOrderOverviewState'] =
    useCallback(
      (state: WorkOrder['state'] = 'created') =>
        navigate(Routes.workOrderOverviewState.replace(':state', state)),
      [navigate],
    );
  const goToWorkOrderDetails: UseRoutes['goToWorkOrderDetails'] = useCallback(
    (id: string) => navigate(Routes.workOrderDetails.replace(':id', id)),
    [navigate],
  );
  const goToStationDetails: UseRoutes['goToStationDetails'] = useCallback(
    (id: string) => navigate(Routes.stationDetails.replace(':id', id)),
    [navigate],
  );
  const goToUserManagementOverview: UseRoutes['goToUserManagementOverview'] =
    useCallback(() => navigate(Routes.UserManagementOverview), [navigate]);
  return {
    goToRoot,
    goToWorkOrderOverview,
    goToWorkOrderDetails,
    goToStationDetails,
    goToWorkOrderOverviewState,
    goToUserManagementOverview,
  };
};
