import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonProps, Menu, MenuItem } from '@mui/material';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface Option {
  id: string;
  name?: string;
}

export type SelectMenuPropsRenderOption<T> = (
  option: T,
  onClick: () => void,
  value: T | null | undefined,
) => React.ReactNode;

export interface SelectMenuProps<T extends Option> {
  options: T[];
  /**
   * Should be either the exact option object which is selected or
   * the ID of the selected option.
   */
  value: T | null | undefined | string;
  renderValue: (value: T | null | undefined) => string;
  renderOption?: SelectMenuPropsRenderOption<T>;
  onChange?: (value: T) => void;
  /** External control of select menu. */
  open?: boolean;
  /** Callback when user closes the menu. Respects 'open' flag. */
  onClose?: () => void;
  sx?: ButtonProps['sx'];
  /** Close menu when option is clicked, defaults to true. */
  closeOnSelect?: boolean;
  disabled?: boolean;
  buttonProps?: Partial<ButtonProps>;
}

function SelectMenu<T extends Option>({
  options,
  value,
  open,
  renderValue,
  renderOption,
  onChange,
  onClose,
  sx,
  closeOnSelect,
  disabled,
  buttonProps,
}: SelectMenuProps<T>): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const internalValue = useMemo(() => {
    if (typeof value === 'string') {
      return options.find((o) => o.id === value);
    }
    return value;
  }, [options, value]);

  useEffect(() => {
    if (typeof open === 'boolean') {
      setIsOpen(open);
    }
  }, [open]);

  const show = useCallback(() => {
    if (typeof open !== 'boolean') {
      setIsOpen(true);
    }
  }, [open]);
  const hide = useCallback(() => {
    if (typeof open !== 'boolean') {
      setIsOpen(false);
    }
    onClose && onClose();
  }, [onClose, open]);
  const onClick = useCallback(
    (o: T) => {
      onChange && onChange(o);
      if (closeOnSelect === false) {
        return;
      }
      hide();
    },
    [closeOnSelect, hide, onChange],
  );

  const renderer = useCallback(
    (o: T): ReactNode => {
      if (renderOption) {
        return renderOption(
          o,
          () => {
            onClick(o);
          },
          internalValue,
        );
      }
      if (!o.name) {
        throw new Error(
          `Missing 'name' in option when no renderOption was provided.`,
        );
      }
      return (
        <MenuItem
          selected={internalValue === o}
          key={o.id}
          onClick={() => onClick(o)}
        >
          {o.name}
        </MenuItem>
      );
    },
    [onClick, renderOption, internalValue],
  );

  return (
    <>
      <Button
        sx={sx}
        variant="outlined"
        disabled={disabled}
        endIcon={<ArrowDropDown />}
        {...buttonProps}
        onClick={show}
        ref={buttonRef}
      >
        {renderValue(internalValue)}
      </Button>
      <Menu open={isOpen} onClose={hide} anchorEl={buttonRef.current}>
        {options.map(renderer)}
      </Menu>
    </>
  );
}

export default SelectMenu;
