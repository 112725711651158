import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { useApiClient } from './useApiClient';
import { ApiTag } from '@allbin/mobilix-api-client';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useCallback } from 'react';

export const reviveTag = (tag: ApiTag): WorkOrderTag => ({
  ...tag,
  editable: !tag.flags,
  meta: reviveMetadata(tag.meta),
});

const useListAndLookup = createListAndLookupStore(reviveTag, 'tags');

export const useTags = (): UseListAndLookupQuery<ApiTag, WorkOrderTag> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );
  const client = useApiClient();

  const query = useQuery<ApiTag[], Error>(
    ['tags', 'list'],
    () => client.tags.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseTagMutations {
  create: UseMutationResult<ApiTag, Error, WorkOrderTagRequest>;
  update: UseMutationResult<
    ApiTag,
    Error,
    { id: string; tag: WorkOrderTagRequest }
  >;
  remove: UseMutationResult<ApiTag, Error, string>;
}

export const useTagMutations = (): UseTagMutations => {
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const client = useApiClient();

  const create: UseTagMutations['create'] = useMutation(
    ['tags', 'create'],
    (tag) => client.tags.create(tag),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const update: UseTagMutations['update'] = useMutation(
    ['tags', 'update'],
    ({ id, tag }) => client.tags.update(id, tag),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseTagMutations['remove'] = useMutation(
    ['tags', 'delete'],
    (id) => client.tags.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return { create, update, remove };
};
