import React, { useMemo, useState, useRef } from 'react';
import { ReactSortable, SortableOptions } from 'react-sortablejs';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  Add,
  DragIndicator,
  PostAdd,
  Delete,
  VisibilityOff,
} from '@mui/icons-material';

import GroupAddPopover from './GroupAddPopover';

import useSchemaManagementDialogStore, {
  PropertyNode,
  GroupNode,
  TreeNode,
} from '../../store/schemaManagementDialog';

interface NodeProps {
  node: TreeNode;
  nodeIndex: number[];
  tree: TreeNode[];
  getTree: () => TreeNode[];
  setTree: (tree: TreeNode[]) => void;
  selectedTreeNode: PropertyNode | undefined;
  setSelectedTreeNode: (selectedTreeNode: PropertyNode | undefined) => void;
  setHasChanges: (changes: boolean) => void;
  sortableOptions: SortableOptions;
  groupsLookup: Record<string, ApiEntitySchemaGroup>;
  originalPropsLookup: Record<string, ApiEntitySchemaProp>;
  propsLookup: Record<string, ApiEntitySchemaProp>;
  extrasLookup: Record<string, EntitySchemaExtra>;
  onRemoveProperty: (id: string) => void;
  onRemoveGroup: (id: number) => void;
}

const Container: React.FC<Omit<NodeProps, 'node'> & { node: GroupNode }> = ({
  node,
  nodeIndex,
  tree,
  getTree,
  setTree,
  selectedTreeNode,
  setSelectedTreeNode,
  setHasChanges,
  sortableOptions,
  groupsLookup,
  originalPropsLookup,
  propsLookup,
  extrasLookup,
  onRemoveProperty,
  onRemoveGroup,
}) => (
  <ReactSortable
    id={node.id}
    key={node.id}
    list={node.children}
    setList={(currentList) => {
      const sourceList = getTree();

      const tempList = [...sourceList];
      const group = tempList.find((x) => x.id === node.id);
      if (group) {
        group.children = currentList;
      }

      setTree(tempList);
    }}
    {...sortableOptions}
  >
    {node.children.map((child, index) => (
      <TreeNodeWrapper
        key={child.id}
        node={child}
        nodeIndex={[...nodeIndex, index]}
        tree={tree}
        getTree={getTree}
        setTree={setTree}
        selectedTreeNode={selectedTreeNode}
        setSelectedTreeNode={setSelectedTreeNode}
        setHasChanges={setHasChanges}
        sortableOptions={sortableOptions}
        groupsLookup={groupsLookup}
        originalPropsLookup={originalPropsLookup}
        propsLookup={propsLookup}
        extrasLookup={extrasLookup}
        onRemoveProperty={onRemoveProperty}
        onRemoveGroup={onRemoveGroup}
      />
    ))}
  </ReactSortable>
);

const TreeNodeWrapper: React.FC<NodeProps> = ({
  node,
  nodeIndex,
  tree,
  getTree,
  setTree,
  selectedTreeNode,
  setSelectedTreeNode,
  setHasChanges,
  sortableOptions,
  groupsLookup,
  originalPropsLookup,
  propsLookup,
  extrasLookup,
  onRemoveProperty,
  onRemoveGroup,
}) =>
  node ? (
    node.type === 'group' ? (
      <Box id={node.id} p={1}>
        <Box
          p={1}
          sx={{
            border: '1px solid lightgray',
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          }}
        >
          <Stack direction="row" alignItems="center">
            <ListItemIcon sx={{ color: 'lightgray' }}>
              <DragIndicator />
            </ListItemIcon>
            <ListItemText primary={groupsLookup[node.id.split('.')[1]]?.name} />
            <Box display="flex" mr={4}>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="schema_management.group"
                    defaultMessage="Group"
                  />
                }
                sx={{ color: 'gray' }}
              />
            </Box>
            {node.children.length === 0 ? (
              <IconButton
                onClick={() =>
                  onRemoveGroup(parseInt(node.id.split('.')[1], 10))
                }
              >
                <Delete />
              </IconButton>
            ) : null}
          </Stack>
          <Box
            p={1}
            sx={{
              border: '1px dashed darkgray',
              borderRadius: '4px',
              backgroundColor: 'white',
            }}
          >
            <Container
              node={node}
              nodeIndex={nodeIndex}
              tree={tree}
              getTree={getTree}
              setTree={setTree}
              selectedTreeNode={selectedTreeNode}
              setSelectedTreeNode={setSelectedTreeNode}
              setHasChanges={setHasChanges}
              sortableOptions={sortableOptions}
              groupsLookup={groupsLookup}
              originalPropsLookup={originalPropsLookup}
              propsLookup={propsLookup}
              extrasLookup={extrasLookup}
              onRemoveProperty={onRemoveProperty}
              onRemoveGroup={onRemoveGroup}
            />
          </Box>
        </Box>
      </Box>
    ) : (
      <Box
        id={node.id}
        p={1}
        onClick={() => {
          setSelectedTreeNode(node);
        }}
      >
        <Box
          p={1}
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:
              node.id === selectedTreeNode?.id
                ? 'entity.main'
                : 'rgba(0, 0, 0 ,0)',
            borderRadius: '4px',
            backgroundColor:
              node.id === selectedTreeNode?.id
                ? 'background.dark'
                : 'background.default',
          }}
        >
          <Stack direction="row" alignItems="center">
            <ListItemIcon sx={{ color: 'lightgray' }}>
              <DragIndicator />
            </ListItemIcon>
            <ListItemText primary={propsLookup[node.id]?.name} />
            <ListItemIcon sx={{ color: 'lightgray' }}>
              {extrasLookup[node.id]?.hidden ? <VisibilityOff /> : undefined}
            </ListItemIcon>
            {!originalPropsLookup[node.id] ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveProperty(node.id);
                }}
              >
                <Delete />
              </IconButton>
            ) : null}
          </Stack>
        </Box>
      </Box>
    )
  ) : null;

export interface SchemaManagementPropertyListProps extends SortableOptions {
  onAddProperty: () => void;
  onAddGroup: (name: string) => void;
  onRemoveProperty: (id: string) => void;
  onRemoveGroup: (id: number) => void;
}

export const SchemaManagementPropertyList: React.FC<
  SchemaManagementPropertyListProps
> = ({ onAddProperty, onAddGroup, onRemoveProperty, onRemoveGroup }) => {
  const groupPopoverAnchor = useRef<HTMLButtonElement | null>(null);
  const [groupPopoverOpen, setGroupPopoverOpen] = useState(false);

  const {
    tree,
    getTree,
    setTree,
    setHasChanges,
    selectedTreeNode,
    setSelectedTreeNode,
    groupsLookup,
    originalPropsLookup,
    propsLookup,
    extrasLookup,
  } = useSchemaManagementDialogStore();

  const sortableOptions = useMemo(
    (): SortableOptions => ({
      animation: 150,
      swapThreshold: 0.65,
      fallbackOnBody: true,
      group: {
        name: 'shared',
        put: (to, from, dragEl) => {
          const drag_id = dragEl.id;
          const to_id = to.el.id;

          if (drag_id.includes('group') && to_id.includes('group')) {
            return false;
          }

          return true;
        },
      },
      onEnd: () => {
        setHasChanges(true);
      },
    }),
    [setHasChanges],
  );

  return (
    <Box pt={4}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" width="100%">
          <Box pl={4} flex={4}>
            <Typography variant="h5">
              <FormattedMessage
                id="schema_management_dialog.properties"
                defaultMessage="Properties"
              />
            </Typography>
          </Box>
          <Box pr={2} justifyContent="flex-end">
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  id="schema_management_dialog.create_new_group"
                  defaultMessage="Create new group"
                />
              }
            >
              <IconButton
                ref={groupPopoverAnchor}
                onClick={() => {
                  setGroupPopoverOpen(!groupPopoverOpen);
                }}
              >
                <PostAdd />
              </IconButton>
            </Tooltip>
            <GroupAddPopover
              anchorEl={groupPopoverAnchor.current || undefined}
              open={groupPopoverOpen}
              onCreate={onAddGroup}
              onClose={() => setGroupPopoverOpen(false)}
            />
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  id="schema_management_dialog.create_new_property"
                  defaultMessage="Create new property"
                />
              }
            >
              <IconButton onClick={onAddProperty}>
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        <Typography
          pl={4}
          pr={4}
          pt={2}
          pb={1}
          variant="body1"
          sx={{ color: 'gray' }}
        >
          <FormattedMessage
            id="schema_management_dialog.dnd_instruction"
            defaultMessage="Reorder the properties by dragging them around"
          />
        </Typography>
        <Box>
          <Box
            maxHeight="600px"
            sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
          >
            <ReactSortable
              id="rs-root"
              list={tree}
              setList={setTree}
              {...sortableOptions}
            >
              {tree.map((node, index) => (
                <TreeNodeWrapper
                  key={node.id}
                  node={node}
                  nodeIndex={[index]}
                  tree={tree}
                  getTree={getTree}
                  setTree={setTree}
                  selectedTreeNode={selectedTreeNode}
                  setSelectedTreeNode={setSelectedTreeNode}
                  setHasChanges={setHasChanges}
                  sortableOptions={sortableOptions}
                  groupsLookup={groupsLookup}
                  originalPropsLookup={originalPropsLookup}
                  propsLookup={propsLookup}
                  extrasLookup={extrasLookup}
                  onRemoveProperty={onRemoveProperty}
                  onRemoveGroup={onRemoveGroup}
                />
              ))}
            </ReactSortable>
            <Box id="bottom-scroll-target">&nbsp;</Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default SchemaManagementPropertyList;
