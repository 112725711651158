import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ColumnSet, TableData } from '../components/Table';

interface TableStore {
  columnSetsByGroup: {
    [colSetGroup: string]: ColumnSet[];
  };
  /** Sets all columnsets for a specific columnset group. */
  setColumnSets: (columnSets: ColumnSet[], colSetGroup: string) => void;
  removeColumnSetsGroup: (colSetGroup: string) => void;
  /** Set will update any existing table of the same id. */
  setTable: (table: TableData) => void;
  removeTable: (id: string) => void;
  tableById: {
    [id: string]: TableData;
  };
}

const useTableStore = create<TableStore>()(
  devtools(
    (set, get) => ({
      columnSetsByGroup: {},
      setColumnSets: (colSets, colSetGroup) => {
        const clone = { ...get().columnSetsByGroup };
        clone[colSetGroup] = colSets;
        set({
          columnSetsByGroup: clone,
        });
      },
      removeColumnSetsGroup: (colSetGroup) => {
        const clone = { ...get().columnSetsByGroup };
        if (!clone[colSetGroup]) {
          throw new Error(
            `Error removing column set group; group '${colSetGroup}' not found in store.`,
          );
        }
        delete clone[colSetGroup];
        set({
          columnSetsByGroup: clone,
        });
      },
      tableById: {},
      setTable: (table) => {
        if (table.columns.length < 1) {
          throw new Error(
            `Error setting table data; at least one column required.`,
          );
        }
        const clone = { ...get().tableById };
        clone[table.id] = table;
        set({
          tableById: clone,
        });
      },
      removeTable: (id) => {
        const clone = { ...get().tableById };
        if (!clone[id]) {
          throw new Error(
            `Error removing table; tableId '${id}' not found in store.`,
          );
        }
        delete clone[id];
        set({
          tableById: clone,
        });
      },
    }),
    { name: 'table-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useTableStore;
