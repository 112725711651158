import { useCallback } from 'react';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { useApiClient } from './useApiClient';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';

const reviveCheckInPlan: ReviverFn<ApiCheckInPlan, CheckInPlan> = (
  checkInPlan,
) => ({
  ...checkInPlan,
  meta: reviveMetadata(checkInPlan.meta),
});

const packCheckInPlan = (
  checkInPlan: Omit<CheckInPlan, 'meta'> | Omit<CheckInPlan, 'id' | 'meta'>,
): ApiCheckInPlanRequest => {
  return {
    duration: checkInPlan.duration,
    entities: checkInPlan.entities,
    periodicity: checkInPlan.periodicity,
    title: checkInPlan.title,
    route_plan: checkInPlan.route_plan,
    contractor_id: checkInPlan.contractor_id,
  };
};

const useListAndLookup = createListAndLookupStore(
  reviveCheckInPlan,
  'checkInPlan',
);

export const useCheckInPlans = (): UseListAndLookupQuery<
  ApiCheckInPlan,
  CheckInPlan
> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );
  const client = useApiClient();
  const query = useQuery<ApiCheckInPlan[], Error>(
    ['checkInPlans', 'list'],
    () => client.checkInPlans.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseCheckInPlanMutations {
  create: UseMutationResult<
    ApiCheckInPlan,
    Error,
    Omit<CheckInPlan, 'id' | 'meta'>
  >;
  update: UseMutationResult<ApiCheckInPlan, Error, Omit<CheckInPlan, 'meta'>>;
  remove: UseMutationResult<ApiCheckInPlan, Error, CheckInPlan['id']>;
}

export const useCheckInPlanMutations = (): UseCheckInPlanMutations => {
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const client = useApiClient();

  const create: UseCheckInPlanMutations['create'] = useMutation(
    ['checkInPlans', 'create'],
    (checkInPlan) => client.checkInPlans.create(packCheckInPlan(checkInPlan)),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const update: UseCheckInPlanMutations['update'] = useMutation(
    ['checkInPlans', 'update'],
    (checkInPlan) =>
      client.checkInPlans.update(checkInPlan.id, packCheckInPlan(checkInPlan)),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseCheckInPlanMutations['remove'] = useMutation(
    ['checkInPlans', 'remove'],
    (id) => client.checkInPlans.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return { create, remove, update };
};
