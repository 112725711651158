import React, { FC, useCallback, useMemo, useState } from 'react';
import { ArrowBack, Delete, Preview, Room, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useContractors } from '../../hooks/useContractors';
import PeriodicityControl from '../CheckInPlanManagement/PeriodicityControl';
import SelectMenu from '../SelectMenu/SelectMenu';
import { PlanValidityResults } from './RecurringWOPlanManagementDialog';
import Permissions from '../auth/Permissions';
import { useWorkOrderInstructions } from '../../hooks/useWorkOrderInstructions';
import TagSelector from '../Tags/TagSelector';

const messages = defineMessages({
  choose_contractor: {
    id: 'recurring_plan_dialog.choose_contractor',
    defaultMessage: 'Choose contractor (optional)',
  },
  workInstructions: {
    id: 'recurring_plan_dialog.work_instructions',
    defaultMessage: 'Enter work instructions here...',
  },
  choose_filter_set: {
    id: 'recurring_plan_dialog.choose_filter_set',
    defaultMessage: 'Choose filter set',
  },
});

interface EditRecurringWOPlanFormProps {
  formData: Partial<RecurringPlan>;
  validity: PlanValidityResults;
  onChange: (formData: Partial<RecurringPlan>) => void;
  isProcessing: boolean;
  onCancel: () => void;
  onSave: () => void;
  filterSets: FilterSet[];
  onRemove: () => void;
  filteredEntityNames: string[];
}

const EditRecurringWOPlanForm: FC<EditRecurringWOPlanFormProps> = ({
  formData,
  filterSets,
  validity,
  onSave,
  onCancel,
  isProcessing,
  onRemove,
  filteredEntityNames,
  onChange,
}) => {
  const { list: contractors } = useContractors();
  const { list: instructions } = useWorkOrderInstructions();
  const intl = useIntl();

  const [showEntityPreview, setShowEntityPreview] = useState<boolean>(false);
  const [templateAnchor, setTemplateAnchor] = useState<null | HTMLElement>(
    null,
  );

  const toggleEntityPreview = useCallback(() => {
    setShowEntityPreview(!showEntityPreview);
  }, [showEntityPreview]);

  const filterSetOptions = useMemo(() => {
    return filterSets
      .map(({ id, name }) => ({
        id,
        name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'));
  }, [filterSets]);

  const isValid = useMemo(() => Object.keys(validity).length === 0, [validity]);

  return (
    <>
      <Dialog open={showEntityPreview} onClose={toggleEntityPreview}>
        <DialogTitle>
          <FormattedMessage
            id="recurring_plan_dialog.filter_set_results_preview"
            defaultMessage="Filter set results preview"
          />
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Typography variant="overline" component="p" sx={{ marginLeft: 5 }}>
              <FormattedMessage
                id="recurring_plan_dialog.stops"
                defaultMessage="Stops"
              />
            </Typography>
            {formData.filterset_id && filteredEntityNames.length > 0 && (
              <Typography
                variant="subtitle1"
                color="GrayText"
                sx={{ marginLeft: 5 }}
              >
                <FormattedMessage
                  id="recurring_plan_dialog.stop_list_explanation"
                  defaultMessage="These are stops resolved by the chosen filter set right now. Execution of this rule later may result in different results!{br}For more information please see the guide."
                  values={{
                    br: <br />,
                  }}
                />
              </Typography>
            )}
            {formData.filterset_id && filteredEntityNames.length === 0 && (
              <Typography
                variant="subtitle1"
                color="GrayText"
                sx={{ marginLeft: 5 }}
              >
                <FormattedMessage
                  id="recurring_plan_dialog.stop_list_explanation_no_stops"
                  defaultMessage="Your chosen filter set does not currently resolve to any stops.{br}For more information please see the guide."
                  values={{
                    br: <br />,
                  }}
                />
              </Typography>
            )}
            {!formData.filterset_id && (
              <Typography
                variant="subtitle1"
                color="GrayText"
                sx={{ marginLeft: 5 }}
              >
                <FormattedMessage
                  id="recurring_plan_dialog.stop_list_explanation_no_filter_set"
                  defaultMessage="Please select a filter set to preview the stops that will be included in the plan."
                  values={{
                    br: <br />,
                  }}
                />
              </Typography>
            )}
            <List dense>
              {filteredEntityNames.map((entityName, i) => {
                return (
                  <ListItem key={entityName + i}>
                    <ListItemIcon>
                      <Room color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={entityName} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
      <DialogContent>
        <Box display="flex" flexDirection="row" gap={5} alignItems="stretch">
          <Box flexGrow={2} flexBasis={0} sx={{ height: '500px' }}>
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="check_in_plan_management.title_input_label"
                defaultMessage="Title"
              />
            </Typography>
            <TextField
              value={formData.title || ''}
              type="text"
              size="small"
              onChange={(ev) => onChange({ title: ev.target.value })}
              sx={{ marginBottom: 2 }}
            />
            <Permissions oneOf={['role:admin']}>
              <Typography variant="overline" component="p">
                <FormattedMessage
                  id="recurring_plan_dialog.contractors_input_label"
                  defaultMessage="Contractors (optional)"
                />
              </Typography>
              <TextField
                select
                size="small"
                SelectProps={{
                  multiple: true,
                  renderValue: (value) => {
                    return (value as string[])
                      .reduce<string[]>(
                        (acc, id) => [
                          ...acc,
                          contractors.find((c) => c.id === id)?.name || id,
                        ],
                        [],
                      )
                      .join(', ');
                  },
                  onChange: (event) => {
                    const ids = event.target.value as string[];
                    onChange({
                      contractors: ids.includes('') ? [] : ids,
                    });
                  },
                }}
                value={formData.contractors ? formData.contractors : []}
              >
                <MenuItem value="">
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="edit_workorder_dialog.no_contractor"
                        defaultMessage="No contractor"
                      />
                    }
                  />
                </MenuItem>
                {contractors.map((contractor) => (
                  <MenuItem key={contractor.id} value={contractor.id}>
                    <Checkbox
                      checked={
                        formData.contractors
                          ? formData.contractors.indexOf(contractor.id) > -1
                          : false
                      }
                    />
                    <ListItemText primary={contractor.name} />
                  </MenuItem>
                ))}
              </TextField>
            </Permissions>
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="recurring_plan_dialog.description_input_label"
                defaultMessage="Work instructions"
              />
            </Typography>
            <TextField
              value={formData.description}
              onChange={(ev) => onChange({ description: ev.target.value })}
              multiline
              rows={13}
              placeholder={intl.formatMessage(messages.workInstructions)}
            />
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                variant="outlined"
                onClick={() => onChange({ description: '' })}
              >
                <FormattedMessage
                  id="recurring_plan_dialog.clear_instructions"
                  defaultMessage="Clear"
                />
              </Button>
              <Button
                variant="contained"
                onClick={(e) => setTemplateAnchor(e.currentTarget)}
              >
                <FormattedMessage
                  id="recurring_plan_dialog.load_template"
                  defaultMessage="Load template"
                />
              </Button>
              <Menu
                open={!!templateAnchor}
                anchorEl={templateAnchor}
                onClose={() => setTemplateAnchor(null)}
              >
                {instructions.length === 0 && (
                  <MenuItem>
                    <ListItemText
                      primary={
                        <FormattedMessage
                          id="no_templates_created"
                          defaultMessage="No templates created"
                        />
                      }
                    />
                  </MenuItem>
                )}
                {instructions.map((instruction) => {
                  return (
                    <MenuItem
                      key={instruction.id}
                      onClick={() =>
                        onChange({
                          description: formData.description
                            ? formData.description +
                              `\n` +
                              instruction.texts.join(`\n`)
                            : instruction.texts.join(`\n`),
                          tags: Array.from(
                            new Set([
                              ...(formData.tags || []),
                              ...(instruction.tags || []),
                            ]),
                          ),
                        })
                      }
                    >
                      <ListItemText primary={instruction.title} />
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Box>
          <Box flexGrow={1} flexBasis={0}>
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="recurring_plan_dialog.valid_from"
                defaultMessage="Valid from"
              />
            </Typography>
            <Tooltip
              arrow
              placement="top"
              title={
                <FormattedMessage
                  id="recurring_plan_dialog.valid_from_input_tooltip"
                  defaultMessage="The date from which this rule will be active."
                />
              }
            >
              <DatePicker
                value={formData.valid_from || null}
                onChange={(date) =>
                  onChange({
                    valid_from: date || undefined,
                  })
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Tooltip>
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="recurring_plan_dialog.valid_to"
                defaultMessage="Valid until (optional)"
              />
            </Typography>
            <Tooltip
              arrow
              placement="top"
              title={
                <FormattedMessage
                  id="recurring_plan_dialog.valid_to_input_tooltip"
                  defaultMessage="The date at which this rule will be deactivated."
                />
              }
            >
              <DatePicker
                value={formData.valid_to || null}
                onChange={(date) =>
                  onChange({
                    valid_to: date || undefined,
                  })
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Tooltip>
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="recurring_plan_dialog.creation_offset_input_label"
                defaultMessage="Creation offset"
              />
            </Typography>

            <Tooltip
              arrow
              placement="top"
              title={
                <FormattedMessage
                  id="recurring_plan_dialog.creation_offset_input_tooltip"
                  defaultMessage="The number of days before occurrence the plan will be executed and work orders created."
                />
              }
            >
              <TextField
                value={formData.creation_offset || ''}
                type="number"
                size="small"
                onChange={(ev) => {
                  const v = ev.target.value
                    ? parseInt(ev.target.value, 10)
                    : undefined;
                  if (!v || !isFinite(v)) {
                    onChange({
                      creation_offset: undefined,
                    });
                    return;
                  }
                  onChange({
                    creation_offset: v,
                  });
                }}
                sx={{ marginBottom: 2 }}
              />
            </Tooltip>

            <PeriodicityControl
              onChange={(periodicity) => onChange({ periodicity })}
              periodicity={
                formData.periodicity || { type: 'yearly', occurrences: [] }
              }
            />
            <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
              <FormattedMessage
                id="recurring_plan_dialog.filter_set_input_label"
                defaultMessage="Filter set"
              />
            </Typography>
            <Box display="flex">
              <SelectMenu
                options={filterSetOptions}
                value={formData.filterset_id}
                sx={{ marginBottom: 2, flexGrow: 1 }}
                renderValue={(value) => {
                  if (!value) {
                    return intl.formatMessage(messages.choose_filter_set);
                  }
                  return value.name
                    ? value.name
                    : intl.formatMessage(messages.choose_filter_set);
                }}
                onChange={(v) => {
                  if (v.id !== formData.filterset_id) {
                    onChange({ filterset_id: v.id });
                  }
                }}
              />
              <IconButton onClick={toggleEntityPreview}>
                <Preview />
              </IconButton>
            </Box>
            <Typography variant="overline" component="p">
              <FormattedMessage
                id="recurring_plan_dialog.tags_input_label"
                defaultMessage="Tags"
              />
            </Typography>
            <TagSelector
              selectedTags={formData.tags || []}
              onChange={(tagIds) => onChange({ tags: tagIds })}
              mode="search"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          gap={2}
          flexDirection="row"
          width="100%"
          alignItems="center"
        >
          <Button
            startIcon={<ArrowBack />}
            disabled={isProcessing}
            onClick={onCancel}
          >
            <FormattedMessage
              id="recurring_plan_dialog.back_to_existing_plans"
              defaultMessage="Back to existing plans"
            />
          </Button>
          {formData.id ? (
            <Box flexGrow={1} display="flex" justifyContent="center">
              <Tooltip
                arrow
                placement="top"
                title={
                  <FormattedMessage
                    id="recurring_plan_dialog.remove_tooltip"
                    defaultMessage="Deletes the selected recurring work order plan."
                  />
                }
              >
                <LoadingButton
                  variant="outlined"
                  color="error"
                  loading={isProcessing}
                  onClick={onRemove}
                  endIcon={<Delete />}
                >
                  <FormattedMessage id="remove" defaultMessage="Remove" />
                </LoadingButton>
              </Tooltip>
            </Box>
          ) : (
            <Box flexGrow={1} />
          )}

          <Tooltip
            arrow
            placement="top"
            title={
              isValid ? (
                <FormattedMessage
                  id="recurring_plan_dialog.save_tooltip"
                  defaultMessage="Save recurring work order plan"
                />
              ) : (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <FormattedMessage
                    id="recurring_plan_dialog.save_disabled_tooltip"
                    defaultMessage="Save recurring work order plan. Disabled because some details are missing or incorrect:"
                  />
                  {validity.creation_offset &&
                    `\n- ` + validity.creation_offset}
                  {validity.description && `\n- ` + validity.description}
                  {validity.title && `\n- ` + validity.title}
                  {validity.valid_from && `\n- ` + validity.valid_from}
                  {validity.periodicity && `\n- ` + validity.periodicity}
                  {validity.filterset_id && `\n- ` + validity.filterset_id}
                </div>
              )
            }
          >
            <div>
              <LoadingButton
                variant="contained"
                loading={isProcessing}
                disabled={!isValid}
                onClick={onSave}
                startIcon={<Save />}
              >
                {formData.id ? (
                  <FormattedMessage
                    id="recurring_plan_dialog.save_recurring_work_order_plan"
                    defaultMessage="Save recurring work order plan"
                  />
                ) : (
                  <FormattedMessage
                    id="recurring_plan_dialog.create_recurring_work_order_plan"
                    defaultMessage="Create recurring work order plan"
                  />
                )}
              </LoadingButton>
            </div>
          </Tooltip>
        </Box>
      </DialogActions>
    </>
  );
};

export default EditRecurringWOPlanForm;
