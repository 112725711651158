import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Help, Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';

import useCheckinManagementDialogStore from '../../store/checkInManagementDialog';
import useGuideStore from '../../store/guide';
import {
  useCheckInPlanMutations,
  useCheckInPlans,
} from '../../hooks/useCheckInPlans';
import { useEntities } from '../../hooks/useEntities';
import EditCheckInPlanForm from './EditCheckInPlanForm';
import CheckInPlanList from './CheckInPlanList';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import useUserStore from '../../store/user';
import CheckInPlanInspection from './CheckInPlanInspection';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';
import { useUserLookup } from '../../hooks/useUserLookup';

const messages = defineMessages({
  invalid_title: {
    id: 'check_in_plan_management.invalid_title',
    defaultMessage: 'Invalid title. Must be at least one character long.',
  },
  invalid_entities: {
    id: 'check_in_plan_management.invalid_entities',
    defaultMessage:
      'Invalid stop selection, at least one stop must be added to the plan.',
  },
  invalid_duration: {
    id: 'check_in_plan_management.invalid_duration',
    defaultMessage:
      'Invalid duration, the plan needs to have a duration of minimum one day.',
  },
  invalid_periodicity: {
    id: 'check_in_plan_management.invalid_periodicity',
    defaultMessage:
      'Invalid periodicity, at least one ocurrence must be added to the plan.',
  },
  invalid_contractor: {
    id: 'check_in_plan_management.invalid_contractor',
    defaultMessage: 'Invalid contractor, a contractor must be chosen.',
  },
});

const resolveCheckInPlan = (
  checkInPlan: Partial<CheckInPlan>,
  entitiesLookup: Record<string, Entity>,
  user_contractor_id: string | undefined,
  isAdmin: boolean,
  intl: IntlShape,
): CheckInPlanResolved => {
  const entities = checkInPlan.entities
    ? checkInPlan.entities
        .map((entityId) => entitiesLookup[entityId])
        .filter((e) => e)
    : [];
  const route_plan_entities = checkInPlan.route_plan
    ? (checkInPlan.route_plan
        .map((entityId) => {
          const entity = entitiesLookup[entityId];
          if (!entity) {
            return undefined;
          }
          const location = entity.properties['meta.location'] as [
            number,
            number,
          ];
          if (!location) {
            return undefined;
          }
          return entity;
        })
        .filter((e) => e) as Entity[])
    : checkInPlan.entities
    ? (checkInPlan.entities
        .map((entityId) => {
          const entity = entitiesLookup[entityId];
          if (!entity) {
            return undefined;
          }
          const location = entity.properties['meta.location'] as [
            number,
            number,
          ];
          if (!location) {
            return undefined;
          }
          return entity;
        })
        .filter((e) => e) as Entity[])
    : [];
  const route_plan_coords = route_plan_entities
    .map((entity) => {
      const location = entity.properties['meta.location'] as [number, number];
      if (!location) {
        return undefined;
      }
      return {
        lat: location[1],
        lng: location[0],
      };
    })
    .filter((location) => location) as { lat: number; lng: number }[];

  const plan: CheckInPlanResolved = {
    id: checkInPlan.id,
    duration:
      typeof checkInPlan.duration === 'number' ? checkInPlan.duration : 0,
    periodicity: checkInPlan.periodicity || {
      type: 'yearly',
      occurrences: [],
    },
    title: checkInPlan.title || '',
    entities,
    entity_ids: checkInPlan.entities || [],
    route_plan: checkInPlan.route_plan,
    route_plan_entities,
    route_plan_coords,
    invalid_inputs: {},
    contractor_id: checkInPlan.contractor_id || user_contractor_id || '',
  };

  if (plan.title.trim().length === 0) {
    plan.invalid_inputs.title = intl.formatMessage(messages.invalid_title);
  }
  if (plan.entities.length === 0) {
    plan.invalid_inputs.entities = intl.formatMessage(
      messages.invalid_entities,
    );
  }
  if (plan.duration < 1) {
    plan.invalid_inputs.duration = intl.formatMessage(
      messages.invalid_duration,
    );
  }
  if (plan.periodicity.occurrences.length === 0) {
    plan.invalid_inputs.periodicity = intl.formatMessage(
      messages.invalid_periodicity,
    );
  }
  if (isAdmin && !plan.contractor_id) {
    plan.invalid_inputs.contractor = intl.formatMessage(
      messages.invalid_contractor,
    );
  }

  return plan;
};

const CheckInPlanManagementDialog: FC = () => {
  const intl = useIntl();
  const {
    show,
    close: closeDialog,
    selectedCheckInPlanId,
    setSelectedCheckInPlanId,
    newCheckInPlanData,
    setNewCheckInPlanData,
    setInspectCheckInPlanId,
    inspectCheckInPlanId,
    clear: clearDialog,
  } = useCheckinManagementDialogStore();
  const { lookup: checkInPlansLookup, list: checkInPlans } = useCheckInPlans();
  const {
    create: {
      mutateAsync: createCheckInPlan,
      isLoading: createCheckInPlanInProgress,
    },
    update: {
      mutateAsync: updateCheckInPlan,
      isLoading: updateCheckInPlanInProgress,
    },
    remove: {
      mutateAsync: removeCheckInPlan,
      isLoading: removeCheckInPlanInProgress,
    },
  } = useCheckInPlanMutations();
  const { lookup: entitiesLookup, list: entities } = useEntities();
  const { openGuide } = useGuideStore();
  const { user: auth0User, permissions } = useUserStore((user) => user);
  const { userLookup } = useUserLookup();

  const [showCancelFormDialog, setShowCancelFormDialog] =
    useState<boolean>(false);
  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [showUpdateConfirm, setUpdateConfirm] = useState<boolean>(false);
  const [checkInPlanChanged, setCheckInPlanChanged] = useState<boolean>(false);

  const user_contractor_id = useMemo(() => {
    const user =
      auth0User && auth0User.sub ? userLookup(auth0User.sub) : undefined;
    return user && user.contractors ? user.contractors[0]?.id : undefined;
  }, [auth0User, userLookup]);

  const isAdmin = useMemo(() => {
    return permissions.includes('role:admin');
  }, [permissions]);

  const onFormChange = useCallback(
    (updated: Partial<CheckInPlan>) => {
      if (!checkInPlanChanged) {
        setCheckInPlanChanged(true);
      }
      setNewCheckInPlanData(updated);
    },
    [checkInPlanChanged, setNewCheckInPlanData],
  );

  const onClose = useCallback(() => {
    setShowCloseDialog(false);
    setCheckInPlanChanged(false);
    closeDialog(true);
  }, [closeDialog]);

  const onTryClose = useCallback(() => {
    if (checkInPlanChanged) {
      setShowCloseDialog(true);
      return;
    }
    onClose();
  }, [checkInPlanChanged, onClose]);

  const onSelectPlan = useCallback(
    (id: string) => {
      setSelectedCheckInPlanId(id);
    },
    [setSelectedCheckInPlanId],
  );

  const onNewPlan = useCallback(() => {
    setNewCheckInPlanData({});
    setSelectedCheckInPlanId(undefined);
  }, [setNewCheckInPlanData, setSelectedCheckInPlanId]);

  const selectedCheckInPlan = useMemo<null | CheckInPlan>(() => {
    if (!selectedCheckInPlanId) {
      return null;
    }
    return checkInPlansLookup[selectedCheckInPlanId];
  }, [checkInPlansLookup, selectedCheckInPlanId]);

  useEffect(() => {
    if (
      (newCheckInPlanData &&
        selectedCheckInPlan &&
        newCheckInPlanData.id !== selectedCheckInPlan.id) ||
      (!newCheckInPlanData && selectedCheckInPlan)
    ) {
      //The selected check-in plan has changed, update data in form store.

      setNewCheckInPlanData({
        entities: selectedCheckInPlan.entities,
        periodicity: selectedCheckInPlan.periodicity,
        route_plan:
          selectedCheckInPlan.route_plan &&
          selectedCheckInPlan.route_plan.length > 0
            ? selectedCheckInPlan.route_plan
            : selectedCheckInPlan.entities,
        title: selectedCheckInPlan.title,
        duration: selectedCheckInPlan.duration,
        id: selectedCheckInPlan.id,
        contractor_id: selectedCheckInPlan.contractor_id,
      });
    }
  }, [newCheckInPlanData, selectedCheckInPlan, setNewCheckInPlanData]);

  const onRemovePlan = useCallback(() => {
    if (!selectedCheckInPlan || !selectedCheckInPlan.id) {
      return;
    }
    void removeCheckInPlan(selectedCheckInPlan.id).then(() => {
      clearDialog();
      setShowDeleteConfirm(false);
    });
  }, [clearDialog, removeCheckInPlan, selectedCheckInPlan]);

  const onCancelForm = useCallback(() => {
    setCheckInPlanChanged(false);
    setShowCancelFormDialog(false);
    clearDialog();
  }, [clearDialog]);

  const onTryCancelForm = useCallback(() => {
    if (checkInPlanChanged) {
      setShowCancelFormDialog(true);
      return;
    }
    onCancelForm();
  }, [checkInPlanChanged, onCancelForm]);

  const formData = useMemo<null | CheckInPlanResolved>(() => {
    if (newCheckInPlanData) {
      return resolveCheckInPlan(
        newCheckInPlanData,
        entitiesLookup,
        user_contractor_id,
        isAdmin,
        intl,
      );
    }
    return null;
  }, [newCheckInPlanData, user_contractor_id, entitiesLookup, isAdmin, intl]);

  const onUpdateConfirm = useCallback(
    (clearRoutePlan?: boolean) => {
      if (!formData || Object.keys(formData.invalid_inputs).length > 0) {
        return;
      }
      const {
        entities,
        duration,
        periodicity,
        id,
        route_plan,
        title,
        contractor_id,
      } = formData;

      const routePlan = clearRoutePlan ? undefined : route_plan;
      if (!id) {
        return;
      }

      void updateCheckInPlan({
        entities: entities.map((e) => e.id),
        duration,
        title,
        route_plan: routePlan,
        periodicity,
        id,
        contractor_id,
      })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          clearDialog();
          setUpdateConfirm(false);
        });
    },
    [clearDialog, formData, updateCheckInPlan],
  );

  const onSave = useCallback(
    (clearRoutePlan?: boolean) => {
      if (!formData || Object.keys(formData.invalid_inputs).length > 0) {
        return;
      }
      const {
        entities,
        duration,
        periodicity,
        id,
        route_plan,
        title,
        contractor_id,
      } = formData;
      const routePlan = clearRoutePlan ? undefined : route_plan;

      if (id) {
        setUpdateConfirm(true);
        return;
      }
      void createCheckInPlan({
        entities: entities.map((e) => e.id),
        duration,
        title,
        route_plan: routePlan,
        periodicity,
        contractor_id,
      }).then(() => {
        clearDialog();
        setShowDeleteConfirm(false);
      });
    },
    [clearDialog, createCheckInPlan, formData],
  );

  const isProcessing =
    removeCheckInPlanInProgress &&
    updateCheckInPlanInProgress &&
    createCheckInPlanInProgress;

  return (
    <Dialog open={show} maxWidth="lg" fullWidth onClose={onTryClose}>
      <ConfirmDialog
        open={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={onRemovePlan}
      >
        {selectedCheckInPlan ? (
          <FormattedMessage
            id="check_in_plan_management.delete_confirm_message"
            defaultMessage="This will permanently remove the `{planTitle}` check-in plan."
            values={{ planTitle: selectedCheckInPlan.title }}
          />
        ) : undefined}
      </ConfirmDialog>

      <ConfirmDialog
        open={showUpdateConfirm}
        onCancel={() => setUpdateConfirm(false)}
        onConfirm={onUpdateConfirm}
      >
        {selectedCheckInPlan ? (
          <Typography variant="h5" align="center">
            <FormattedMessage
              id="check_in_plan_management.edit_plan_disclaimer"
              defaultMessage="Existing plan `{planTitle}` has been edited."
              values={{ planTitle: selectedCheckInPlan.title }}
            />
          </Typography>
        ) : undefined}
        <Typography variant="body1">
          <p></p>
          <FormattedMessage
            id="check_in_plan_management.edit_plan_warning"
            defaultMessage="Changes are retroactive, so any previous check-in may no longer display as ‘done’. Reports generated with the selected plan will no longer show the same historical data."
          />
        </Typography>
        <Typography variant="subtitle1">
          <p></p>
          <FormattedMessage
            id="check_in_plan_management.edit_plan_confirm"
            defaultMessage="Do you wish to save your changes to the check-in plan?"
          />
        </Typography>
      </ConfirmDialog>

      <UnsavedChangesDialog
        open={showCancelFormDialog || showCloseDialog}
        onCancel={() =>
          showCancelFormDialog
            ? setShowCancelFormDialog(false)
            : setShowCloseDialog(false)
        }
        onOk={() => (showCancelFormDialog ? onCancelForm() : onClose())}
      />

      <DialogTitle>
        {inspectCheckInPlanId && checkInPlansLookup[inspectCheckInPlanId] && (
          <FormattedMessage
            id="check_in_plan_management.inspect_plan"
            defaultMessage="Inspect check-in plan: {planTitle}"
            values={{
              planTitle: checkInPlansLookup[inspectCheckInPlanId].title,
            }}
          />
        )}
        {!formData &&
          !selectedCheckInPlan &&
          !checkInPlansLookup[inspectCheckInPlanId as string] && (
            <FormattedMessage
              id="check_in_plan_management.title"
              defaultMessage="Check-in plan management"
            />
          )}
        {formData && selectedCheckInPlan && (
          <FormattedMessage
            id="check_in_plan_management.edit_plan"
            defaultMessage="Edit check-in plan: {planTitle}"
            values={{
              planTitle: selectedCheckInPlan.title,
            }}
          />
        )}
        {formData && !selectedCheckInPlan && (
          <FormattedMessage
            id="check_in_plan_management.new_check_in_plan_title"
            defaultMessage="Create new check-in plan"
          />
        )}
        <div>
          <IconButton
            color="inherit"
            onClick={() => openGuide('CheckInPlanManagementDialog')}
          >
            <Help />
          </IconButton>
          <IconButton color="inherit" onClick={onTryClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      {!selectedCheckInPlan &&
        !formData &&
        !checkInPlansLookup[inspectCheckInPlanId as string] && (
          <CheckInPlanList
            onNewPlan={onNewPlan}
            plans={checkInPlans}
            isProcessing={isProcessing}
            onClose={onTryClose}
            onView={setInspectCheckInPlanId}
            onSelect={onSelectPlan}
            selectedPlanId={selectedCheckInPlanId}
          />
        )}
      {inspectCheckInPlanId && checkInPlansLookup[inspectCheckInPlanId] && (
        <CheckInPlanInspection
          plan={checkInPlansLookup[inspectCheckInPlanId]}
          onBack={() => setInspectCheckInPlanId(undefined)}
          entitiesLookup={entitiesLookup}
          alternateColor="#eee"
        />
      )}
      {formData && (
        <EditCheckInPlanForm
          isProcessing={isProcessing}
          resolvedPlan={formData}
          entities={{ list: entities, lookup: entitiesLookup }}
          onSave={onSave}
          onBack={onTryCancelForm}
          onChange={(p) => onFormChange(p)}
          onRemovePlan={
            selectedCheckInPlan ? () => setShowDeleteConfirm(true) : undefined
          }
        />
      )}
    </Dialog>
  );
};

export default CheckInPlanManagementDialog;
