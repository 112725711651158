import { defineMessages } from 'react-intl';

export const translations = defineMessages<FilterCondition['operator']>({
  eq: {
    id: 'filter.condition.eq',
    defaultMessage: 'Equal to',
  },
  neq: {
    id: 'filter.condition.neq',
    defaultMessage: 'Not equal to',
  },
  matches: {
    id: 'filter.condition.matches',
    defaultMessage: 'Matches',
  },
  not_matches: {
    id: 'filter.condition.not_matches',
    defaultMessage: 'Does not match',
  },
  known: {
    id: 'filter.condition.known',
    defaultMessage: 'Known',
  },
  unknown: {
    id: 'filter.condition.unknown',
    defaultMessage: 'Unknown',
  },
  true: {
    id: 'filter.condition.true',
    defaultMessage: 'True',
  },
  false: {
    id: 'filter.condition.false',
    defaultMessage: 'False',
  },
  none_of: {
    id: 'filter.condition.none_of',
    defaultMessage: 'None of',
  },
  any_of: {
    id: 'filter.condition.any_of',
    defaultMessage: 'Any of',
  },
  between: {
    id: 'filter.condition.between',
    defaultMessage: 'Between',
  },
  not_between: {
    id: 'filter.condition.not_between',
    defaultMessage: 'Not between',
  },
  gt: {
    id: 'filter.condition.gt',
    defaultMessage: 'Greater than',
  },
  gte: {
    id: 'filter.condition.gte',
    defaultMessage: 'Greater or equal to',
  },
  lt: {
    id: 'filter.condition.lt',
    defaultMessage: 'Less than',
  },
  lte: {
    id: 'filter.condition.lte',
    defaultMessage: 'Less or equal to',
  },
  before: {
    id: 'filter.condition.before',
    defaultMessage: 'Before',
  },
  after: {
    id: 'filter.condition.after',
    defaultMessage: 'After',
  },
  all_of: {
    id: 'filter.condition.all_of',
    defaultMessage: 'All of',
  },
});
