import {
  Box,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react';
import { ColorProps } from '../../providers/ThemeProvider';

export interface InputContainerProps {
  label?: ReactNode;
  sx?: SxProps;
  tooltip?: string | ReactElement<any, string | JSXElementConstructor<any>>;
  palette?: ColorProps;
}

/**
 * The input container is used to add a label and tooltip to an input.
 * NOTE: This is already added to custom inputs such as Dropdown!
 */
const InputContainer: FC<InputContainerProps & { children: ReactNode }> = ({
  label,
  sx,
  children,
  tooltip,
  palette,
}) => {
  const theme = useTheme();

  if (!label) {
    return <>{children}</>;
  }

  const colors = palette || theme.palette.dark;
  return (
    <Tooltip enterDelay={500} title={tooltip || ''} placement="top" arrow>
      <Box
        sx={{
          minWidth: 0,
          color: colors.dark,
          ...sx,
        }}
      >
        <Stack direction="column">
          <Typography variant="overline" mb={1}>
            {label}
          </Typography>
          {children}
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default InputContainer;
