import { useAuth0 } from '@auth0/auth0-react';
import MobilixLoadingScreen from './components/MobilixLoadingScreen';
import { useRoutes } from './hooks/useRoutes';
import { useEffect } from 'react';

const Callback: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const route = useRoutes();

  useEffect(() => {
    if (isAuthenticated) {
      route.goToRoot();
    }
  }, [isAuthenticated, route]);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <MobilixLoadingScreen />
    </div>
  );
};

export default Callback;
