import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useApiClient } from './useApiClient';
import {
  createListAndLookupStore,
  reviveCheckIn,
  reviveMetadata,
} from '../helpers/revivers';

import { useCallback } from 'react';
import { ApiEntity, ApiEntityEvent } from '@allbin/mobilix-api-client';
import { ApiEntityRequest } from '@allbin/mobilix-api-client/lib/api';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { defineMessages, useIntl } from 'react-intl';
import { useEntitySchema } from './useEntitySchema';
import { DateTime } from 'luxon';

const messages = defineMessages({
  conflicts: {
    id: 'use_entities.conflicts',
    defaultMessage: 'Conflict when updating. Please try again.',
  },
});

const sortCheckIns = (a: CheckIn, b: CheckIn): number =>
  a.timestamp > b.timestamp ? 1 : -1;

export const reviveEntity: ReviverFn<ApiEntity, Entity> = (
  apiEntity,
  schema,
) => {
  if (!apiEntity.checkins) {
    throw new Error('Missing checkins in entity: ' + apiEntity.id);
  }
  const stop_letter = apiEntity.source_id.split('-')[1];
  const entity: Entity = {
    ...apiEntity,
    properties: {
      ...unserializeEntityProps(schema as EntitySchema, {
        ...apiEntity.properties,
        ...apiEntity.derived_properties,
      }),
      'meta.group_id':
        (apiEntity.properties['meta.group_id'] as string) ||
        (apiEntity.properties['meta.id'] as string).split('-')[0],
    },
    meta: reviveMetadata(apiEntity.meta),
    stop_letter,
    full_name: `${apiEntity.properties['meta.name'] as string} ${stop_letter}`,
    checkins: {
      admin: apiEntity.checkins.admin
        .map((c) => reviveCheckIn(c))
        .sort(sortCheckIns),
      contractor: apiEntity.checkins.contractor
        .map((c) => reviveCheckIn(c))
        .sort(sortCheckIns),
    },
  };
  return entity;
};

export const serializeEntityProps = (
  schema: EntitySchema,
  entityProps: Entity['properties'],
): ApiEntity['properties'] => {
  const apiEntityProps: ApiEntity['properties'] = Object.entries(
    entityProps,
  ).reduce<ApiEntity['properties']>((props, [k, v]) => {
    if (schema.definition.propertiesLookup[k]?.type === 'date') {
      props[k] = (v as DateTime).toISO();
    } else {
      props[k] = v as string | number | boolean | string[] | number[];
    }
    return props;
  }, {});
  return apiEntityProps;
};

export const unserializeEntityProps = (
  schema: EntitySchema,
  apiEntityProps: ApiEntity['properties'],
): EntityProps => {
  const entityProps: EntityProps = Object.entries(
    apiEntityProps,
  ).reduce<EntityProps>((props, [k, v]) => {
    if (schema.definition.propertiesLookup[k]?.type === 'date') {
      props[k] = DateTime.fromISO(v as string);
    } else {
      props[k] = v;
    }
    return props;
  }, {});
  return entityProps;
};

export const packEntityRequest: SerializerFn<
  EntityRequest,
  ApiEntityRequest
> = (entity, schema): ApiEntityRequest => {
  const modifiable_props = Object.entries(
    serializeEntityProps(schema as EntitySchema, entity.properties),
  ).reduce<ApiEntity['properties']>((acc, [key, value]) => {
    if (
      key.startsWith('derived') ||
      !schema?.definition.propertiesLookup[key]
    ) {
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {});
  return {
    entity_type_id: entity.entity_type_id,
    properties: modifiable_props,
    source_id: entity.source_id,
    changeset_head: entity.changeset_head,
  };
};

export const reviveEntityEvent: ReviverFn<ApiEntityEvent, EntityEvent> = (
  entityEvent,
) => ({
  ...entityEvent,
  meta: reviveMetadata(entityEvent.meta),
  sourceType: 'entity',
});

const useEntitiesListAndLookup = createListAndLookupStore(
  reviveEntity,
  'entities',
);

export const useEntities = (
  entityTypeId?: string,
): UseQueryResult<ApiEntity[], Error> & ListAndLookup<Entity> => {
  const { data: schema } = useEntitySchema();
  const { set, list, lookup } = useEntitiesListAndLookup(
    useCallback((props) => props, []),
  );

  const apiClient = useApiClient();
  const query = useQuery<ApiEntity[], Error>(
    ['entities', 'list', entityTypeId],
    ({ queryKey }) => apiClient.entities.list(queryKey[2] as string),
    {
      onSuccess: (data) => {
        set(data, schema);
      },
    },
  );

  return { ...query, list, lookup };
};

export const useEntitiesById = (
  ids?: string[],
): UseQueryResult<Entity[], Error> => {
  const apiClient = useApiClient();
  const { data: schema } = useEntitySchema();

  return useQuery<Entity[], Error>(['entities', 'list', ids], ({ queryKey }) =>
    ids
      ? apiClient.entities
          .getMany(queryKey[2] as string[])
          .then((data) => data.map((i) => reviveEntity(i, schema)))
      : Promise.resolve([]),
  );
};

interface CreateEventParams {
  entityId: Entity['id'];
  event: EntityEventClientRequest;
  files?: File[];
}

interface SetPhotoParams {
  entityId: Entity['id'];
  entity: EntityRequest;
  propKey: string;
  file: File;
}

interface UpdateEntityProps {
  entities: Entity[];
}

export interface UseEntityMutations {
  update: UseMutationResult<
    Record<string, ApiEntity>,
    Error,
    UpdateEntityProps
  >;
  setPhoto: UseMutationResult<ApiEntity, Error, SetPhotoParams>;
  createEvent: UseMutationResult<ApiEntityEvent, Error, CreateEventParams>;
}

export const useEntityMutations = (): UseEntityMutations => {
  const { data: schema } = useEntitySchema();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();
  const { merge } = useEntitiesListAndLookup(useCallback((props) => props, []));
  const { merge: eventsMerge } = useEntityEventsLNL(
    useCallback((props) => props, []),
  );
  const queryClient = useQueryClient();
  const intl = useIntl();

  const update: UseEntityMutations['update'] = useMutation(
    ['entities', 'update'],
    ({ entities }) => {
      return client.entities.updateMany(
        entities.reduce<Record<string, ApiEntityRequest>>((acc, entity) => {
          acc[entity.id] = packEntityRequest(entity, schema as EntitySchema);
          return acc;
        }, {}),
      );
    },
    {
      onSuccess: (data) => {
        merge(Object.values(data), schema);
        return Promise.all([
          queryClient.invalidateQueries(['entities', 'listEvents']),
          queryClient.invalidateQueries(['entities', 'listChangeSets']),
        ]);
      },
      onError: (error: AxiosError) => {
        void queryClient.invalidateQueries([
          ['entities', 'list'],
          ['entities', 'listEvents'],
        ]);
        if (error.response?.status === 409) {
          enqueueSnackbar(intl.formatMessage(messages.conflicts), {
            variant: 'error',
          });
          return;
        }
        enqueueSnackbar(
          (error.response?.data as { message: string }).message ||
            error.message,
          { variant: 'error' },
        );
      },
    },
  );

  const setPhoto: UseEntityMutations['setPhoto'] = useMutation(
    ['entities', 'setPhoto'],
    ({ entityId, entity, propKey, file }) =>
      client.attachments.create(file).then((attachment) =>
        client.entities.update(
          entityId,
          packEntityRequest(
            {
              ...entity,
              properties: { ...entity.properties, [propKey]: attachment.id },
            },
            schema,
          ),
        ),
      ),
    {
      onSuccess: (data) => {
        merge([data], schema);
        return queryClient.invalidateQueries([['entities', 'listEvents']]);
      },
    },
  );

  const createEvent: UseEntityMutations['createEvent'] = useMutation(
    ['entities', 'createEvent'],
    ({ entityId, event, files }) =>
      client.entities.createEvent(entityId, event, files),
    {
      onSuccess: (data) => {
        eventsMerge([data]);
      },
    },
  );

  return { update, setPhoto, createEvent };
};

const useEntityEventsLNL = createListAndLookupStore(
  reviveEntityEvent,
  'entity.events',
);

export const useEntityEvents = (
  entities: Entity['id'][],
): UseListAndLookupQuery<ApiEntityEvent, EntityEvent> => {
  const { set, list, lookup } = useEntityEventsLNL(
    useCallback((props) => props, []),
  );

  const client = useApiClient();

  const query = useQuery<ApiEntityEvent[], Error>(
    ['entities', 'listEvents', entities],
    ({ queryKey }) => {
      const ids = queryKey[2] as Entity['id'][];
      return Promise.all(ids.map((id) => client.entities.listEvents(id))).then(
        (results) => results.flat(),
      );
    },
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};
