import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { FC, ReactNode, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import messagesSwedish from '../translations/locales/sv-SE.json';
import { Locale, useLocale } from '../store/locale';

const LocaleProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useLocale();

  const messages = useMemo<Record<Locale, Record<string, string>>>(
    () => ({
      'en-US': {},
      'sv-SE': { ...messagesSwedish },
    }),
    [],
  );

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={'en-US'}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
        {children}
      </LocalizationProvider>
    </IntlProvider>
  );
};

export { LocaleProvider };
