import React, { FC } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  ListItemButton,
  ListItemText,
  MenuList,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@mui/lab';

interface RecurringWOPlanListProps {
  plans: RecurringPlan[];
  onClose: () => void;
  isProcessing: boolean;
  onSelect: (id: string) => void;
  onNewPlan: () => void;
}

const RecurringWOPlanList: FC<RecurringWOPlanListProps> = ({
  plans,
  isProcessing,
  onClose,
  onSelect,
  onNewPlan,
}) => {
  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} flexBasis={0}>
            <MenuList dense>
              {plans.map((plan) => {
                const subtitle =
                  plan.periodicity.type === 'monthly' ? (
                    <FormattedMessage
                      id="recurring_plan_dialog.executes_x_times_per_month"
                      defaultMessage="Executes {occurrences} {occurrences, plural, one {time} other {times}} per month"
                      values={{
                        occurrences: plan.periodicity.occurrences.length,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="recurring_plan_dialog.executes_x_times_per_year"
                      defaultMessage="Executes {occurrences} {occurrences, plural, one {time} other {times}} per year"
                      values={{
                        occurrences: plan.periodicity.occurrences.length,
                      }}
                    />
                  );
                return (
                  <ListItemButton
                    key={plan.id}
                    onClick={() => onSelect(plan.id)}
                  >
                    <ListItemText primary={plan.title} secondary={subtitle} />
                  </ListItemButton>
                );
              })}
            </MenuList>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            flexBasis={0}
          >
            <Typography>
              <FormattedMessage
                id="recurring_plan_dialog.select_plan_to_the_left_or_create_new"
                defaultMessage="Select recurring work order plan in the left list or create a new plan."
              />
            </Typography>
            <Button
              variant="contained"
              sx={{ marginTop: 4 }}
              onClick={onNewPlan}
            >
              <FormattedMessage
                id="recurring_plan_dialog.new_check_in_plan"
                defaultMessage="New check-in plan"
              />
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          loading={isProcessing}
          onClick={onClose}
        >
          <FormattedMessage id="close" defaultMessage="Close" />
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RecurringWOPlanList;
