import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { dateFormat } from '../../helpers/datetime_helpers';

interface EntityListItemERTooltipProps {
  errorReports: ErrorReport[];
  schema: EntitySchema;
}

const EntityListItemERTooltip: FC<EntityListItemERTooltipProps> = ({
  errorReports,
  schema,
}) => {
  return (
    <Box>
      <Typography variant="h5">
        <FormattedMessage
          id="entity_list_item.current_error_reports"
          defaultMessage="Current error reports"
        />
      </Typography>
      {errorReports.map((er) => {
        const schemaProp = schema.definition.propertiesLookup[er.property_key];
        if (!schemaProp) {
          console.warn(
            "Missing schema property for error report popup for key '" +
              er.property_key +
              "'.",
          );
          return null;
        }
        const name = schemaProp.name;
        let groupName = '';
        if (typeof schemaProp.group_id === 'number') {
          const group = schema.definition.groupsLookup[schemaProp.group_id];
          groupName = `${group.name}: `;
        }
        return (
          <Box key={er.id} mt={4}>
            <Typography variant="body1" color="secondary">
              <Typography component="span" color="white">
                {groupName}
              </Typography>
              {name}
            </Typography>
            <Typography variant="body2">
              {er.meta.created_at.toFormat(dateFormat)}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default EntityListItemERTooltip;
