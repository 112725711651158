import React from 'react';
import { CheckBox, Warning, Circle } from '@mui/icons-material';
import { PeriodicityPeriodStatus } from '@allbin/mobilix-utils';

export const getPeriodStatusColorIcon = (
  status: PeriodicityPeriodStatus,
): React.ReactElement => {
  if (status.executed === 'on_time' && status.remarks.length > 0) {
    return <Warning color="error" />;
  }

  if (status.executed === 'on_time') {
    return <CheckBox color="success" />;
  }

  if (status.executed === 'late') {
    return <Circle color="warning" />;
  }

  return <Circle color="error" />;
};
