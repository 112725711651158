import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, FilterOptionsState } from '@mui/material';
import { useTags } from '../../hooks/useTags';
import RoundedInput from '../Inputs/RoundedInput';
import SearchField from '../Inputs/SearchField';
import Fuse from 'fuse.js';
import { SxProps } from '@mui/system';
import Chip from '../Inputs/Chip';

interface TagSelectorProps {
  /** If provided will be used instead of store. Allows custom pre-filtering of tags. */
  tags?: WorkOrderTag[];
  selectedTags: WorkOrderTag[] | string[];
  /**
   * Search mode: only displays selected tags and allows the user to search for additional tags through auto-complete.
   * 'onChange' callback is required for search mode.
   *
   * Filter mode: displays all tags but highlights the selected tags and allows user filtering of all tags.
   *
   * Defaults to 'filter'.
   */
  mode?: 'search' | 'filter';
  /**
   * If a positive number is provided the component will automatically switch to search mode
   * when the number of tags in store or provided by 'tags' prop exceeds this value.
   *
   * If 'mode' is provided this property is ignored.
   * */
  automaticSearchMode?: number;
  onChange?: (selectedTagIds: string[]) => void;
  onTagClick?: (tagId: string, selected: boolean) => void;
  sx?: SxProps;
  excludeUneditable?: boolean;
}

const TagSelector: FC<TagSelectorProps> = ({
  selectedTags,
  mode,
  tags,
  automaticSearchMode,
  onChange,
  onTagClick,
  sx,
  excludeUneditable,
}) => {
  const { list: tagsInStore, lookup: tagsLookup } = useTags();
  const [inputText, setInputText] = useState<string>('');

  const allTags = useMemo(() => {
    if (tags) {
      return tags;
    }
    if (excludeUneditable) {
      return tagsInStore.filter((t) => t.editable);
    }
    return tagsInStore;
  }, [tags, tagsInStore, excludeUneditable]);

  const consolidatedMode = useMemo(() => {
    if (automaticSearchMode && automaticSearchMode > 0 && !mode) {
      return allTags.length > automaticSearchMode ? 'search' : 'filter';
    }
    if (onChange && mode && mode === 'search') {
      return 'search';
    }
    return 'filter';
  }, [allTags.length, automaticSearchMode, mode, onChange]);

  const selectedTagIds = useMemo(() => {
    return selectedTags.map((st) => (typeof st === 'string' ? st : st.id));
  }, [selectedTags]);

  const filteredTags = useMemo(() => {
    if (inputText) {
      const ft = inputText.toLowerCase().trim();
      return allTags.filter((t) => t.name.toLowerCase().includes(ft));
    }
    return allTags;
  }, [allTags, inputText]);

  const sortedTags = useMemo(() => {
    const tags =
      consolidatedMode === 'search'
        ? selectedTagIds.map((tid) => tagsLookup[tid])
        : filteredTags;
    return tags.sort((a, b) => a.name.localeCompare(b.name, 'sv'));
  }, [consolidatedMode, filteredTags, selectedTagIds, tagsLookup]);

  const tagFuse = useMemo(
    () =>
      new Fuse(allTags, {
        keys: ['name'],
      }),
    [allTags],
  );

  const tagsFilterOptions = useCallback(
    (options: string[], state: FilterOptionsState<string>): string[] => {
      const found = tagFuse.search(state.inputValue, { limit: 5 });
      return found.map((result) => result.item.id);
    },
    [tagFuse],
  );

  const onTagSelect = useCallback(
    (tagId: string) => {
      if (onChange) {
        const ids = [...selectedTagIds];
        if (!ids.includes(tagId)) {
          ids.push(tagId);
        }
        onChange(ids);
      }
      if (onTagClick) {
        onTagClick(tagId, false);
      }
    },
    [onChange, onTagClick, selectedTagIds],
  );
  const onTagDeselect = useCallback(
    (tagId: string) => {
      if (onChange) {
        const ids = [...selectedTagIds];
        const index = ids.findIndex((t) => t === tagId);
        if (index > -1) {
          ids.splice(index, 1);
        }
        onChange(ids);
      }
      if (onTagClick) {
        onTagClick(tagId, true);
      }
    },
    [onChange, onTagClick, selectedTagIds],
  );

  if (consolidatedMode === 'search' && onChange) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexBasis: 0,
          minHeight: 0,
          ...sx,
        }}
      >
        <SearchField
          onChange={onChange}
          filterOptions={tagsFilterOptions}
          disablePortal={false}
          options={allTags.map((t) => t.id)}
          value={selectedTagIds}
          renderOption={(tagId) => tagsLookup[tagId]?.name}
        />
        <Box sx={{ overflow: 'auto', flex: '1 1 auto', minHeight: 0 }}>
          {sortedTags.map((t) => {
            const selected = selectedTagIds.includes(t.id);
            return (
              <Chip
                key={t.id}
                name={t.name}
                selected={selected}
                filled={true}
                onClick={() => onTagDeselect(t.id)}
              />
            );
          })}
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 0,
        minHeight: 0,
        ...sx,
      }}
    >
      <RoundedInput
        type="filter"
        onChange={(ev) => setInputText(ev.target.value)}
        sx={{ marginBottom: 4 }}
      />
      <Box sx={{ overflow: 'auto', flex: '1 1 auto', minHeight: 0 }}>
        {sortedTags.map((t) => {
          const selected = selectedTagIds.includes(t.id);
          return (
            <Chip
              key={t.id}
              name={t.name}
              selected={selected}
              filled={selected ? true : false}
              onClick={() =>
                selected ? onTagDeselect(t.id) : onTagSelect(t.id)
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TagSelector;
