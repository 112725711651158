import React, { FC, ReactElement, useMemo } from 'react';
import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
  useTheme,
  Tooltip,
  Box,
  BoxProps,
  TooltipProps,
} from '@mui/material';
import { ColorProps } from '../../providers/ThemeProvider';
import { LoadingButton } from '@mui/lab';
interface ButtonProps extends MuiButtonProps {
  /** fullWidth will be set on the button component, but also set `width: 100%` on the wrapper if a tooltip is used. */
  fullWidth?: boolean;
  loading?: boolean;
  palette?: ColorProps;
  tooltip?: ReactElement | string;
  tooltipProps?: TooltipProps;
  /** When using a tooltip the button is wrapped, use this property to style that wrapper. */
  tooltipContainerSx?: BoxProps['sx'];
}

const Button: FC<ButtonProps> = ({
  loading,
  palette,
  tooltip,
  tooltipContainerSx,
  tooltipProps,
  ...props
}) => {
  const theme = useTheme();
  const sx = useMemo(() => {
    const colors = palette || theme.palette.wo;
    switch (props.variant) {
      case 'contained':
        return {
          color: colors.contrastText,
          backgroundColor: colors.main,
          '&:hover': {
            backgroundColor: colors.dark,
          },
          ...props.sx,
        };
      case 'outlined':
        return {
          color: colors.main,
          borderColor: colors.main,
          '&:hover': {
            borderColor: colors.dark,
            backgroundColor: colors.a20,
          },
          ...props.sx,
        };

      default:
        return {
          color: colors.main,
          '&:hover': {
            backgroundColor: colors.a20,
          },
          ...props.sx,
        };
    }
  }, [palette, props.variant, theme, props.sx]);

  const tooltipSx = useMemo(() => {
    const base = {
      display: 'inline-block',
    };
    return props.fullWidth
      ? {
          ...base,
          width: '100%',
          display: 'block',
          ...tooltipContainerSx,
        }
      : {
          ...base,
          ...tooltipContainerSx,
        };
  }, [props.fullWidth, tooltipContainerSx]);

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow {...tooltipProps}>
        <Box sx={tooltipSx}>
          <MuiButton {...props} color={undefined} sx={sx} />
        </Box>
      </Tooltip>
    );
  }

  if (loading) {
    return (
      <Box sx={tooltipSx}>
        <LoadingButton {...props} color={undefined} sx={sx} loading={loading} />
      </Box>
    );
  }

  return (
    <Box sx={tooltipSx}>
      <MuiButton {...props} color={undefined} sx={sx} />
    </Box>
  );
};

export default Button;
