import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V13.5M4 10H20M10 4V20M14 19H21M21 19L18 16M21 19L18 22"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'TableExport',
);

const Icon: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default Icon;
