import { ApiContractor, ApiContractorAgent } from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';

export const tdContractors: ApiContractor[] = [
  {
    id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0',
    name: 'Björnligan tvätt',
    tenant_id: 'vl',
    phone: '+46705055050',
    contact_person: 'Björn Bandhund',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: 'e819f564-d873-4e86-ad91-fdff2b74ee08',
    name: 'Jönsonligan Enterprises Co. Ltd. AB',
    tenant_id: 'vl',
    phone: '+46733233232',
    filterset_id: '41e1315a-68ae-4c01-965f-678d0d2f9d59',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
];

export const tdContractorAgents: ApiContractorAgent[] = [
  {
    id: 'fcdd1b8e-4a41-4c66-be61-5f356384167e',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    tenant_id: 'vl',
    contractor_id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0',
    admin: true,
    user_id: 'auth0|6138bc632c6a45006940ff70',
  },
  {
    id: '827fccdb-054b-4478-b5ea-7fe167efe8d0',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    tenant_id: 'vl',
    contractor_id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0',
    admin: false,
    user_id: 'auth0|6138bc7a898692006a243d1b',
  },
];
