import { useQuery } from 'react-query';
import { useApiClient } from './useApiClient';
import { ApiEntityType } from '@allbin/mobilix-api-client';
import { useMemo } from 'react';

interface UseEntityTypes {
  all: ApiEntityType[];
  stop?: ApiEntityType;
}

export const useEntityTypes = (): UseEntityTypes => {
  const client = useApiClient();

  const list = useQuery<ApiEntityType[], Error>(
    ['entity_types', 'list'],
    client.entityTypes.list,
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const all = useMemo(() => list.data || [], [list.data]);

  const stop = useMemo(
    () => list.data?.find((et) => et.name === 'stop'),
    [list.data],
  );

  return { all, stop };
};
