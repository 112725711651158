import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { tdGetEntityChangesets } from './changesets';
import { entityProps, tdGetEntities } from './entities';
import { ApiWorkOrderEvent } from '@allbin/mobilix-api-client';
import { tdErrorReports } from './errorreports';
import { dateFormat } from '../helpers/datetime_helpers';

const entities = tdGetEntities();

export const tdEntityEvents: EntityEvent[] = [
  {
    id: v4(),
    type: 'police-report',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-08-11', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-08-11', dateFormat),
    },
    sourceType: 'entity',
    data: {
      message: `This is a message in a police report event.
      This is a new line.`,
    },
  },
  {
    id: v4(),
    type: 'error-report',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-08-06', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-08-06', dateFormat),
    },
    sourceType: 'entity',
    data: {
      action: 'cleared',
      error_report_id: tdErrorReports[0].id,
    },
  },
  {
    id: v4(),
    type: 'error-report',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-08-03', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-08-03', dateFormat),
    },
    sourceType: 'entity',
    data: {
      action: 'filed',
      error_report_id: tdErrorReports[0].id,
    },
  },
  {
    id: v4(),
    type: 'comment',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-02-03', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-02-03', dateFormat),
    },
    sourceType: 'entity',
    data: {
      text: 'This is a comment without attachments.',
      attachments: [],
    },
  },
  {
    id: v4(),
    type: 'comment',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-02-04', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc5',
      updated_at: DateTime.fromFormat('2021-02-04', dateFormat),
    },
    sourceType: 'entity',
    data: {
      text: 'This is a comment from a non-existing user.',
      attachments: [
        { attachment: v4(), mime_type: 'image/jpeg' },
        { attachment: v4(), mime_type: 'image/jpeg' },
        { attachment: v4(), mime_type: 'image/jpeg' },
        { attachment: v4(), mime_type: 'image/jpeg' },
        { attachment: v4(), mime_type: 'image/jpeg' },
        { attachment: v4(), mime_type: 'pdf' },
        { attachment: v4(), mime_type: 'pdf' },
      ],
    },
  },
  {
    id: v4(),
    type: 'comment',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-05-05', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-05-06', dateFormat),
    },
    sourceType: 'entity',
    data: {
      attachments: [{ attachment: v4(), mime_type: 'image/jpeg' }],
    },
  },
  {
    id: v4(),
    type: 'workorder:state',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-04-03', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-04-03', dateFormat),
    },
    sourceType: 'entity',
    data: {
      workorder_id: 'cb097347-f816-4c79-97b3-035a7dec041f',
      state: 'completed',
    },
  },
  {
    id: v4(),
    type: 'changeset',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-04-07', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-04-07', dateFormat),
    },
    sourceType: 'entity',
    data: {
      changeset_id: tdGetEntityChangesets(
        entities[0].id,
        entities[0].properties,
      )[0].id,
    },
  },
  {
    id: v4(),
    type: 'changeset',
    entity_id: entities[0].id,
    meta: {
      created_at: DateTime.fromFormat('2021-04-08', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-04-08', dateFormat),
    },
    sourceType: 'entity',
    data: {
      prev_changeset_id: tdGetEntityChangesets(
        entities[0].id,
        entities[0].properties,
      )[0].id,
      changeset_id: tdGetEntityChangesets(
        entities[0].id,
        entities[0].properties,
      )[1].id,
    },
  },
];

export const tdWorkOrderEvents: WorkOrderEvent[] = [
  {
    id: v4(),
    sourceType: 'workorder',
    workorder_id: '0a1c7700-ce4e-471f-92e4-6b82a911cfac',
    type: 'comment',
    data: {
      attachments: [],
      text: 'This is a work order comment without attachments.',
    },
    meta: {
      created_at: DateTime.fromFormat('2021-03-17', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-03-17', dateFormat),
    },
  },
  {
    id: v4(),
    sourceType: 'workorder',
    type: 'state',
    data: {
      state: 'completed',
    },
    meta: {
      created_at: DateTime.fromFormat('2021-03-17', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-03-17', dateFormat),
    },
    workorder_id: '0a1c7700-ce4e-471f-92e4-6b82a911cfac',
  },
  {
    id: v4(),
    sourceType: 'workorder',
    type: 'changeset',
    data: {
      entity_id: entities[0].id,
      properties: entityProps[0],
    },
    meta: {
      created_at: DateTime.fromFormat('2021-03-17', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-03-17', dateFormat),
    },
    workorder_id: '0a1c7700-ce4e-471f-92e4-6b82a911cfac',
  },
  {
    id: v4(),
    sourceType: 'workorder',
    type: 'tag',
    data: {
      tags_added: ['AddedTag'],
      tags_removed: ['RemovedTag'],
    },
    meta: {
      created_at: DateTime.fromFormat('2021-03-17', dateFormat),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.fromFormat('2021-03-17', dateFormat),
    },
    workorder_id: '0a1c7700-ce4e-471f-92e4-6b82a911cfac',
  },
];

export const tdGetApiWorkOrderEvents = (
  workOrderId: string,
): ApiWorkOrderEvent[] =>
  tdWorkOrderEvents
    .filter((e) => e.workorder_id === workOrderId)
    .map(
      (e) =>
        ({
          ...e,
          meta: {
            created_at: e.meta.created_at.toISO(),
            created_by: 'auth0|6172bc995cd96b0070339cc7',
            updated_at: e.meta.updated_at.toISO(),
          },
        } as ApiWorkOrderEvent),
    );
