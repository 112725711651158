import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface PropertyNode {
  id: string;
  type: 'property';
  children: PropertyNode[];
}

export interface GroupNode {
  id: string;
  type: 'group';
  children: PropertyNode[];
}

export type TreeNode = PropertyNode | GroupNode;

export const propToNode = (
  schema: EntitySchema,
  prop: ApiEntitySchemaProp,
): PropertyNode => ({
  id: prop.key,
  type: 'property',
  children: [],
});

export const groupToNode = (
  schema: EntitySchema,
  group_id: number,
): GroupNode => ({
  id: `group.${group_id}`,
  type: 'group',
  children: [],
});

export const createTree = (schema?: EntitySchema): TreeNode[] => {
  if (!schema) {
    return [];
  }

  let currentGroupId: number | undefined = undefined;
  let currentNode: GroupNode | undefined = undefined;

  const tree: TreeNode[] = [];
  for (const prop of schema.definition.properties) {
    if (prop.group_id) {
      if (prop.group_id !== currentGroupId) {
        const group_node = groupToNode(schema, prop.group_id);
        group_node.children.push(propToNode(schema, prop));

        currentGroupId = prop.group_id;
        currentNode = group_node;
        tree.push(currentNode);
      } else {
        const prop_node = propToNode(schema, prop);
        if (!currentNode) {
          tree.push(prop_node);
        } else {
          currentNode.children.push(prop_node);
        }
      }
    } else {
      currentNode = undefined;
      currentGroupId = undefined;

      tree.push(propToNode(schema, prop));
    }
  }
  return tree;
};

const getNextGroupId = (
  groupsLookup: Record<string, ApiEntitySchemaGroup>,
): number =>
  Object.values(groupsLookup).reduce(
    (max, cur) => (max > cur.id ? max : cur.id),
    0,
  ) + 1;

const getNextPropertyId = (
  propsLookup: Record<string, ApiEntitySchemaProp>,
): number =>
  Object.values(propsLookup)
    .filter((p) => /^inventory/.test(p.key))
    .map((p) => parseInt(p.key.split('.')[1], 10))
    .reduce((max, cur) => (max > cur ? max : cur), 0) + 1;

interface SchemaManagementDialogStore {
  show: boolean;
  hasChanges: boolean;

  tree: TreeNode[];
  selectedTreeNode?: PropertyNode;

  groupsLookup: Record<string, ApiEntitySchemaGroup>;
  originalPropsLookup: Record<string, ApiEntitySchemaProp>;
  propsLookup: Record<string, ApiEntitySchemaProp>;
  extrasLookup: Record<string, EntitySchemaExtra>;

  setShow: (show: boolean) => void;
  setHasChanges: (changes: boolean) => void;
  getTree: () => TreeNode[];
  setTree: (tree: TreeNode[]) => void;
  setSelectedTreeNode: (selectedTreeNode: PropertyNode | undefined) => void;

  createContext: (schema: EntitySchema) => void;

  setProperty: (key: string, prop: Partial<ApiEntitySchemaProp>) => void;
  setExtras: (key: string, extras: Partial<EntitySchemaExtra>) => void;
  setGroup: (id: number, group: Partial<ApiEntitySchemaGroup>) => void;

  addGroup: (name: string) => void;
  removeGroup: (id: number) => void;

  addProperty: (name: string) => void;
  removeProperty: (id: string) => void;
}

const removeTreeNode = (tree: TreeNode[], id: string): TreeNode[] => {
  const index = tree.findIndex((n) => n.id === id);
  if (index === -1) {
    return tree;
  }

  const head = tree.slice(0, index);
  const tail = tree.slice(index + 1);

  return [...head, ...tail];
};

const replaceChildren = (
  tree: TreeNode[],
  id: string,
  children: TreeNode[],
): TreeNode[] => {
  const index = tree.findIndex((n) => n.id === id);
  if (index === -1) {
    return tree;
  }

  const head = tree.slice(0, index);
  const tail = tree.slice(index + 1);

  const newNode = {
    ...tree[index],
    children,
  } as TreeNode;

  return [...head, newNode, ...tail];
};

const useSchemaManagementDialogStore = create<SchemaManagementDialogStore>()(
  devtools(
    (set, get) => ({
      show: false,
      hasChanges: false,

      tree: [],
      selectedTreeNode: undefined,

      groupsLookup: {},
      originalPropsLookup: {},
      propsLookup: {},
      extrasLookup: {},

      setShow: (show) => set({ show }),
      setHasChanges: (hasChanges) => set({ hasChanges }),
      setTree: (tree) => set({ tree }),
      getTree: () => get().tree,
      setSelectedTreeNode: (selectedTreeNode) => set({ selectedTreeNode }),

      createContext: (schema) => {
        const groupsLookup = schema
          ? schema.definition.groups.reduce<
              Record<string, ApiEntitySchemaGroup>
            >((gbk, g) => {
              gbk[g.id.toString()] = { ...g };
              return gbk;
            }, {})
          : {};
        const originalPropsLookup = schema
          ? schema.definition.properties.reduce<
              Record<string, ApiEntitySchemaProp>
            >((pbk, prop) => {
              pbk[prop.key] = {
                ...prop,
              };
              return pbk;
            }, {})
          : {};
        const propsLookup = schema
          ? schema.definition.properties.reduce<
              Record<string, ApiEntitySchemaProp>
            >((pbk, prop) => {
              pbk[prop.key] = {
                ...prop,
              };
              return pbk;
            }, {})
          : {};
        const extrasLookup = schema
          ? Object.keys(schema.extras).reduce<
              Record<string, EntitySchemaExtra>
            >((ebk, key) => {
              ebk[key] = { ...schema.extras[key] };
              return ebk;
            }, {})
          : {};

        set({
          groupsLookup,
          originalPropsLookup,
          propsLookup,
          extrasLookup,
        });
      },

      setGroup: (id, group) => {
        const { groupsLookup } = get();
        set({
          hasChanges: true,
          groupsLookup: {
            ...groupsLookup,
            [id.toString()]: {
              ...(groupsLookup[id.toString()] || {}),
              ...group,
            },
          },
        });
      },
      setProperty: (key, prop) => {
        const { propsLookup } = get();
        set({
          hasChanges: true,
          propsLookup: {
            ...propsLookup,
            [key]: { ...(propsLookup[key] || {}), ...prop },
          },
        });
      },
      setExtras: (key, extras) => {
        const { extrasLookup } = get();
        set({
          hasChanges: true,
          extrasLookup: {
            ...extrasLookup,
            [key]: { ...(extrasLookup[key] || {}), ...extras },
          },
        });
      },

      addGroup: (name) => {
        const { tree, groupsLookup } = get();
        const id = getNextGroupId(groupsLookup);

        const newGroup = {
          id,
          name,
        };
        const newTreeNode: GroupNode = {
          id: `group.${id}`,
          type: 'group',
          children: [],
        };

        set({
          tree: [...tree, newTreeNode],
          hasChanges: true,
          groupsLookup: { ...groupsLookup, [newGroup.id.toString()]: newGroup },
        });
      },
      removeGroup: (id) => {
        const { tree, groupsLookup } = get();
        const nodeId = `group.${id}`;
        const idx = tree.findIndex((n) => n.id === nodeId);
        if (idx !== -1) {
          const head = tree.slice(0, idx);
          const tail = tree.slice(idx + 1);
          set({
            tree: [...head, ...tail],
            hasChanges: true,
            groupsLookup: Object.values(groupsLookup)
              .filter((g) => g.id === id)
              .reduce<Record<string, ApiEntitySchemaGroup>>((lookup, group) => {
                lookup[group.id.toString()] = group;
                return lookup;
              }, {}),
          });
        }
      },

      addProperty: (name) => {
        const { tree, propsLookup } = get();
        const id = getNextPropertyId(propsLookup);

        const newProperty: ApiEntitySchemaProp = {
          key: `inventory.${id}`,
          name: `${name} ${id}`,
          type: 'boolean',
          modifiable: true,
        };

        const newTreeNode: PropertyNode = {
          id: `inventory.${id}`,
          type: 'property',
          children: [],
        };

        set({
          tree: [...tree, newTreeNode],
          hasChanges: true,
          propsLookup: { ...propsLookup, [newProperty.key]: newProperty },
          selectedTreeNode: newTreeNode,
        });
      },

      removeProperty: (id) => {
        const { tree, propsLookup, selectedTreeNode } = get();

        const rootIndex = tree.findIndex(
          (n) => n.id === id || n.children.find((c) => c.id === id),
        );
        if (rootIndex === -1) {
          return;
        }

        const updatedTree =
          tree[rootIndex].id !== id
            ? replaceChildren(
                tree,
                tree[rootIndex].id,
                removeTreeNode(tree[rootIndex].children, id),
              )
            : removeTreeNode(tree, id);

        const updatedPropsLookup = Object.values(propsLookup)
          .filter((p) => p.key !== id)
          .reduce<Record<string, ApiEntitySchemaProp>>((pl, p) => {
            pl[p.key] = p;
            return pl;
          }, {});

        const updatedSelectedTreeNode =
          selectedTreeNode?.id === id ? undefined : selectedTreeNode;
        set({
          tree: updatedTree,
          hasChanges: true,
          propsLookup: updatedPropsLookup,
          selectedTreeNode: updatedSelectedTreeNode,
        });
      },
    }),
    {
      name: 'schema-management-dialog-store',
      enabled: process.env.NODE_ENV !== 'test',
    },
  ),
);

export default useSchemaManagementDialogStore;
