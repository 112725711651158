import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 6H20" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 12H20" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 18H20" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Menu',
);

const Icon: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default Icon;
