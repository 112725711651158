import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { ApiContractor } from '@allbin/mobilix-api-client';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useApiClient } from './useApiClient';
import { useCallback } from 'react';

export const reviveContractor = (contractor: ApiContractor): Contractor => ({
  ...contractor,
  meta: reviveMetadata(contractor.meta),
});

const useContractorsListAndLookup = createListAndLookupStore(
  reviveContractor,
  'contractors',
);

export const useContractors = (): UseListAndLookupQuery<
  ApiContractor,
  Contractor
> => {
  const { set, list, lookup } = useContractorsListAndLookup(
    useCallback((props) => props, []),
  );

  const apiClient = useApiClient();
  const query = useQuery<ApiContractor[], Error>(
    ['contractors', 'list'],
    () => apiClient.contractors.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UpdateParams {
  id: Contractor['id'];
  contractor: ContractorRequest;
}

interface UseContractorMutations {
  create: UseMutationResult<ApiContractor, Error, ContractorRequest>;
  update: UseMutationResult<ApiContractor, Error, UpdateParams>;
  remove: UseMutationResult<ApiContractor, Error, Contractor['id']>;
}

export const useContractorMutations = (): UseContractorMutations => {
  const client = useApiClient();
  const { merge, clear } = useContractorsListAndLookup(
    useCallback((props) => props, []),
  );

  const create: UseContractorMutations['create'] = useMutation(
    ['contractors', 'create'],
    (request) => client.contractors.create(request),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );
  const update: UseContractorMutations['update'] = useMutation(
    ['contractors', 'update'],
    ({ id, contractor }) => client.contractors.update(id, contractor),
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseContractorMutations['remove'] = useMutation(
    ['contractors', 'remove'],
    (id) => client.contractors.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return {
    create,
    update,
    remove,
  };
};

export const useContractor = (
  id?: Contractor['id'],
): UseQueryResult<Contractor | undefined, Error> => {
  const client = useApiClient();
  return useQuery<Contractor | undefined, Error>(
    ['contractors', 'get', id],
    () => (id ? client.contractors.get(id).then(reviveContractor) : undefined),
  );
};
