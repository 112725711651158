import React, { FC } from 'react';
import { Card, IconButton, Tooltip, Typography } from '@mui/material';
import { SearchResult } from '../../store/search';
import { Assignment, Settings } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

interface ResultCardProps {
  result: SearchResult;
  onClick?: (result: SearchResult, action?: string) => void;
  noTopRadius?: boolean;
}

const ResultCard: FC<ResultCardProps> = ({ result, onClick, noTopRadius }) => {
  let colorRef = 'lightgrey';

  switch (result.type) {
    case 'workorder': {
      colorRef = 'wo.dark';
      break;
    }
    case 'entityGroup': {
      colorRef = 'entity.dark';
      break;
    }
    default:
      break;
  }

  return (
    <Card
      elevation={4}
      sx={{
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0)',
        padding: ({ spacing }) => spacing(2),
        marginTop: 0,
        backgroundColor: 'background.default',
        // backgroundColor: 'palette.paper',
        borderRadius: noTopRadius === true ? '0 0 4px 4px' : '4px',
        '&:hover': {
          // backgroundColor: colorRef,
          borderColor: colorRef,
          backgroundColor: 'white',
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick(result);
      }}
    >
      <Typography variant="subtitle1" color="GrayText" gutterBottom>
        {result.title}
      </Typography>
      <Typography variant="body2" color="GrayText">
        {result.subtitle || ' '}
      </Typography>
      {result.type === 'contractor' && (
        <>
          <Tooltip
            PopperProps={{ disablePortal: true }}
            arrow
            title={
              <FormattedMessage
                id="search.go_to_workorderoverivew"
                defaultMessage="Go to workorder overview"
              />
            }
          >
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick && onClick(result, 'overview');
              }}
              size="small"
            >
              <Assignment />
            </IconButton>
          </Tooltip>
          <Tooltip
            PopperProps={{ disablePortal: true }}
            arrow
            title={
              <FormattedMessage
                id="search.manage_contractor_organization"
                defaultMessage="Manage contractor organization"
              />
            }
          >
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick && onClick(result, 'manage');
              }}
              size="small"
            >
              <Settings />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Card>
  );
};

export default ResultCard;
