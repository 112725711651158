import React, { FC } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@mui/lab';

export interface TagFormProps {
  onNameChange: (name: string) => void;
  name: string;
  onDescriptionChange: (description: string) => void;
  description: string;
  isNameValid: boolean;
  processing: boolean;
  buttonProps: {
    onSaveTag: () => void;
    onResetTag?: () => void;
    onRemoveTag?: () => void;
    disableSubmit?: boolean;
    resetEnabled?: boolean;
    removeEnabled?: boolean;
  };
}

const TagForm: FC<TagFormProps> = ({
  onNameChange,
  onDescriptionChange,
  isNameValid,
  name,
  processing,
  description,
  buttonProps,
}) => {
  const {
    onSaveTag,
    onResetTag,
    onRemoveTag,
    resetEnabled,
    removeEnabled,
    disableSubmit,
  } = buttonProps;

  return (
    <>
      <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
        <FormattedMessage
          id="tag_management_dialog.name_required"
          defaultMessage="Name *"
        />
      </Typography>
      <TextField
        value={name}
        onChange={(ev) => onNameChange(ev.target.value)}
        sx={{
          backgroundColor: 'background.paper',
        }}
        error={!isNameValid}
        helperText={
          !isNameValid ? (
            <FormattedMessage
              id="tag_management_dialog.name_error_hint"
              defaultMessage="Name must be at least one character."
            />
          ) : (
            ' '
          )
        }
        FormHelperTextProps={{
          sx: {
            backgroundColor: 'background.dark',
            color: 'error.main',
            margin: 0,
            paddingLeft: 3,
          },
        }}
      />
      <Typography variant="overline" component="p" sx={{ marginTop: 2 }}>
        <FormattedMessage
          id="tag_management_dialog.description"
          defaultMessage="Description"
        />
      </Typography>
      <TextField
        value={description}
        onChange={(ev) => onDescriptionChange(ev.target.value)}
        sx={{
          backgroundColor: 'background.paper',
        }}
        rows={8}
        multiline
      />
      <Box flexGrow={1} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        {onRemoveTag && (
          <LoadingButton
            loading={processing}
            disabled={!removeEnabled}
            onClick={onRemoveTag}
            variant="outlined"
          >
            <FormattedMessage
              id="tag_management_dialog.remove_tag"
              defaultMessage="Remove tag"
            />
          </LoadingButton>
        )}
        {onResetTag && (
          <LoadingButton
            loading={processing}
            disabled={!resetEnabled}
            onClick={onResetTag}
            variant="outlined"
          >
            <FormattedMessage
              id="tag_management_dialog.reset_changes"
              defaultMessage="Reset changes"
            />
          </LoadingButton>
        )}
        <LoadingButton
          loading={processing}
          disabled={disableSubmit || !isNameValid}
          onClick={onSaveTag}
          variant="contained"
        >
          <FormattedMessage
            id="tag_management_dialog.save_tag"
            defaultMessage="Save tag"
          />
        </LoadingButton>
      </Box>
    </>
  );
};

export default TagForm;
