import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useErrorReportsMutations } from '../../hooks/useErrorReports';
import NewErrorForm from './NewErrorForm';
import ErrorReportList from './ErrorReportList';
import { useEntities } from '../../hooks/useEntities';
import ErrorReportDetails from './ErrorReportDetails';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';

interface ErrorReportDialogProps {
  schema: EntitySchema;
  open: boolean;
  onClose: () => void;
  entities: Entity[];
  errorReports: ErrorReport[];
}

const ErrorReportDialog: FC<ErrorReportDialogProps> = ({
  schema,
  open,
  onClose,
  entities,
  errorReports,
}) => {
  const {
    remove: { isLoading: removingErrorReportInProgress },
    create: { isLoading: creatingErrorReportInProgress },
  } = useErrorReportsMutations();
  const { lookup: entityLookup } = useEntities();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSelectConfirmation, setShowSelectConfirmation] = useState<
    ErrorReport | undefined
  >(undefined);
  const [selectedErrorReport, setSelectedErrorReport] = useState<
    ErrorReport | undefined
  >(undefined);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);

  const availableProps = useMemo(() => {
    const propsWithDestinations = Object.entries(schema.extras)
      .filter(([, { support_urls }]) => support_urls)
      .map(([key]) => key);
    if (propsWithDestinations.length === 0) {
      return undefined;
    }
    return propsWithDestinations.map((pkey) => {
      const prop = schema.definition.propertiesLookup[pkey];
      const group =
        typeof prop.group_id === 'number'
          ? schema.definition.groupsLookup[prop.group_id]
          : undefined;
      return {
        id: pkey,
        name: group ? `${group.name}: ${prop.name}` : prop.name,
      };
    });
  }, [schema]);

  const { stationName, toggles, selectedEntityName } = useMemo(() => {
    let selectedEntityName = '';
    const toggles = entities.map((e) => {
      const selected = e.id === selectedEntityId;
      if (selected) {
        selectedEntityName = e.properties['meta.name'] + ' ' + e.stop_letter;
      }
      return {
        id: e.id,
        selected,
        label: e.stop_letter,
      };
    });

    const stationName =
      entities.length > 0
        ? (entities[0].properties['meta.name'] as string)
        : '';
    return {
      stationName,
      toggles,
      selectedEntityName,
    };
  }, [entities, selectedEntityId]);

  const onTryClose = useCallback(() => {
    if (selectedEntityId) {
      setShowCancelConfirmation(true);
      return;
    }
    onClose();
  }, [onClose, selectedEntityId]);
  const onTrySelect = useCallback(
    (errorReport: ErrorReport) => {
      if (selectedEntityId) {
        setShowSelectConfirmation(errorReport);
        return;
      }
      setSelectedErrorReport(errorReport);
    },
    [selectedEntityId],
  );

  const isProcessing = useMemo(() => {
    return creatingErrorReportInProgress || removingErrorReportInProgress;
  }, [creatingErrorReportInProgress, removingErrorReportInProgress]);

  return (
    <Dialog
      open={open}
      onClose={() => !isProcessing && onTryClose()}
      maxWidth="md"
      fullWidth
    >
      <UnsavedChangesDialog
        open={showCancelConfirmation}
        onOk={() => {
          setSelectedEntityId(null);
          setSelectedErrorReport(undefined);
          setShowCancelConfirmation(false);
          onClose();
        }}
        onCancel={() => setShowCancelConfirmation(false)}
      />
      <UnsavedChangesDialog
        open={!!showSelectConfirmation}
        onOk={() => {
          setSelectedErrorReport(showSelectConfirmation);
          setShowSelectConfirmation(undefined);
          setSelectedEntityId(null);
        }}
        onCancel={() => setShowSelectConfirmation(undefined)}
      />
      <DialogContent sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
        <Box flex={1} p={4} paddingRight={0}>
          <Typography variant="h5" mb={2}>
            <FormattedMessage
              id="error_report.current_error_reports_for"
              defaultMessage="Current error reports for {stationName}"
              values={{ stationName }}
            />
          </Typography>
          <Button
            variant="contained"
            sx={{
              width: '97%',
              marginTop: 2,
              marginBottom: 2,
            }}
            color="secondary"
            disabled={isProcessing}
            onClick={() => setSelectedErrorReport(undefined)}
          >
            <FormattedMessage
              id="error_report.new_error_report"
              defaultMessage="New error report"
            />
          </Button>
          <ErrorReportList
            errorReports={errorReports}
            schemaDefinition={schema.definition}
            selectedErrorReport={selectedErrorReport}
            setSelectedErrorReport={onTrySelect}
            entityLookup={entityLookup}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.background.dark,
            flex: 1,
            p: 4,
          }}
        >
          {selectedErrorReport ? (
            <ErrorReportDetails
              errorReport={selectedErrorReport}
              entityLookup={entityLookup}
              schemaDefinition={schema.definition}
              onClose={onTryClose}
              onClear={() => setSelectedErrorReport(undefined)}
            />
          ) : (
            <NewErrorForm
              toggles={toggles}
              setSelectedEntityId={setSelectedEntityId}
              selectedEntityId={selectedEntityId}
              schema={schema}
              onClose={onTryClose}
              availableProps={availableProps}
              entityName={selectedEntityName}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorReportDialog;
