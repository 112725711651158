import React, { useCallback, useMemo } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { useConfigStore } from '../../store/config';
import { useAttachmentMutations } from '../../hooks/useAttachments';

import SelectMenu, {
  SelectMenuProps,
  SelectMenuPropsRenderOption,
} from '../SelectMenu/SelectMenu';
import { typeOperators } from '../filter/FilterCard/FilterConditionOperatorInput';

import {
  Box,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import CreatableMultiSelect from './CreatableMultiSelect';
import useSchemaManagementDialogStore from '../../store/schemaManagementDialog';

const types = Object.keys(typeOperators)
  .filter((t) => !t.includes('array') || t === 'array:string')
  .map((t) => ({ id: t }));

const messages = defineMessages({
  name: {
    id: 'schema_management_dialog.name',
    defaultMessage: 'Name',
  },
  type: {
    id: 'schema_management_dialog.type',
    defaultMessage: 'Type',
  },
  help_text: {
    id: 'schema_management_dialog.help_text',
    defaultMessage: 'Help text',
  },
  help_image: {
    id: 'schema_management_dialog.help_image',
    defaultMessage: 'Help image',
  },
  errorReportingURL: {
    id: 'schema_management_dialog.error_reporting_url',
    defaultMessage: 'Error reporting URL',
  },
  choose: {
    id: 'choose',
    defaultMessage: 'choose',
  },
  operator_type_unknown: {
    id: 'schema_management_dialog.operator_type.unknown',
    defaultMessage: 'unknown',
  },
  operator_type_boolean: {
    id: 'schema_management_dialog.operator_type.boolean',
    defaultMessage: 'boolean',
  },
  operator_type_date: {
    id: 'schema_management_dialog.operator_type.date',
    defaultMessage: 'date',
  },
  operator_type_enum: {
    id: 'schema_management_dialog.operator_type.enum',
    defaultMessage: 'enum',
  },
  operator_type_number: {
    id: 'schema_management_dialog.operator_type.number',
    defaultMessage: 'number',
  },
  operator_type_photo: {
    id: 'schema_management_dialog.operator_type.photo',
    defaultMessage: 'photo',
  },
  operator_type_string: {
    id: 'schema_management_dialog.operator_type.string',
    defaultMessage: 'string',
  },
  operator_type_location: {
    id: 'schema_management_dialog.operator_type.location',
    defaultMessage: 'location',
  },
  operator_type_array_string: {
    id: 'schema_management_dialog.operator_type.array:string',
    defaultMessage: 'array:string',
  },
  operator_type_array_number: {
    id: 'schema_management_dialog.operator_type.array:number',
    defaultMessage: 'array:number',
  },
});

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const SchemaManagementPropertyEditor: React.FC = () => {
  const intl = useIntl();
  const { attachmentUrl } = useConfigStore();
  const {
    create: { mutateAsync: createAttachment },
  } = useAttachmentMutations();

  const {
    selectedTreeNode,
    originalPropsLookup,
    propsLookup,
    setProperty,
    extrasLookup,
    setExtras,
  } = useSchemaManagementDialogStore();

  const onDrop = useCallback(
    (image: File[]) => {
      void createAttachment(image[0]).then((attachment) => {
        if (selectedTreeNode) {
          setExtras(selectedTreeNode.id, {
            help_image: attachment.id,
          });
        }
      });
    },
    [setExtras, selectedTreeNode, createAttachment],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted: onDrop,
    accept: ['image/png', 'image/jpeg', 'image/jpg'],
    multiple: false,
  });

  const dropzoneStyle: React.CSSProperties = useMemo(() => {
    const bgImage =
      selectedTreeNode && extrasLookup[selectedTreeNode.id]?.help_image
        ? extrasLookup[selectedTreeNode.id].help_image
        : undefined;

    return bgImage
      ? {
          backgroundImage: `url(${attachmentUrl}/${bgImage}/md)`,
          ...baseStyle,
        }
      : { ...baseStyle };
  }, [attachmentUrl, extrasLookup, selectedTreeNode]);

  const style = useMemo(
    () => ({
      ...dropzoneStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [dropzoneStyle, isDragActive, isDragReject, isDragAccept],
  );

  const typeSelectValueRenderer: SelectMenuProps<{
    id: string;
  }>['renderValue'] = useCallback(
    (o) =>
      o
        ? o.id === 'boolean'
          ? intl.formatMessage(messages.operator_type_boolean)
          : o.id === 'date'
          ? intl.formatMessage(messages.operator_type_date)
          : o.id === 'enum'
          ? intl.formatMessage(messages.operator_type_enum)
          : o.id === 'number'
          ? intl.formatMessage(messages.operator_type_number)
          : o.id === 'photo'
          ? intl.formatMessage(messages.operator_type_photo)
          : o.id === 'string'
          ? intl.formatMessage(messages.operator_type_string)
          : o.id === 'location'
          ? intl.formatMessage(messages.operator_type_location)
          : o.id === 'array:string'
          ? intl.formatMessage(messages.operator_type_array_string)
          : o.id === 'array:number'
          ? intl.formatMessage(messages.operator_type_array_number)
          : intl.formatMessage(messages.operator_type_unknown)
        : intl.formatMessage(messages.choose),
    [intl],
  );

  const typeSelectOptionRenderer: SelectMenuProps<{
    id: string;
  }>['renderOption'] = useCallback<SelectMenuPropsRenderOption<{ id: string }>>(
    (o, onClick, value) => {
      return (
        <MenuItem key={o.id} selected={o === value} onClick={onClick}>
          <FormattedMessage
            id={`schema_management_dialog.operator_type.${o.id}`}
            defaultMessage={o.id}
          />
        </MenuItem>
      );
    },
    [],
  );

  return (
    <Box
      p={2}
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: 'background.dark',
      }}
    >
      {selectedTreeNode ? (
        <Stack p={4} flexGrow={1} direction="column" sx={{ height: '100%' }}>
          <Typography variant="h5">
            <FormattedMessage
              id="schema_management_dialog.edit_property"
              defaultMessage="Edit property"
            />
          </Typography>
          <Stack pt={2} direction="row" sx={{ height: '100%' }}>
            <Stack m={2} ml={0} pr={2} flex={1} direction="column">
              <TextField
                label={intl.formatMessage(messages.name)}
                value={propsLookup[selectedTreeNode.id]?.name || ''}
                onChange={(e) => {
                  if (selectedTreeNode) {
                    setProperty(selectedTreeNode.id, {
                      name: e.currentTarget.value,
                    });
                  }
                }}
              />
              <Stack pt={2} alignItems="flex-start" direction="column">
                <Box mr={2}>
                  <Typography variant="overline">
                    <FormattedMessage
                      id="schema_management_dialog.type"
                      defaultMessage="Type"
                    />
                  </Typography>
                </Box>
                <Box>
                  <SelectMenu
                    disabled={!!originalPropsLookup[selectedTreeNode.id]}
                    value={types.find(
                      (t) => t.id === propsLookup[selectedTreeNode.id]?.type,
                    )}
                    options={types}
                    renderValue={typeSelectValueRenderer}
                    renderOption={typeSelectOptionRenderer}
                    onChange={(v) => {
                      if (selectedTreeNode) {
                        setProperty(selectedTreeNode.id, {
                          type: v.id as ApiEntitySchemaProp['type'],
                        });
                      }
                    }}
                  />
                </Box>
              </Stack>
              {selectedTreeNode &&
              propsLookup[selectedTreeNode.id]?.type === 'enum' ? (
                <Box mt={2}>
                  <Typography variant="subtitle1">
                    <FormattedMessage
                      id="schema_management_dialog.alternatives"
                      defaultMessage="Alternatives"
                    />
                  </Typography>
                  <CreatableMultiSelect
                    values={propsLookup[selectedTreeNode.id].alternatives || []}
                    staticValues={
                      originalPropsLookup[selectedTreeNode.id]?.alternatives ||
                      []
                    }
                    onChange={(v) => {
                      if (selectedTreeNode) {
                        setProperty(selectedTreeNode.id, { alternatives: v });
                      }
                    }}
                  />
                </Box>
              ) : undefined}
              <Stack pt={2} alignItems="center" direction="row">
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="schema_management_dialog.hidden"
                      defaultMessage="Hidden"
                    />
                  </Typography>
                </Box>
                <Box>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="schema_management_dialog.hidden_properties_explanation"
                        defaultMessage="Hidden properties are no longer shown to users"
                      />
                    }
                  >
                    <Checkbox
                      checked={
                        extrasLookup[selectedTreeNode.id]?.hidden || false
                      }
                      onChange={() => {
                        if (selectedTreeNode) {
                          setExtras(selectedTreeNode.id, {
                            hidden: !(
                              extrasLookup[selectedTreeNode.id]?.hidden || false
                            ),
                          });
                        }
                      }}
                    />
                  </Tooltip>
                </Box>
              </Stack>
            </Stack>
            <Stack m={2} flex={1} flexGrow={1} direction="column">
              <Box mb={2}>
                <TextField
                  label={intl.formatMessage(messages.errorReportingURL)}
                  value={
                    extrasLookup[selectedTreeNode.id]?.support_urls?.default ||
                    ''
                  }
                  onChange={(e) => {
                    if (selectedTreeNode) {
                      const def = { default: e.currentTarget.value };
                      setExtras(selectedTreeNode.id, { support_urls: def });
                    }
                  }}
                />
              </Box>
              <TextField
                label={intl.formatMessage(messages.help_text)}
                value={extrasLookup[selectedTreeNode.id]?.help_text || ''}
                multiline
                minRows={3}
                onChange={(e) => {
                  if (selectedTreeNode) {
                    setExtras(selectedTreeNode.id, {
                      help_text: e.currentTarget.value,
                    });
                  }
                }}
              />
              <Box mt={4} mb={2}>
                <Typography variant="overline">
                  <FormattedMessage
                    id="schema_management_dialog.help_image"
                    defaultMessage="Help image (Replace by clicking or dropping onto the image)"
                  />
                </Typography>
              </Box>
              <Box mt={2} {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {!extrasLookup[selectedTreeNode.id]?.help_image ? (
                  isDragActive ? (
                    <p>
                      <FormattedMessage
                        id="schema_management_dialog.drop_image_here"
                        defaultMessage="Drop replacement image here..."
                      />
                    </p>
                  ) : (
                    <p>
                      <FormattedMessage
                        id="schema_management_dialog.drag_or_click"
                        defaultMessage="Drag a replacement image here, or click to select one"
                      />
                    </p>
                  )
                ) : undefined}
              </Box>
            </Stack>
          </Stack>
        </Stack>
      ) : undefined}
    </Box>
  );
};

export default SchemaManagementPropertyEditor;
