import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import { Logout } from '@mui/icons-material';

import MobilixLogo from './MobilixLogo';
import useConfig from '../../store/config';

interface SplashMessageProps {
  message: string;
}

const SplashMessage: React.FC<SplashMessageProps> = ({ message }) => {
  const { logout } = useAuth0();
  const {
    auth0: { clientId },
  } = useConfig();
  return (
    <>
      <Stack
        direction="column"
        sx={{ width: '100%', height: '90%' }}
        p={4}
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <MobilixLogo />
        </Box>
        <Box mt={8} sx={{ maxWidth: 500, textAlign: 'center' }}>
          <Typography>{message}</Typography>
        </Box>
        <Box pt={8}>
          <IconButton
            onClick={() =>
              logout({
                logoutParams: {
                  client_id: clientId,
                  returnTo: window.location.origin,
                },
              })
            }
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Logout />
              <Typography variant="button">
                <FormattedMessage id="splash.logout" defaultMessage="Logout" />
              </Typography>
            </Stack>
          </IconButton>
        </Box>
      </Stack>
    </>
  );
};

export default SplashMessage;
