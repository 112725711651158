import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';

const Component = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9L6 9Z"
      stroke="#141E29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'CaretDown',
);

const CaretDown: React.FC<SvgIconProps> = (props) => {
  return <Component {...props} />;
};

export default CaretDown;
