import { ApiUser } from '@allbin/mobilix-api-client';
import { User as Auth0User } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

export const TestUserWorkorderAdmin: Auth0User = {
  'https://mobilix.allbin.se/roles': ['Mobilix Work Order Administrator'],
  nickname: 'mobilix-workorder-admin',
  name: 'Workorder Administrator',
  picture:
    'https://s.gravatar.com/avatar/c94ed39509d71d948ed19ac351ba93e4?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmo.png',
  updated_at: '2021-09-10T08:49:20.487Z',
  email: 'mobilix-workorder-admin@allbinary.se',
  email_verified: true,
  sub: 'auth0|6138bc01fc2828006ad2fa43',
  org_id: 'org_6VQOVdnHclkpg9HN',
};

export const TestUserWorkorderContractorAdmin: Auth0User = {
  'https://mobilix.allbin.se/roles': [
    'Mobilix Work Order Contractor Administrator',
  ],
  nickname: 'mobilix-workorder-contractor-admin',
  name: 'Workorder Contractor Admin',
  picture:
    'https://s.gravatar.com/avatar/89cf04ce96ec8f870aa32d1150426664?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmo.png',
  updated_at: '2021-09-10T08:59:29.598Z',
  email: 'mobilix-workorder-contractor-admin@allbinary.se',
  email_verified: true,
  sub: 'auth0|6138bc632c6a45006940ff70',
  org_id: 'org_6VQOVdnHclkpg9HN',
};

export const TestUserWorkorderContractorUser: Auth0User = {
  'https://mobilix.allbin.se/roles': ['Mobilix Work Order Contractor User'],
  nickname: 'mobilix-workorder-contractor-user',
  name: 'Workorder Contractor Agent',
  picture:
    'https://s.gravatar.com/avatar/c487e30db148414e0d1af03fb9203f13?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmo.png',
  updated_at: '2021-09-10T08:58:09.495Z',
  email: 'mobilix-workorder-contractor-user@allbinary.se',
  email_verified: true,
  sub: 'auth0|6138bc7a898692006a243d1b',
  org_id: 'org_6VQOVdnHclkpg9HN',
};

export const tdUsers: ApiUser[] = [
  {
    id: 'auth0|6138bc01fc2828006ad2fa43',
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    email: 'mobilix-workorder-admin@allbinary.se',
    name: 'Workorder Administrator',
    user_id: 'auth0|6138bc01fc2828006ad2fa43',
    admin: true,
  },
  {
    id: 'auth0|6138bc632c6a45006940ff70',
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    email: 'mobilix-workorder-contractor-admin@allbinary.se',
    name: 'Workorder Contractor Admin',
    user_id: 'auth0|6138bc632c6a45006940ff70',
    contractors: [{ id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0', admin: true }],
  },
  {
    id: 'auth0|6138bc7a898692006a243d1b',
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    email: 'mobilix-workorder-contractor-user@allbinary.se',
    name: 'Workorder Contractor Agent',
    user_id: 'auth0|6138bc7a898692006a243d1b',
    contractors: [{ id: '41c084fc-c39f-4e85-bd35-9921b5c10cc0' }],
  },
  {
    id: 'auth0|000000000000000000000000',
    meta: {
      created_at: DateTime.now().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.now().toISO(),
    },
    email: 'storybook@allbinary.se',
    name: 'Storybook User',
    user_id: 'auth0|000000000000000000000000',
    admin: true,
    contractors: [],
  },
];
