import React from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

interface Props {
  to: string;
  tooltip: React.ReactNode;
  icon: React.ReactNode;
  params?: Record<string, string | number>;
}

const NavButton: React.FC<Props> = ({ to, tooltip, icon, params }) => {
  return (
    <Tooltip label={tooltip}>
      <NavLink
        to={generatePath(to, params)}
        className="relative flex flex-col items-center justify-center"
      >
        {({ isActive }) => (
          <>
            <IconButton className={`${isActive ? 'border bg-white/30' : ''}`}>
              {icon}
            </IconButton>
          </>
        )}
      </NavLink>
    </Tooltip>
  );
};

export default NavButton;
