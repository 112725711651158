import { DateTime, Duration } from 'luxon';
import { ApiUserInvitation } from '@allbin/mobilix-api-client';

export const tdInvitations: ApiUserInvitation[] = [
  {
    id: 'inv_abcdef',
    state: 'pending',
    tenant_id: 'vl',
    contractor_id: '5cc5d2bd-77cf-40b2-ab40-558c9234bb7e',
    role: 'contractor-agent',
    email: 'invitee@example.org',
    created_at: DateTime.now()
      .minus(Duration.fromObject({ days: 15 }))
      .toISO(),
    expires_at: DateTime.now()
      .plus(Duration.fromObject({ days: 15 }))
      .toISO(),
  },
  {
    id: 'inv_ghijkl',
    state: 'accepted',
    tenant_id: 'vl',
    email: 'invitee2@example.org',
    role: 'admin',
    created_at: DateTime.now()
      .minus(Duration.fromObject({ days: 90 }))
      .toISO(),
    expires_at: DateTime.now()
      .plus(Duration.fromObject({ days: 60 }))
      .toISO(),
  },
];
