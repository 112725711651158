import React, { useCallback, useMemo } from 'react';
import Fuse from 'fuse.js';
import SearchField from '../Inputs/SearchField';
import { FilterOptionsState } from '@mui/material';

interface StopSearchProps {
  value: string[];
  onChange: (entities: string[]) => void;
  entities: Entity[];
  entitiesLookup: Record<Entity['id'], Entity>;
}

const StopSearch: React.FC<StopSearchProps> = ({
  value,
  onChange,
  entities,
  entitiesLookup,
}) => {
  const fuse = useMemo(
    () =>
      new Fuse(entities, {
        keys: [['properties', 'meta.name']],
      }),
    [entities],
  );

  const stopFilterOptions = useCallback(
    (options: string[], state: FilterOptionsState<string>): string[] => {
      // console.log(state.inputValue);
      const found = fuse.search(state.inputValue, { limit: 5 });
      return found.map((result) => result.item.id);
    },
    [fuse],
  );

  return (
    <SearchField<Entity>
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      options={entities.map((entity) => entity.id)}
      filterOptions={stopFilterOptions}
      renderOption={(id) => {
        const entity = entitiesLookup[id];
        if (!entity) {
          return;
        }
        return `${entity.properties['meta.name'] as string} ${
          entity.properties['meta.id'] as string
        }`;
      }}
    />
  );
};

export default StopSearch;
