import { ApiTag, ApiWorkOrder } from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';
import { createNameId } from 'mnemonic-id';
import { v4 } from 'uuid';
import { tdGetApiEntityChangesetsRequests } from './changesets';
import { tdGetEntities } from './entities';

const entities = tdGetEntities();

export const tdWorkorderTags: ApiTag[] = [
  {
    id: '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
    name: 'Kundrapporterat',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '1460d22c-a09a-41b5-a061-bc8f46510b80',
    name: 'Glaskross',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: 'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    name: 'Vandalism',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '47486810-a3ec-43e4-ab6e-772048a212db',
    name: 'Polisanmält polisanmält polisanmält',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '38bded42-cae4-4815-a89b-ce273b2cfb2f',
    name: 'Klotter',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f45',
    name: 'Inventering',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f01',
    name: 'Test tag 01',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f02',
    name: 'Test tag 02',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f03',
    name: 'Test tag 03',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f04',
    name: 'Test tag 04',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f05',
    name: 'Test tag 05',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1fth',
    name: 'Temporär hållplats',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f06',
    name: 'Test tag 06',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f07',
    name: 'Test tag 07',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f08',
    name: 'Test tag 08',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f09',
    name: 'Test tag 09',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f10',
    name: 'Test tag 10',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f11',
    name: 'Test tag 11',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f12',
    name: 'Test tag 12',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1fab',
    name: 'En annan tag',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1fmt',
    name: 'Min tag',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f13',
    name: 'Test tag 13',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f14',
    name: 'Test tag 14',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f15',
    name: 'Test tag 15',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1fen',
    name: 'En riktigt bra tag',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
  {
    id: '345b1ce8-6c48-409a-9687-4481a67f1f16',
    name: 'Test tag 16',
    tenant_id: 'vl',
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
  },
];

export const tdWorkorders: WorkOrder[] = [
  {
    id: '0a1c7700-ce4e-471f-92e4-6b82a911cfac',
    title: createNameId(),
    state: 'created',
    title_suffix: '1cfac',
    description: '',
    due_at: DateTime.utc(2021, 10, 2),
    // due_at: DateTime.now(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
      '47486810-a3ec-43e4-ab6e-772048a212db',
      '38bded42-cae4-4815-a89b-ce273b2cfb2f',
      '345b1ce8-6c48-409a-9687-4481a67f1f45',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc(),
    },
    contractors: [],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [],
  },
  {
    id: 'cb097347-f816-4c79-97b3-035a7dec041f',
    title: 'cluedo',
    state: 'completed',
    title_suffix: 'c041f',
    description:
      'Due date is always today!!\n\nLorem ipsum dolor sit amet consectetur, adipisicing elit.\nAperiam aspernatur odio possimus tenetur odit placeat quas ipsa corrupti, eveniet ipsam excepturi necessitatibus nisi tempore iure unde dolorum laboriosam, delectus maiores totam molestias nemo neque doloribus, non atque? Odit itaque ratione dicta nesciunt animi aliquid corrupti laborum perferendis laboriosam. Eaque, sunt!',
    due_at: DateTime.now().set({
      second: 0,
      hour: 0,
      minute: 0,
      millisecond: 0,
    }),
    tags: [
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
      '345b1ce8-6c48-409a-9687-4481a67f1f45',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc(),
    },
    contractors: [],
    entity_type_id: '8e74171b-5fd1-4c9f-b66a-9411f6f9709f',
    entities: [entities[0].id],
  },
];
export const tdApiWorkorders: ApiWorkOrder[] = [
  {
    id: v4(),
    title: createNameId(),
    tenant_id: 'whatever',
    state: 'created',
    description: '',
    due_at: DateTime.utc(2021, 10, 2).toISODate(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
      '47486810-a3ec-43e4-ab6e-772048a212db',
      '38bded42-cae4-4815-a89b-ce273b2cfb2f',
      '345b1ce8-6c48-409a-9687-4481a67f1f45',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: [],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [],
  },
  {
    id: v4(),
    title: createNameId(),
    tenant_id: 'whatever',
    state: 'created',
    description: '',
    due_at: DateTime.utc(2021, 10, 2).toISODate(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    ],
    entity_changesets: {
      [entities[0].id]: tdGetApiEntityChangesetsRequests(
        entities[0].id,
        entities[0].properties,
      )[0],
      [entities[5].id]: tdGetApiEntityChangesetsRequests(
        entities[5].id,
        entities[5].properties,
      )[1],
    },
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: ['41c084fc-c39f-4e85-bd35-9921b5c10cc0'],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [entities[0].id, entities[5].id, entities[7].id],
  },
  {
    id: v4(),
    title: createNameId(),
    tenant_id: 'whatever',
    state: 'completed',
    description: '',
    due_at: DateTime.utc(2021, 10, 2).toISODate(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    ],
    entity_changesets: {
      [entities[1].id]: tdGetApiEntityChangesetsRequests(
        entities[1].id,
        entities[1].properties,
      )[0],
      [entities[7].id]: tdGetApiEntityChangesetsRequests(
        entities[7].id,
        entities[7].properties,
      )[1],
    },
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: ['41c084fc-c39f-4e85-bd35-9921b5c10cc0'],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [entities[1].id, entities[6].id, entities[7].id],
  },
  {
    id: v4(),
    title: createNameId(),
    tenant_id: 'whatever',
    state: 'created',
    description: '',
    due_at: DateTime.now().plus({ days: 30 }).toISODate(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: ['41c084fc-c39f-4e85-bd35-9921b5c10cc0'],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [entities[2].id, entities[4].id, entities[8].id],
  },
  {
    id: v4(),
    title: createNameId(),
    tenant_id: 'whatever',
    state: 'rejected',
    description: '',
    due_at: DateTime.now().plus({ days: 2 }).toISODate(),
    tags: [
      '0cc7ac9c-eba0-4e60-a383-cc103d8547c7',
      '1460d22c-a09a-41b5-a061-bc8f46510b80',
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: ['41c084fc-c39f-4e85-bd35-9921b5c10cc0'],
    entity_type_id: 'faa9e1dd-927d-4a1e-89a5-daf4e5738781',
    entities: [entities[1].id, entities[5].id, entities[7].id],
  },
  {
    id: 'cb097347-f816-4c79-97b3-035a7dec041f',
    title: 'cluedo',
    tenant_id: 'vl',
    state: 'completed',
    description: '',
    due_at: DateTime.utc(2021, 10, 2).toISODate(),
    tags: [
      'a4d4ac05-8990-41c6-bf79-a904a69d63e6',
      '345b1ce8-6c48-409a-9687-4481a67f1f45',
    ],
    entity_changesets: {},
    meta: {
      created_at: DateTime.utc().toISO(),
      created_by: 'auth0|6172bc995cd96b0070339cc7',
      updated_at: DateTime.utc().toISO(),
    },
    contractors: [],
    entity_type_id: '8e74171b-5fd1-4c9f-b66a-9411f6f9709f',
    entities: [entities[0].id],
  },
];
