import React, { ReactNode, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserStore from '../../store/user';
import LoadingScreen from './LoadingScreen';
import OrgSelectorScreen from './OrgSelectorScreen';
import LoginFailedScreen from './LoginFailedScreen';

const RequireLogin: React.FC<{ children: ReactNode }> = ({ children }) => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    logout,
    user: auth0User,
  } = useAuth0();
  const { orgId, setOrgId, setPermissions, setUser, user } = useUserStore();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      return;
    }

    if (!isAuthenticated && orgId) {
      console.log('login with redirect');
      void loginWithRedirect({
        authorizationParams: {
          organization: orgId,
        },
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [error, isAuthenticated, isLoading, loginWithRedirect, orgId]);

  useEffect(() => {
    if (auth0User && !user.name && isAuthenticated) {
      setUser(auth0User);
    }
  }, [auth0User, user, isAuthenticated, setUser]);

  useEffect(() => {
    if (error) {
      setOrgId();
      setPermissions([]);
      setUser();
    }
  }, [error, setOrgId, setPermissions, setUser]);

  if (error) {
    return (
      <LoginFailedScreen
        onConfirm={() =>
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          })
        }
      />
    );
  }
  if (!orgId) {
    return <OrgSelectorScreen onSelectOrgId={(orgId) => setOrgId(orgId)} />;
  }
  if (isLoading || !isAuthenticated) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

export default RequireLogin;
