import { useCallback } from 'react';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { useApiClient } from './useApiClient';
import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { DateTime } from 'luxon';
import { useEntityTypes } from './useEntityTypes';

export const reviveRecurringWOPlan: ReviverFn<
  ApiRecurringWorkOrderPlan,
  RecurringPlan
> = (recurringWOPlan) => ({
  ...recurringWOPlan,
  meta: reviveMetadata(recurringWOPlan.meta),
  valid_from: DateTime.fromISO(recurringWOPlan.valid_from),
  valid_to: recurringWOPlan.valid_to
    ? DateTime.fromISO(recurringWOPlan.valid_to)
    : undefined,
});

const packRecurringWOPlan = (
  recurringPlan:
    | Omit<RecurringPlan, 'meta'>
    | Omit<RecurringPlan, 'id' | 'meta'>,
  stop_entity_id: string,
): ApiRecurringWorkOrderPlanRequest => {
  return {
    creation_offset: recurringPlan.creation_offset,
    valid_to: recurringPlan.valid_to?.toISO(),
    contractor_id: recurringPlan.contractor_id,
    contractors: recurringPlan.contractors,
    valid_from: recurringPlan.valid_from.toISO(),
    title: recurringPlan.title,
    tags: recurringPlan.tags,
    periodicity: recurringPlan.periodicity,
    filterset_id: recurringPlan.filterset_id,
    entity_type_id: stop_entity_id,
    description: recurringPlan.description,
  };
};

const useListAndLookup = createListAndLookupStore(
  reviveRecurringWOPlan,
  'recurringWOPlan',
);

export const useRecurringWOPlans = (): UseListAndLookupQuery<
  ApiRecurringWorkOrderPlan,
  RecurringPlan
> => {
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );

  const client = useApiClient();
  const query = useQuery<ApiRecurringWorkOrderPlan[], Error>(
    ['recurringWOPlans', 'list'],
    () => client.recurringWorkOrderPlans.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );

  return { ...query, list, lookup };
};

interface UseRecurringWOPlanMutations {
  create: UseMutationResult<
    ApiRecurringWorkOrderPlan,
    Error,
    Omit<RecurringPlan, 'id' | 'meta'>
  >;
  update: UseMutationResult<
    ApiRecurringWorkOrderPlan,
    Error,
    Omit<RecurringPlan, 'meta'>
  >;
  remove: UseMutationResult<
    ApiRecurringWorkOrderPlan,
    Error,
    RecurringPlan['id']
  >;
}

export const useRecurringWOPlanMutations = (): UseRecurringWOPlanMutations => {
  const { merge, clear } = useListAndLookup(useCallback((props) => props, []));
  const { stop: stop_entity_id } = useEntityTypes();

  const client = useApiClient();
  const create: UseRecurringWOPlanMutations['create'] = useMutation(
    ['recurringWOPlans', 'create'],
    (checkInPlan) => {
      if (stop_entity_id) {
        return client.recurringWorkOrderPlans.create(
          packRecurringWOPlan(checkInPlan, stop_entity_id.id),
        );
      }
      throw Error('Missing stop entity id.');
    },
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );
  const update: UseRecurringWOPlanMutations['update'] = useMutation(
    ['recurringWOPlans', 'update'],
    (checkInPlan) => {
      if (stop_entity_id) {
        return client.recurringWorkOrderPlans.update(
          checkInPlan.id,
          packRecurringWOPlan(checkInPlan, stop_entity_id.id),
        );
      }
      throw new Error('Missing stop entity id.');
    },
    {
      onSuccess: (data) => {
        merge([data]);
      },
    },
  );

  const remove: UseRecurringWOPlanMutations['remove'] = useMutation(
    ['recurringWOPlans', 'remove'],
    (id) => client.recurringWorkOrderPlans.delete(id),
    {
      onSuccess: (data) => {
        clear([data.id]);
      },
    },
  );

  return { create, remove, update };
};
