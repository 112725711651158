import { useApiClient } from './useApiClient';
import { useMutation, UseMutationResult } from 'react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

export const useRebusMutations = (): {
  upload: UseMutationResult<unknown, Error, File>;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApiClient();
  const upload = useMutation<unknown, Error, File>(
    ['rebus', 'upload'],
    (file: File) => client.rebus.upload(file),
    {
      onError: (error) => {
        enqueueSnackbar(
          ((error as AxiosError).response?.data as { message: string })
            .message || error.message,
          { variant: 'error' },
        );
      },
    },
  );
  return { upload };
};
