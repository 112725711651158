import { useApiClient } from './useApiClient';
import { useMutation, UseMutationResult } from 'react-query';
import { ApiAttachment } from '@allbin/mobilix-api-client/lib/api';

export const useAttachmentMutations = (): {
  create: UseMutationResult<ApiAttachment, Error, File>;
} => {
  const client = useApiClient();
  const create = useMutation<ApiAttachment, Error, File>(
    ['attachments', 'create'],
    (file: File) => client.attachments.create(file),
  );
  return { create };
};
