import { useApiClient } from './useApiClient';
import {
  useQuery,
  UseQueryResult,
  useQueryClient,
  useMutation,
  UseMutationResult,
} from 'react-query';
import { reviveMetadata } from '../helpers/revivers';

export const serializeEntitySchemaExtra = (
  input: EntitySchemaExtra,
): ApiEntitySchemaExtra => {
  const out: ApiEntitySchemaExtra = { ...input };
  if (input.number_options) {
    out.number_options = {
      grouping: input.number_options.grouping,
      decimals: input.number_options.toFixed,
      round_to_decimals: input.number_options.roundTo,
    };
  }
  return out;
};

export const serializeEntitySchemaExtras = (
  input: EntitySchema['extras'],
): ApiEntitySchemaExtras =>
  Object.entries(input).reduce<ApiEntitySchemaExtras>((extras, [k, extra]) => {
    extras[k] = serializeEntitySchemaExtra(extra);
    return extras;
  }, {});

export const reviveEntitySchema = (schema: ApiEntitySchema): EntitySchema => {
  const properties = [...schema.definition.properties];

  const extras: EntitySchema['extras'] = Object.entries(
    schema.extras || {},
  ).reduce<EntitySchema['extras']>((acc, [k, extra]) => {
    acc[k] = reviveSchemaExtra(extra);
    return acc;
  }, {});

  return {
    ...schema,
    meta: reviveMetadata(schema.meta),
    definition: {
      groups: schema.definition.groups,
      groupsLookup: schema.definition.groups.reduce(
        (acc, group) => ({ ...acc, [group.id]: group }),
        {},
      ),
      properties: properties,
      propertiesLookup: properties.reduce(
        (acc, prop) => ({ ...acc, [prop.key]: prop }),
        {},
      ),
    },
    extras,
  };
};

const reviveSchemaExtra = (
  api_extras: ApiEntitySchemaExtra,
): EntitySchemaExtra => {
  const extras: EntitySchemaExtra = {
    ...api_extras,
  };
  if (api_extras.number_options) {
    extras.number_options = {
      grouping: api_extras.number_options.grouping,
      toFixed: api_extras.number_options.decimals,
      roundTo: api_extras.number_options.round_to_decimals,
    };
  }
  return extras;
};

export const useEntitySchema = (): UseQueryResult<
  EntitySchema | undefined,
  Error
> => {
  const client = useApiClient();
  const query = useQuery<EntitySchema | undefined, Error>(
    ['entitySchemas', 'list'],
    () =>
      client.entitySchemas.list().then((schemas) => {
        console.log(`got number of schemas: ${schemas.length}`);
        return schemas.length > 0 ? reviveEntitySchema(schemas[0]) : undefined;
      }),
  );
  return { ...query };
};

interface UseEntitySchemaMutations {
  update: UseMutationResult<
    EntitySchema,
    Error,
    { id: ApiEntitySchema['id']; schema: ApiEntitySchemaRequest }
  >;
}

export const useEntitySchemaMutations = (): UseEntitySchemaMutations => {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const update = useMutation<
    EntitySchema,
    Error,
    { id: ApiEntitySchema['id']; schema: ApiEntitySchemaRequest }
  >(
    ['entitySchemas', 'update'],
    ({ id, schema }) =>
      apiClient.entitySchemas
        .update(id, schema)
        .then((apiSchema) => reviveEntitySchema(apiSchema)),
    {
      onSuccess: () => queryClient.invalidateQueries(['entitySchemas', 'list']),
    },
  );

  return {
    update,
  };
};
