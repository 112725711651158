import React, { useMemo } from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import MultiTextField from './MultiTextField';
import { dateFormat } from '../../../helpers/datetime_helpers';

const coerceNumberToInputValue = (probablyNumber: number): number | string => {
  return typeof probablyNumber === 'number' && isFinite(probablyNumber)
    ? probablyNumber
    : '';
};

export const defaultValue = (
  type: FilterCondition['type'],
  operator: FilterCondition['operator'],
  alternatives?: string[],
): unknown => {
  switch (operator) {
    case 'eq':
    case 'neq':
      if (alternatives && alternatives.length > 0) {
        return alternatives[0];
      }
      switch (type) {
        case 'string':
          return '';
        case 'number':
          return 0;
        default:
          return undefined;
      }
    case 'after':
    case 'before':
      return DateTime.local().toFormat(dateFormat);
    case 'false':
    case 'gt':
    case 'gte':
    case 'lt':
    case 'lte':
    case 'unknown':
    case 'true':
    case 'known':
    case 'matches':
    case 'not_matches':
      return undefined;
    case 'all_of':
    case 'any_of':
    case 'none_of':
      return [];
    case 'between':
    case 'not_between':
      if (type === 'date') {
        return [
          DateTime.local().toFormat(dateFormat),
          DateTime.local().toFormat(dateFormat),
        ];
      }
      return [];
    default:
      return undefined;
  }
};

const defaultInputProps: Partial<TextFieldProps> = {
  variant: 'standard',
  size: 'small',
  onKeyDown: (event) => {
    if (event.key === 'Enter') {
      event.currentTarget.getElementsByTagName('input')[0].blur();
    }
  },
};

const FilterConditionInput: React.FC<{
  filterCondition: FilterCondition;
  onChange: (filterCondition: FilterCondition) => void;
  schema: EntitySchema;
}> = ({ filterCondition, onChange, schema }) => {
  const schemaProp = useMemo(
    () => schema.definition.propertiesLookup[filterCondition.field],
    [filterCondition.field, schema.definition.propertiesLookup],
  );

  if (filterCondition.type === 'date') {
    switch (filterCondition.operator) {
      case 'before':
      case 'after': {
        const value = DateTime.fromISO(filterCondition.value);
        return (
          <DesktopDatePicker
            inputFormat={dateFormat}
            onChange={(date) =>
              onChange({
                ...filterCondition,
                value: date?.toFormat(dateFormat) || '',
              })
            }
            value={value}
            renderInput={(params) => {
              return <TextField {...params} {...defaultInputProps} />;
            }}
          />
        );
      }
      case 'between':
      case 'not_between': {
        const [from, to] = filterCondition.value.map((d) =>
          DateTime.fromISO(d),
        );
        return (
          <>
            <DesktopDatePicker
              inputFormat={dateFormat}
              onChange={(date) =>
                onChange({
                  ...filterCondition,
                  value: [
                    date?.toFormat(dateFormat) || '',
                    filterCondition.value[1],
                  ],
                })
              }
              value={from}
              renderInput={(params) => {
                return <TextField {...params} {...defaultInputProps} />;
              }}
            />
            <DesktopDatePicker
              inputFormat={dateFormat}
              onChange={(date) =>
                onChange({
                  ...filterCondition,
                  value: [
                    filterCondition.value[0],
                    date?.toFormat(dateFormat) || '',
                  ],
                })
              }
              value={to}
              renderInput={(params) => {
                return <TextField {...params} {...defaultInputProps} />;
              }}
            />
          </>
        );
      }
    }
  }

  if (filterCondition.type === 'enum') {
    switch (filterCondition.operator) {
      case 'eq':
      case 'neq':
      case 'matches':
      case 'not_matches':
        return (
          <TextField
            {...defaultInputProps}
            type="text"
            select
            label={
              <FormattedMessage
                id="filter_condition_row.specify_value"
                defaultMessage="Specify value"
              />
            }
            value={filterCondition.value}
            onChange={(e) =>
              onChange({ ...filterCondition, value: e.target.value })
            }
          >
            {schemaProp.alternatives?.map((alternative) => (
              <MenuItem key={alternative} value={alternative}>
                {alternative}
              </MenuItem>
            ))}
          </TextField>
        );
      case 'none_of':
      case 'any_of':
        return (
          <TextField
            {...defaultInputProps}
            type="text"
            select
            SelectProps={{
              multiple: true,
              value: filterCondition.value || [],
              onChange: (e) =>
                onChange({
                  ...filterCondition,
                  value: e.target.value as typeof filterCondition.value,
                }),
            }}
            label={
              <FormattedMessage
                id="filter_condition_row.specify_value"
                defaultMessage="Specify value"
              />
            }
          >
            {schemaProp.alternatives?.map((alternative) => (
              <MenuItem key={alternative} value={alternative}>
                {alternative}
              </MenuItem>
            ))}
          </TextField>
        );
    }
  }

  if (filterCondition.type === 'number') {
    switch (filterCondition.operator) {
      case 'eq':
      case 'neq':
      case 'gt':
      case 'gte':
      case 'lt':
      case 'lte':
        return (
          <TextField
            {...defaultInputProps}
            type="number"
            label={
              <FormattedMessage
                id="filter_condition_row.specify_value"
                defaultMessage="Specify value"
              />
            }
            value={coerceNumberToInputValue(filterCondition.value)}
            onChange={(e) =>
              onChange({
                ...filterCondition,
                value: parseFloat(e.target.value),
              })
            }
          />
        );
      case 'between':
      case 'not_between': {
        return (
          <Stack direction="row" spacing={4}>
            <TextField
              {...defaultInputProps}
              inputProps={{ 'aria-label': 'Value 1' }}
              type="number"
              value={coerceNumberToInputValue(filterCondition.value[0])}
              onChange={(e) =>
                onChange({
                  ...filterCondition,
                  value: [parseFloat(e.target.value), filterCondition.value[1]],
                })
              }
            />
            <Typography>
              <FormattedMessage
                id="filter_condition_input.and"
                defaultMessage="and"
              />
            </Typography>
            <TextField
              {...defaultInputProps}
              inputProps={{ 'aria-label': 'Value 2' }}
              type="number"
              value={coerceNumberToInputValue(filterCondition.value[1])}
              onChange={(e) =>
                onChange({
                  ...filterCondition,
                  value: [filterCondition.value[0], parseFloat(e.target.value)],
                })
              }
            />
          </Stack>
        );
      }
      case 'any_of':
      case 'none_of':
        return (
          <MultiTextField
            {...defaultInputProps}
            type="number"
            value={filterCondition.value}
            onChange={(value: number[]) =>
              onChange({ ...filterCondition, value })
            }
          />
        );
    }
  }

  if (filterCondition.type === 'string') {
    switch (filterCondition.operator) {
      case 'eq':
      case 'neq':
      case 'matches':
      case 'not_matches':
        // String Single Arg
        return (
          <TextField
            {...defaultInputProps}
            type="text"
            label={
              <FormattedMessage
                id="filter_condition_row.specify_value"
                defaultMessage="Specify value"
              />
            }
            value={filterCondition.value || ''}
            onChange={(e) =>
              onChange({ ...filterCondition, value: e.target.value || '' })
            }
          />
        );
      case 'any_of':
      case 'none_of':
        return (
          <MultiTextField
            {...defaultInputProps}
            type="string"
            value={filterCondition.value}
            onChange={(value: string[]) =>
              onChange({ ...filterCondition, value })
            }
          />
        );
    }
  }

  if (filterCondition.type === 'array:number') {
    switch (filterCondition.operator) {
      case 'none_of':
      case 'any_of':
      case 'all_of':
        return (
          <MultiTextField
            {...defaultInputProps}
            type="number"
            value={filterCondition.value}
            onChange={(value: number[]) =>
              onChange({ ...filterCondition, value })
            }
          />
        );
    }
  }

  if (filterCondition.type === 'array:string') {
    switch (filterCondition.operator) {
      case 'none_of':
      case 'any_of':
      case 'all_of':
        return (
          <MultiTextField
            {...defaultInputProps}
            type="string"
            value={filterCondition.value}
            onChange={(value: string[]) =>
              onChange({ ...filterCondition, value })
            }
          />
        );
    }
  }
  return null;
};

export default FilterConditionInput;
