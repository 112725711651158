import React, { FC, useState } from 'react';
import StopSearch from '../../../components/Inputs/StopSearch';
import { reviveEntity } from '../../../hooks/useEntities';
import { tdGetEntities } from '../../../testdata/entities';
import { reviveEntitySchema } from '../../../hooks/useEntitySchema';
import { tdSchema } from '../../../testdata/schema';

const schema = reviveEntitySchema(tdSchema);
const entities = tdGetEntities().map((e) => reviveEntity(e, schema));

const entityLookup = entities.reduce<{ [id: string]: Entity }>((acc, e) => {
  acc[e.id] = e;
  return acc;
}, {});

const GStopSearch: FC = () => {
  const [value, setValue] = useState<string[]>([]);
  return (
    <div>
      <StopSearch
        entities={entities}
        entitiesLookup={entityLookup}
        value={value}
        onChange={setValue}
      />
    </div>
  );
};

export default GStopSearch;
