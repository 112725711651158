import React, { useState, useCallback } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import InstantUploadDropper from './InstantUploadDropper';
import { useRebusMutations } from '../../hooks/useRebus';

interface ImportRebusDataDialogProps {
  open: boolean;
  onClose: () => void;
}

const messages = defineMessages({
  fileUploaded: {
    id: 'import_rebus_Data.file_uploaded',
    defaultMessage:
      'File uploaded. You will be notified by email once the import process has concluded.',
  },
});

const ImportRebusDataDialog: React.FC<ImportRebusDataDialogProps> = ({
  open,
  onClose,
}) => {
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    upload: { mutateAsync: upload },
  } = useRebusMutations();

  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    (files: File[]) => {
      setUploading(true);
      console.log(files);
      void upload(files[0])
        .then(() => {
          setUploading(false);
          const action = (key: string | number): React.ReactNode => {
            return (
              <Button onClick={() => closeSnackbar(key)}>
                <Close />
              </Button>
            );
          };
          enqueueSnackbar(intl.formatMessage(messages.fileUploaded), {
            autoHideDuration: 10000,
            action,
          });
          onClose();
        })
        .catch(() => onClose());
    },
    [onClose, upload, enqueueSnackbar, closeSnackbar, intl, setUploading],
  );

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <FormattedMessage
            id="import_rebus_data_dialog.import_rebus_data"
            defaultMessage="Import rebus data"
          />
          <div>
            <IconButton color="inherit" onClick={() => onClose()}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box mt={4}>
            <InstantUploadDropper
              disabled={uploading}
              dropzoneOptions={{
                onDrop,
                maxFiles: 1,
                accept: [
                  'application/zip',
                  'application/x-zip',
                  'application/x-zip-compressed',
                  'application/octet-stream',
                ],
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImportRebusDataDialog;
