import { FC, ReactNode, useMemo, useState } from 'react';
import { Box, InputBase, SxProps, useTheme } from '@mui/material';
import { ColorProps } from '../../providers/ThemeProvider';
import InputContainer, { InputContainerProps } from './InputContainer';

export interface TextInputProps extends Partial<InputContainerProps> {
  value: string;
  onChange: (value: string) => void;
  tabIndex?: number;
  disabled?: boolean;
  valid?: boolean;
  palette?: ColorProps;
  endAdornment?: ReactNode;
  multiline?: boolean;
  rows?: number;
  startAdornment?: ReactNode;
  placeholder?: string;
}

const TextInput: FC<TextInputProps> = ({
  value,
  onChange,
  tabIndex,
  disabled,
  valid,
  palette,
  endAdornment,
  startAdornment,
  placeholder,
  multiline,
  rows,
  ...containerProps
}) => {
  const theme = useTheme();
  const colors = palette || theme.palette.dark;
  const [focus, setFocus] = useState<boolean>(false);

  const style = useMemo<SxProps>(
    () => ({
      display: 'flex',
      borderRadius: 1,
      // backgroundColor: 'white',
      border: '1px solid ' + colors.medium,
      alignItems: 'center',
      position: 'relative',
      height: multiline ? 'unset' : '40px',
      minWidth: 0,
      cursor: disabled ? 'default' : 'pointer',
      //This color will affect any MUI icons used, but not the input's text.
      color: colors.medium,
      '& input': {
        color: colors.medium,
      },
      p: 0.5,
      pl: 4,
      pr: endAdornment ? 2 : 4,
      '&:hover, &.focus': {
        border: '1px solid ' + colors.main,
        '& input': {
          color: colors.dark,
        },
      },
      '&.focus': {
        backgroundColor: 'white',
      },
      '& path': {
        stroke: colors.medium,
      },
      '&.disabled': {
        border: '1px solid ' + colors.light,
        color: colors.light,
        '& path:focus': {
          stroke: colors.light,
        },
      },
      '&.invalid': {
        border: '1px solid ' + theme.palette.error.main,
        '& input': {
          color: theme.palette.error.main,
        },
      },
    }),
    [
      colors.medium,
      colors.main,
      colors.dark,
      colors.light,
      multiline,
      disabled,
      endAdornment,
      theme.palette.error.main,
    ],
  );

  return (
    <InputContainer palette={colors} {...containerProps}>
      <Box
        sx={style}
        className={
          'TextInputBorderBox ' +
          (valid === undefined || valid ? 'valid' : 'invalid') +
          (focus ? ' focus' : ' unfocused') +
          (disabled ? ' disabled' : '')
        }
      >
        {startAdornment}
        <InputBase
          tabIndex={tabIndex}
          disabled={disabled}
          fullWidth
          sx={{
            flexGrow: 1,
          }}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          value={value}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        {endAdornment}
      </Box>
    </InputContainer>
  );
};

export default TextInput;
