import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Popover, TextField } from '@mui/material';

interface GroupAddPopoverProps {
  anchorEl?: HTMLButtonElement;
  open: boolean;
  onCreate: (name: string) => void;
  onClose: () => void;
}

const GroupAddPopover: React.FC<GroupAddPopoverProps> = ({
  anchorEl,
  open,
  onCreate,
  onClose,
}) => {
  useEffect(() => {
    if (!open) {
      setValue('');
    }
  }, [open]);

  const [value, setValue] = useState('');
  return (
    <Popover open={open} onClose={onClose} anchorEl={anchorEl}>
      <Box p={2}>
        <TextField
          autoFocus
          label={
            <FormattedMessage
              id="schema_management_dialog.new_group_name"
              defaultMessage="New group name"
            />
          }
          size="small"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && value.trim().length > 0) {
              onCreate(value.trim());
              onClose();
            }
          }}
        />
      </Box>
    </Popover>
  );
};

export default GroupAddPopover;
