import React, { FC, useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Help, Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';

import { reviveTag, useTagMutations, useTags } from '../../../hooks/useTags';
import useGuideStore from '../../../store/guide';
import useTagManagementDialog, {
  TagManagementTab,
} from '../../../store/tagManagementDialog';
import { TabContent, Tabs } from '../../Tabs';
import UnsavedChangesDialog from '../../UnsavedChangesDialog/UnsavedChangesDialog';
import EditTagForm from './EditTagForm';
import TagForm from './TagForm';

interface ChangeTabCB {
  type: 'changeTab';
  tab: TagManagementTab;
}
interface SelectTagCB {
  type: 'selectTag';
  tagId: string;
}
interface CloseCB {
  type: 'close';
}
type UnsavedChangesCBType = ChangeTabCB | CloseCB | SelectTagCB;

const messages = defineMessages({
  newTag: {
    id: 'tag_management_dialog.new_tag',
    defaultMessage: 'New tag',
  },
  editTag: {
    id: 'tag_management_dialog.edit_tag',
    defaultMessage: 'Edit tag',
  },
});

const TagManagementDialog: FC = () => {
  const intl = useIntl();
  const { openGuide } = useGuideStore();
  const { show, close, selectedTab, setSelectedTab } = useTagManagementDialog();
  const { lookup: tagsLookup } = useTags();
  const {
    create: { mutateAsync: createTag, isLoading: createProcessing },
    update: { mutateAsync: updateTag, isLoading: updateProcessing },
    remove: { mutateAsync: removeTag, isLoading: removeProcessing },
  } = useTagMutations();
  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [selectedTagId, setSelectedTagId] = useState<string | undefined>(
    undefined,
  );
  const [unsavedChangesCb, setUnsavedChangesCb] = useState<
    UnsavedChangesCBType | undefined
  >(undefined);

  const [selectedTag, setSelectedTag] = useState<WorkOrderTag | undefined>(
    undefined,
  );

  const resetDefaults = (): void => {
    setName(null);
    setDescription(null);
    setSelectedTagId(undefined);
    setSelectedTag(undefined);
    setUnsavedChangesCb(undefined);
  };

  const onClose = useCallback(() => {
    resetDefaults();
    close(true);
  }, [close]);

  const processing = useMemo(() => {
    return createProcessing || updateProcessing || removeProcessing;
  }, [createProcessing, removeProcessing, updateProcessing]);

  const onCreateTag = useCallback(() => {
    if (name && name.trim().length > 0) {
      void createTag({
        name: name.trim(),
        description: description ? description.trim() : undefined,
      }).then((tag) => {
        setSelectedTagId(tag.id);
        setSelectedTag(reviveTag(tag));
        setName(null);
        setSelectedTab('edit');
        setDescription(null);
      });
    }
  }, [createTag, description, name, setSelectedTab]);
  const onUpdateTag = useCallback(() => {
    if (selectedTag) {
      void updateTag({
        id: selectedTag.id,
        tag: {
          name: name?.trim() ?? selectedTag.name,
          description: description?.trim() ?? selectedTag.description,
          flags: selectedTag.flags,
        },
      }).then((tag) => {
        setSelectedTagId(tag.id);
        setSelectedTag(reviveTag(tag));
        setName(null);
        setSelectedTab('edit');
        setDescription(null);
      });
    }
  }, [description, name, selectedTag, setSelectedTab, updateTag]);
  const onRemoveTag = useCallback(() => {
    if (selectedTag) {
      void removeTag(selectedTag.id).then(() => {
        resetDefaults();
        setSelectedTab('edit');
      });
    }
  }, [removeTag, selectedTag, setSelectedTab]);

  const onResetTag = useCallback(() => {
    if (selectedTag) {
      setName(null);
      setDescription(null);
    }
  }, [selectedTag]);

  const onChangeTab = useCallback(
    (tabId: TagManagementTab) => {
      setSelectedTab(tabId);
      resetDefaults();
    },
    [setSelectedTab],
  );
  const onSelectTag = useCallback(
    (tagId: string) => {
      setName(null);
      setDescription(null);
      setUnsavedChangesCb(undefined);
      setSelectedTagId(tagId);
      setSelectedTag(tagsLookup[tagId]);
    },
    [tagsLookup],
  );

  const confirmUnsavedChanges = useCallback(
    (cbtype?: UnsavedChangesCBType) => {
      const type = cbtype || unsavedChangesCb;
      if (!type) {
        return;
      }
      if (type.type === 'close') {
        onClose();
      } else if (type.type === 'changeTab') {
        onChangeTab(type.tab);
      } else {
        onSelectTag(type.tagId);
      }
    },
    [onChangeTab, onClose, onSelectTag, unsavedChangesCb],
  );

  const tryChange = (cbtype: UnsavedChangesCBType): void => {
    // Check if new value exist and is changed from
    if (
      (description !== null && description !== selectedTag?.description) ||
      (name !== null && name !== selectedTag?.name)
    ) {
      setUnsavedChangesCb(cbtype);
    } else {
      confirmUnsavedChanges(cbtype);
    }
  };

  const isPristine = name === null && description === null;
  return (
    <>
      <UnsavedChangesDialog
        open={!!unsavedChangesCb}
        onCancel={() => setUnsavedChangesCb(undefined)}
        onOk={() => confirmUnsavedChanges()}
      />
      <Dialog
        open={show}
        onClose={() => tryChange({ type: 'close' })}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '500px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle>
          <FormattedMessage
            id="tag_management_dialog.manage_tags"
            defaultMessage="Manage tags"
          />
          <div>
            <IconButton
              color="inherit"
              onClick={() => openGuide('TagManagementDialog')}
            >
              <Help />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => tryChange({ type: 'close' })}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <Box display="flex" height="500px" pt={1}>
          <Tabs
            selectedTab={selectedTab}
            onClick={(t) =>
              tryChange({ type: 'changeTab', tab: t as TagManagementTab })
            }
            sx={{
              height: 'unset',
              flexGrow: 1,
            }}
          >
            <TabContent
              tabId="edit"
              label={intl.formatMessage(messages.editTag)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'stretch',
              }}
            >
              <EditTagForm
                setSelectedTagId={(tagId) =>
                  tryChange({ type: 'selectTag', tagId })
                }
                selectedTagId={selectedTagId}
                name={name ?? selectedTag?.name ?? ''}
                isNameValid={name === null || name.length > 0}
                onNameChange={setName}
                description={description ?? selectedTag?.description ?? ''}
                onDescriptionChange={setDescription}
                processing={processing}
                buttonProps={{
                  disableSubmit: isPristine,
                  onSaveTag: onUpdateTag,
                  onRemoveTag: onRemoveTag,
                  removeEnabled: !!selectedTag,
                  onResetTag: onResetTag,
                  resetEnabled:
                    selectedTag &&
                    ((description !== null &&
                      description !== selectedTag.description) ||
                      (name !== null && name !== selectedTag.name)),
                }}
              />
            </TabContent>
            <TabContent
              tabId="new"
              label={intl.formatMessage(messages.newTag)}
              sx={{
                backgroundColor: 'background.dark',
                display: 'flex',
                flexDirection: 'column',
                padding: 4,
              }}
            >
              <TagForm
                name={name || ''}
                isNameValid={name === null || name.length > 0}
                onNameChange={setName}
                description={description || ''}
                onDescriptionChange={setDescription}
                processing={processing}
                buttonProps={{
                  onSaveTag: onCreateTag,
                }}
              />
            </TabContent>
          </Tabs>
        </Box>
      </Dialog>
    </>
  );
};

export default TagManagementDialog;
