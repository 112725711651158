import React, { FC, ReactNode } from 'react';
import { BoxProps } from '@mui/material';

export interface TabContentProps extends BoxProps {
  keepMounted?: boolean;
  tabId: string;
  label: string;
  disabled?: boolean;
  default?: true;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  renamingAllowed?: boolean;
}

export const TabContent: FC<TabContentProps> = ({ children }) => {
  return <>{children}</>;
};

export default TabContent;
