import React, { ReactNode } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface ContainedIconProps {
  circleColor?: BoxProps['color'];
  sx?: SxProps<Theme>;
  svgSx?: SxProps<Theme>;
  children?: ReactNode;
}

export const ContainedIcon: React.FC<ContainedIconProps> = ({
  children,
  sx,
  circleColor,
}) => {
  const defaultIfCircleProps = circleColor
    ? {
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: circleColor,
        alignItems: 'center',
        justifyContent: 'center',
      }
    : {};
  return (
    <Box
      display="flex"
      sx={{
        color: 'inherit',
        ...defaultIfCircleProps,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
