import React, { useMemo } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Box, CircularProgress } from '@mui/material';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  minWidth: '300px',
  minHeight: '150px',
};

const activeStyle = {
  borderColor: '#2196f3',
};
const acceptStyle = {
  borderColor: '#00e676',
};
const rejectStyle = {
  borderColor: '#ff1744',
};

interface InstantUploadDropperProps {
  disabled?: boolean;
  dropzoneOptions?: DropzoneOptions;
}

const InstantUploadDropperProps: React.FC<InstantUploadDropperProps> = ({
  disabled,
  dropzoneOptions,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Box {...getRootProps({ style })}>
      <input disabled={disabled} {...getInputProps()} />
      {disabled ? (
        <p>
          <CircularProgress />
        </p>
      ) : isDragActive ? (
        <p>
          <FormattedMessage
            id="instant_upload_dropper.drop_file_here"
            defaultMessage="Drop file here..."
          />
        </p>
      ) : (
        <p>
          <FormattedMessage
            id="instant_upload_dropper.drag_or_click"
            defaultMessage="Drag a file here, or click to select"
          />
        </p>
      )}
    </Box>
  );
};

export default InstantUploadDropperProps;
