import React, {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Theme, SxProps, useTheme, InputBase } from '@mui/material';
import { ColorProps } from '../../providers/ThemeProvider';

interface TabProps {
  sx?: SxProps<Theme>;
  label: string;
  selected?: boolean;
  onClick: (tabId: string) => void;
  disabled?: boolean;
  id: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  palette?: ColorProps;
  onRename?: (tabId: string, newLabel: string) => void;
}

const Tab: FC<TabProps> = ({
  sx,
  disabled,
  endAdornment,
  id,
  label,
  onClick,
  selected,
  startAdornment,
  palette,
  onRename,
}) => {
  const theme = useTheme();
  const colors = palette || theme.palette.wo;
  const renameInput = useRef<HTMLInputElement>();
  const [renaming, setRenaming] = useState<string | undefined>(undefined);

  const internalSx = useMemo<SxProps<Theme>>(() => {
    return {
      display: 'flex',
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 0.5,
      height: '34px',
      marginLeft: 1,
      marginRight: 1,
      typography: 'button',
      backgroundColor: 'rgba(0,0,0,0)',
      color: colors.main,
      cursor: 'pointer',
      borderRadius: '4px 4px 0 0',
      whiteSpace: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 200ms ease',
      textTransform: 'none',
      flexGrow: 1,
      ...sx,
      '&.selected': {
        cursor: onRename ? 'text' : 'default',
        backgroundColor: colors.main,
        color: colors.contrastText,
      } as SxProps<Theme> as any,
      '&.disabled': {
        cursor: 'default',
        backgroundColor: 'rgba(0,0,0,0)',
        color: colors.a20,
      } as SxProps<Theme> as any,
      '&:hover:not(.disabled, .selected)': {
        backgroundColor: colors.light,
      } as SxProps<Theme> as any,
      '& .renameBox': {
        backgroundColor: theme.palette.light.a40,
        border: '1px solid ' + theme.palette.light.main,
      },
      '& input': {
        color: theme.palette.light.contrastText,
        padding: 0,
        pl: 1,
      },
    };
  }, [colors, sx, theme, onRename]);

  const doRename = useCallback<
    FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >(
    (ev) => {
      const value = (ev.currentTarget.value || '').trim();
      ev.stopPropagation();
      ev.preventDefault();
      if (onRename && value && value.length > 0) {
        onRename(id, value);
      }
      setRenaming(undefined);
    },
    [id, onRename],
  );

  const doClick = useCallback(() => {
    if (!disabled && selected && onRename) {
      setRenaming(label);
      renameInput.current && renameInput.current.focus();
      return;
    }
    if (!disabled) {
      onClick(id);
    }
  }, [disabled, id, label, onClick, onRename, selected]);

  const onKeyDown = useCallback<
    KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >((ev) => {
    switch (ev.key) {
      case 'Escape': {
        setRenaming(undefined);
        ev.stopPropagation();
        ev.preventDefault();
        return;
      }
      case 'Enter': {
        ev.stopPropagation();
        ev.preventDefault();
        //Blurring the input will trigger "doRename" callback.
        renameInput.current && renameInput.current.blur();
        return;
      }
      default: {
        return;
      }
    }
  }, []);

  return (
    <Box
      sx={internalSx}
      className={`${disabled ? 'disabled ' : ''}${
        selected && !disabled ? 'selected ' : ''
      }`}
      onClick={doClick}
    >
      {startAdornment}
      {onRename && renaming !== undefined ? (
        <InputBase
          value={renaming}
          fullWidth
          className="renameBox"
          inputRef={renameInput}
          autoFocus
          onBlur={doRename}
          onKeyDown={onKeyDown}
          onChange={(ev) => setRenaming(ev.currentTarget.value)}
          onClick={(ev) => ev.stopPropagation()}
        />
      ) : (
        label
      )}
      {endAdornment}
    </Box>
  );
};

export default Tab;
