/* eslint-disable @typescript-eslint/no-inferrable-types */
import proj4 from 'proj4';
export const roundToDecimals = (v: number, decimals = 2): number =>
  Math.round(v * Math.pow(10, decimals)) / Math.pow(10, decimals);

const digitRegex = /(\D+)?(\d+)/g;
const separatorRegex = /\.|,/;
export const sanitizedParseFloat = (v: string): number => {
  if (typeof v !== 'string' || v.length === 0) {
    return NaN;
  }
  const numbers = [...v.matchAll(digitRegex)];
  const sanitizedValue = numbers.reduce<string>((acc, digits, i) => {
    if (i === numbers.length - 1 && separatorRegex.test(digits[1])) {
      return acc + '.' + digits[2];
    }
    return acc + digits[2];
  }, '');
  return parseFloat(sanitizedValue);
};

let isProjInitialized: boolean = false;
export const initProj4 = (): void => {
  proj4.defs(
    'EPSG:3021',
    '+proj=tmerc +lat_0=0 +lon_0=15.80827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs',
  );
  proj4.defs(
    'EPSG:3006',
    '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  );
  isProjInitialized = true;
};
export type Projection = 'WGS84' | 'EPSG:3021' | 'EPSG:3006';
type ProjectionWithLabel = {
  label: string;
  projection: Projection;
};
export const projectionsWithLabels: Record<Projection, ProjectionWithLabel> = {
  'EPSG:3021': {
    label: 'RT90',
    projection: 'EPSG:3021',
  },
  'EPSG:3006': {
    label: 'SWEREF99',
    projection: 'EPSG:3006',
  },
  WGS84: {
    label: 'WGS84',
    projection: 'WGS84',
  },
};

export const proj = (
  from: Projection,
  to: Projection,
  coordinate: [number, number],
): [number, number] => {
  if (!isProjInitialized) {
    initProj4();
  }
  return proj4(from, to, coordinate);
};

/**
 * Min is inclusive, max exclusive.
 */
export const randInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min) + min);

export const round = (val: number, decimals: number = 0): number => {
  const p = Math.pow(10, decimals);
  const n = val * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};
