import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Tab from './Tab';
import { TabContentProps } from './TabContent';
import { Box, BoxProps, SxProps } from '@mui/material';
import { ColorProps } from '../../providers/ThemeProvider';

export interface TabData {
  label: string;
  id: string;
  disabled?: boolean;
  default?: true;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  /** Defaults to false. */
  renamingAllowed?: boolean;
}

interface WrappedChild {
  component: ReactElement | null;
  tabId: string;
  label: string;
  disabled: boolean;
  default?: true;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  renamingAllowed?: boolean;
}

interface TabsProps {
  sx?: BoxProps['sx'];
  /**
   * Grow the tabs to occupy the full width of the parent container, will resize tabs equally.
   *
   * Default: false.
   */
  fullWidth?: boolean;
  /**
   * A border that runs below the entire length of the parent container and takes the color of the selected tab.
   *
   * Default: true.
   */
  showBottomBorder?: boolean;
  /**
   * Color scheme.
   *
   * Default: 'theme.palette.wo'.
   */
  palette?: ColorProps;
  onClick?: (tabId: string) => void;
  /**
   * Callback for when a tab is renamed. Renaming is only enabled on a selected tab and if that tabs data is set to allow it.
   * Rename by clicking the tab again.
   */
  onRename?: (tabId: string, newLabel: string) => void;
  children?: ReactNode;
  /**
   * If not using TabContent child components specify an array of TabData.
   * TabData provided with the same ID as a TabContent's tabId will be overwritten by the TabContent.
   *
   * Default: undefined.
   */
  tabs?: TabData[];
  /**
   * Optionally use managed mode by providing the selectedTab id.
   */
  selectedTab?: string;
  endAdornment?: ReactNode;
}

const mountedInvisibleStyle: SxProps = {
  position: 'fixed',
  left: '-100px',
  top: 0,
  width: '10px',
  height: '10px',
  overflow: 'hidden',
};
const visibleStyle: SxProps = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

export const Tabs: FC<TabsProps> = ({
  children,
  palette,
  onClick,
  onRename,
  selectedTab,
  showBottomBorder,
  tabs,
  fullWidth,
  endAdornment,
  sx,
}) => {
  const [selectedTabId, setSelectedTabId] = useState<string>('');
  const wrappedChildren = useMemo(() => {
    const wrapped: WrappedChild[] = [];
    if (children && typeof children === 'object' && Array.isArray(children)) {
      for (const child of children as React.ReactElement[]) {
        if (!child) {
          //Child might be nullish, such as if the child is conditionally mounted and sometimes returns null or undefined.
          continue;
        }
        if (
          child &&
          (!child.props || !child.props.tabId || !child.props.label)
        ) {
          console.error(
            'Invalid child component. Tabs only accepts TabContent children.',
            child,
          );
          continue;
        }
        const {
          default: isDefault,
          disabled,
          endAdornment,
          keepMounted,
          label,
          startAdornment,
          tabId,
          renamingAllowed,
          ...boxProps
        } = child.props as TabContentProps;

        const wrappedChild: WrappedChild = {
          component: null,
          default: isDefault,
          disabled: !!disabled,
          endAdornment,
          label,
          startAdornment,
          tabId,
          renamingAllowed,
        };

        if (tabId === selectedTab || tabId === selectedTabId) {
          wrappedChild.component = (
            <Box
              {...boxProps}
              key={tabId}
              sx={{ ...boxProps.sx, ...visibleStyle } as SxProps}
              className={`tabContent ${tabId}`}
            >
              {child}
            </Box>
          );
        } else if (keepMounted) {
          wrappedChild.component = (
            <Box
              {...boxProps}
              key={tabId}
              sx={mountedInvisibleStyle}
              className={`tabContent ${tabId}`}
            >
              {child}
            </Box>
          );
        }

        wrapped.push(wrappedChild);
      }
    }
    return wrapped;
  }, [children, selectedTab, selectedTabId]);

  const allTabs = useMemo<TabData[]>(() => {
    const combinedTabs: TabData[] = tabs
      ? tabs.filter(
          (t) => wrappedChildren.findIndex((wc) => wc.tabId === t.id) === -1,
        )
      : [];
    if (wrappedChildren.length > 0) {
      combinedTabs.push(
        ...wrappedChildren.map<TabData>((wc) => ({
          id: wc.tabId,
          label: wc.label,
          disabled: wc.disabled,
          default: wc.default,
          startAdornment: wc.startAdornment,
          endAdornment: wc.endAdornment,
          renamingAllowed: wc.renamingAllowed,
        })),
      );
    }
    return combinedTabs;
  }, [tabs, wrappedChildren]);

  useEffect(() => {
    if (!selectedTabId) {
      const defaultTabs = allTabs.filter((t) => t.default);
      if (defaultTabs.length > 0) {
        setSelectedTabId(defaultTabs[0].id);
      }
    }
  }, [selectedTabId, allTabs]);
  useEffect(() => {
    selectedTab && setSelectedTabId(selectedTab);
  }, [selectedTab]);

  const bottomBorder = useMemo(() => {
    // if (wrappedChildren.wrappedContent.length > 0) {
    //   return true;
    // }
    return typeof showBottomBorder !== 'boolean' || showBottomBorder;
  }, [showBottomBorder]);

  const onTabClick = useCallback(
    (tabId: string) => {
      if (onClick) {
        onClick(tabId);
      } else {
        setSelectedTabId(tabId);
      }
    },
    [onClick],
  );

  return (
    <Box
      sx={{
        height: wrappedChildren.length > 0 ? '100%' : 'initial',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      className="tabs"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 0,
          flexBasis: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: fullWidth ? 1 : 'unset',
            alignItems: 'flex-end',
          }}
        >
          {allTabs.map((t) => {
            return (
              <Tab
                key={t.id}
                {...t}
                onClick={onTabClick}
                onRename={t.renamingAllowed && onRename ? onRename : undefined}
                palette={palette}
                selected={selectedTab === t.id || selectedTabId === t.id}
              />
            );
          })}
        </Box>
        {endAdornment}
      </Box>
      {bottomBorder && (
        <Box
          sx={{
            height: '5px',
            backgroundColor: palette ? palette.main : 'wo.main',
          }}
        >
          &nbsp;
        </Box>
      )}
      {wrappedChildren.length > 0 && (
        <Box display="flex" flexGrow={1} flexBasis={0}>
          {wrappedChildren.map((wc) => wc.component)}
        </Box>
      )}
    </Box>
  );
};

export default Tabs;
