import { Column, ColumnGroup, TableData } from '../components/Table';
import { ValueGetterParams } from 'ag-grid-community';
import { defineMessages, IntlShape } from 'react-intl';
import {
  FormatNumberOpts,
  formatEntityPropValue,
  formatNumber,
} from './formatting_helpers';
import { CellObject, utils, WorkBook, WorkSheet } from 'xlsx';
import { proj, projectionsWithLabels } from './math_helpers';
import { DateTime } from 'luxon';

const colExpFilter = (col: Column): boolean => {
  return col.exportable !== false;
};

const messages = defineMessages({
  noHelpText: {
    id: 'no_help_text_defined',
    defaultMessage: 'No help text defined',
  },
});

const filterExportableColumns = (
  columns: (Column | ColumnGroup)[],
): Column[] => {
  return columns.reduce((allCol: Column[], currCol: ColumnGroup | Column) => {
    if ('children' in currCol) {
      const filteredCols = currCol.children.filter(colExpFilter);
      allCol.push(...filteredCols);
    } else if (currCol.exportable !== false) {
      allCol.push(currCol);
    }
    return allCol;
  }, []);
};

/* You won't believe it, but these things have apparently been broken in
 * firefox and chrome since 2007, at least. A quick read suggests there is something
 * inherently wrong in the design of ECMA script regexes that causes regexes to not
 * reset their internal state after execution, meaning every other call to test(),
 * match(), etc. will fail.
 *
 * The solution, I guess, would be to not reuse regexes and just create a new
 * regex for every execution. Yuck!
 *
 * Enjoy the insanity:
 *   https://stackoverflow.com/questions/3891641/regex-test-only-works-every-other-time#comment36309570_3891672
 *
const escape_regex = /[",\n]/g;
const replace_regex = /"/g;
*/

export const csvFromTableData = (
  schema: EntitySchema,
  profile: UserProfile,
  table_data: TableData,
): string => {
  const { data, columns } = table_data;
  const exportedCols = filterExportableColumns(columns)
    .filter((col) => !schema?.extras[col.colId]?.hidden)
    .filter(
      (col) =>
        profile.visible_columns.length === 0 ||
        profile.visible_columns.includes(col.colId),
    );

  const projection = profile.projection;
  const projection_label = projectionsWithLabels[projection].label;

  const headers = exportedCols
    .flatMap((col) => {
      return col.format === 'location'
        ? [
            `${col.headerName || ''} ${projection_label}`,
            `${col.headerName || ''} ${projection_label} X`,
            `${col.headerName || ''} ${projection_label} Y`,
          ]
        : col.headerName || '';
    })
    .join(',');

  const rows: string[] = data.map((rowData) =>
    exportedCols
      .flatMap((c) => {
        if (c.format === 'number') {
          const number_options = schema.extras[c.colId]?.number_options;
          const v = rowData.properties[c.colId];
          return typeof v !== 'undefined'
            ? number_options
              ? formatNumber(v as number, number_options)
              : rowData.properties[c.colId] + ''
            : '';
        } else if (c.format === 'location') {
          const coord: [number, number] = rowData.properties[c.colId];
          if (!coord) {
            return ['', ''];
          }
          const [x, y] = proj('WGS84', profile.projection, coord);

          const ndecimals = profile.projection === 'WGS84' ? 5 : 0;
          return [
            `${formatNumber(x, {
              grouping: false,
              roundTo: ndecimals,
              toFixed: ndecimals,
            })}, ${formatNumber(y, {
              grouping: false,
              roundTo: ndecimals,
              toFixed: ndecimals,
            })}`,
            formatNumber(x, {
              grouping: false,
              roundTo: ndecimals,
              toFixed: ndecimals,
            }),
            formatNumber(y, {
              grouping: false,
              roundTo: ndecimals,
              toFixed: ndecimals,
            }),
          ];
        } else if (c.format === 'array:string' || c.format === 'array:number') {
          const v = rowData.properties[c.colId];
          return typeof v !== 'undefined'
            ? (v as string[] | number[]).map((x) => x.toString()).join(', ')
            : [];
        }
        return typeof rowData.properties[c.colId] === 'undefined'
          ? ''
          : rowData.properties[c.colId] + '';
      })
      .map((v) => {
        const data = /[",\n]/g.test(v) ? `"${v.replace(/"/g, '""')}"` : v;
        return data;
      })
      .join(','),
  );

  return `${headers}\n${rows.join('\n')}`;
};

const createCell = (t: CellObject['t'], v: any): CellObject => {
  return typeof v === 'undefined' ? { t: 'z', v } : { t, v };
};

const createCellNumber = (
  v: number,
  number_options: FormatNumberOpts,
): CellObject => {
  const formatted_number = formatNumber(v, number_options);
  return createCell('n', parseFloat(formatted_number));
};

const xlsxBooleanCoercer = (input: any): CellObject => {
  const v = typeof input === 'boolean' || input === null ? undefined : !!input;
  return createCell('b', v);
};

const xlsxDateCoercer = (input: any): CellObject => {
  const v = DateTime.isDateTime(input) ? input.toJSDate() : undefined;
  return createCell('d', v);
};

const xlsxNumberCoercer = (
  schema: EntitySchema,
  colId: string,
  input: any,
): CellObject => {
  const v =
    typeof input === 'number' && !Number.isNaN(input) && Number.isFinite(input)
      ? input
      : undefined;

  if (typeof v === 'number') {
    const number_options = schema.extras[colId]?.number_options;
    if (number_options) {
      return createCellNumber(v, number_options);
    }
  }

  return createCell('n', v);
};

const xlsxArrayNumberCoercer = (input: any): CellObject => {
  const v: number[] | undefined = Array.isArray(input) ? input : undefined;

  const num_arr = v?.map((x) => x.toString()).join(', ');
  return createCell('s', num_arr);
};

const xlsxArrayStringCoercer = (input: any): CellObject => {
  const v: string[] | undefined = Array.isArray(input) ? input : undefined;

  const str_arr = v?.map((x) => x.toString()).join(', ');
  return createCell('s', str_arr);
};

const xlsxLocationCoercer = (
  profile: UserProfile,
  input: any,
): CellObject[] => {
  if (
    !input ||
    !Array.isArray(input) ||
    input.length !== 2 ||
    input.some((x) => typeof x !== 'number' || Number.isNaN(x))
  ) {
    return [
      createCell('z', undefined),
      createCell('z', undefined),
      createCell('z', undefined),
    ];
  }
  const [x, y] = proj('WGS84', profile.projection, input as [number, number]);
  const decimals = profile.projection === 'WGS84' ? 5 : 0;
  return [
    createCell(
      's',
      `${formatNumber(x, {
        grouping: false,
        roundTo: decimals,
        toFixed: decimals,
      })}, ${formatNumber(y, {
        grouping: false,
        roundTo: decimals,
        toFixed: decimals,
      })}`,
    ),
    createCellNumber(x, {
      grouping: false,
      roundTo: decimals,
      toFixed: decimals,
    }),
    createCellNumber(y, {
      grouping: false,
      roundTo: decimals,
      toFixed: decimals,
    }),
  ];
};

const xlsxStringCoercer = (input: any): CellObject => {
  const v = input ? (input as string).toString() : undefined;
  return createCell('s', v);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const xlsxValueCoercer = (
  schema: EntitySchema,
  profile: UserProfile,
  c: Column,
  v: any,
): CellObject | CellObject[] => {
  switch (c.format) {
    case 'enum':
    case 'photo':
    case 'string': {
      return xlsxStringCoercer(v);
    }
    case 'boolean': {
      return xlsxBooleanCoercer(v);
    }
    case 'date': {
      return xlsxDateCoercer(v);
    }
    case 'number': {
      return xlsxNumberCoercer(schema, c.colId, v);
    }
    case 'array:number': {
      return xlsxArrayNumberCoercer(v);
    }
    case 'array:string': {
      return xlsxArrayStringCoercer(v);
    }
    case 'location': {
      return xlsxLocationCoercer(profile, v);
    }
    default: {
      throw new Error(`Unknown format: ${c.format as string}`);
    }
  }
};

interface XlsxSheetWrapper {
  title: string;
  sheet: WorkSheet;
}

export const xlsxSheetFromTableData = (
  schema: EntitySchema,
  profile: UserProfile,
  table_data: TableData,
): XlsxSheetWrapper => {
  const { data, columns } = table_data;
  const exportedCols = filterExportableColumns(columns)
    .filter((col) => !schema?.extras[col.colId]?.hidden)
    .filter(
      (col) =>
        profile.visible_columns.length === 0 ||
        profile.visible_columns.includes(col.colId),
    );

  const rows = data.map((rowData) =>
    exportedCols.flatMap((column) => {
      const val = rowData.properties[column.colId ?? ''];
      return xlsxValueCoercer(schema, profile, column, val);
    }),
  );

  const projection = profile.projection;
  const projection_label = projectionsWithLabels[projection].label;

  const sheet_data = [
    exportedCols.flatMap((c) =>
      c.format === 'location'
        ? [
            {
              t: 's',
              v: c.groupName
                ? `${c.groupName}: ${c.headerName || ''} ${projection_label}`
                : `${c.headerName || ''} ${projection_label}`,
            },
            {
              t: 's',
              v: c.groupName
                ? `${c.groupName}: ${c.headerName || ''} ${projection_label} X`
                : `${c.headerName || ''} ${projection_label} X`,
            },
            {
              t: 's',
              v: c.groupName
                ? `${c.groupName}: ${c.headerName || ''} ${projection_label} Y`
                : `${c.headerName || ''} ${projection_label} Y`,
            },
          ]
        : {
            t: 's',
            v: c.groupName
              ? `${c.groupName}: ${c.headerName || ''}`
              : c.headerName || '',
          },
    ),
    ...rows,
  ];

  const sheet = utils.aoa_to_sheet(sheet_data);

  sheet['!cols'] = [];
  for (let col = 0; col < sheet_data[0].length; col++) {
    let sz = 0;
    for (let row = 0; row < sheet_data.length; row++) {
      const cell = sheet_data[row][col];
      const len = cell.v ? cell.v.toString().length : 10;
      sz = Math.max(sz, len);
    }

    sheet['!cols'].push({ wch: sz });
  }

  return {
    title: table_data.title || '',
    sheet,
  };
};

export const xlsxWorkBookFromTableDatas = (
  schema: EntitySchema,
  profile: UserProfile,
  table_datas: TableData[],
): WorkBook => {
  const book = utils.book_new();

  return table_datas
    .map((data) => xlsxSheetFromTableData(schema, profile, data))
    .reduce<WorkBook>((book, sheet_wrapper) => {
      utils.book_append_sheet(book, sheet_wrapper.sheet, sheet_wrapper.title);
      return book;
    }, book);
};

export const tableColumnsFromSchemaProps = (
  schema: EntitySchema,
  intl: IntlShape,
  profile: UserProfile,
): (Column<Entity> | ColumnGroup<Entity>)[] => {
  return schema.definition.properties.reduce(
    (
      allCol: (Column<Entity> | ColumnGroup<Entity>)[],
      col: ApiEntitySchemaProp,
    ): (Column<Entity> | ColumnGroup<Entity>)[] => {
      const { width, number_options, date_options, help_text } =
        schema.extras[col.key] || {};

      const sorter = col.key === 'meta.id' ? stopIdSorter : undefined;

      const defaultColDef: Column<Entity> = {
        format: col.type,
        colId: col.key,
        headerName: col.name,
        comparator: sorter,
        exportable: col.type !== 'photo',
        width: width || 140,
        headerTooltip: `${
          help_text || intl.formatMessage(messages.noHelpText)
        }`,
        valueGetter: (params: ValueGetterParams<Entity>) => {
          if (params?.data?.properties[col.key] === undefined) return undefined;
          return formatEntityPropValue(
            params?.data?.properties[col.key],
            intl,
            col.type,
            profile,
            { ...number_options, ...date_options },
          );
        },
        ...(col.group_id
          ? { groupName: schema.definition.groupsLookup[col.group_id].name }
          : {}),
      };

      // Create and append data to column groups
      if (col.group_id) {
        const groupName = schema.definition.groupsLookup[col.group_id].name;
        defaultColDef.tooltipComponentParams = { groupName: groupName };

        let defaultColGroup: ColumnGroup<Entity> = {
          children: [],
        };
        const index = allCol.findIndex((x) => x.headerName === groupName);
        if (index !== -1) {
          (allCol[index] as ColumnGroup<Entity>).children.push(defaultColDef);
          return allCol;
        }
        defaultColGroup = {
          headerName: groupName,
          children: [defaultColDef],
        };
        allCol.push(defaultColGroup);
        return allCol;
      }
      allCol.push(defaultColDef);
      return allCol;
    },
    [],
  );
};

export const filterIndicatorSorter = (a: number, b: number): number => {
  if (a === b) {
    return 0;
  }

  if (a === -1) {
    return 1;
  }

  if (b === -1) {
    return -1;
  }

  return b - a;
};

export const parseIntSorter = (a: string, b: string): number => {
  const aInt = parseInt(a, 10);
  const bInt = parseInt(b, 10);

  if (isNaN(aInt) || isNaN(bInt)) {
    return 0;
  }

  return aInt - bInt;
};

export const stopIdSorter = (a: string, b: string): number => {
  const intSort = parseIntSorter(a, b);
  if (intSort === 0) {
    //They have the same station id, use letter to diff.
    return a.localeCompare(b, 'sv', {});
  }
  return intSort;
};

export const tableColumnsFromProfile = (
  columns: (Column<Entity> | ColumnGroup<Entity>)[],
  profile: UserProfile,
): (Column<Entity> | ColumnGroup<Entity>)[] => {
  const visibleColumns = profile.visible_columns;
  if (visibleColumns.length === 0) {
    return columns;
  }

  return columns.map<Column | ColumnGroup>((c) => {
    if ('children' in c) {
      return {
        ...c,
        children: c.children.map((child) => {
          if (visibleColumns.includes(child.colId)) {
            return { ...child, hide: false };
          }
          return { ...child, hide: true };
        }),
      };
    }
    if (visibleColumns.includes(c.colId)) {
      return { ...c, hide: false };
    }
    return { ...c, hide: true };
  });
};
