import React, { FC, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import MobilixLogo from './MobilixLogo';
import { useSearchParams } from 'react-router-dom';

interface LoginFailedScreenProps {
  onConfirm: () => void;
}

const messages = defineMessages({
  not_part_of_org: {
    id: 'login_failed_not_part_of_org',
    defaultMessage: 'You are not part of the selected organization.',
  },
  too_many_attempts: {
    id: 'login_failed_too_many_attempts',
    defaultMessage: 'Too many login attempts.',
  },
  password_leaked: {
    id: 'login_failed_password_leaked',
    defaultMessage:
      'Your password has been leaked. Please reset your password by clicking ”Forgot password?” on the login screen.',
  },
  unauthorized: {
    id: 'login_failed_unauthorized',
    defaultMessage:
      'Your account is not available. Contact support at support@allbinary.se.',
  },
});

const LoginFailedScreen: FC<LoginFailedScreenProps> = ({ onConfirm }) => {
  const intl = useIntl();
  const [params] = useSearchParams();
  const errorDescription = useMemo(() => {
    const err = params.get('error');
    const error_description = params.get('error_description');
    if (error_description?.includes('not part of the')) {
      return intl.formatMessage(messages.not_part_of_org);
    }
    switch (err) {
      case 'too_many_attempts':
        return intl.formatMessage(messages.too_many_attempts);
      case 'password_leaked':
        return intl.formatMessage(messages.password_leaked);
      case 'unauthorized':
        return intl.formatMessage(messages.unauthorized);
      default:
        return '';
    }
  }, [intl, params]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 3,
          width: '520px',
        }}
      >
        <Box>
          <MobilixLogo />
        </Box>
        <Typography variant="h3" align="left" width="100%" color="error">
          <FormattedMessage
            id="login_failed_header"
            defaultMessage="Login failed"
          />
        </Typography>
        {errorDescription && (
          <Typography variant="body1" align="left" width="100%" color="error">
            {errorDescription}
          </Typography>
        )}
        <Typography variant="body1" align="left" width="100%" color="error">
          <FormattedMessage
            id="login_failed_instructions"
            defaultMessage="Click to login again."
          />
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '400px',
            gap: 3,
            mt: 4,
          }}
        >
          <Button fullWidth variant="contained" onClick={onConfirm}>
            <FormattedMessage id="ok" defaultMessage="Ok" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginFailedScreen;
