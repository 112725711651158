import React, { FC } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { SearchResult } from '../../store/search';
import { FormattedMessage } from 'react-intl';

interface ResultColumnHederProps {
  type: SearchResult['type'];
  empty: boolean;
}

const ResultColumnHeader: FC<ResultColumnHederProps> = ({ type, empty }) => {
  let colorRef = 'lightgrey';

  switch (type) {
    case 'workorder': {
      colorRef = 'wo.light';
      break;
    }
    case 'entityGroup': {
      colorRef = 'entity.light';
      break;
    }
    default:
      break;
  }

  return (
    <Card
      elevation={0}
      sx={{
        cursor: 'default',
        borderRadius: '4px 4px 0 0',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
    >
      <Box
        sx={{
          padding: ({ spacing }) => '0 ' + spacing(2),
          backgroundColor: colorRef,
        }}
      >
        <Typography variant="subtitle2" color="black">
          {type === 'contractor' && (
            <FormattedMessage
              id="search.column.contractor"
              defaultMessage="Contractor"
            />
          )}
          {type === 'filterSet' && (
            <FormattedMessage
              id="search.column.filter_set"
              defaultMessage="Filter set"
            />
          )}
          {type === 'entityGroup' && (
            <FormattedMessage
              id="search.column.entity_group"
              defaultMessage="Station"
            />
          )}
          {type === 'workorder' && (
            <FormattedMessage
              id="search.column.workorder"
              defaultMessage="Workorder"
            />
          )}
        </Typography>
      </Box>
      {empty && (
        <Typography component="p" mt={4} sx={{ textAlign: 'center' }}>
          <FormattedMessage
            id="search.no_matches_of_type"
            defaultMessage="No matches of this type"
          />
        </Typography>
      )}
    </Card>
  );
};

export default ResultColumnHeader;
