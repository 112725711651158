import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ConfirmDialogProps {
  open: boolean;
  titleLabel?: React.ReactNode;
  confirmLabel?: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  children,
  onConfirm,
  onCancel,
  confirmLabel,
  titleLabel,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        {titleLabel ? (
          titleLabel
        ) : (
          <FormattedMessage
            id="confirm_dialog.are_you_sure"
            defaultMessage="Are you sure?"
          />
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginTop: '1rem', whiteSpace: 'pre-wrap' }}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
          >
            <FormattedMessage
              id="filter_management_dialog.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
          }}
        >
          {confirmLabel ? (
            confirmLabel
          ) : (
            <FormattedMessage
              id="filter_management_dialog.yes"
              defaultMessage="Yes"
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
