import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useApiClient } from './useApiClient';
import { useQuery } from 'react-query';
import { useCallback, useEffect } from 'react';
import { unserializeEntityProps } from './useEntities';
import { useEntitySchema } from './useEntitySchema';

export const reviveEntityChangeset: ReviverFn<
  ApiEntityChangeset,
  EntityChangeset
> = (cs, schema) => {
  const props = unserializeEntityProps(schema as EntitySchema, cs.properties);
  return { ...cs, meta: reviveMetadata(cs.meta), properties: props };
};

const useListAndLookup = createListAndLookupStore(
  reviveEntityChangeset,
  'entities.changeSets',
);

export const useEntityChangesets = (
  entityId: Entity['id'],
): UseListAndLookupQuery<ApiEntityChangeset, EntityChangeset> => {
  const { data: schema } = useEntitySchema();
  const { set, list, lookup } = useListAndLookup(
    useCallback((props) => props, []),
  );

  useEffect(() => {
    /*
    Passing schema as undefined as it is unnecessary when using set on an
    empty array.
    */
    set([], undefined);
  }, [entityId, set]);

  const client = useApiClient();
  const query = useQuery<ApiEntityChangeset[], Error>(
    ['entities', 'listChangeSets', entityId, schema],
    ({ queryKey }) => client.entities.listChangeSets(queryKey[2] as string),
    {
      onSuccess: (data) => {
        set(data, schema);
      },
    },
  );

  return { ...query, list, lookup };
};
