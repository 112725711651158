import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from 'react-query/devtools';

import Router from './Router';
import { mswWorker } from './gallery/mswroutes';
import Gallery from './gallery/Gallery';
import Providers from './providers/Providers';
import './index.css';
import { mock } from './hooks/useApiClient';
import { useSentry } from './sentry';

//MSW:
async function prepare(): Promise<unknown> {
  if (
    import.meta.env.MODE === 'galleryNoMsw' ||
    import.meta.env.MODE === 'gallery'
  ) {
    document.title = 'Mobilix Gallery';
  }
  if (import.meta.env.MODE === 'msw' || import.meta.env.MODE === 'gallery') {
    document.title += ' (MSW)';
    mock.mock = true;

    return mswWorker.start({
      onUnhandledRequest(req) {
        if (
          req.url.href.startsWith('http://localhost:3000') ||
          req.url.href.startsWith('http://localhost:3001')
        ) {
          //Requests to 3000 and 3001 are always ui development vite server itself, so always ignore.
          return;
        }
        if (req.url.href.startsWith('https://maps.g')) {
          //Ignore Google maps `maps.googleapis.com/..` and `maps.gstatic.com/..`
          return;
        }
        console.warn('Unhandled: %s %s', req.method, req.url.href);
      },
    });
  }
  return Promise.resolve(undefined);
}

const AppWithSentry: React.FC = () => {
  const { loaded } = useSentry();
  if (!loaded) {
    return null;
  }

  return (
    <Providers includeAuth={true}>
      <Router />
      <ReactQueryDevtools initialIsOpen={false} />
    </Providers>
  );
};

//GALLERY:
//The following is to enable MSW and gallery when in gallery mode.
//ALSO note that there is further custom code inside useApiClient to enable mocking of api auth.
prepare()
  .then(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        {import.meta.env.MODE === 'gallery' ||
        import.meta.env.MODE === 'galleryNoMsw' ? (
          <Providers includeAuth={false}>
            <Gallery />
          </Providers>
        ) : (
          <AppWithSentry />
        )}
      </React.StrictMode>,
    );
  })
  .catch((err) => {
    throw err;
  });

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       staleTime: 5000,
//     },
//   },
// });

// ReactDOM.render(
//   <React.StrictMode>
//     <ConfigProvider>
//       <ThemeProvider>
//         <BrowserRouter>
//           <Auth0ProviderWithHistory>
//             <QueryClientProvider client={queryClient}>
//               <LocaleProvider>
//                 <LocalizationProvider dateAdapter={AdapterLuxon} locale="sv-SE">
//                   <DndProvider backend={HTML5Backend}>
//                     <SnackbarProvider
//                       maxSnack={3}
//                       autoHideDuration={3000}
//                       anchorOrigin={{
//                         vertical: 'top',
//                         horizontal: 'center',
//                       }}
//                     >
//                       <Router />
//                       <ReactQueryDevtools initialIsOpen={false} />
//                     </SnackbarProvider>
//                   </DndProvider>
//                 </LocalizationProvider>
//               </LocaleProvider>
//             </QueryClientProvider>
//           </Auth0ProviderWithHistory>
//         </BrowserRouter>
//       </ThemeProvider>
//     </ConfigProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
