import { ApiUser } from '@allbin/mobilix-api-client';

import { createListAndLookupStore, reviveMetadata } from '../helpers/revivers';
import { useApiClient } from './useApiClient';
import { useQuery } from 'react-query';

export const reviveUser: ReviverFn<ApiUser, User> = (user) => ({
  ...user,
  meta: reviveMetadata(user.meta),
});

const useOrganizationUserListAndLookup = createListAndLookupStore(
  reviveUser,
  'organizationUsers',
  {
    /* Since we dont control the updated_at property of users,
     * the change detection feature of listAndLookupStore must be disabled for the state to be properly updated. */
    force_updates: true,
  },
);

export const useOrganizationUsers = (): UseListAndLookupQuery<
  ApiUser,
  User
> => {
  const { set, list, lookup } = useOrganizationUserListAndLookup();

  const client = useApiClient();
  const query = useQuery<ApiUser[], Error>(
    ['users', 'list'],
    () => client.users.list(),
    {
      onSuccess: (data) => {
        set(data);
      },
    },
  );
  return { ...query, list, lookup };
};
