import React, { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { useConfigStore } from '../store/config';

const Auth0ProviderWithHistory: React.FC<{
  enabled: boolean;
  children: ReactNode;
}> = ({ enabled, children }) => {
  const config = useConfigStore();
  const [params] = useSearchParams();

  const invitation = (params && params.get('invitation')) || undefined;
  const organization = (params && params.get('organization')) || undefined;

  if (!enabled) {
    return <>{children}</>;
  }

  // TODO cacheLocation="localstorage" is temporary for debugging purposes
  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      authorizationParams={{
        audience: config.auth0.audience,
        redirect_uri: `${window.location.origin}/callback`,
        invitation,
        organization,
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
