import { useEffect, useMemo } from 'react';
import useOrganizationStore, {
  OrganizationStore,
} from '../store/organizationStore';
import { usePublicApiClient } from './useApiClient';

const useOrganizations = (): Omit<OrganizationStore, 'setOrgs'> => {
  const client = usePublicApiClient();
  const { list, lookup, setOrgs } = useOrganizationStore();

  const orgs = useMemo(() => {
    return { list, lookup };
  }, [list, lookup]);

  useEffect(() => {
    client.tenants
      .list()
      .then((apiOrgs) => setOrgs(apiOrgs))
      .catch((err) => {
        throw err;
      });
    //Does not need to run more than once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return orgs;
};

export default useOrganizations;
