import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { ColumnSet, TableData } from '../components/Table';
import useTableStore from '../store/table';
import useLoadingStore from '../store/loading';
import { useConfigStore } from '../store/config';
import {
  csvFromTableData,
  xlsxWorkBookFromTableDatas,
} from '../helpers/table_helpers';
import Zip from 'jszip';
import FileSaver from 'file-saver';
import { write } from 'xlsx';
import { useUserProfiles } from './useUserProfiles';
import { useEntitySchema } from './useEntitySchema';

interface UseTable {
  /** Fetches and updates table store with column sets. */
  fetchColumnSets: () => Promise<void>;
  generateExport: (tableId: string) => void;
  getColumnSets: (colSetGroup: string) => ColumnSet[];
  getTable: (tableId: string) => TableData | undefined;
  removeColumnSet: (colSetGroup: string, columnSetId: string) => Promise<void>;
  removeTable: (tableId: string) => void;
  /** Updates the store with the new set optimistically and saves the set to the server. */
  saveColumnSet: (
    colSetGroup: string,
    title: string,
    columnKeys: string[],
  ) => Promise<void>;
  /** Updates the store optimistically and removes the set from the server. */
  setColumnVisibility: (
    entitySchema: EntitySchema,
    tableId: string,
    columnsToShow: string[],
  ) => void;
  setTable: (table: TableData) => void;
  /**
   * Returns a string for each tableId provided.
   *
   * If no tableIds are provided all tables are included.
   */
  generateTableCSV: (tableIds?: string[]) => string[];
}

const useTables = (): UseTable => {
  const {
    profile: { data: profile },
  } = useUserProfiles();
  const { data: schema } = useEntitySchema();
  const {
    columnSetsByGroup,
    removeColumnSetsGroup,
    removeTable,
    setColumnSets,
    setTable,
    tableById,
  } = useTableStore(
    useCallback(
      ({
        columnSetsByGroup,
        removeColumnSetsGroup,
        removeTable,
        setColumnSets,
        setTable,
        tableById,
      }) => ({
        columnSetsByGroup,
        removeColumnSetsGroup,
        removeTable,
        setColumnSets,
        setTable,
        tableById,
      }),
      [],
    ),
  );
  const { noHookApiCalls } = useConfigStore();
  const { addLoading, removeLoading } = useLoadingStore();

  const fetchColumnSets: UseTable['fetchColumnSets'] = useCallback(() => {
    if (noHookApiCalls) {
      console.warn('Tried to fetchColumnSets in no api call mode.');
      return Promise.resolve();
    }
    addLoading(true);
    //TODO: Remplace Promise.resolve with api call.
    return Promise.resolve().then(() => {
      //TODO: Run som revivers maybe.
      //TODO: Use setColumnSets(columnSets, group) for each group and colSets returned from api.
      removeLoading(true);
    });
  }, [noHookApiCalls, addLoading, removeLoading]);

  const generateTableCSV: UseTable['generateTableCSV'] = useCallback(
    (tableIds) => {
      if (!profile || !schema) {
        return [];
      }
      if (tableIds) {
        return tableIds.map((id) => {
          const tableData = tableById[id];
          if (!tableData) {
            throw new Error(`Generating CSV failed, table '${id}' not found.'`);
          }
          return csvFromTableData(schema, profile, tableData);
        });
      }
      return Object.values(tableById).map((tableData) =>
        csvFromTableData(schema, profile, tableData),
      );
    },
    [tableById, profile, schema],
  );

  const generateExport: UseTable['generateExport'] = useCallback(
    (tableId) => {
      if (!profile || !schema) {
        return;
      }
      const table = tableById[tableId];
      if (!table) {
        throw new Error(
          `Error exporting table; tableId '${tableId}' not found.`,
        );
      }
      if (!table.title || !table.description) {
        throw new Error(`Error exporting table; missing title or description.`);
      }
      const csv = generateTableCSV([tableId])[0];
      const zip = new Zip();
      zip.file('export.csv', csv);
      zip.file(
        'export.xlsx', //Follwing type conversion because zip.file and xlsx.write output don't agree.
        write(xlsxWorkBookFromTableDatas(schema, profile, [table]), {
          type: 'array',
        }) as unknown as null,
      );
      void zip
        .generateAsync({
          type: 'blob',
        })
        .then((blob) => {
          FileSaver.saveAs(blob, 'export.zip');
        });
    },
    [profile, schema, tableById, generateTableCSV],
  );

  const setColumnVisibility: UseTable['setColumnVisibility'] = useCallback(
    (schema, tableId, columnsToShow) => {
      const table = tableById[tableId];
      if (!table) {
        throw new Error(
          `Error updating table; tableId '${tableId}' not found.`,
        );
      }

      const updatedColumns = table.columns.map((column) => {
        if ('children' in column) {
          column.children.map((child) => {
            const schema_hidden = schema.extras[child.colId]?.hidden;
            return {
              ...child,
              hide:
                schema_hidden || child.colId
                  ? !columnsToShow.includes(child.colId)
                  : child.hide,
            };
          });
          return column;
        } else {
          const schema_hidden = schema.extras[column.colId]?.hidden;
          return {
            ...column,
            hide:
              schema_hidden || column.colId
                ? !columnsToShow.includes(column.colId)
                : true,
          };
        }
      });
      setTable({
        ...table,
        columns: updatedColumns,
      });
    },
    [setTable, tableById],
  );

  const getTable: UseTable['getTable'] = useCallback(
    (tableId) => {
      return tableById[tableId];
    },
    [tableById],
  );

  const getColumnSets: UseTable['getColumnSets'] = useCallback(
    (colSetGroup) => {
      return columnSetsByGroup[colSetGroup];
    },
    [columnSetsByGroup],
  );
  const saveColumnSet: UseTable['saveColumnSet'] = useCallback(
    (colSetGroup, title, columnKeys) => {
      const current = columnSetsByGroup[colSetGroup] || [];
      const colSet: ColumnSet = {
        group: colSetGroup,
        id: uuid(),
        title,
        columnKeys,
      };
      setColumnSets([...current, colSet], colSetGroup);

      if (noHookApiCalls) {
        return Promise.resolve();
      }

      addLoading(true);
      //TODO: Change Promise.resolve() to api request.
      return Promise.resolve()
        .catch(() => {
          //Request failed, we're not in sync with server. Reload column sets.
          return fetchColumnSets();
        })
        .finally(() => {
          removeLoading(true);
        });
    },
    [
      addLoading,
      columnSetsByGroup,
      noHookApiCalls,
      fetchColumnSets,
      removeLoading,
      setColumnSets,
    ],
  );
  const removeColumnSet: UseTable['removeColumnSet'] = useCallback(
    (colSetGroup, colSetId) => {
      const currentGroup = columnSetsByGroup[colSetGroup];
      if (!currentGroup) {
        throw new Error(
          `Error removing column set; columnSetGroup '${colSetGroup}' not in store.`,
        );
      }
      const groupSets = [...currentGroup];
      const index = groupSets.findIndex((colSet) => colSet.id === colSetId);
      if (index === -1) {
        throw new Error(
          `Error removing column set; columnSetId '${colSetId}' not group '${colSetGroup}'.`,
        );
      }

      groupSets.splice(index, 1);
      if (groupSets.length === 0) {
        //All sets have been removed from the group.
        removeColumnSetsGroup(colSetGroup);
      } else {
        setColumnSets(groupSets, colSetGroup);
      }

      if (noHookApiCalls) {
        // console.log('No api calls');

        return Promise.resolve();
      }

      addLoading(true);
      //TODO: Change Promise.resolve() to api request.
      return Promise.resolve()
        .catch(() => {
          //Request failed, we're not in sync with server. Reload column sets.
          return fetchColumnSets();
        })
        .finally(() => {
          removeLoading(true);
        });
    },
    [
      addLoading,
      columnSetsByGroup,
      noHookApiCalls,
      fetchColumnSets,
      removeColumnSetsGroup,
      removeLoading,
      setColumnSets,
    ],
  );

  return {
    fetchColumnSets,
    removeColumnSet,
    generateExport,
    generateTableCSV,
    getColumnSets,
    getTable,
    removeTable,
    saveColumnSet,
    setColumnVisibility,
    setTable,
  };
};

export default useTables;
