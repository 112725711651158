import React, { FC, useState } from 'react';
import {
  Box,
  IconButton,
  Skeleton,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { DeleteOutline, EditOutlined, Flag, Room } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import EntityListItemERTooltip from './EntityListItemERTooltip';
import { useEntitySchema } from '../../hooks/useEntitySchema';
import ErrorReportDialog from '../ErrorReportDialog/ErrorReportDialog';
import Permissions from '../auth/Permissions';

interface EntityListItemProps {
  entity: Entity;
  errorReports?: ErrorReport[];
  sx?: SxProps<Theme>;
  onRemoveClick?: () => void;
  onEditClick?: () => void;
  onLocationClick?: () => void;
  /** A color used as background for the odd elements. */
  oddRowBGColor?: string;
  /** To disable default tooltip set it to empty string. */
  locationTooltip?: React.ReactFragment;
  /** To disable default tooltip set it to empty string. */
  removeTooltip?: React.ReactFragment;
  /** To disable default tooltip set it to empty string. */
  editTooltip?: React.ReactFragment;
}

const EntityListItem: FC<EntityListItemProps> = ({
  entity,
  sx,
  errorReports,
  onRemoveClick,
  onEditClick,
  onLocationClick,
  oddRowBGColor,
  locationTooltip,
  removeTooltip,
  editTooltip,
}) => {
  const { data: schema } = useEntitySchema();
  const [showERDialog, setShowERDialog] = useState<boolean>(false);

  if (!schema) {
    return <Skeleton width={'100%'} height={'38px'} />;
  }

  return (
    <>
      {showERDialog && errorReports && (
        <ErrorReportDialog
          open={showERDialog}
          entities={[entity]}
          onClose={() => setShowERDialog(false)}
          schema={schema}
          errorReports={errorReports}
        />
      )}
      <Box
        sx={{
          width: '100%',
          paddingTop: 1,
          paddingBottom: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: '34px',
          overflow: 'hidden',
          minWidth: 0,
          gap: 1,
          '&:nth-child(even)': {
            backgroundColor: oddRowBGColor ? oddRowBGColor : 'initial',
          },
          ...sx,
        }}
      >
        {onLocationClick && (
          <Tooltip
            arrow
            title={
              locationTooltip !== undefined ? (
                locationTooltip
              ) : (
                <FormattedMessage
                  id="entity_list_item.location_tooltip"
                  defaultMessage="Go to station details for {station_name}"
                  values={{
                    station_name: entity.properties['meta.name'] as string,
                  }}
                />
              )
            }
          >
            <IconButton onClick={onLocationClick} size="small">
              <Room color="secondary" />
            </IconButton>
          </Tooltip>
        )}
        {errorReports && errorReports.length > 0 && (
          <Tooltip
            arrow
            enterDelay={80}
            title={
              <EntityListItemERTooltip
                schema={schema}
                errorReports={errorReports}
              />
            }
          >
            <div>
              <Permissions oneOf={['error-reports:create']}>
                <IconButton onClick={() => setShowERDialog(true)} size="small">
                  <Flag color="error" />
                </IconButton>
              </Permissions>
              <Permissions noneOf={['error-reports:create']}>
                <Flag color="error" />
              </Permissions>
            </div>
          </Tooltip>
        )}
        <Typography
          sx={{ minWidth: 0, flexGrow: 0 }}
          noWrap
          variant="subtitle1"
        >
          {entity.full_name}
        </Typography>
        <Typography
          noWrap
          sx={{ flexGrow: 1, minWidth: 0 }}
          color="text.secondary"
          component="span"
        >
          {entity.properties['meta.id'] as string}
        </Typography>
        {entity.properties['meta.active'] === false && (
          <Typography
            noWrap
            sx={{ flexGrow: 0, minWidth: 0 }}
            color="text.secondary"
            component="span"
            variant="subtitle2"
          >
            <FormattedMessage id="inactive" defaultMessage="Inactive" />
          </Typography>
        )}
        {onEditClick && (
          <Tooltip
            arrow
            title={
              editTooltip !== undefined ? (
                editTooltip
              ) : (
                <FormattedMessage
                  id="entity_list_item.edit_tooltip"
                  defaultMessage="Edit stop {stop_name}"
                  values={{
                    stop_name: entity.full_name,
                  }}
                />
              )
            }
          >
            <IconButton onClick={onEditClick} size="small">
              <EditOutlined />
            </IconButton>
          </Tooltip>
        )}
        {onRemoveClick && (
          <Tooltip
            arrow
            title={
              removeTooltip !== undefined ? (
                removeTooltip
              ) : (
                <FormattedMessage
                  id="entity_list_item.remove_tooltip"
                  defaultMessage="Remove stop {stop_name} from list"
                  values={{
                    stop_name: entity.full_name,
                  }}
                />
              )
            }
          >
            <IconButton onClick={onRemoveClick} size="small">
              <DeleteOutline color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export default EntityListItem;
