import React from 'react';
import { createSvgIcon, SvgIconProps } from '@mui/material';

const Component = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4Z"
      stroke="#141E29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'SquareUnchecked',
);

const SquareUnchecked: React.FC<SvgIconProps> = (props) => {
  return <Component {...props} />;
};

export default SquareUnchecked;
