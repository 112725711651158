import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface EditRecurringWorkOrderDialogStore {
  show: boolean;
  open: () => void;
  /** If clear is true the dialog state will be emptied. */
  close: (clear?: boolean) => void;
  selectedFilterSetId: string;
  setSelectedFilterSetId: (id: string) => void;
  selectedPlan?: RecurringPlan;
  setSelectedPlan: (plan: RecurringPlan | undefined) => void;
}

const useRecurringWorkOrderPlanManagementDialogStore =
  create<EditRecurringWorkOrderDialogStore>()(
    devtools(
      (set) => ({
        show: false,
        selectedFilterSetId: '',
        open: () => {
          set({ show: true });
        },
        close: (clear) => {
          if (clear) {
            set({
              show: false,
              selectedFilterSetId: '',
              selectedPlan: undefined,
            });
            return;
          }
          set({ show: false });
        },
        setSelectedFilterSetId: (id) => {
          set({ selectedFilterSetId: id });
        },
        setSelectedPlan: (plan) => {
          set({ selectedPlan: plan });
        },
      }),
      {
        name: 'recurring-plan-management-dialog-store',
        enabled: process.env.NODE_ENV !== 'test',
      },
    ),
  );

export default useRecurringWorkOrderPlanManagementDialogStore;
