import React from 'react';
import { createSvgIcon } from '@mui/material';

import { ContainedIcon, ContainedIconProps } from './ContainedIcon';

const Component = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.828 9.828C11.3875 9.26864 11.7686 8.55591 11.9231 7.77995C12.0775 7.00399 11.9984 6.19967 11.6956 5.46869C11.3929 4.73772 10.8802 4.11293 10.2224 3.67335C9.56459 3.23377 8.79118 2.99915 8 2.99915C7.20883 2.99915 6.43542 3.23377 5.7776 3.67335C5.11977 4.11293 4.60708 4.73772 4.30437 5.46869C4.00165 6.19967 3.92251 7.00399 4.07695 7.77995C4.23139 8.55591 4.61247 9.26864 5.172 9.828L8 12.657L10.828 9.828Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 7V7.01" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.828 17.828C19.3875 17.2686 19.7686 16.5559 19.9231 15.78C20.0775 15.004 19.9983 14.1997 19.6956 13.4687C19.3929 12.7377 18.8802 12.1129 18.2224 11.6734C17.5646 11.2338 16.7912 10.9991 16 10.9991C15.2088 10.9991 14.4354 11.2338 13.7776 11.6734C13.1198 12.1129 12.6071 12.7377 12.3044 13.4687C12.0017 14.1997 11.9225 15.004 12.0769 15.78C12.2314 16.5559 12.6125 17.2686 13.172 17.828L16 20.657L18.828 17.828Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16 15V15.01" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Entities',
);

const Entities: React.FC<ContainedIconProps> = (props) => {
  const defaultIfCircleProps = props.circleColor
    ? { width: 16, height: 16 }
    : {};
  return (
    <ContainedIcon {...props}>
      <Component
        sx={{
          ...defaultIfCircleProps,
          ...props.svgSx,
        }}
      />
    </ContainedIcon>
  );
};

export default Entities;
