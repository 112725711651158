import { defineMessages } from '@formatjs/intl';
import { useTheme, Grid, Typography } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import React, { FC, useRef, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Dropdown from './Inputs/Dropdown';
import { ColorProps } from '../providers/ThemeProvider';

// Register chart drawables: bar, line chart, etc
Chart.register(...registerables);

export enum ChartPresentation {
  HOUR = 'hour',
  WEEK = 'week',
  WEEK_DAY = 'weekDay',
  MONTH = 'month',
}

const messages = defineMessages({
  weekDay: {
    id: 'weekDay',
    defaultMessage: 'week day',
  },
  week: {
    id: 'week',
    defaultMessage: 'week',
  },
  hour: {
    id: 'hour',
    defaultMessage: 'hour',
  },
  month: {
    id: 'month',
    defaultMessage: 'month',
  },
});

const chartTypes = {
  hour: {
    id: ChartPresentation.HOUR,
    label: messages.hour.id,
  },
  week: { id: ChartPresentation.WEEK, label: messages.week.id },
  weekDay: { id: ChartPresentation.WEEK_DAY, label: messages.weekDay.id },
  month: {
    id: ChartPresentation.MONTH,
    label: messages.month.id,
  },
};

export interface ChartConfiguration {
  datasets: string[];
  labels: string[];
}

interface ChartComponentProps {
  /** Title for the chart */
  title: string;
  /** Configuration with alternatives. Data will be selected through key name*/
  chartConfig: {
    weekDay: ChartConfiguration;
    week: ChartConfiguration;
    hour: ChartConfiguration;
    month: ChartConfiguration;
  };
  /** Theme palette
   *  Default: Traffic */
  palette?: ColorProps;
}

/**
 * Chart component
 *
 * Note: the chart keeps aspects ration when changing width/height
 *
 */

const ChartComponent: FC<ChartComponentProps> = ({
  title,
  chartConfig,
  palette,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const colors = palette ?? theme.palette.traffic;

  const [selectedType, setType] = useState<ChartPresentation>(
    ChartPresentation.HOUR,
  );

  const changeType = (selectedIds: string[]): void => {
    setType(selectedIds[0] as ChartPresentation);
  };

  const mappedAlternatives = useMemo(() => {
    return Object.values(chartTypes).map((value) => {
      return { ...value, label: intl.formatMessage({ id: value.id }) };
    });
  }, [intl]);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext('2d');
    if (!ctx) return;

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: chartConfig[selectedType].labels,
        datasets: [
          {
            label: title,
            data: chartConfig[selectedType].datasets,
            backgroundColor: [colors.main],
            hoverBackgroundColor: [colors.light],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [colors, title, selectedType, chartConfig]);

  return (
    <Grid container padding={4}>
      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="end"
      >
        <Typography variant="subtitle1">{`${title} (per ${intl.formatMessage({
          id: chartTypes[selectedType].label,
        })})`}</Typography>
        <Dropdown
          placeholder="Presentation"
          selectedIds={[selectedType]}
          onSelect={changeType}
          alternatives={mappedAlternatives}
          palette={colors}
        />
      </Grid>
      <canvas id="myChart" ref={chartRef}>
        Your browser does not support the canvas element.
      </canvas>
    </Grid>
  );
};

export default ChartComponent;
