import { ApiEntityChangeSetRequest } from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { dateFormat } from '../helpers/datetime_helpers';

const id1 = v4();
const id2 = v4();
export const tdGetEntityChangesets = (
  id: string,
  props: Entity['properties'],
): ApiEntityChangeset[] => {
  return [
    {
      id: id1,
      tenant_id: 'vl',
      entity_id: id,
      meta: {
        created_at: DateTime.fromFormat('2021-04-02', dateFormat).toISO(),
        created_by: 'auth0|6172bc995cd96b0070339cc7',
        updated_at: DateTime.fromFormat('2021-04-02', dateFormat).toISO(),
      },
      properties: {
        ...props,
        'inventory.1': false,
        'inventory.2': false,
        'inventory.3': 'Klack',
        'inventory.4': true,
        'inventory.6': 18,
      },
    },
    {
      id: id2,
      prev_changeset_id: id1,
      tenant_id: 'vl',
      entity_id: id,
      meta: {
        created_at: DateTime.fromFormat('2021-05-20', dateFormat).toISO(),
        created_by: 'auth0|6172bc995cd96b0070339cc7',
        updated_at: DateTime.fromFormat('2021-05-20', dateFormat).toISO(),
      },
      properties: {
        ...props,
        'inventory.1': true,
        'inventory.2': false,
        'inventory.3': 'Körbana',
        'inventory.5': 'Asfalt',
      },
    },
  ];
};

export const tdGetApiEntityChangesetsRequests = (
  id: string,
  props: Entity['properties'],
): ApiEntityChangeSetRequest[] => {
  return [
    {
      entity_id: id,
      properties: {
        ...props,
        'inventory.1': false,
        'inventory.2': false,
        'inventory.3': 'Klack',
        'inventory.4': true,
        'inventory.6': 18,
      },
    },
    {
      entity_id: id,
      properties: {
        ...props,
        'inventory.1': true,
        'inventory.2': false,
        'inventory.3': 'Körbana',
        'inventory.5': 'Asfalt',
      },
    },
  ];
};
