import React, { useEffect, useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';
import { Box, LinearProgress } from '@mui/material';

const FetchIndicator: React.FC = () => {
  const isFetching = useIsFetching();

  const [max, setMax] = useState(0);
  useEffect(() => {
    if (isFetching === 0) {
      setMax(0);
      return;
    }
    if (isFetching > max) {
      setMax(isFetching);
    }
  }, [isFetching, max]);

  const progress = useMemo(
    () => (max > 0 ? 100 - (isFetching / max) * 100 : 0),
    [isFetching, max],
  );

  return (
    <Box
      hidden={isFetching === 0}
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      zIndex={999999}
    >
      <LinearProgress color="primary" value={progress} />
    </Box>
  );
};

export default FetchIndicator;
