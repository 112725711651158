import { useCallback } from 'react';
import { executeFilters } from '../helpers/entity_logic_helpers';
import { useEntities } from './useEntities';
import useTables from './useTables';
import {
  filterIndicatorSorter,
  tableColumnsFromSchemaProps,
} from '../helpers/table_helpers';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Column, ColumnGroup } from '../components/Table';
import useFilterStore from '../store/filter';
import mapStore from '../store/map';
import entityOverviewStore from '../store/entityOverview';
import FilterIndicator from '../components/Table/FilterIndicator';
import { ValueGetterParams } from 'ag-grid-community';
import { defaultProfile, useUserProfiles } from './useUserProfiles';

const messages = defineMessages({
  filter_header: {
    id: 'table.filter_header',
    defaultMessage: 'Filter',
  },
  entity_table: {
    id: 'table.entity_table',
    defaultMessage: 'Stop table',
  },
  noHelpText: {
    id: 'no_help_text_defined',
    defaultMessage: 'No help text defined',
  },
});

interface UseFiltering {
  /** Execute the provided filters against entities in store.
   * includeUnmatched indicates if entities that do not meet first filter are included in the results.
   *
   * Results are stored in 'results', with a reference to the provided filters. */
  filter: (
    schema: EntitySchema,
    filters: ExecutionFilter[],
    showUnmatched?: boolean,
    showInactive?: boolean,
  ) => void;
  executionFilters: ExecutionFilter[];
  toggleFilterActivity: (index: number) => void;

  results: ExtendedFilterQueryResults;
  /** Indicates if entities that do not meet first filter should be displayed. */
  showUnmatched: boolean;
  showInactive: boolean;
}

export const calculateFilteredEntities = (
  entities: Entity[],
  entityLookup: Record<string, Entity>,
  executionFilters: ExecutionFilter[],
  stopEntitySchema: EntitySchema,
  showInactive = false,
): FilterQueryResults => {
  if (entities.length === 0 || executionFilters === undefined) {
    return {
      final: [],
      steps: [],
    };
  }

  return executeFilters(
    stopEntitySchema.definition,
    showInactive
      ? entities
      : entities.filter((e) => e.properties['meta.active'] !== false),
    executionFilters,
  );
};

/**
 * Add filter row to existing columns
 */
const getColumns = (
  results: FilterQueryResults,
  intl: IntlShape,
  profile: UserProfile,
  schema: EntitySchema,
  onEntityLocationMarkerClick: (entity: Entity) => void,
): (Column<Entity> | ColumnGroup<Entity>)[] => {
  const columns = tableColumnsFromSchemaProps(schema, intl, profile);
  columns.unshift({
    colId: 'filterIndicator',
    format: 'filterIndicator',
    headerName: intl.formatMessage(messages.filter_header),
    headerTooltip: `${
      schema.extras['filter']?.help_text ||
      intl.formatMessage(messages.noHelpText)
    }`,
    valueGetter: (params: ValueGetterParams) => {
      // TODO: if step - 1 is a disabled filter, maybe keep going backwards
      const idx = results.steps.findIndex(({ unmatched }) =>
        unmatched.some((e) => e.id === params.data.id),
      );

      const startAt = idx === -1 ? results.steps.length - 1 : idx - 1;

      for (let i = startAt; i >= 0; i--) {
        if (results.steps[i].filter.active) {
          return i;
        }
      }
      return -1;
    },
    width: 100,
    cellRenderer: FilterIndicator,
    onClick: (data) => {
      onEntityLocationMarkerClick(data);
    },
    comparator: filterIndicatorSorter,
    exportable: false,
  });
  return columns;
};

const useFiltering = (): UseFiltering => {
  const { list: entities, lookup: entityLookup } = useEntities();
  const intl = useIntl();
  const {
    profile: { data: profile },
  } = useUserProfiles();

  const { setTable } = useTables();
  const { setPartial } = mapStore();
  const { setTab: setEntityOverviewTab } = entityOverviewStore();
  const {
    executionFilters,
    results,
    setExecutionFilters,
    setResults,
    setShowUnmatched,
    setShowInactive,
    showUnmatched,
    showInactive,
  } = useFilterStore(
    useCallback(
      ({
        executionFilters,
        results,
        setExecutionFilters,
        setResults,
        setShowUnmatched,
        setShowInactive,
        showUnmatched,
        showInactive,
      }) => ({
        executionFilters,
        results,
        setExecutionFilters,
        setResults,
        setShowUnmatched,
        setShowInactive,
        showUnmatched,
        showInactive,
      }),
      [],
    ),
  );

  const setCenterZoom = useCallback(
    (entity: Entity) => {
      const location = entity.properties['meta.location'] as [number, number];
      const center = {
        lng: location[0],
        lat: location[1],
      };
      setPartial({ id: 'EntityOverview', center, zoom: 16 });
      setEntityOverviewTab('map');
    },
    [setEntityOverviewTab, setPartial],
  );

  const toggleFilterActivity: UseFiltering['toggleFilterActivity'] =
    useCallback(
      (index) => {
        const currentFilter = executionFilters[index];
        const replacedFilter = {
          ...currentFilter,
          active: !currentFilter.active,
        };

        executionFilters[index] = replacedFilter;
        setExecutionFilters(executionFilters);
      },
      [executionFilters, setExecutionFilters],
    );

  const filter: UseFiltering['filter'] = useCallback(
    (schema, filters, showUnmatched = false, showInactive = false) => {
      setExecutionFilters(filters);
      setShowUnmatched(showUnmatched);
      setShowInactive(showInactive);

      const results = calculateFilteredEntities(
        entities,
        entityLookup,
        filters,
        schema,
        showInactive,
      );

      const extendedResults = { ...results, showUnmatched, showInactive };
      setResults(extendedResults);
      const columns = getColumns(
        results,
        intl,
        profile ?? defaultProfile,
        schema,
        setCenterZoom,
      );

      setTable({
        id: 'entityTable',
        title: intl.formatMessage(messages.entity_table),
        description: ['TODO'],
        data: showUnmatched ? entities : results.final,
        columns,
      });
    },
    [
      setExecutionFilters,
      setShowUnmatched,
      setShowInactive,
      entities,
      entityLookup,
      setResults,
      intl,
      profile,
      setCenterZoom,
      setTable,
    ],
  );

  return {
    filter,
    executionFilters,
    results,
    showUnmatched,
    showInactive,
    toggleFilterActivity,
  };
};

export default useFiltering;
