import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { defaultValue } from './FilterConditionInput';
import { Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { translations } from '../translations';

export const typeOperators: Record<
  FilterCondition['type'],
  FilterCondition['operator'][]
> = {
  boolean: ['known', 'unknown', 'true', 'false'],
  date: ['known', 'unknown', 'before', 'after', 'between', 'not_between'],
  enum: ['known', 'unknown', 'eq', 'neq', 'any_of', 'none_of'],
  number: [
    'known',
    'unknown',
    'eq',
    'neq',
    'gt',
    'gte',
    'lt',
    'lte',
    'between',
    'not_between',
    'none_of',
    'any_of',
  ],
  string: [
    'known',
    'unknown',
    'eq',
    'neq',
    'matches',
    'not_matches',
    'none_of',
    'any_of',
  ],
  location: ['known', 'unknown'],
  photo: ['known', 'unknown'],
  'array:number': ['known', 'unknown', 'none_of', 'any_of', 'all_of'],
  'array:string': ['known', 'unknown', 'none_of', 'any_of', 'all_of'],
};

const FilterConditionOperatorInput: React.FC<{
  filterCondition: FilterCondition;
  schema: EntitySchema;
  onChange: (filterCondition: FilterCondition) => void;
}> = ({ filterCondition, schema, onChange }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const operators: FilterCondition['operator'][] = useMemo(
    () => typeOperators[filterCondition.type],
    [filterCondition.type],
  );

  const handleChange = useCallback(
    (operator: FilterCondition['operator']) => {
      const newFilterCondition = {
        ...filterCondition,
        operator,
        value: defaultValue(
          filterCondition.type,
          operator,
          schema.definition.propertiesLookup[filterCondition.field]
            .alternatives,
        ),
      } as FilterCondition;

      setAnchorEl(null);
      // TODO reset value if operator demands it (eq to known and such)
      onChange(newFilterCondition);
    },
    [filterCondition, schema.definition.propertiesLookup, onChange],
  );

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        data-testid="operator-button"
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {intl.formatMessage(translations[filterCondition.operator]) ||
          filterCondition.operator}
      </Button>
      <Menu
        data-testid="operator-dropdown"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {operators.map((operator) => (
          <MenuItem
            data-testid="operator-option"
            key={operator}
            value={operator}
            onClick={() => handleChange(operator)}
          >
            {intl.formatMessage(translations[operator]) || operator}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterConditionOperatorInput;
