import { Box, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { FC, useRef, useState } from 'react';
import TopListSettings, {
  TopListDataSettings,
} from '../../../components/TopList/TopListSettings';
import { GalleryToggle } from '../../GalleryToggle';

const yesterday = DateTime.now()
  .set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  .minus({ day: 1 });

const GTopListSettings: FC = () => {
  const theme = useTheme();
  const anchorEl = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(false);
  const [settings, setSettings] = useState<TopListDataSettings>({
    dateRange: 'previous_7_days',
    entryCount: 5,
    minDate: yesterday.minus({ days: 7 }),
    maxDate: yesterday,
  });

  return (
    <Box>
      <Box display="flex">
        <GalleryToggle
          label="Open"
          active={open}
          onClick={() => setOpen(true)}
        />
      </Box>
      <Box
        ref={anchorEl}
        sx={{ m: 5, width: '40px', height: '40px', border: '1px solid blue' }}
      >
        {anchorEl.current && (
          <TopListSettings
            open={open}
            onClose={(settings) => {
              console.log('TopListSettings: onClose:', settings);
              setSettings(settings);
              setOpen(false);
            }}
            colors={theme.palette.traffic}
            anchorEl={anchorEl.current}
            title="Settings title"
            settings={settings}
          />
        )}
      </Box>
    </Box>
  );
};

export default GTopListSettings;
