import React from 'react';

import { Autocomplete, Chip, TextField } from '@mui/material';

interface CreatableMultiselectProps {
  label?: string;
  placeholder?: string;
  values: string[];
  staticValues?: string[];
  onChange: (values: string[]) => void;
}

const CreatableMultiSelect: React.FC<CreatableMultiselectProps> = ({
  label,
  placeholder,
  staticValues,
  values,
  onChange,
}) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={values}
      onChange={(x, items) => {
        const newValues = [
          ...(items as string[]).filter(
            (x) => !(staticValues || []).includes(x),
          ),
        ];
        const allValues = [...(staticValues || []), ...newValues];
        onChange(allValues);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label={label || ''}
          placeholder={placeholder}
        />
      )}
      renderTags={(value, getTagProps) =>
        values.map((val, index) => (
          <Chip
            variant="outlined"
            label={val}
            {...getTagProps({ index })}
            {...((staticValues || []).includes(val)
              ? { onDelete: undefined }
              : {})}
            key={val}
          />
        ))
      }
    />
  );
};

export default CreatableMultiSelect;
