import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ApiTenant } from '@allbin/mobilix-api-client';

export type Organization = ApiTenant;

export interface OrganizationStore {
  setOrgs: (orgs: ApiTenant[]) => void;
  list: Organization[];
  lookup: Record<string, Organization>;
}

const useOrganizationStore = create<OrganizationStore>()(
  devtools(
    (set) => ({
      list: [],
      lookup: {},
      setOrgs: (orgs) => {
        set({
          list: orgs,
          lookup: orgs.reduce<Record<string, Organization>>((acc, org) => {
            acc[org.organization_id] = org;
            return acc;
          }, {}),
        });
      },
    }),
    { name: 'organization-store', enabled: process.env.NODE_ENV !== 'test' },
  ),
);

export default useOrganizationStore;
