import { ApiErrorReport } from '@allbin/mobilix-api-client';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import { tdGetEntities } from './entities';
import { dateFormat } from '../helpers/datetime_helpers';

export const tdErrorReports: ApiErrorReport[] = [
  {
    id: v4(),
    meta: {
      created_by: 'auth0|6138bc632c6a45006940ff70',
      created_at: DateTime.fromFormat('2021-08-03', dateFormat).toISO(),
      updated_at: DateTime.fromFormat('2021-08-03', dateFormat).toISO(),
      deleted_at: DateTime.fromFormat('2021-08-06', dateFormat).toISO(),
      deleted_by: 'auth0|6138bc632c6a45006940ff70',
    },
    tenant_id: '',
    destination: 'example@example.com',
    message: 'This is an error report message text.',
    entity_id: tdGetEntities()[0].id,
    property_key: 'inventory.9',
  },
  {
    id: v4(),
    meta: {
      created_by: 'auth0|6138bc632c6a45006940ff70',
      created_at: DateTime.now().toISO(),
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: 'nothing',
    destination: 'example@example.com',
    message: 'Attribute inventory.9 error report message text.',
    entity_id: tdGetEntities()[0].id,
    property_key: 'inventory.9',
  },
  {
    id: v4(),
    meta: {
      created_by: 'auth0|6138bc632c6a45006940ff70',
      created_at: DateTime.now().minus({ days: 20 }).toISO(),
      updated_at: DateTime.now().minus({ days: 20 }).toISO(),
    },
    tenant_id: 'whatever',
    destination: 'example@example.com',
    message:
      'This is an error report message text for inventory.13. It was broken like 16 day ago by a cat-wielding granny. This is an error report message text for inventory.13.',
    entity_id: tdGetEntities()[0].id,
    property_key: 'inventory.13',
  },
  {
    id: v4(),
    meta: {
      created_by: 'auth0|6138bc632c6a45006940ff70',
      created_at: DateTime.now().toISO(),
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: '',
    destination: 'http://www.trafikverket.se',
    message: 'This is an error report message text.',
    entity_id: tdGetEntities()[1].id,
    property_key: 'inventory.3',
  },
  {
    id: v4(),
    meta: {
      created_by: 'auth0|6138bc632c6a45006940ff70',
      created_at: DateTime.now().toISO(),
      updated_at: DateTime.now().toISO(),
    },
    tenant_id: '',
    destination: 'http://www.trafikverket.se',
    message: 'This is an error report message text.',
    entity_id: tdGetEntities()[5].id,
    property_key: 'inventory.3',
  },
];
